/**
 * @ngdoc controller
 * @memberof dugun.attachments
 * @name AttachmentUploadCtrl
 *
 * @requires $scope
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires $timeout
 * @requires dugun.notifications:dgNotifications
 * @requires AttachmentUpload
 *
 * @param itemId
 */
function AttachmentUploadCtrl(
    $scope, $uibModalInstance, $timeout, dgNotifications,
    AttachmentUpload,
    itemId,
    $filter
) {

    $scope.customerId = itemId;
    $scope.data = {
        providerId: null
    };
    $scope.list = [];

    /**
     * @ngdoc method
     * @memberof AttachmentUploadCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.close();
        var filteredProvider = $filter('filter')($scope.providersActive, {id: $scope.data.providerId}, true)[0];
        dataLayer.push(
            {
                event: 'addAttachments',
                provider: {
                    id: filteredProvider.id,
                    name: filteredProvider.name,
                    category: $scope.getProviderCategory(filteredProvider.categoryId),
                    cityId: $scope.getCity(filteredProvider.cityId)
                }
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Uploads the selected Attachment
     */
    $scope.uploadAttachments = function($files) {
        if (!$scope.data.providerId && $scope.user.providers.length === 1) {
            $scope.data.providerId = $scope.user.providerIds[0];
        }

        angular.forEach($files, function(file) {
            file.upload = AttachmentUpload.upload(file, {customerId: $scope.customerId, providerId: $scope.data.providerId});
            file.progress = 0;

            file.upload.then(
                function(successResponse) {
                    $timeout(function() {
                        file.result = successResponse.data;
                    });
                },
                function(errorResponse) {
                    dgNotifications.error(errorResponse);
                },
                function(event) {
                    file.progress = (event.loaded / event.total) * 100;
                }
            );

            $scope.list.push(file);
        });
    };
}

AttachmentUploadCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$timeout',
    'dgNotifications',
    'AttachmentUpload',
    'itemId',
    '$filter'
];

angular.module('dugun.attachments')
    .controller('AttachmentUploadCtrl', AttachmentUploadCtrl);
