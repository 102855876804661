function CustomerMenuCtrl($scope, $uibModalInstance, $filter, $translate, $timeout, $q, $window,
                          moment, itemId, data, dgNotifications, CustomerMenu) {
    $scope.id = itemId ? itemId : null;
    $scope.customerId = $scope.user.customerId;
    $scope.loading = [];
    $scope.providerCategory = {};
    $scope.activeTabIndex = 0;
    $scope.data = data || null;
    $scope.isChanged = false;
    $scope.stepProgress = $scope.id ? 0 : 1;
    $scope.dataLayerSectionsItems = [];
    $translate.onReady().then(function () {
        $scope.approvalStatuses = {
            'approved': {
                class: 'success',
                text: $translate.instant('CUSTOMER_MENU.APPROVED')
            },
            'rejected': {
                class: 'danger',
                text: $translate.instant('CUSTOMER_MENU.REJECTED')
            },
            'pending': {
                class: 'warning',
                text: $translate.instant('CUSTOMER_MENU.PENDING')
            },
            'auto_pending': {
                class: 'warning',
                text: $translate.instant('CUSTOMER_MENU.PENDING')
            }
        };
    });
    $scope.options = {
        steps: [],
        doneSteps: 1
    };
    $scope.cleanData = {
        menu: {},
        pricing: {},
        menuSections: {},
        menuSectionItems: {},
        details: {},
    };
    $scope.dirtyData = {
        menu: {
            active: false,
            provider: {id: $scope.data.providerId}
        },
        pricing: {
            includeTax: 0,
            currency: $scope.environment.payment.currencyId
        },
        menuSections: {
            description: {}
        },
        menuSectionItems: {},
        details: {
            radio: {},
            multicheck: {}
        }
    };
    $scope.copyOfPricing = angular.copy($scope.dirtyData.pricing);
    $scope.tabs = [];
    $scope.pricingType = $scope.getConfig('customer_menu_pricing_type');

    $scope.close = function (data) {
        if (data) $uibModalInstance.close(data);
        else $uibModalInstance.dismiss('cancel');
    };

    $scope.pricingType = $scope.getConfig('customer_menu_pricing_type');

    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('GENERAL.UNSAVED_INFO_WARNING');

        if(reason === 'success' || ($scope.stepProgress && !$scope.id)) {
            return;
        }

        if ($scope.id && !$scope.stepProgress) {
            return;
        }

        if (reason === true) {
            return;
        }

        switch (reason){
            // escape key
            case "escape key press":
                break;
            case "cancel":
                break;
        }

        if (!confirm(message)) {
            event.preventDefault();
        } else {
            $scope.deleteCustomerMenu();
        }
    });

    $scope.setCustomerMenu = function () {
        var urlData = {
            customerId: $scope.customerId
        }, method = 'insert', data, menuTypeId, customerMenuTypeId;

        if ($scope.id) {
            method = 'update';
            urlData.customerMenuId = $scope.id;
        }

        if ($scope.data.mode && $scope.data.mode === 'copy') {
            data = $scope.dirtyData.menu;
        } else {
            data = $scope.dirtyData.menu.getDiff($scope.cleanData.menu);
        }

        if (data.provider && !data.provider.id) {
            delete data.provider;
        }

        if (data.menuType) {
            menuTypeId = data.menuType.id;
            delete data.menuType;
        }

        var deferred = $q.defer();
        var loading = CustomerMenu[method](
            urlData,
            data,
            function (success) {
                if ($scope.data.mode !== 'copy') {
                    dgNotifications.success($translate.instant('GENERAL.ADDED_SUCCESSFULLY'));
                }

                if ($scope.stepProgress && method === 'insert') {
                    angular.forEach($scope.dirtyData.menu['menuTypeIds[]'], function (id) {
                        $scope.setCustomerMenuTypes(id);
                    });
                }

                if ($scope.stepProgress) {
                    $scope.selectedTab = $scope.tabs[$scope.options.doneSteps - 1];
                    $scope.options.doneSteps++;
                }

                if (!$scope.id) {
                    $scope.id = success.id;
                    $scope.setCustomerMenuTypes(menuTypeId);
                } else {
                    $scope.updateCustomerMenuTypes($scope.customerMenuType.id, menuTypeId);
                }

                $scope.activeTabIndex++;
                $scope.isChanged = true;

                deferred.resolve();
                $scope.getMenuTypes();

                var provider = $filter('filter')($scope.providersActive, {id: $scope.dirtyData.menu.provider.id}, true)[0];
                dataLayer.push(
                    {
                        provider: provider ? {
                            id: provider.id,
                            name: provider.name,
                            city: $scope.getCity(provider.cityId),
                            category: $scope.getProviderCategory(provider.categoryId)
                        } : null,
                        menu: {
                            type: $filter('filter')($scope.menuTypes, {id: $scope.dirtyData.menu.menuType.id})[0].name,
                            name: $scope.dirtyData.menu.name,
                            status: $scope.dirtyData.menu.active
                        }
                    });
            },
            function (error) {
                dgNotifications.error(error);
                deferred.reject(error);
            }
        );
        $scope.loading.push(loading);

        return deferred.promise;
    };

    $scope.getCustomerMenu = function () {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: $scope.id
        };
        var loading = CustomerMenu.get(
            urlParams,
            function (success) {
                $scope.cleanData.menu = success.data;
                $scope.dirtyData.menu = angular.copy($scope.cleanData.menu);
                $scope.customerMenuType = angular.copy($scope.dirtyData.menu.menuTypes[0]);
                $scope.getCustomerMenuTypes();
                $scope.getCustomerMenuPricing();
                $scope.getMenuTypes();
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
        $scope.loading.push(loading);
    };

    $scope.getCustomerMenuTypes = function () {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: $scope.id
        };

        CustomerMenu.getCustomerMenuTypes(
            urlParams,
            function (success) {
                $scope.customerMenuType = success.data[0] || {};
                $scope.dirtyData.menu.menuType = $scope.customerMenuType.menuType;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
    };

    $scope.setCustomerMenuTypes = function (menuTypeId) {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: $scope.id
        };

        var data = {
            menuType: {
                id: menuTypeId
            }
        };

        CustomerMenu.setCustomerMenuTypes(
            urlParams,
            data,
            function (success) {
                $scope.customerMenuType = {
                    id: success.id
                };
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
    };

    $scope.updateCustomerMenuTypes = function (customerMenuTypeId, menuTypeId) {
        var urlParams = {
                customerId: $scope.customerId,
                customerMenuId: $scope.id,
                typeId: customerMenuTypeId
            },
            data = {
                menuType: {
                    id: menuTypeId
                }
            };

        CustomerMenu.updateCustomerMenuTypes(
            urlParams,
            data,
            function (success) {

            },
            function (error) {
                dgNotifications.error(error);
            }
        );
    };

    $scope.getMenuTypes = function () {
        CustomerMenu.getMenuTypes(
            {
                providerCategoryId: $scope.providerCategory.id
            },
            function (success) {
                $scope.menuTypes = success.data;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
    };

    $scope.$watch("dirtyData.menu.menuType.id", function (newId, oldId) {
        arrangeTabs([]);
        if (newId) {
            $scope.getMenuSectionGroups();
        }
    });

    $scope.getMenuSectionGroups = function () {
        $scope.menuSectionGroups = [];
        $scope.tabs = [];

        if (!$scope.dirtyData.menu.menuType) return;

        var urlParams = {
            'menuTypeIds[]': $scope.dirtyData.menu.menuType.id
        };
        var loading = CustomerMenu.getMenuSectionGroups(
            urlParams,
            function (success) {
                $scope.menuSectionGroups = $filter('orderBy')(success.data, 'orderNumber');
                arrangeTabs($scope.menuSectionGroups);
                if ($scope.id) {
                    $scope.getCustomerMenuSection();
                }
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
        $scope.loading.push(loading);
    };

    function arrangeTabs(menuSectionGroups) {
        $scope.tabs = [];
        $scope.options.steps = [];
        $scope.options.steps.push(
            $translate.instant('CUSTOMER_MENU.GENERAL_INFO')
        );
        $scope.menuSectionsByFieldType = {};
        $scope.menuSectionsByGroupId = {};
        angular.forEach(menuSectionGroups, function (value, key) {
            if (!$scope.menuSectionsByFieldType[JSON.parse(value.sectionType).fieldType]) {
                $scope.menuSectionsByFieldType[JSON.parse(value.sectionType).fieldType] = [];
            }

            if (!$scope.menuSectionsByGroupId[value.id]) {
                $scope.menuSectionsByGroupId[value.id] = [];
            }

            for (var i in value.menuSections) {
                $scope.menuSectionsByGroupId[value.id].push(value.menuSections[i].id);
            }

            $scope.menuSectionsByFieldType[JSON.parse(value.sectionType).fieldType] = $scope.menuSectionsByFieldType[JSON.parse(value.sectionType).fieldType].concat(value.menuSections);
            $scope.tabs.push({
                name: value.name,
                template: 'customer-menu/menu-section.html',
                data: value.menuSections,
                properties: JSON.parse(value.sectionType),
                groupId: value.id
            });
            $scope.options.steps.push(
                value.name
            );
        });
        $scope.options.steps.push(
            $translate.instant('CUSTOMER_MENU.PRICING')
        );
    }

    $scope.getMenuSections = function () {
        var urlParams = {
            providerCategoryId: $scope.providerCategory.id,
            isActive: true
        };
        var loading = CustomerMenu.getMenuSections(
            urlParams,
            function (success) {
                $scope.menuSections = success.data;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
        $scope.loading.push(loading);
    };

    $scope.getMenuSectionItems = function () {
        var urlParams = {
            menuSectionId: 1,
            isActive: true
        };
        var loading = CustomerMenu.getMenuSectionItems(
            urlParams,
            function (success) {
                $scope.menuSectionItems = success.data;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
        $scope.loading.push(loading);
    };

    $scope.getCustomerMenuSection = function () {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: $scope.id
        };
        var loading = CustomerMenu.getCustomerMenuSections(
            urlParams,
            function (success) {
                $scope.customerMenuSections = success.data;
                $scope.dirtyData.details = modifyResponse(success.data);
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
        $scope.loading.push(loading);
    };


    function modifyResponse(customerMenuSections) {
        var preparedSectionData = {
            radio: {},
            multicheck: {}
        }, preparedItemData = {}, radioSection=[], multicheckSection=[], groupId;

        angular.forEach(customerMenuSections, function (section) {
            radioSection = $filter('filter')($scope.menuSectionsByFieldType.radio || [], {id: section.menuSection.id}, true);
            multicheckSection = $filter('filter')($scope.menuSectionsByFieldType.multicheck || [], {id: section.menuSection.id}, true);
            preparedItemData = {};

            for (var i in $scope.menuSectionsByGroupId) {
                if ($scope.menuSectionsByGroupId[i].indexOf(section.menuSection.id) !== -1) {
                    groupId = i;
                }
            }

            if (!preparedSectionData.radio[groupId])
                preparedSectionData.radio[groupId] = {};

            if (!preparedSectionData.multicheck[groupId])
                preparedSectionData.multicheck[groupId] = {};

            if (radioSection.length) {
                angular.forEach(section.customerMenuSectionItems, function (item) {
                    preparedItemData[item.menuSectionItem.id] = item;
                    preparedItemData[item.menuSectionItem.id].id = item.menuSectionItem.id;
                    delete preparedItemData[item.menuSectionItem.id].menuSectionItem;
                });

                preparedSectionData.radio[groupId][section.menuSection.id] = section;
                preparedSectionData.radio[groupId][section.menuSection.id].id = section.menuSection.id;
                preparedSectionData.radio[groupId][section.menuSection.id].items = preparedItemData;
                delete preparedSectionData.radio[groupId][section.menuSection.id].customerMenuSectionItems;
                delete preparedSectionData.radio[groupId][section.menuSection.id].menuSection;
            }

            if (multicheckSection.length) {
                angular.forEach(section.customerMenuSectionItems, function (item) {
                    preparedItemData[item.menuSectionItem.id] = item;
                    preparedItemData[item.menuSectionItem.id].id = item.menuSectionItem.id;
                    delete preparedItemData[item.menuSectionItem.id].menuSectionItem;
                });
                preparedSectionData.multicheck[groupId][section.menuSection.id] = section;
                preparedSectionData.multicheck[groupId][section.menuSection.id].id = section.menuSection.id;
                preparedSectionData.multicheck[groupId][section.menuSection.id].items = preparedItemData;
                delete preparedSectionData.multicheck[groupId][section.menuSection.id].customerMenuSectionItems;
                delete preparedSectionData.multicheck[groupId][section.menuSection.id].menuSection;
            }
        });

        return preparedSectionData;
    }

    $scope.checkedMenuSection = function (menuSection) {
        var radioData = angular.copy($scope.dirtyData.details.radio);
        var multicheckData = angular.copy($scope.dirtyData.details.multicheck);
        var checkedMenuSections = preparePostSectionItemsData(radioData)
            .concat(preparePostSectionItemsData(multicheckData));

        return $filter('filter')(checkedMenuSections, {id: menuSection.id}, true);
    };

    $scope.setCustomerMenuPricing = function () {
        var data = $scope.dirtyData.pricing.getDiff($scope.cleanData.pricing),
            method = 'insertCustomerMenuPricing',
            urlParams = {
                customerId: $scope.customerId,
                customerMenuId: $scope.id
            };

        if ($scope.data.mode && $scope.data.mode === 'copy') {
            data = $scope.dirtyData.pricing;
        }

        if ($scope.dirtyData.pricing.id) {
            method = 'updateCustomerMenuPricing';
            urlParams.id = $scope.dirtyData.pricing.id;
        }

        if (typeof data.includeTax !== 'undefined') {
            data.includeTax = data.includeTax ? 1 : 0;
        }

        if ($scope.getProviderCategoryDetail($scope.providerCategory.id).categoryType !== 'venue') {
            data.minCapacity = 1;
        }

        var loading = CustomerMenu[method](
            urlParams,
            data,
            function (success) {
                if ($scope.data.mode !== 'copy') {
                    dgNotifications.success($translate.instant('GENERAL.ADDED_SUCCESSFULLY'));
                }

                $scope.getCustomerMenuPricing();
                $scope.options.doneSteps++;

                var provider = $filter('filter')($scope.providersActive, {id: $scope.dirtyData.menu.provider.id}, true)[0];
                dataLayer.push({
                    event: 'pricing',
                    provider: provider ? {
                        id: provider.id,
                        name: provider.name,
                        city: $scope.getCity(provider.cityId),
                        category: $scope.getProviderCategory(provider.categoryId)
                    } : null,
                    validFrom: data.validFrom,
                    validUntil: data.validUntil,
                    price: data.price + $scope.getConfigItem('currency', data.currency),
                    minCapacity: data.minCapacity,
                    includeTax: data.includeTax,
                    description: data.description
                });

                if ($scope.stepProgress) {
                    $scope.close($scope.isChanged);
                }
            },
            function (error) {
                $scope.pricingErrors = error.data.errors;
                dgNotifications.error(error);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.getCustomerMenuPricing = function () {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: $scope.id
        };
        var loading = CustomerMenu.getCustomerMenuPricing(
            urlParams,
            function (success) {
                $scope.customerMenuPricingList = success.data;
                $scope.customerMenuPricingTotal = success.meta.total;
                $scope.isOpenPricingForm = !success.meta.total;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.customerMenuPricingDetail = function (index) {
        $scope.cleanData.pricing = $scope.customerMenuPricingList[index];
        $scope.cleanData.pricing.validFrom = moment($scope.cleanData.pricing.validFrom).format('YYYY-MM-DD');
        $scope.cleanData.pricing.validUntil = moment($scope.cleanData.pricing.validUntil).format('YYYY-MM-DD');
        $scope.cleanData.pricing.currency = parseInt($scope.cleanData.pricing.currency);
        $scope.dirtyData.pricing = angular.copy($scope.cleanData.pricing);
        $scope.isOpenPricingForm = true;
        $scope.pricingErrors = [];
    };

    $scope.deleteCustomerMenuPricing = function (item, index) {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: $scope.id,
            id: item.id
        };
        CustomerMenu.deleteCustomerMenuPricing(
            urlParams,
            function (success) {
                dgNotifications.success($translate.instant('GENERAL.DELETED'));
                $scope.customerMenuPricingList.splice(index, 1);
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
    };

    $scope.isOpenPricingForm = true;
    $scope.showPricingForm = function () {
        $scope.isOpenPricingForm = !$scope.isOpenPricingForm;
    };

    $scope.closePricingForm = function () {
        $scope.isOpenPricingForm = !$scope.isOpenPricingForm;
        $scope.dirtyData.pricing = $scope.copyOfPricing;
    };

    $translate.onReady().then(function () {
        $scope.weekDays = {
            'MON': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.MON'),
            'TUES': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.TUES'),
            'WED': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.WED'),
            'THURS': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.THURS'),
            'FRI':  $translate.instant('CUSTOMER_MENU.WEEK_DAYS.FRI'),
            'SAT':  $translate.instant('CUSTOMER_MENU.WEEK_DAYS.SAT'),
            'SUN': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.SUN')
        };
    });

    $scope.selectTab = function (tabIndex) {
        $scope.selectedTab = $scope.tabs[tabIndex];
        $scope.activeTabIndex = tabIndex + 1;
        $scope.collapsedStatus = 'up';
    };

    $scope.next = function () {
        $scope.selectedTab = $scope.tabs[$scope.options.doneSteps - 1];
        $scope.options.doneSteps++;

        if (angular.equals($scope.dirtyData.details.sections, {})) return;

        if ($scope.options.doneSteps > 2 && $scope.options.doneSteps <= $scope.options.steps.length) {
            $scope.setSectionsItems();
        }
    };

    $scope.skip = function () {
        $scope.selectedTab = $scope.tabs[$scope.options.doneSteps - 1];
        $scope.options.doneSteps++;
    };

    $scope.prev = function () {
        $scope.options.doneSteps--;
        $scope.selectedTab = $scope.tabs[$scope.options.doneSteps - 2];
    };

    $scope.focusOnInput = function (inputId) {
        document.getElementById(inputId).focus();
    };

    $scope.setSectionsItems = function () {
        var radioData = angular.copy($scope.dirtyData.details.radio);
        var multicheckData = angular.copy($scope.dirtyData.details.multicheck);

        var urlParams = {
                customerId: $scope.customerId,
                customerMenuId: $scope.id
            },
            data = {
                sections: preparePostSectionItemsData(radioData)
                    .concat(preparePostSectionItemsData(multicheckData))
            };

        var loading = CustomerMenu.insertCustomerMenuSectionsItems(
            urlParams,
            data,
            function (success) {
                if ($scope.data.mode !== 'copy') {
                    dgNotifications.success($translate.instant('GENERAL.ADDED_SUCCESSFULLY'));
                }
                var provider = $filter('filter')($scope.providersActive, {id: $scope.dirtyData.menu.provider.id}, true)[0];
                dataLayer.push({
                    event: 'saveSectionsItems',
                    menu: $scope.dataLayerSectionsItems,
                    provider: provider ? {
                        id: provider.id,
                        name: provider.name,
                        city: $scope.getCity(provider.cityId),
                        category: $scope.getProviderCategory(provider.categoryId)
                    } : null,
                });
                $scope.activeTabIndex++;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.uncheckedSections = {};
    $scope.checkSections = function (menuSection, groupId) {
        if (!$scope.dirtyData.details.multicheck[groupId]) {
            $scope.dirtyData.details.multicheck[groupId] = {};
        }

        if ($scope.dirtyData.details.multicheck[groupId][menuSection.id]) {
            $scope.uncheckedSections[menuSection.id] = angular.copy($scope.dirtyData.details.multicheck[groupId][menuSection.id]);
            delete $scope.dirtyData.details.multicheck[groupId][menuSection.id];
        } else {
            if (!angular.equals($scope.uncheckedSections, {}) && $scope.uncheckedSections[menuSection.id]) {
                $scope.dirtyData.details.multicheck[groupId][menuSection.id] = $scope.uncheckedSections[menuSection.id];
            } else {
                $scope.dirtyData.details.multicheck[groupId][menuSection.id] = {
                    id: menuSection.id,
                    name: menuSection.name,
                    active: true,
                    orderNumber: menuSection.orderNumber,
                    description: '',
                    items: {}
                };
            }

            $timeout(function () {
                $scope.focusOnInput('menu-section-input-' + menuSection.id);
            }, 500);
        }
    };

    $scope.checkSection = function (menuSection, groupId) {
        $scope.dirtyData.details.radio[groupId] = {};
        $scope.dirtyData.details.radio[groupId][menuSection.id] = {
            id: menuSection.id,
            name: menuSection.name,
            active: true,
            orderNumber: menuSection.orderNumber,
            description: '',
            items: {}
        };

        $timeout(function () {
            $scope.focusOnInput('menu-section-input-' + menuSection.id);
        }, 1000);
    };

    $scope.checkSectionItems = function (menuSection, menuSectionItem, fieldType, groupId) {
        if ($scope.dirtyData.details[fieldType][groupId][menuSection.id].items[menuSectionItem.id]) {
            delete $scope.dirtyData.details[fieldType][groupId][menuSection.id].items[menuSectionItem.id];
        } else {
            $scope.dirtyData.details[fieldType][groupId][menuSection.id].items[menuSectionItem.id] = {
                id: menuSectionItem.id,
                name: menuSectionItem.name,
                active: true,
                orderNumber: menuSectionItem.orderNumber,
            };
            $timeout(function () {
                $scope.focusOnInput('menu-section-item-input-' + menuSectionItem.id);
            }, 500);
        }
    };

    $scope.checkSectionItem = function (menuSection, menuSectionItem, fieldType, groupId) {
        $scope.dirtyData.details[fieldType][groupId][menuSection.id].items = {};
        $scope.dirtyData.details[fieldType][groupId][menuSection.id].items[menuSectionItem.id] = {
            id: menuSectionItem.id,
            name: menuSectionItem.name,
            active: true,
            orderNumber: menuSectionItem.orderNumber,
        };
        $timeout(function () {
            $scope.focusOnInput('menu-section-item-input-' + menuSectionItem.id);
        }, 500);
    };

    $scope.copyCustomerMenu = function () {
        $scope.id = null;
        $scope.dirtyData.menu.name = $translate.instant('CUSTOMER_MENU.COPY') + '-' + $scope.dirtyData.menu.name;
        delete $scope.dirtyData.menu.adminUser;
        delete $scope.dirtyData.menu.customerMenuPricings;
        delete $scope.dirtyData.menu.customerMenuSections;
        $q.all([
            $scope.setCustomerMenu()
        ]).then(function () {
            $scope.setSectionsItems();
            angular.forEach($scope.customerMenuPricingList, function (pricing) {
                pricing.id = null;
                $scope.dirtyData.pricing = pricing;
                $scope.setCustomerMenuPricing();
            });
            dgNotifications.success($translate.instant('GENERAL.COPIED'));
            $scope.close($scope.isChanged);
        });
    };

    function preparePostSectionItemsData (groups) {
        var sections = {}, i, j;
        for (i in groups) {
            for (j in groups[i]){
                sections[j] = groups[i][j];
            }
        }

        var preparedData = [], jsonString;
        for (i in sections) {
            if (angular.equals(sections[i].items, {})) {
                sections[i].items = [];
            } else {
                jsonString = JSON.stringify(sections[i].items).trim();
                jsonString = jsonString.replace(/(\"[0-9]{1,}\"\:\{)/g, '{');
                jsonString = jsonString.replace('{{', '[{');
                jsonString = jsonString.replace('}}', '}]');
                sections[i].items = JSON.parse(jsonString);
            }
            preparedData.push(sections[i]);

            $scope.dataLayerSectionsItems.push(
                {
                    label: sections[i].name,
                    selectedValues: sections[i].items
                }
            );
        }

        return preparedData;
    }

    $scope.collapsedSections = [];
    $scope.collapseSection = function (id) {
        var index = $scope.collapsedSections.indexOf(id);
        if (index !== -1) {
            $scope.collapsedSections.splice(index, 1);
        } else {
            $scope.collapsedSections.push(id);
        }
    };

    $scope.collapsedStatus = 'up';
    $scope.collapseSections = function (menuSections, status) {
        if (status === 'down') {
            $scope.collapsedSections = [];
            $scope.collapsedStatus = 'up';
        } else {
            for (var i in menuSections) {
                if ($scope.collapsedSections.indexOf(menuSections[i].id) === -1){
                    $scope.collapsedSections.push(menuSections[i].id);
                    $scope.collapsedStatus = 'down';
                }
            }
        }
    };

    $scope.showCollapseButton = function (menuSections) {
        var show = false;
        for (var i in menuSections) {
            show = $scope.checkedMenuSection(menuSections[i]).length;
            if (show) break;
        }

        return show;
    };

    $scope.deleteCustomerMenu = function () {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: $scope.id
        };

        CustomerMenu.delete(
            urlParams,
            function (success) {
                dgNotifications.success($translate.instant('GENERAL.DELETED'));
                $scope.close(true);
            },
            function (error) {
                dgNotifications.success(error);
            }
        );
    };

    $scope.$watch('providerCategory.id', function (providerCategoryId) {
        if (providerCategoryId) {
            $scope.getMenuTypes();
        }
    });

    $scope.$watch('dirtyData.menu.provider.id', function (providerId) {
        if (providerId) {
            var provider = $filter('filter')($scope.providersActive, {id: providerId})[0];
            $scope.providerCategory.id = provider.categoryId;
            $scope.getMenuTypes();
        }
    });

    $scope.validUntilOptions = {};
    $scope.$watch('dirtyData.pricing.validFrom', function (date) {
        if (date) {
            $scope.validUntilOptions.minDate = new Date(date);
        }
    });

    $scope.weddingValidUntilOptions = {};
    $scope.$watch('dirtyData.pricing.weddingValidFrom', function (date) {
        if (date) {
            $scope.weddingValidUntilOptions.minDate = new Date(date);
        }
    });

    if ($scope.id) {
        $scope.getCustomerMenu();
    }
}

CustomerMenuCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$filter',
    '$translate',
    '$timeout',
    '$q',
    '$window',
    'moment',
    'itemId',
    'data',
    'dgNotifications',
    'CustomerMenu',
];

angular.module('dugun.customerMenu').controller('CustomerMenuCtrl', CustomerMenuCtrl);
