function DgFormProviderService($http) {
    function Service() {
        var self = {};

        self.loading = false;

        self.providers = function(searchText, filters, options) {
            if(!searchText) {
                self.providersResult = [];
            }

            options = options || {};
            filters = filters || {};

            if(angular.isUndefined(options.minimumInputLength)) {
                options.minimumInputLength = 1;
            }

            if(options.minimumInputLength > 0 && (!searchText || searchText.length < options.minimumInputLength) && angular.equals(filters, {})) {
                return false;
            }

            self.loading = true;

            filters = filters || {};
            filters.text = searchText;

            $http
                .get('search/provider', {params: filters})
                .then(function(response) {
                    self.providersResult = response.data.data;

                    if(options && options.createOption && self.providersResult.length === 0) {
                        self.providersResult.push({
                            name: searchText,
                            id: searchText
                        });
                    }
                    self.loading = false;
                }, function(errorResponse) {
                    self.loading = false;
                });
        };

        self.providersResult = [];

        return self;
    }

    return Service;
}

DgFormProviderService.$inject = [
    '$http',
];

angular.module('dugun.forms')
    .factory('DgFormProviderService', DgFormProviderService);
