function Auth($http, $cookies, $q, $rootScope, resource, environmentConfig) {
    var accessToken = null;
    var url = environmentConfig.api.url + 'access-tokens';
    var fbLoginURL = environmentConfig.api.url + 'auth/facebook';
    var googleLoginURL = environmentConfig.api.url + 'auth/google';
    var consumerId = environmentConfig.api.consumerKey;

    var accessTokenResource = resource(
        'access-tokens/:accessToken',
        {
            accessToken: function() {
                if($cookies.get('switch_user') && $cookies.get('admin_access_token')) {
                    return $cookies.get('admin_access_token');
                } else {
                    return $cookies.get('access_token');
                }
            }
        }
    );

    function getAccessTokenFromCookie() {
        var token = $cookies.get('access_token');
        if(token) {
            accessToken = token;
            $rootScope.accessToken = token;
            $rootScope.consumerId = consumerId;

            // For select2 directives
            window.accessToken = token;
            window.consumerId = consumerId;
        }
    }

    function getAccessToken(username, password) {
        var deferred = $q.defer();
        $http
            .post(url, {
                username: username,
                password: password,
                userType: 'customer'
            })
            .then(function(response) {
                if (!environmentConfig.fieldsSettings.passwordRenew) {
                    response.data.accessToken.passwordChangeRequired = false;
                }

                if (!response.data.accessToken.passwordChangeRequired) {
                    accessToken = response.data.accessToken.id;
                    if($cookies.get('access_token')) {
                        $cookies.remove('access_token');
                    }
                    var date = new Date();
                    date.setDate(date.getDate() + 2);
                    $cookies.put('access_token', accessToken, { 'expires': date });
                }
                deferred.resolve(response.data);
            }, function(response) {
                deferred.reject(response);
            });
        return deferred.promise;
    }

    function fbLogin(accessToken) {
        var deferred = $q.defer();
        $http
            .post(fbLoginURL, {
                accessToken: accessToken,
                userType: 'customer'
            })
            .then(function(response) {
                accessToken = response.data.accessToken.id;
                if($cookies.get('access_token')) {
                    $cookies.remove('access_token');
                }
                var date = new Date();
                date.setDate(date.getDate() + 2);
                $cookies.put('access_token', accessToken, { 'expires': date });
                deferred.resolve(response.data.accessToken);
            }, function(response) {
                deferred.reject(response);
            });
        return deferred.promise;
    }


    function googleLogin(data) {
        var deferred = $q.defer();
        $http
            .post(googleLoginURL, data)
            .then(function(response) {
                if (response.data.success) {
                    accessToken = response.data.data.id;
                    if($cookies.get('access_token')) {
                        $cookies.remove('access_token');
                    }
                    var yearAfter = new Date();
                    yearAfter.setDate(yearAfter.getDate() + 2);
                    $cookies.put('access_token', accessToken, { 'expires': yearAfter });
                }

                deferred.resolve(response.data);
            }, function(response) {
                deferred.reject(response);
            });
        return deferred.promise;
    }

    function deleteAccessToken(successCallback, errorCallback) {
        var deferred = $q.defer();
        accessTokenResource.delete(
            function(successResponse) {
                $cookies.remove('access_token');
                successCallback(successResponse);
                deferred.resolve(successResponse);
            },
            function(errorResponse) {
                errorCallback(errorResponse);
                deferred.reject(errorResponse);
            }
        );
        return deferred.promise;
    }

    function isAuthenticated() {
        return !!accessToken;
    }

    getAccessTokenFromCookie();

    return {
        getAccessToken: getAccessToken,
        fbLogin: fbLogin,
        googleLogin: googleLogin,
        isAuthenticated: isAuthenticated,
        getUser: accessTokenResource.get,
        deleteAccessToken: deleteAccessToken
    };
}

Auth.$inject = [
    '$http',
    '$cookies',
    '$q',
    '$rootScope',
    'resource',
    'environmentConfig'
];

angular.module('dugun.core')
    .factory('Auth', Auth);
