function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function PaymentsRoutes($routeProvider) {
    $routeProvider
        .when('/payments', {
            templateUrl: 'payments/list.html',
            controller: 'PaymentsListCtrl',
            label: 'PAYMENTS.PAYMENTS',
            reloadOnSearch: false,
            resolve: {
                init: userResolver
            }
        })
        .when('/payments/:paymentId', {
            templateUrl: 'payments/form.html',
            controller: 'PaymentCtrl',
            label: 'PAYMENTS.PAY_NOW',
            reloadOnSearch: false,
            resolve: {
                init: userResolver
            }
        });
}

PaymentsRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.payments').config(PaymentsRoutes);
