angular.module('dugun.payments')
    .factory('Payments', [
        'resource',
        Payments
    ]);

function Payments(resource) {
    var url = 'payments/:id';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof Payments
             * @name update
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Update Payment
             */
            update: {
                url: url,
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Payments
             * @name insert
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Insert Payment
             */
            insert: {
                url: url,
                method: 'POST'
            },
        }
    );

    return resourceService;
}
