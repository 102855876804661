function DgFormStatusSlider() {
    return {
        scope: {
            model: '=ngModel',
            trueLabel: '=trueLabel',
            falseLabel: '=falseLabel',
            spaceBetweenLabels: '=spaceBetweenLabels',
            ngDisabled: '=ngDisabled'
        },
        templateUrl: 'dg-forms/form-elements/status/slider.html',
        link: function (scope, element, attrs) {
            scope.label = scope.trueLabel;
            for (var i = parseInt(scope.spaceBetweenLabels); i--; i >= 0) {
                scope.label = scope.label + ' __ ';
            }
            scope.label = scope.label + scope.falseLabel;
        }
    };
}

angular.module('dugun.forms').directive('dgFormStatusSlider', DgFormStatusSlider);
