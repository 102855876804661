function DiscountRoutesLoader($rootScope, $q, $window) {
    if (!$rootScope.environment.fieldsSettings.discount) $window.location.href = '/home';

    var deferred = $q.defer();
    $q.all($rootScope.loadingBase).finally(function() {
        deferred.resolve();
    });
    return deferred.promise;
}

DiscountRoutesLoader.$inject = [
    '$rootScope',
    '$q',
    '$window'
];

function DiscountRoutes($routeProvider) {
    $routeProvider
        .when('/discounts', {
            templateUrl: 'discounts/list.html',
            controller: 'DiscountListCtrl',
            label: 'DISCOUNTS.DISCOUNTS',
            resolve: {
                loader: DiscountRoutesLoader
            },
            reloadOnSearch: false
        });
}

DiscountRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.discounts').config(DiscountRoutes);
