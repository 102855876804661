/**
 * Providers factory
 * @ngdoc service
 * @name Providers
 * @memberof dugun.customers.providers.provider
 * @requires dugun.core:resource
 * @requires angular-cache:CacheFactory
 *
 * @returns {service} Providers resource service
 */
function Providers(resource, CacheFactory) {
    var url = 'providers/:id';
    var urlData = 'provider-data/:id';

    function getProviderFormCache() {
        return CacheFactory.get('coupleStatistics') || CacheFactory('coupleStatistics', {
            maxAge: 24*60*60*1000 // 24 hours
        });
    }

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof Providers
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get all additional data of provider
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name update
             * @param params {Object} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Update all additional data of provider
             */
            update: {
                url: url,
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name getMissingAdditionalData
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Gets missing additional data of provider
             */
            getMissingAdditionalData: {
                url: urlData + '/missing-data',
                method: 'GET',
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name getAdditionalData
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Gets additional data of provider
             */
            getAdditionalData: {
                url: urlData,
                method: 'GET',
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name setAdditionalData
             * @param params {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Updates additional data of provider
             */
            setAdditionalData: {
                url: urlData,
                method: 'PUT',
                params: {
                    additionalData: []
                }
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name getForm
             * @param params {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Gets the related provider form elements
             */
            getForm: {
                url: urlData + '/provider-forms',
                method: 'GET',
                isArray: false,
                params: {
                    id: '@id'
                },
                cache: getProviderFormCache()
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name getRedirectNumber
             * @param urlParams {Object=} Data in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Gets the number to redirect in inbound calls for provider
             */
            getRedirectNumber: {
                url: url + '/redirect-number',
                method: 'GET',
                params: {
                    id: '@id'
                },
                transformResponse: function (response) {
                    try {
                        response = JSON.parse(response);
                    }
                    catch (e) {
                    }

                    if (!response.data) return {};

                    var result = angular.copy(response.data);

                    result.providerNoWeddingNumber = result.providerNoWeddingNumber ? result.providerNoWeddingNumber.number : null;
                    result.inboundNumber = result.inboundNumber ? result.inboundNumber.number : null;
                    result.providerNumber = result.providerNumber ? result.providerNumber.number : null;
                    response.data = result;

                    return response;
                }
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name setRedirectNumber
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Sets the number to redirect in inbound calls for provider
             */
            setRedirectNumber: {
                url: url + '/redirect-number/:inboundNumderId',
                method: 'PUT',
                params: {
                    id: '@id',
                    inboundNumderId: '@inboundNumderId'
                }
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name getMainImage
             * @param urlParams {Object=} Data in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Gets the main image for provider
             */
            getMainImage: {
                url: url + '/main-image',
                method: 'GET',
                params: {
                    id: '@id'
                }
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name getProducts
             * @param urlParams {Object=} Data in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Gets the products of the provider
             */
            getProducts: {
                url: 'provider-product-price',
                method: 'GET',
            },
            /**
             * @ngdoc method
             * @memberof Providers
             * @name getProducts
             * @param urlParams {Object=} Data in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * actions
             */
            actions: {
                url: url + '/actions',
                method: 'POST',
            },
        }
    );

    return resourceService;
}

Providers.$inject = [
    'resource',
    'CacheFactory'
];

angular.module('dugun.customers.providers.provider')
    .factory('Providers', Providers);
