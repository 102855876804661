(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/lead-messages/components/message.html',
    '<span><div class="name-circle"><div class="name-text"><span title="{{ leadData.leadDetails.name }}" ng-if="item.data.extra.sender == \'couple\' && leadData.leadDetails.name">{{getFirstLetters(leadData.leadDetails.name)}}</span> <span title="{{ item.data.extra.customerUser ? item.data.extra.customerUser.name : \'\' }}" ng-if="item.data.extra.sender == \'provider\' && !providerMainImage">{{ getFirstLetters(item.data.extra.customerUser ? item.data.extra.customerUser.name : \'\') }} </span><img title="{{ item.data.extra.customerUser ? item.data.extra.customerUser.name : \'\' }}" ng-if="item.data.extra.sender == \'provider\' && providerMainImage" class="provider-thumbnail-img img-circle" src="{{providerMainImage}}" width="40" height="40"></div></div><div class="name-message"><label ng-if="item.data.extra.sender === \'couple\' && !leadData.couple.partnerName" ng-bind="leadData.leadDetails.name" class="text-bold" style="display: block"></label><label ng-if="item.data.extra.sender === \'couple\' && leadData.couple.partnerName" ng-bind="leadData.leadDetails.name + \' & \' + leadData.couple.partnerName" class="text-bold" style="display: block"></label><label ng-if="item.data.extra.sender === \'provider\'" ng-bind="item.data.extra.customerUser.name" class="text-bold" style="display: block"></label><span ng-if="item.data.extra.body" ng-bind-html="item.data.extra.body | nl2br"></span><ul ng-if="item.data.extra.attachments" class="attachments-list"><li ng-repeat="attachment in item.data.extra.attachments"><i class="fa fa-paperclip"></i>&nbsp; <a target="_blank" ng-href="{{imageUrl + \'/\' + attachment.path }}">{{attachment.name}}</a></li></ul></div></span>');
}]);
})();
