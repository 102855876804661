angular.module('dugun.helpers.dgSentenceCase')
    .directive('dgSentenceCase', dgSentenceCase);

function dgSentenceCase(environmentConfig) {
    return {
        require: 'ngModel',
        scope: {
            ngModel: "="
        },
        link: function (scope, element, attrs, modelCtrl) {
            scope.$watch("ngModel", function () {
                if(scope.ngModel) {
                    scope.ngModel = scope.ngModel.replace(/([^\x00-\x7F]|\w)\S*/g, function(txt){return txt.charAt(0).toLocaleUpperCase(environmentConfig.languages.default) + txt.substr(1).toLocaleLowerCase(environmentConfig.languages.default);});
                }
            });
        }
    };
}

dgSentenceCase.$inject = [
    'environmentConfig'
];