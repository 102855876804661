(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/provider/provider.html',
    '<ui-select class="three-line full-width" ng-attr-id="id || \'providerId\'" ng-model="$parent.model" theme="select2" search-enabled="false" on-select="selected($item, $model)" ng-disabled="ngDisabled()"><ui-select-match placeholder="{{ attrs.placeholder || (\'DG_FORMS.CHOOSE_PROVIDER\' | translate) }}" allow-clear="{{ allowClear() }}"><p><span ng-bind="$select.selected.name"></span></p><p><span ng-bind="getProviderCategory($select.selected.categoryId)"></span></p><p><small ng-bind="getCity($select.selected.cityId)"></small></p></ui-select-match><ui-select-choices repeat="item.id as item in finalOptions"><p><strong ng-bind-html="item.name | highlight: $select.search"></strong></p><p><span ng-bind="getProviderCategory(item.categoryId)"></span></p><p><small ng-bind="getCity(item.cityId)"></small></p></ui-select-choices></ui-select>');
}]);
})();
