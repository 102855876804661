angular.module('dugun.testimonials')
    .factory('Testimonials', [
        'resource',
        Testimonials
    ]);

function Testimonials(resource) {
    var url = 'provider-testimonial/:id';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name query
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get Testimonial List
             */
            query: {
                url: url,
                method: 'GET',
                transformResponse: function (response) {
                    try {
                        response = JSON.parse(response);
                    }
                    catch (e) {
                    }

                    var result = response.data;
                    angular.forEach(result, function (item) {
                        item.phone = item.phone ? item.phone.number : null;
                    });
                    response.data = result;
                    return response;
                }
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name get
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get Testimonial
             */
            get: {
                url: url,
                method: 'GET',
                transformResponse: function (response) {
                    try {
                        response = JSON.parse(response);
                    }
                    catch (e) {
                    }

                    var result = response.data;
                    result.phone = result.phone ? result.phone.number : null;

                    response.data = result;

                    return response;
                }
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name update
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Update Testimonial
             */
            update: {
                url: url,
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name insert
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Insert Testimonial
             */
            insert: {
                url: url,
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name delete
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Delete Testimonial
             */
            delete: {
                url: url,
                method: 'DELETE'
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name requestComment
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Request Comment
             */
            requestComment: {
                url: 'couple-providers/:coupleProviderId/request-comment',
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name addComment
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Add Comment
             */
            addComment: {
                url: 'provider-testimonial/:id/comment',
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name updateComment
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Update Comment
             */
            updateComment: {
                url: 'provider-testimonial/:id/comment/:commentId',
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Testimonials
             * @name countsByStatus
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get the count of testimonials for each status
             */
            countsByStatus: {
                url: url + '/counts-by-status',
                method: 'GET'
            }
        }
    );

    return resourceService;
}
