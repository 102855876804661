/**
 * @ngdoc factory
 * @memberof dugun.attachments
 * @name AttachmentsPopups
 *
 * @requires dugun.popup:dgPopup
 *
 * @description
 * AttachmentsPopups factory
 */
function AttachmentsPopups(dgPopup) {
    var service = {};

    /**
     * @ngdoc method
     * @memberof AttachmentsPopups
     * @param customerId {integer} ID of customer
     * @description
     * Opens an attachment upload modal
     */
    service.openUpload = function(customerId) {
        return dgPopup.openModal({
            templateUrl: 'attachments/upload.html',
            controller: 'AttachmentUploadCtrl',
            itemId: customerId
        });
    };

    /**
     * @ngdoc method
     * @memberof AttachmentsPopups
     * @param data {Object=} Any additional data for modal controller
     * @description
     * Opens an attachment edit form
     */
    service.openForm = function(data) {
        return dgPopup.openModal({
            templateUrl: 'attachments/form.html',
            controller: 'AttachmentCtrl',
            data: data
        });
    };

    /**
     * @ngdoc method
     * @memberof AttachmentsPopups
     * @param data {Object=} Any additional data for modal controller
     * @description
     * Opens an attachment select form
     */
    service.selectAttatchments = function(data) {
        return dgPopup.openModal({
            templateUrl: 'attachments/select.html',
            controller: 'AttachmentSelectCtrl',
            data: data
        });
    };

    return service;
}

AttachmentsPopups.$inject = [
    'dgPopup',
];

angular.module('dugun.attachments')
    .factory('AttachmentsPopups', AttachmentsPopups);
