/**
 * @param $translate
 * @param uibPaginationConfig
 * @param uibPagerConfig
 * @constructor
 */
function PaginationTranslate($translate, uibPaginationConfig, uibPagerConfig) {
    var service = {};

    service.translate = function () {
        $translate.onReady().then(function () {
            uibPaginationConfig.nextText = $translate.instant('PAGINATION.NEXT_TEXT');
            uibPaginationConfig.previousText = $translate.instant('PAGINATION.PREVIOUS_TEXT');
            uibPaginationConfig.firstText = $translate.instant('PAGINATION.FIRST_TEXT');
            uibPaginationConfig.lastText = $translate.instant('PAGINATION.LAST_TEXT');

            uibPagerConfig.nextText = $translate.instant('PAGINATION.NEXT_TEXT');
            uibPagerConfig.previousText = $translate.instant('PAGINATION.PREVIOUS_TEXT');
        });
    };

    return service;

}

PaginationTranslate.$inject = [
    '$translate',
    'uibPaginationConfig',
    'uibPagerConfig',
];
angular.module('dugun.provider').factory('PaginationTranslate', PaginationTranslate);
