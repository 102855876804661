/**
 * ImageTags factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.galleries.media.tags
 * @name ImageTags
 * @requires dugun.core:resource
 * @requires angular-cache:CacheFactory
 *
 * @returns {service} ImageTags resource service
 */
function ImageTags(resource, CacheFactory) {
    var url = 'gallery-category-tag/:id';

    function getCacheFactory() {
        return CacheFactory.get('imageTags') || CacheFactory('imageTags', {
            maxAge: 24*60*60*1000 // 24 hours
        });
    }

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof ImageTags
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get tags list
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof ImageTags
             * @name insert
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in POST data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Insert new tag
             */
            insert: {
                url: url,
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof ImageTags
             * @name delete
             * @param params {Object} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Remove tag
             */
            delete: {
                url: url,
                method: 'POST'
            }
        }
    );

    return resourceService;
}

ImageTags.$inject = [
    'resource',
    'CacheFactory'
];

angular.module('dugun.galleries.media.tags')
    .factory('ImageTags', ImageTags);
