function CollectionMerchantsLoader($rootScope, $q, $window) {
    var deferred = $q.defer();

    $rootScope.$watchCollection('activeMerchantList', function (value) {
        if (value) {
            deferred.resolve();
            if (value.length === 0) {
                $window.location.hash = '#2';
                $window.location.href = '/collection/merchants';
            }
        }
    });

    return deferred.promise;
}

CollectionMerchantsLoader.$inject = [
    '$rootScope',
    '$q',
    '$window'
];

function CollectionRoutes($routeProvider) {
    $routeProvider
        .when('/collection/merchants', {
            templateUrl: 'collection/index.html',
            controller: CollectionCtrl,
            label: ''
        })
        .when('/collection/payments', {
            templateUrl: 'collection/payments/list.html',
            controller: CollectionsPaymentListCtrl,
            label: '',
            resolve: {
                loader: CollectionMerchantsLoader
            }
        });
}

CollectionRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.collection').config(CollectionRoutes);
