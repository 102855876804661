function CollectionsPayments(resource) {
    var url = 'collections/payments/:id';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            query: {

            },
            get: {

            },
            insert: {
                method: 'POST'
            },
            update: {
                method: 'PUT'
            },
            action: {
                url: url + '/action',
                method: 'POST'
            }
        }
    );

    return resourceService;
}

CollectionsPayments.$inject = [
    'resource'
];

angular.module('dugun.collection').factory('CollectionsPayments', CollectionsPayments);
