(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/history/history.html',
    '<div ng-repeat="history in leadHistory | orderBy: \'-data.date\'"><div class="comment"><span class="comment-avatar text-center mt-10"><i ng-if="history.type !== \'outbound\' || history.type !== \'inbound\'" class="fa-2x" ng-class="{\n' +
    '                    \'fas fa-comment-dots panel-color\': history.type == \'message\',\n' +
    '                    \'far fa-sticky-note panel-color\': history.type == \'note\',\n' +
    '                    \'far fa-sticky-note panel-color\': history.type == \'interaction\' && history.data.status === \'comment\',\n' +
    '                    \'far fa-thumbs-up panel-color\': history.type == \'interaction\' && history.data.status === \'deal\',\n' +
    '                    \'far fa-thumbs-down pink-color\': history.type == \'interaction\' && history.data.status === \'reject\',\n' +
    '                    \'far fa-envelope panel-color\': history.type == \'info-request\',\n' +
    '                    \'fas fa-eye panel-color\': history.type == \'visit\',\n' +
    '                    \'fas fa-plus panel-color\': history.type == \'plusLead\',\n' +
    '                    \'far fa-star panel-color\': history.type == \'like\',\n' +
    '                    \'far fa-bell panel-color\': history.type == \'interaction\' && history.data.status === \'callAgain\',\n' +
    '                    \'far fa-envelope-open panel-color\': history.type == \'last-open\',\n' +
    '                    \'fas fa-exchange-alt panel-color\': history.type == \'first-response\',\n' +
    '                }"></i> <span ng-if="history.type == \'outbound\'" ng-class="{\n' +
    '                    \'panel-color\': history.data.extra.successful,\n' +
    '                    \'pink-color\': !history.data.extra.successful,\n' +
    '                    \'gray\': history.type == \'waitingStatusOutboundCall\'\n' +
    '                }" class="fa-2x"><i class="fas fa-phone fa-stack-1x"></i> <i class="fas fa-long-arrow-alt-up fa-stack-1x" style="transform: rotateZ(45deg);\n' +
    '                    margin-left: -11px;\n' +
    '                    margin-top: -3px"></i> </span><span ng-if="history.type == \'inbound\'" ng-class="{\n' +
    '                    \'panel-color\': history.data.extra.successful,\n' +
    '                    \'pink-color\': !history.data.extra.successful,\n' +
    '                    \'gray\': history.type == \'waitingStatusInboundCall\'\n' +
    '                }" class="fa-2x"><i class="fas fa-phone fa-stack-1x"></i> <i class="fas fa-long-arrow-alt-up fa-stack-1x" style="transform: rotateZ(-135deg);\n' +
    '                    margin-left: -11px;\n' +
    '                    margin-top: -6px"></i></span></span><div class="comment-body"><div class="comment-text"><div class="comment-header"><label class="m-0" ng-bind="history.data.date | date:\'dd MMMM EEEE, HH:mm\'" ng-if="checkIsSameYear(history.data.date)"></label><label class="m-0" ng-bind="history.data.date | date:\'dd MMMM EEEE yyyy, HH:mm\'" ng-if="!checkIsSameYear(history.data.date)"></label></div><i ng-if="history.type == \'outbound\' && history.data.extra.callerNumber" ng-bind="history.callerNumber | phone"></i> <span ng-bind-html="history.data.text"></span> <span ng-if="history.data.extra.customerUserName" style="display: block; font-size: 10px" ng-bind-html="history.data.extra.customerUserName"></span></div></div></div></div>');
}]);
})();
