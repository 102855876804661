/**
 * TutorialsService factory
 * @ngdoc factory
 * @memberof dugun.provider.tutorials
 * @name TutorialsService
 *
 * @requires dugun.popup:dgPopup
 */
function TutorialsService(dgPopup) {
    var service = {};

    /**
     * @ngdoc method
     * @memberof TutorialsService
     * @param title {string} Title of video
     * @param code {string} Code of video
     * @description
     * Opens a video popup
     */

    service.openVideo = function(videoTitle, videoCode) {
        return dgPopup.openModal({
            templateUrl: 'tutorials/video.modal.html',
            controller: 'VideoTutorialCtrl',
            itemId: null,
            data: {
                video_title : videoTitle,
                video_code : videoCode
            },
            modalOptions: {
                size : 'lg'
            }
        });
    };

    /**
     * @ngdoc method
     * @memberof TutorialsService
     * @param title {string} Title of Page
     * @param page {string} Url of the page
     * @description
     * Opens a page popup
     */

    service.openPage = function(pageTitle, pageUrl) {
        return dgPopup.openModal({
            templateUrl: 'tutorials/page.modal.html',
            controller: 'PageTutorialCtrl',
            itemId: null,
            data: {
                page_title : pageTitle,
                page_url : pageUrl
            }
        });
    };

    return service;
}

TutorialsService.$inject = [
    'dgPopup',
];

angular.module('dugun.provider.tutorials')
    .factory('TutorialsService', TutorialsService);