function FBLoginConfig(FacebookProvider, environmentConfig) {

    FacebookProvider.init(environmentConfig.facebookAppId);
}

FBLoginConfig.$inject = [
    'FacebookProvider',
    'environmentConfig',
];

angular.module('dugun.provider').config(FBLoginConfig);