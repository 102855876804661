function UibPaginationConfig(uibPaginationConfig) {
    uibPaginationConfig.nextText = 'Sonraki';
    uibPaginationConfig.previousText = 'Önceki';
    uibPaginationConfig.firstText = 'İlk';
    uibPaginationConfig.lastText = 'Son';
}

function UibPagerConfig(uibPagerConfig) {
    uibPagerConfig.nextText = 'Sonraki';
    uibPagerConfig.previousText = 'Önceki';
}

UibPaginationConfig.$inject = [
    'uibPaginationConfig'
];
UibPagerConfig.$inject = [
    'uibPagerConfig'
];

angular.module('dugun.provider')
    .config(UibPaginationConfig)
    .config(UibPagerConfig);