/**
 * @ngdoc controller
 * @memberof dugun.testimonials
 * @name TestimonialCtrl
 *
 * @requires $scope
 * @requires $uibModalInstance
 * @requires $window
 * @requires $filter
 * @requires dugun.notifications:dgNotifications
 * @requires Analytics
 * @requires Testimonials
 *
 * @param itemId
 */
function TestimonialCtrl($scope, $uibModalInstance, $window, $filter,
                                 dgNotifications,
                                 Testimonials, $translate,
                                 itemId) {

    $scope.id = $window.parseInt(itemId);
    $scope.cleanData = {};
    $scope.dirtyData = {};
    $scope.canAddComment = false;
    $scope.showAddComment = false;
    $scope.loading = [];

    $scope.providersCanAdd = $filter('filter')($scope.providersWithProduct, function (value) {
        return value.canAddTestimonials && !value.hasPromotion;
    });

    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('GENERAL.UNSAVED_INFO_WARNING'),
            data = $scope.dirtyData.getDiff($scope.cleanData);
        if(!angular.equals(data, {})) {
            switch (reason){
                // clicked outside
                case "backdrop click":
                    break;
                // cancel button
                case "cancel":
                    break;
                // escape key
                case "escape key press":
                    break;
            }
            if (!confirm(message)) {
                event.preventDefault();
            }
        }
    });

    $scope.close = function() {
        $uibModalInstance.close(angular.copy($scope.cleanData));
    };

    $scope.getTestimonial = function() {
        var promise = Testimonials.get(
            { id: $scope.id },
            function(successResponse) {
                if(successResponse.data.organizationDate) {
                    successResponse.data.organizationDate = new Date(successResponse.data.organizationDate);
                }
                $scope.cleanData = successResponse.data;
                $scope.dirtyData = angular.copy($scope.cleanData);
                if($scope.cleanData.addedBy !== 'provider' && $scope.cleanData.approvalStatus !== '3') {
                    if($scope.cleanData.providerTestimonialComment) {
                        if($scope.cleanData.providerTestimonialComment.status != 1) {
                            $scope.canAddComment = true;
                            $scope.showAddComment = true;
                        }
                    } else {
                        $scope.showAddComment = true;
                    }
                }
                var testimonialStatus = successResponse.data.status ? 'active' : 'rejected';
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );
        $scope.loading.push(promise);
    };

    $scope.checkAddComment = function () {
        if(!$scope.hasPermission('replyToReviews', $scope.cleanData.provider.id)) {
            $scope.openUpgradeModal('testimonial-reply');
        } else {
            $scope.canAddComment = true;
        }
        dataLayer.push({
            event: 'replyTestimonial',
            provider: {
                id: $scope.cleanData.provider.id,
                name: $scope.cleanData.provider.name,
                category: $scope.getProviderCategory($scope.cleanData.provider.categoryId),
                city: $scope.getCity($scope.cleanData.provider.cityId)
            },
            couple: {
                id: $scope.cleanData.coupleId,
            },
            lead: {
                id: $scope.cleanData.id,
            }
        });
    };

    $scope.setTestimonial = function() {
        var data = $scope.dirtyData.getDiff($scope.cleanData),
            method = 'insert',
            actionLabel = 'Add',
            params = {};

        if (data.phone) {
            data.phone = '+' + data.phone;
        } else if (data.phone === null || data.phone === '') {
            delete data.phone;
        }

        if(angular.equals(data, {})) return true;

        if($scope.id) {
            method = 'update';
            actionLabel = 'Edit';
            params.id = $scope.id;
        }

        data = data.convertDates(['organizationDate']);

        $scope.loading = Testimonials[method](
            params,
            data,
            function(successResponse) {
                if(!$scope.id) {
                    $scope.id = successResponse.id;
                    dgNotifications.success($translate.instant('TESTIMONIALS.TESTIMONIAL_PUBLISH_INFO', {number: 2}));
                }
                dgNotifications.success($translate.instant('GENERAL.ADDED_SUCCESSFULLY'));
                data.success = true;
                $scope.cleanData = angular.copy($scope.dirtyData);
                $scope.close();
            },
            function(errorResponse) {
                if(errorResponse.data.errors.length > 0) {
                    dgNotifications.error(errorResponse);
                } else {
                    dgNotifications.error(errorResponse.data.message);
                }
                data.success = false;
                data.error = errorResponse.data.message;
            }
        );
    };

    $scope.setComment = function () {
        if(!$scope.dirtyData.providerTestimonialComment) {
            return true;
        }
        var urlData = {
                id: $scope.id
            },
            method = 'addComment',
            data = {
                comment: $scope.dirtyData.providerTestimonialComment.comment
            },
            promise;

        if($scope.cleanData.providerTestimonialComment) {
            method = 'updateComment';
            urlData.commentId = $scope.cleanData.providerTestimonialComment.id;
        }

         promise = Testimonials[method](
            urlData,
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('GENERAL.ADDED_SUCCESSFULLY'));
                $scope.dirtyData.providerTestimonialComment.status = 0;
                $scope.cleanData = angular.copy($scope.dirtyData);
                $scope.close();
                dataLayer.push({
                    event: 'replyTestimonial',
                    provider: {
                        id: $scope.cleanData.provider.id,
                        name: $scope.cleanData.provider.name,
                        category: $scope.getProviderCategory($scope.cleanData.provider.categoryId),
                        city: $scope.getCity($scope.cleanData.provider.cityId)
                    },
                    couple: {
                        id: $scope.cleanData.coupleId,
                    },
                    lead: {
                        id: $scope.cleanData.id,
                    }
                });
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(promise);
    };

    if($scope.id) {
        $scope.getTestimonial();
    } else {
        if($scope.providersCanAdd.length == 1) {
            $scope.dirtyData.providerId = $scope.providersCanAdd[0].id;
        }
    }
}

TestimonialCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    '$filter',
    'dgNotifications',
    'Testimonials',
    '$translate',
    'itemId'
];

angular.module('dugun.testimonials')
    .controller('TestimonialCtrl', TestimonialCtrl);
