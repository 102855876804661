(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/week-days/week-days.html',
    '<div class="col-xs-6 col-md-4 mb-10" ng-repeat="day in weekDays" ng-click="check(day)" style="cursor: pointer"><button type="button" class="btn btn-default btn-xs icon-only" ng-class="{\n' +
    '            \'btn-default\': model.indexOf(day.itemId) === -1,\n' +
    '            \'btn-success\': model.indexOf(day.itemId) !== -1\n' +
    '        }"><i class="fa" ng-class="{\'fa-check\': model.indexOf(day.itemId) !== -1}"></i></button><label class="bold-text" style="cursor: pointer">{{ day.value }}</label></div>');
}]);
})();
