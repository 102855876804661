angular.module('dugun.filters')
    .filter('phone', function() {
        return function(tel) {
            if(!tel) return '';

            var value = tel.toString().trim();

            if(value.length === 10) {
                return '(0' +
                value.substr(0, 3) +
                ') ' +
                value.substr(3, 3) +
                ' ' +
                value.substr(6, 2) +
                ' ' +
                value.substr(8);
            } else if(value.length === 11) {
                return '(' +
                value.substr(0, 4) +
                ') ' +
                value.substr(4, 3) +
                ' ' +
                value.substr(7, 2) +
                ' ' +
                value.substr(9);
            } else {
                return value;
            }
        };
    });
