/**
 * @ngdoc controller
 * @memberof dugun.testimonials.request
 * @name TestimonialRequestCtrl
 *
 * @include $scope {service} controller scope
 * @include $uibModalInstance {service} $uibModalInstance
 * @include $window {service} $window
 * @include dgNotifications {service} dgNotifications
 * @include Analytics
 * @include Testimonials {factory} Testimonials service
 *
 * @param itemId {integer=} ID of couple provider
 * @param data {Object=} Data of couple provider
 */
function TestimonialRequestCtrl($scope, $uibModalInstance, $window,
                          dgNotifications,
                          Testimonials, $translate,
                          itemId, data) {
    $scope.coupleProviderId = $window.parseInt(itemId);
    $scope.cleanData = data;
    $scope.dirtyData = {};

    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('GENERAL.UNSAVED_INFO_WARNING'),
            data = $scope.dirtyData.getDiff($scope.cleanData);
        if(angular.equals(data, {})) {
            return;
        }
        switch (reason){
            // clicked outside
            case "backdrop click":
                break;

            // cancel button
            case "cancel":
                break;

            // escape key
            case "escape key press":
                break;
        }
        if (!confirm(message)) {
            event.preventDefault();
        }
    });

    $scope.close = function(data) {
        if(data) $uibModalInstance.close(data);
        else $uibModalInstance.dismiss('cancel');
    };

    $scope.sendRequest = function() {
        if(!$window.confirm($translate.instant('TESTIMONIALS.TESTIMONIALS_REQUEST_CONFIRMATION_QUESTION'))) {
            return;
        }
        var data = angular.copy($scope.dirtyData);
        var urlData = {
            coupleProviderId: $scope.coupleProviderId
        };

        $scope.loading = Testimonials.requestComment(
            urlData,
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('TESTIMONIALS.MAIL_WAS_SENT'));
                $scope.cleanData = angular.copy($scope.dirtyData);
                $scope.close(successResponse);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };
}

TestimonialRequestCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    'dgNotifications',
    'Testimonials',
    '$translate',
    'itemId',
    'data'
];

angular.module('dugun.testimonials.request')
    .controller('TestimonialRequestCtrl', TestimonialRequestCtrl);
