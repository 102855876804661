/**
 * @memberof dugun.attachments
 * @ngdoc route
 * @name AttachmentsRoutes
 *
 * @param $routeProvider {provider} $routeProvider
 * @param $q
 *
 * @description /attachments
 */
function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function AttachmentsRoutes($routeProvider) {
    $routeProvider
        .when('/attachments', {
            templateUrl: 'attachments/list.html',
            controller: 'AttachmentsListCtrl',
            controllerAs: 'attachmentsListCtrl',
            label: 'ATTACHMENTS.ATTACHMENTS',
            reloadOnSearch: false,
            resolve: {
                init: userResolver
            }
        });
}

AttachmentsRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.attachments').config(AttachmentsRoutes);
