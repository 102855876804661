/**
 * @ngdoc object
 * @memberof dugun.leads
 * @name LeadsListCtrl
 * @scope
 * @requires $scope {service} controller scope
 * @requires $route {service} $route
 * @requires $routeParams {service} $routeParams
 * @requires $window {service} $window
 * @requires $timeout
 * @requires $q
 * @requires $uibModal
 * @requires $filter
 * @requires dgNotifications {service} dgNotifications
 * @requires queryString {service} dugun.queryString:queryString
 * @requires moment {service} moment
 * @requires Analytics
 * @requires $mixpanel
 * @requires $translate
 * @requires Providers
 * @requires Leads {factory} Leads service
 * @requires LeadInteractions {factory} LeadInteractions service
 * @requires MailTemplates
 * @requires AttachmentsPopups
 */
function LeadMessagesCtrl(
    $scope, $rootScope, $route, $routeParams, $window, $timeout, $q, $uibModal, $filter,
    dgNotifications, queryString, moment, $translate, $location,
    Providers, Leads, LeadInteractions, MailTemplates, AttachmentsPopups, CustomerUsers
) {

    $scope.leadId = parseInt($routeParams.id) || null;

    $scope.loadingReplyMode = [];
    $scope.sendMessageMode = false;
    $scope.replyMode = 'email';
    $scope.leadMessage = {
        body: '',
        carbonCopy: '',
        attachments: []
    };

    $scope.toggleSendMessageMode = function () {
        if($scope.screenWidth == 'xs') {
            if(!$scope.messageModalOpen) {
                $scope.messageModalInstance = $uibModal.open({
                    templateUrl: 'leads/lead-messages/message.modal.html',
                    scope: $scope,
                    size: 'md',
                    backdrop: 'static',
                    keyboard: false
                });
                $scope.messageModalInstance.opened.then(function () {
                    $scope.messageModalOpen = true;
                    $timeout(function() {
                        if($scope.replyMode == 'email') {
                            if(document.querySelector('.ta-bind')) {
                                document.querySelector('.ta-bind').focus();
                            }
                        } else {
                            if(document.querySelector('#sendMessageTextarea')) {
                                document.querySelector('#sendMessageTextarea').focus();
                            }
                        }
                    });
                });
                $scope.messageModalInstance.result.finally(function (selectedItem) {
                    $scope.messageModalOpen = false;
                });
            } else {
                $scope.closeMessageModal();
            }
        } else {
            $scope.sendMessageMode = !$scope.sendMessageMode;
            if($scope.sendMessageMode) {
                $timeout(function() {
                    // angular.element(document).scrollTop(angular.element(document.querySelector('.send-message-seperator')).offset().top);
                    angular.element(document).scrollTop(document.querySelector('body').scrollHeight);
                    if($scope.replyMode == 'email') {
                        if(document.querySelector('.ta-bind')) {
                            document.querySelector('.ta-bind').focus();
                        }
                    } else {
                        if(document.querySelector('#sendMessageTextarea')) {
                            document.querySelector('#sendMessageTextarea').focus();
                        }
                    }
                });
            }
        }
    };

    $scope.toggleReplyMode = function (mode) {
        if(mode == $scope.replyMode) {
            return;
        }

        var promise = changeEmailMode(mode);

        $scope.loadingReplyMode.push(promise);

        function changeEmailMode(newMode) {
            var deferred = $q.defer();
            $scope.replyMode = '';
            if(newMode == 'sms' || !$scope.leadHistoryMeta.canSendEmail) {
                $scope.maxMessageLength = 160;
            } else {
                $scope.maxMessageLength = '';
            }
            $scope.leadMessage.body = '';
            $timeout(function() {
                $scope.replyMode = newMode;
                deferred.resolve();
            }, 500);

            return deferred.promise;
        }
    };

    $scope.closeMessageModal = function () {
        $scope.messageModalInstance.dismiss('close');
    };

    $scope.getSignatures = function () {
        var promise = CustomerUsers.get(
            {
                id: 'me',
                customerId: $scope.user.customerId
            },
            function (successResponse) {
                $scope.signaturesList = successResponse.data.customerUserProviders;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );

        $scope.loading.push(promise);
    };

    $scope.signatureCheckbox = {
        checked: false
    };
    $scope.signatureOnchange = function (checked) {
        if (checked) {
            $scope.signature = $filter('filter')($scope.signaturesList, {providerId: $scope.leadData.provider.id})[0];
            var signaturePreview = '<hr><br><div class="signature-preview" style="background: #fbfbfb;border: 1px solid #e4e2e2;border-radius: 3px;"><div style="text-align: center"><img width="100%" src="' + $rootScope.imageUrl +'/'+ $scope.signature.signatureImage + '"></div><div>'+ $scope.signature.signatureContent +'</div></div>';
            $scope.leadMessage.bodyCopy = $scope.leadMessage.body;
            $scope.leadMessage.body += signaturePreview;
        } else {
            $scope.leadMessage.body = $scope.leadMessage.bodyCopy;
        }
    };

    $scope.getMailTemplates = function () {
        var data = {
            customerId: $scope.user.customerId,
            'providerIds[]': [$scope.leadData.provider.id]
        };

        var promise = MailTemplates.query(
            data,
            function (successResponse) {
                $scope.mailTemplatesList = successResponse.data;
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.selectMailTemplate = function (item) {
        if($scope.selectedMailTemplateId == item.id) {
            return;
        }
        $scope.selectedMailTemplateId = item.id;
        $scope.leadMessage.body = item.content;
        $scope.leadMessage.attachments = [];
        $scope.leadMessage.bodyCopy = item.content;
        $scope.signatureCheckbox.checked = false;
        if(item.customerMessageTemplateAttachments) {
            if(item.customerMessageTemplateAttachments.length > 0) {
                angular.forEach(item.customerMessageTemplateAttachments, function(attachment, key) {
                    if(attachment.customerMessageAttachment) {
                        if(attachment.customerMessageAttachment.messageAttachment) {
                            $scope.leadMessage.attachments.push(attachment.customerMessageAttachment.messageAttachment);
                        }
                    }
                });
            }
        }
    };

    $scope.clearAttachment = function ($index) {
        $scope.leadMessage.attachments.splice($index, 1);
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * send message
     */
    $scope.sendMessage = function() {
        if(!$scope.leadData.id) return true;

        var data = {
                id: $scope.leadData.id,
                body: $scope.leadMessage.body
            },
            method = 'insertMessage';

        if($scope.replyMode == 'email') {
            data.subject = $translate.instant('LEADS.MESSAGE_FORM.MESSAGE_EMAIL_SUBJECT', {provider: $scope.leadData.provider.name});
            if($scope.leadMessage.attachments) {
                if($scope.leadMessage.attachments.length > 0) {
                    data.messageAttachmentIds = [];
                    angular.forEach($scope.leadMessage.attachments, function(attachment, key) {
                        data.messageAttachmentIds.push(attachment.id);
                    });
                }
            }
            if($scope.leadMessage.carbonCopy) {
                data.carbonCopy = $scope.leadMessage.carbonCopy;
            }
        } else {
            method = 'sendSMS';
        }

        var loading = Leads[method](
            data,
            function(successResponse) {
                $scope.leadMessage.body = '';
                $scope.leadMessage.carbonCopy = '';
                $scope.leadMessage.attachments = [];
                $scope.selectedMailTemplateId = null;
                $scope.toggleSendMessageMode();
                if (!$scope.leadData.firstResponseAt) {
                    $scope.getLeadData();
                } else {
                    $scope.loadLeadHistory($scope.leadId, ['messages', 'calls', 'interactions']);
                }
                $scope.showInteractionButtons = true;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);

            }
        );
        $scope.loading.push(loading);
    };

    $scope.selectAttatchments = function () {
        AttachmentsPopups.selectAttatchments({selectedAttachments: $scope.leadMessage.attachments, providerId: $scope.leadData.provider.id}).result.then(
            function(response) {
                if(response.attachments) {
                    if(response.attachments.length > 0) {
                        $scope.leadMessage.attachments = response.attachments;
                    }
                }
            }
        );
    };

    $scope.openCarbonCopyModal = function () {
        $scope.carbonCopyData.email = angular.copy($scope.leadMessage.carbonCopy);
        $scope.carbonCopyModalInstance = $uibModal.open({
            templateUrl: 'leads/message-carbonCopy.modal.html',
            scope: $scope,
            size: 'md',
            backdrop: 'static',
            keyboard: false
        });
    };

    $scope.carbonCopyData = {
        email: ''
    };

    $scope.setCarbonCopy = function () {
        $scope.leadMessage.carbonCopy = angular.copy($scope.carbonCopyData.email);
        $scope.carbonCopyModalInstance.dismiss('close');
    };

    $scope.closeCarbonCopyModal = function() {
        $scope.carbonCopyModalInstance.dismiss('close');
    };

    $scope.$watch('leadHistory', function (newV, oldV) {
        if (newV) {
            $scope.leadCallsMessages = angular.copy(newV);
            $filter('orderBy')($scope.leadCallsMessages, 'data.date');
        }
    });

    $scope.$watch('leadHistoryMeta', function (newV, oldV) {
        if (newV) {
            $scope.leadCallsMessagesMeta = angular.copy(newV);
        }
    });

    $scope.$watch('leadData', function (newV, oldV) {
        if (newV) {
            $scope.getSignatures();
            $scope.getMailTemplates();
            $scope.leadId = $scope.leadData.id;
        }
    });
}

LeadMessagesCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$route',
    '$routeParams',
    '$window',
    '$timeout',
    '$q',
    '$uibModal',
    '$filter',
    'dgNotifications',
    'queryString',
    'moment',
    '$translate',
    '$location',
    'Providers',
    'Leads',
    'LeadInteractions',
    'MailTemplates',
    'AttachmentsPopups',
    'CustomerUsers'
];

angular.module('dugun.leads')
    .controller('LeadMessagesCtrl', LeadMessagesCtrl);
