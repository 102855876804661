/**
 * @memberof dugun.galleries.galleries
 * @ngdoc overview
 * @description
 * Galleries module
 */
angular.module('dugun.galleries.galleries', [
    // vendor
    'dugun.notifications',
    'dugun.confirm',
]);
