/**
 * Signatures factory
 * @ngdoc factory
 * @memberof dugun.signatures
 * @name Signatures
 * @requires dugun.core:resource
 *
 * @returns {service} Signatures resource service
 */
function Signatures(resource) {
    var url = 'customers/:customerId/users/:customerUserId/providers/:customerUserProviderId/signature';

    var resourceService = resource(
        url,
        {
            customerId: '@customerId',
            customerUserId: '@customerUserId',
            customerUserProviderId: '@customerUserProviderId'
        },
        {
            /**
             * @ngdoc method
             * @memberof Signatures
             * @name update
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * update signature
             */
            insert: {
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof Signatures
             * @name update
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * update signature
             */
            update: {
                method: 'PUT'
            },
        }
    );

    return resourceService;
}

Signatures.$inject = [
    'resource'
];

angular.module('dugun.signatures')
    .factory('Signatures', Signatures);
