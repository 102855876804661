(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('collection/step-progress-bar/step-progress-bar.html',
    '<div class="dg-progressbar-container"><ul class="dg-progressbar"><li data-ng-style="{\'z-index\': ((options.steps.length - $index)*4)}" ng-class="{\'active\': ($index + 1) <= options.doneSteps}" style="width: {{100 / options.steps.length}}%" ng-repeat="item in options.steps">{{ item }}</li></ul></div><style>.dg-progressbar-container {\n' +
    '        width: 100%;\n' +
    '    }\n' +
    '    .dg-progressbar {\n' +
    '        counter-reset: step;\n' +
    '        padding: 0;\n' +
    '    }\n' +
    '    .dg-progressbar li {\n' +
    '        list-style: none;\n' +
    '        float: left;\n' +
    '        position: relative;\n' +
    '        text-align: center;\n' +
    '        color: #7d7d7d;\n' +
    '    }\n' +
    '    .dg-progressbar li:before {\n' +
    '        content: counter(step);\n' +
    '        counter-increment: step;\n' +
    '        width: 40px;\n' +
    '        height: 40px;\n' +
    '        border: 2px solid #ddd;\n' +
    '        display: block;\n' +
    '        text-align: center;\n' +
    '        font-size: 16px;\n' +
    '        border-radius: 50%;\n' +
    '        margin:  10px auto;\n' +
    '        line-height: 2.3;\n' +
    '        background-color: white;\n' +
    '        font-weight: bold;\n' +
    '    }\n' +
    '    .dg-progressbar li:after {\n' +
    '        content: \'\';\n' +
    '        width: calc(100% - 20px);\n' +
    '        height: 2px;\n' +
    '        background-color: #ddd;\n' +
    '        position: absolute;\n' +
    '        top: 30px;\n' +
    '        left: -50%;\n' +
    '        z-index: 0;\n' +
    '    }\n' +
    '    .dg-progressbar li:first-child:after {\n' +
    '        content: none;\n' +
    '    }\n' +
    '    .dg-progressbar li.active {\n' +
    '        color: green;\n' +
    '    }\n' +
    '    .dg-progressbar li.active:before {\n' +
    '        border-color: #55b776;\n' +
    '    }\n' +
    '    .dg-progressbar li.active + li:after {\n' +
    '        background-color: #55b776;\n' +
    '    }\n' +
    '\n' +
    '    @media (max-width:767px) {\n' +
    '        .dg-progressbar li {\n' +
    '            font-size: 0.8em;\n' +
    '        }\n' +
    '        .dg-progressbar li:before {\n' +
    '            width: 25px;\n' +
    '            height: 25px;\n' +
    '            font-size: 1.3em;\n' +
    '            line-height: 1.5;\n' +
    '        }\n' +
    '        .dg-progressbar li:after {\n' +
    '            top: 20px;\n' +
    '        }\n' +
    '    }</style>');
}]);
})();
