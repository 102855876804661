/**
 * @ngdoc object
 * @memberof dugun.mailTemplates
 * @name MailTemplatesFormCtrl

 * @requires $scope {service} controller scope
 * @requires $route {service} $route
 * @requires $routeParams {service} $routeParams
 * @requires $window {service} $window
 * @requires $location {service} $location
 * @requires dgNotifications {service} dgNotifications
 * @requires MailTemplates {factory} MailTemplates service
 * @requires AttachmentsPopups {factory} AttachmentsPopups service
 */
function MailTemplatesFormCtrl($scope, $route, $routeParams, $window, $location,
                               dgNotifications, $translate,
                               MailTemplates, AttachmentsPopups, $filter, $rootScope) {

    $scope.id = $window.parseInt($routeParams.templateId) || null;

    $scope.cleanData = {};
    $scope.dirtyData = {};
    $scope.loading = [];
    if($scope.id === null) {
        if($routeParams.templateId !== 'new') {
            $location.path('/mail-templates').search({});
        }
        if($rootScope.user.providerIds.length > 1){
            $scope.dirtyData = {
                provider: null
            };
        }
        else{
            $scope.dirtyData = {
                provider: {id: $rootScope.user.providerIds[0]}
            };
        }
    }

    $scope.get = function () {
        var urlData = {
            customerId: $scope.user.customerId,
            templateId: $scope.id
        };

        var promise = MailTemplates.get(
            urlData,
            function (successResponse) {
                if(!successResponse.data.provider) {	
                    successResponse.data.provider = {id: 0};	
                }
                $scope.cleanData = successResponse.data;
                $scope.dirtyData = angular.copy($scope.cleanData);                
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.set = function () {
        var method = 'insert',
            urlData = {
                customerId: $scope.user.customerId
            },
            data = $scope.dirtyData.getDiff($scope.cleanData);

        delete data.customerMessageTemplateAttachments;
        if(data.provider) {
            if(data.provider.id === 0) {
                data.provider = null;
            }
        }

        if($scope.dirtyData.customerMessageAttachments) {
            if($scope.dirtyData.customerMessageAttachments.length > 0) {
                data.customerMessageAttachmentIds = [];
                angular.forEach($scope.dirtyData.customerMessageAttachments, function(attachment, key) {
                    data.customerMessageAttachmentIds.push(attachment.id);
                });
            }
        }

        if(angular.equals(data, {})) {
            return true;
        }

        if($scope.id) {
            method = 'update';
            urlData.templateId = $scope.id;
        }

        var promise = MailTemplates[method](
            urlData,
            data,
            function (successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
                if(!$scope.id) {
                    $location.path('/mail-templates/' + successResponse.id).search({});
                } else {
                    $scope.get();
                }
                var filteredProvider = $filter('filter')($scope.providersActive, {id: $scope.dirtyData.provider ? $scope.dirtyData.provider.id : null}, true)[0];
                if (filteredProvider){
                dataLayer.push(
                    {
                        event: 'addMailTemplate',
                        provider: {
                            id: filteredProvider.id,
                            name: filteredProvider.name,
                            category: $scope.getProviderCategory(filteredProvider.categoryId),
                            cityId: $scope.getCity(filteredProvider.cityId)
                        }
                    }
                );
            }
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.selectAttatchments = function () {
        AttachmentsPopups.selectAttatchments({selectedAttachments: $scope.dirtyData.customerMessageAttachments, providerId: $scope.dirtyData.provider.id}).result.then(
            function(response) {
                if(response.attachments) {
                    if(response.attachments.length > 0) {
                        $scope.dirtyData.customerMessageAttachments = response.attachments;
                    }
                }
            }
        );
    };

    $scope.clearAttachment = function (attachment) {
        var urlData = {
            customerId: $scope.user.customerId,
            templateId: $scope.id,
            attachmentId: attachment.id
        };

        var promise = MailTemplates.deleteAttachment(
            urlData,
            function (successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
                $scope.get();
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.removeAttachment = function ($index) {
        $scope.dirtyData.customerMessageAttachments.splice($index, 1);
    };

    if($scope.id) {
        $scope.get();
    }

    $scope.$root.heading.name = $route.current.label;
}

MailTemplatesFormCtrl.$inject = [
    '$scope',
    '$route',
    '$routeParams',
    '$window',
    '$location',
    'dgNotifications',
    '$translate',
    'MailTemplates',
    'AttachmentsPopups',
    '$filter',
    '$rootScope'
];

angular.module('dugun.mailTemplates')
    .controller('MailTemplatesFormCtrl', MailTemplatesFormCtrl);
