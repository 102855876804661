/**
 * PasswordReset factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.login.passwordReset
 * @name PasswordReset
 * @requires dugun.core:resource
 * @requires angular-cache:CacheFactory
 *
 * @returns {service} PasswordReset resource service
 */
function PasswordReset(resource) {
    var url = 'password-reset/:recoveryKey';

    var resourceService = resource(
        url,
        {
            recoveryKey: '@recoveryKey'
        },
        {
            /**
             * @ngdoc method
             * @memberof PasswordReset
             * @name insert
             * @param params {Object} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Send password reset email
             */
            insert: {
                url: url,
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof PasswordReset
             * @name update
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in data.
             * Available data: recoveryKey, userType, password, customerId
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Send password reset email
             */
            update: {
                url: url,
                method: 'POST',
                params: {
                    userType: 'customer'
                }
            },
            forgotUsername: {
                url: 'users/forgot-username',
                method: 'POST'
            }
        }
    );

    return resourceService;
}

PasswordReset.$inject = [
    'resource'
];

angular.module('dugun.login.passwordReset')
    .factory('PasswordReset', PasswordReset);
