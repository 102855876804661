/**
 * @ngdoc controller
 * @memberof dugun.provider.upgrade
 * @name ProviderUpgradeRequestFailedCtrl
 *
 * @requires $scope
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires $translate
 * @requires Analytics
 *
 */
function ProviderUpgradeRequestFailedCtrl($scope, $uibModalInstance, $translate, Analytics) {

    /**
     * @ngdoc method
     * @memberof ProviderUpgradeRequestFailedCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.dismiss();
    };

    $scope.header = $translate.instant('PROVIDER_UPGRADE.REQUEST_FAILED.HEADER');
    $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_FAILED.BODY_TEXT');
    $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_FAILED.CTA_BUTTON_TEXT');

    Analytics.trackEvent('failedUpgradeModal', 'open', 'modal');
}

ProviderUpgradeRequestFailedCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$translate',
    'Analytics'
];

angular.module('dugun.provider.upgrade')
    .controller('ProviderUpgradeRequestFailedCtrl', ProviderUpgradeRequestFailedCtrl);
