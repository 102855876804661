/**
 * @ngdoc controller
 * @memberof dugun.provider.upgrade
 * @name ProviderUpgradeRequestAlreadySentCtrl
 *
 * @requires $scope
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires $translate
 * @requires Analytics
 *
 */
function ProviderUpgradeRequestAlreadySentCtrl($scope, $uibModalInstance, $translate, Analytics) {

    /**
     * @ngdoc method
     * @memberof ProviderUpgradeRequestAlreadySentCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.dismiss();
    };

    $scope.header = $translate.instant('PROVIDER_UPGRADE.REQUEST_ALREADY_SENT.HEADER');
    $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_ALREADY_SENT.BODY_TEXT');
    $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_ALREADY_SENT.CTA_BUTTON_TEXT');

    Analytics.trackEvent('alreadySentUpgradeModal', 'open', 'modal');
}

ProviderUpgradeRequestAlreadySentCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$translate',
    'Analytics'
];

angular.module('dugun.provider.upgrade')
    .controller('ProviderUpgradeRequestAlreadySentCtrl', ProviderUpgradeRequestAlreadySentCtrl);
