function CustomerMenuListCtrl($scope, $translate, $window, $filter, $routeParams, queryString, dgNotifications, CustomerMenu) {
    $scope.list = [];
    $scope.loading = [];
    $scope.providerId = $window.parseInt($routeParams.providerId) || null;
    $scope.filters = angular.equals(queryString.getFilters(), {}) ?  {providerId: $scope.providerId} : queryString.getFilters();
    $scope.showDetails = false;

    $scope.pagination = {
        perPage: 25,
        currentPage: 1
    };

    $translate.onReady().then(function () {
        $scope.weekDays = {
            'MON': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.MON'),
            'TUES': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.TUES'),
            'WED': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.WED'),
            'THURS': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.THURS'),
            'FRI':  $translate.instant('CUSTOMER_MENU.WEEK_DAYS.FRI'),
            'SAT':  $translate.instant('CUSTOMER_MENU.WEEK_DAYS.SAT'),
            'SUN': $translate.instant('CUSTOMER_MENU.WEEK_DAYS.SUN')
        };
    });

    $scope.openForm = function (item) {
        var modalInstance = $scope.openModal(
            'customer-menu/form.html',
            'CustomerMenuCtrl',
            item ? item.id : null,
            null,
            {
                providerId: $scope.providerId
            },
            {
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            }
        );

        modalInstance.result.then(function (data) {
            if (data) {
                $scope.loadList();
            }
        });
    };

    $scope.loadList = function () {
        var urlParams = angular.copy($scope.filters);
        urlParams.customerId = $scope.customerId;
        urlParams.page = $scope.pagination.currentPage;
        var loading = CustomerMenu.query(
            urlParams,
            function (success) {
                $scope.list = success.data;
                $scope.pagination = success.meta;
                var provider = $filter('filter')($scope.providersActive, {id: $scope.providerId}, true)[0];
            dataLayer.push(
                {
                    event: 'menuFilter',
                    provider: {
                        id: provider.id,
                        name: provider.name,
                        city: $scope.getCity(provider.cityId),
                        category: $scope.getProviderCategory(provider.categoryId)
                    }
                });
            },
            function (error) {
                dgNotifications.error(error);
            }
        ); 
        $scope.loading.push(loading);
    };

    $scope.deleteCustomerMenu = function (menu, index) {
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: menu.id
        };

        CustomerMenu.delete(
            urlParams,
            function (success) {
                dgNotifications.success($translate.instant('GENERAL.DELETED'));
                $scope.list.splice(index, 1);
            },
            function (error) {
                dgNotifications.success(error);
            }
        );
    };

    $scope.getMenuTypes = function () {
        CustomerMenu.getMenuTypes(
            function (success) {
                $scope.menuTypes = success.data;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
    };

    $scope.setFilters = function () {
        var data = angular.copy($scope.filters);
        queryString.setFilters(data);
        $scope.loadList();
    };

    $scope.getCustomerMenuPricing = function (menu) {
        $scope.customerMenuPricingList = [];
        var urlParams = {
            customerId: $scope.customerId,
            customerMenuId: menu.id
        };
        var loading = CustomerMenu.getCustomerMenuPricing(
            urlParams,
            function (success) {
                $scope.customerMenuPricingList = success.data;
            },
            function (error) {
                dgNotifications.error(error);
            }
        );

        $scope.loading.push(loading);
    };

    function getMenuSectionGroups (providerCategoryId) {
        var urlParams = {
            providerCategoryId: providerCategoryId
        };
        var loading = CustomerMenu.getMenuSectionGroups(
            urlParams,
            function (success) {
                $scope.menuSectionGroups = $filter('orderBy')(success.data, 'orderNumber');
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
        $scope.loading.push(loading);
    }

    $scope.showPricingDetails = function (menu, index) {
        $scope.openedPricingIndex = index;
        $scope.getCustomerMenuPricing(menu);
    };

    $scope.openCopyCustomerMenuPopup = function (item) {
        var modalInstance = $scope.openModal(
            'customer-menu/menu-copy.html',
            'CustomerMenuCtrl',
            item ? item.id : null,
            null,
            {
                providerId: $scope.providerId,
                mode: 'copy'
            },
            {
                size: 'md'
            }
        );

        modalInstance.result.then(function (data) {
            if (data) {
                $scope.loadList();
            }
        });
    };

    $scope.$watchCollection('user', function (user) {
        if (user) {
            $scope.customerId = $scope.user.customerId;
            var provider = $filter('filter')($scope.providersActive, {id: $scope.providerId}, true)[0];
            $scope.loadList();
            getMenuSectionGroups(provider.categoryId);
        }
    });

    $scope.$watch('pagination.currentPage', function (newValue, oldValue) {
        if (newValue != oldValue) {
            $scope.loadList();
        }
    });

    $scope.getMenuTypes();

}

CustomerMenuListCtrl.$inject = [
    '$scope',
    '$translate',
    '$window',
    '$filter',
    '$routeParams',
    'queryString',
    'dgNotifications',
    'CustomerMenu',
];

angular.module('dugun.customerMenu').controller('CustomerMenuListCtrl', CustomerMenuListCtrl);
