function ProvidersMissingDetailsCtrl($scope, $location, $uibModalInstance) {

    $scope.page = 'general';
    if($scope.missingProviderDetails.details.length > 0) {
        if($scope.missingProviderDetails.details[0].groupId !== 0) {
            $scope.page = 'detail';
        }
    }

    $location.url('/providers/'+ $scope.missingProviderDetails.provider.id + '/info#' + $scope.page);

    $scope.close = function() {
        $uibModalInstance.dismiss('cancel');
    };
}

ProvidersMissingDetailsCtrl.$inject = [
    '$scope',
    '$location',
    '$uibModalInstance'
];

angular.module('dugun.customers.providers.provider')
    .controller('ProvidersMissingDetailsCtrl', ProvidersMissingDetailsCtrl);