(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/related-provider/provider.html',
    '<ui-select class="single-select three-line full-width {{ searcher.loading ? \'ui-select-loading\' : \'\' }}" ng-attr-id="{{ id || \'providerId\' }}" ng-model="$parent.model" ui-select-required="{{ required ? true : false }}" on-select="selected($item, $model)" ng-attr-form="{{ attrs.form ? attrs.form : null }}" ng-disabled="ngDisabled()"><ui-select-match placeholder="{{ attrs.placeholder || (\'DG_FORMS.CHOOSE_COMPANY\' | translate) }}" allow-clear="{{ allowClear }}"><p ng-bind="$select.selected.name || $select.selected"></p></ui-select-match><ui-select-choices repeat="item.id as item in searcher.providersResult" refresh="search($select.search)"><p><strong ng-bind-html="item.name | highlight: $select.search"></strong></p><p><span ng-bind="$root.getProviderCategory(item.categoryId)"></span></p><p><small ng-bind="$root.getCity(item.cityId)"></small> <small ng-bind="$root.getDistrict(item.districtId).name"></small></p></ui-select-choices></ui-select>');
}]);
})();
