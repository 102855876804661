(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('layout/header.html',
    '<div class="header-title" lang="tr"><h1>{{ heading.name | translate }} <small ng-if="heading.total">({{ heading.total }})</small></h1></div><div class="header-buttons hidden-xs" ng-if="tutorials.length > 0"><button ng-repeat="tutorial in tutorials" class="btn btn-sm btn-default tutorial-button" ga-track-event="[\'tutorial{{tutorial.type.charAt(0).toUpperCase() + tutorial.type.slice(1) }}\', \'click\', \'openModal\']" ng-click="openTutorialModal(tutorial)"><i class="fa fa-question-circle"></i> <span>{{ tutorial.buttonText ? tutorial.buttonText : ( tutorial.type == \'video\' ? (\'LAYOUT.HEADER.HELPER_VIDEO\' | translate) : (\'LAYOUT.HEADER.HELP\' | translate)) }}</span></button></div>');
}]);
})();
