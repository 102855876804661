/**
 * ui-select doesn't support required or ng-required, so it's the workaround.
 */

angular.module('dugun.provider')
    .directive('uiSelectRequired', function () {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            if(attrs.uiSelectRequired == 'true') {
                var selectElement = $('<select class="hidden-select"></select>')
                    .prop('required', true);
                $(element).after(selectElement);

                scope.$watch(function () {
                    return ngModel.$modelValue;
                }, function (newValue) {
                    if(newValue) {
                        selectElement.append('<option value="'+ newValue +'"></option>')
                            .find('option:last-child')
                            .prop('selected', true);
                    } else {
                        selectElement.find('option').remove();
                    }
                });
            }
        }
    };
});
