function ItemsCtrl($scope, $routeParams, $window, $filter, $timeout, $translate, $uibModalInstance, dgNotifications, Items, itemId, MediaUpload, Galleries, Media, Providers) {
    $scope.cleanData = {};
    $scope.providerId = $window.parseInt($routeParams.providerId ) || null;
    $scope.providerGalleryId = null;
    $scope.itemId = itemId || null;
    $scope.isPageChoosen = false;
    $scope.uploadedImages = [];
    $scope.mediaPagination = {
        currentPage: 1
    };
    $scope.dirtyData = {
        itemOptionData: []
    };
    $scope.details = {
        cleanData: [],
        dirtyData: []
    };
    $scope.detailsCheckboxes = {
        cleanData: {},
        dirtyData: {}
    };
    $scope.loading = [];

    $scope.close = function () {
        $uibModalInstance.close(angular.copy($scope.cleanData));
    };

    $scope.getItem = function () {
        var loading = Items.get({
            id: $scope.itemId,
            providerId: $scope.providerId
        }, function (successResponse) {
            $scope.cleanData = successResponse;
            $scope.dirtyData = angular.copy($scope.cleanData);
            $scope.detailsData.checkboxData = [];
            transformResponse(successResponse.data);
            $scope.cleanData = successResponse;

            if ($scope.dirtyData.status === 'active' ) {
                $scope.dirtyData.status = true;
            } else {
                $scope.dirtyData.status = false;
            }

        }, function (errorResponse) {
            dgNotifications.error(errorResponse);
        });

    };

    function transformResponse(data) {
        angular.forEach(data, function (value, index) {
            if (value.inputType === 'checkbox') {
                angular.forEach(value.itemOptions, function (option) {
                    if (value.value && value.value.indexOf(option.id) !== -1) {
                        $scope.detailsData.checkboxData.push(option);
                    }
                });
            }

            if (value.inputType === 'select') {
                angular.forEach(value.itemOptions, function (option) {
                    if (value.value && value.value.indexOf(option.id) !== -1) {
                        $scope.detailsData.selectData.push(option);
                    }
                });
            }

            if (value.inputType === 'radio') {
                angular.forEach(value.itemOptions, function (option) {
                    if (value.value && value.value.indexOf(option.id) !== -1) {
                        $scope.detailsData.radioData[value.id] = option;
                    }
                });
            }

            if (value.inputType === 'textarea') {
                if (value.value) {
                    $scope.detailsData.textAreaData[value.id] = value.value;
                }
            }
        });

    }

    $scope.set = function () {
        if ($scope.uploadedImages.length === 0 && $scope.itemId === null) {
            dgNotifications.error($translate.instant('ITEMS.CHOOSE_PRODUCT_WARNING'));
            return;
        }
        var data = $scope.dirtyData.getDiff($scope.cleanData),
            method = 'insert',
            params = {
                providerId: $scope.providerId
            };

        data.status = data.status ? 'active' : 'passive';
        data.orderNumber = $scope.total + 1;
        data.itemOptionData = [];
        data.itemOptionGroupData = [];
        angular.forEach($scope.detailsData.checkboxData, function (value, index) {
            data.itemOptionData.push(value);
        });

        angular.forEach($scope.detailsData.selectData, function (value, index) {
            data.itemOptionData.push(value);
        });

        angular.forEach($scope.detailsData.textAreaData, function (value, index) {
            data.itemOptionGroupData.push({
                id: index,
                value: value
            });

        });

        angular.forEach($scope.detailsData.radioData, function (value, index) {
            if (typeof value === 'string') {
                value = JSON.parse(value);
            }
            data.itemOptionData.push(value);
        });

        if (data.itemOptionData && data.itemOptionData.length !== 0 && typeof data.itemOptionData !== 'undefined') {
            angular.forEach(data.itemOptionData, function (value, index) {
                if (typeof value !== 'undefined') {
                    data.itemOptionData[index] = {
                        id: value.id
                    };
                }
            });
            var newArray = [];
            for (var i = 0; i < data.itemOptionData.length; i++) {
                if (data.itemOptionData[i]){
                    newArray.push(data.itemOptionData[i]);
                }
            }
            data.itemOptionData = newArray;
        } else {
            delete data.itemOptionData;
        }

        if ($scope.itemId) {
            method = 'update';
            params.id = $scope.itemId;
        }

        var loading = Items[method](
            params,
            data,
            function (successResponse) {
                if (method == 'insert') {
                    $scope.itemId = successResponse.id;
                    $scope.newItem = successResponse.id;
                    $scope.uploadImages($scope.uploadedImages);
                }
                dgNotifications.success($translate.instant('GENERAL.ADDED_SUCCESSFULLY'));
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }

        );
        $scope.loading.push(loading);
    };

    $scope.providerItemsCount = function () {
        var data = {
            page: 1,
            limit: 10,
            providerId: $scope.providerId
        };
        var loading = Items.query(
            data,
            function (successResponse) {
                $scope.total = successResponse.meta.total;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    $scope.detailsData = {
        selectData: [],
        checkboxData: [],
        radioData: [],
        textAreaData: []
    };

    $scope.checkBoxOnChange = function (checked, checkbox) {
        if (checked) {
            $scope.detailsData.checkboxData.push({
                id: checkbox.id,
                name: checkbox.name
            });
        } else {
            for(var i=0 ; i < $scope.detailsData.checkboxData.length; i++) {
                if($scope.detailsData.checkboxData[i] && $scope.detailsData.checkboxData[i].id == checkbox.id){
                    delete $scope.detailsData.checkboxData[i];
                }
            }
        }
    };

    $scope.isChecked = function (option) {
        var checked = false;
        angular.forEach($scope.detailsData.checkboxData, function (value, index) {
            if (value.id === option.id) {
                checked = true;
            }
        });
        return checked;
    };

    $scope.isCheckedRadio = function (option) {
        var checked = false;
        angular.forEach($scope.detailsData.radioData, function (value, index) {
            if (value.id === option.id) {
                checked = true;
            }
        });
        return checked;
    };

    $scope.getProviderItemBrands = function () {
        Items.getProviderItemBrands(
            {
                providerId: $scope.providerId
            },
            function (successResponse) {
                $scope.itemBrands = successResponse.data;
            }
        );
    };

    $scope.getItemOptionGroups = function () {
        $timeout(function () {
            var loading = Items.getItemOptionGroups(
                {
                    providerCategoryId: $scope.providerCategoryId,
                    isActive: true
                },
                function (successResponse) {
                    $scope.itemOptionGroups = {
                        checkbox: [],
                        textarea: [],
                        select: [],
                        radio: []
                    };
                    angular.forEach(successResponse.data, function (value, index) {
                        if (value.inputType == 'checkbox') {
                            $scope.itemOptionGroups.checkbox.push(value);
                        } else if (value.inputType == 'textarea') {
                            $scope.itemOptionGroups.textarea.push(value);
                        } else if (value.inputType == 'select') {
                            $scope.itemOptionGroups.select.push(value);
                        } else if (value.inputType == 'radio') {
                            $scope.itemOptionGroups.radio.push(value);
                        }
                    });
                }
            );
            $scope.loading.push(loading);

        }, 2000);

    };

    //media
    $scope.uploadImages = function ($files) {
        $scope.list = [];
        var params = {
            galleryId: $scope.providerGalleryId
        };

        var data = {
            providerItemId: $scope.itemId
        };

        angular.forEach($files, function (file) {
            file.upload = MediaUpload.upload(file, params, data);
            file.progress = 0;
            
            file.upload.then(function (successResponse) {
                file.result = successResponse.data;
                dgNotifications.success($translate.instant('ITEMS.IMAGES_UPLOADED'));
                $scope.getMedias();
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            }, function (event) {
                file.progress = (event.loaded / event.total) * 100;
            });
            $scope.list.push(file);
        });
    };

    $scope.getMedias = function () {
        if (!$scope.itemId) return;

        var data = {
            galleryId: $scope.providerGalleryId,
            providerItemId: $scope.itemId
        };

        var loading = Media.query(data, function (successResponse) {
            $scope.mediaList = successResponse.data;
            var count = 0, indexOfMainImage = 0;
            orderList();
            var temp = $scope.mediaList[0];
            $scope.mediaList[0] = $scope.mediaList[indexOfMainImage];
            $scope.mediaList[indexOfMainImage] = temp;
        }, function (errorResponse) {
            dgNotifications.error(errorResponse);
        });
        $scope.loading.push(loading);
    };

    $scope.deleteMedia = function (media) {
        var urlData = {
            galleryId: media.gallery.id,
            id: media.id
        };

        var index = $scope.mediaList.indexOf(media);
        $scope.mediaList.splice(index, 1);
        Media.delete(
            urlData,
            function (successResponse) {
                $scope.getMedias();
                dgNotifications.success($translate.instant('ITEMS.DELETED_SUCCESSFULLY'));
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };
    
    $scope.$watch('mediaPagination.currentPage', function (newVal, oldVal) {
        if (typeof newVal === 'undefined') return;
        if (newVal != oldVal) {
            $scope.mediaPagination.currentPage = newVal;
            $scope.isPageChoosen = true;
            $scope.getMedias();
        }
    });

    $scope.getGalleries = function () {
        var providerIds = new Array($scope.providerId.toString());
        Galleries.query({"providerIds[]": providerIds, isMainGallery: 1}, function (successResponse) {
            if (successResponse.data && successResponse.data.length !== 0) {
                $scope.providerGallery = successResponse.data;
                $scope.providerGalleryId = successResponse.data[0].id;
            }
        }, function (errorResponse) {
            dgNotifications.error(errorResponse);
        });
    };

    function orderList() {
        $scope.mediaList = $filter('orderBy')($scope.mediaList, ['-status', 'orderNum']);
    }

    $scope.getProvider = function () {
        Providers.get({id: $scope.providerId}, function (successResponse) {
            $scope.providerCategoryId = successResponse.data.category.id;
        }, function (errorResponse) {
            dgNotifications.error(errorResponse);
        });
    };

    $scope.setMainImage = function (media) {
        var data = {providerId: $scope.providerId, id: $scope.itemId, mainImage: {id: media.id}};
        Items.update(data, function (successResponse) {
            dgNotifications.success($translate.instant('ITEMS.MAIN_IMAGE_CHOSEN'));
            $scope.getMedias();
            $scope.getItem();
        }, function (errorResponse) {
            dgNotifications.error(errorResponse);
        });
    };

    $scope.getImages = function ($files) {
        $scope.uploadedImages = $files;
    };

    $scope.getProvider();
    $scope.getItemOptionGroups();
    $scope.getGalleries();
    $scope.providerItemsCount();

    if (itemId) {
        $scope.getItem();
        $timeout(function () {
            $scope.getMedias();
        },1000);
    }
}

ItemsCtrl.$inject = [
    '$scope',
    '$routeParams',
    '$window',
    '$filter',
    '$timeout',
    '$translate',
    '$uibModalInstance',
    'dgNotifications',
    'Items',
    'itemId',
    'MediaUpload',
    'Galleries',
    'Media',
    'Providers',
];

angular.module('dugun.items').controller('ItemsCtrl', ItemsCtrl);
