angular.module('dugun.config')
    .factory('dugunConfig', [
        '$filter',
        '$q',
        'resource',
        'CacheFactory',
        DugunConfig
    ]);

function DugunConfig($filter, $q, resource, CacheFactory) {
    var url = 'configs',
        items = {},
        loading = $q.defer();

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            query: {
                isArray: false,
                cache: getCacheFactory()
            }
        },
        {
            cache: getCacheFactory()
        }
    );

    function init() {
        resourceService.query(null, initSuccess, initError);
        return loading.promise;
    }

    function initSuccess(response) {
        for(var i in response.data) {
            response.data[i] = convertNumbers($filter('filter')(response.data[i], {status: true}));
        }

        items = response.data;

        for(var j in items) {
            items[j] = $filter('orderBy')(items[j], 'value');
        }

        loading.resolve(items);

        return items;
    }

    function initError(response, a, b, c) {
        loading.reject(items);
        console.error(response, a, b, c);
    }

    function getItems(type) {
        if(typeof items[type] === 'undefined') {
            return [];
        }

        return items[type];
    }

    function getItem(type, id) {
        if(typeof items[type] === 'undefined') {
            return null;
        }
        for(var i in items[type]) {
            if(items[type][i].itemId == id) {
                return items[type][i].value;
            }
        }

        return null;
    }

    function getCacheFactory() {
        return CacheFactory.get('config') || CacheFactory('config', {
            maxAge: 24*60*60*1000, // 1 day
            onExpire: cacheExpired
        });
    }

    function cacheExpired() {
        init();
    }

    // Converts stringified items in object to number
    function convertNumbers(object) {
        for(var i in object) {
            if(typeof object[i] === 'object') {
                object[i] = convertNumbers(object[i]);
            } else if(object[i] === 'true' || object[i] === 'false') {
                object[i] = object[i] === 'true' ? true : false;
            } else if(!isNaN(object[i]) && object[i] !== '' && typeof object[i] !== 'boolean') {
                object[i] = parseFloat(object[i]);
            }
        }
        return object;
    }

    return {
        getItems: getItems,
        getItem: getItem,
        init: init,
        loading: loading.promise
    };
}
