function DgTableSort() {
    return {
        restrict: 'AC',
        scope: {
            dgTableSort: '@',
            orderBy: '='
        },
        link: function(scope, element, attrs) {
            scope.$watch('orderBy', function() {
                if(scope.orderBy.key === scope.dgTableSort) {
                    if(scope.orderBy.direction === true) {
                        element.removeClass('dg-table-sort-descent')
                            .addClass('dg-table-sort-ascent');
                    } else {
                        element.removeClass('dg-table-sort-ascent')
                            .addClass('dg-table-sort-descent');
                    }
                } else {
                    element.removeClass('dg-table-sort-ascent')
                        .removeClass('dg-table-sort-descent');
                }
            }, true);
        }
    };
}

angular.module('dugun.helpers.tableSort')
    .directive('dgTableSort', DgTableSort);
