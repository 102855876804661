(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/couple-header.html',
    '<div class="header-title couple-header-title" lang="tr"><div class="fs-18"><i class="fa fa-arrow-circle-left panel-color go-couple-list" ng-click="heading.scope.hideDetails(true)"></i> <span class="couple-seperator"></span> <span class="couple-header-name">{{heading.data.leadDetails.name ? (heading.data.leadDetails.name | limitTo: screenWidth == \'xs\' ? 21 : 35) : (heading.data.leadDetails.phone.number || heading.data.leadDetails.phone)}} </span>&nbsp;<div class="edit-couple" ng-click="heading.scope.openCoupleLeadForm()" title="{{\'LEADS.COUPLE_EDIT\' | translate}}"><i class="fa fa-pencil-alt"></i></div><span class="couple-header-actions"><span class="couple-seperator hidden-xs">|</span> <span class="clearfix visible-xs"></span><div class="btn-group" ng-if="![\'offered\', \'new\', \'reject\'].includes(heading.scope.leadData.status) && heading.scope.leadData.status === \'negotiating\'"><button class="btn btn-sm btn-blue" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth === \'xs\',\n' +
    '                    }"><i class="far fa-dot-circle"></i>&nbsp; {{\'LEADS.NEGOTIATING\' | translate}} {{ heading.scope.leadData.processState ? \'- \' + heading.scope.statuses[heading.scope.leadData.processState] : \'\' }}</button> <a class="btn btn-sm btn-blue dropdown-toggle" data-toggle="dropdown"><i class="fa fa-angle-down"></i></a><ul class="dropdown-menu dropdown-blue" role="menu"><li><a data-track="lead-status" data-item="offered" ng-click="heading.scope.toggleStatus(\'offered\')">{{ heading.scope.statuses.offered }}</a></li><li><a data-track="lead-status" data-item="offer-pending" ng-click="heading.scope.openRemindedAtModal(\'offer_pending\')">{{ heading.scope.statuses.offer_pending }}</a></li><li><a data-track="lead-status" data-item="appointment" ng-click="heading.scope.openRemindedAtModal(\'appointment\')">{{ heading.scope.statuses.appointment }}</a></li></ul></div><span class="couple-seperator" ng-if="heading.scope.showInteractionButtons"></span> <span ng-if="heading.scope.leadData.status !== \'new\'"><button class="btn btn-filter deal-btn" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                    }" title="{{\'LEADS.DEAL\' | translate}}" style="cursor: default" ng-if="heading.scope.leadData.status == \'deal\'" data-track="lead-status" data-item="deal"><i class="fa fa-dot-circle"></i> {{\'LEADS.DEAL\' | translate}}</button> <button class="btn btn-default reject-btn" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                    }" title="{{\'LEADS.REJECT\' | translate}}" style="cursor: default" ng-if="heading.scope.leadData.status == \'reject\'" data-track="lead-status" data-item="reject"><i class="fa fa-dot-circle"></i> {{\'LEADS.REJECT\' | translate}}: {{heading.scope.leadData.rejectReason}}</button> <span class="couple-seperator"></span> <button class="btn btn-default" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                    }" title="Değiştir" ng-click="heading.scope.toggleInteractionButtons()" ng-if="heading.scope.leadData.status == \'deal\' || heading.scope.leadData.status == \'reject\' || heading.scope.leadData.status == \'offered\'" style="background-color: transparent;border-color: transparent;box-shadow: none;color: #777" data-track="lead-status" data-item="change"><i class="fas fa-exchange-alt"></i> {{\'LEADS.CHANGE_STATUS\' | translate}}</button> </span><span ng-if="heading.scope.leadData.status !== \'new\'"><button class="btn btn-filter deal-btn" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                    }" title="{{\'LEADS.DEAL\' | translate}}" ng-click="heading.scope.toggleStatus(\'deal\')" ng-if="![\'reject\', \'deal\'].includes(heading.scope.leadData.status)" data-track="lead-status" data-item="deal"><i class="fa fa-circle"></i> {{\'LEADS.DEAL\' | translate}}</button> <span class="couple-seperator" ng-if="heading.scope.showInteractionButtons"></span> <button class="btn btn-default reject-btn" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                     }" title="{{\'LEADS.REJECT\' | translate}}" ng-click="heading.scope.openRejectionModal()" ng-if="![\'reject\', \'deal\'].includes(heading.scope.leadData.status)" data-track="lead-status" data-item="reject"><i class="fa fa-circle"></i> {{\'LEADS.REJECT\' | translate}}</button> <span class="couple-seperator" ng-if="heading.scope.showInteractionButtons"></span> <button class="btn btn-sm mr-5 btn-danger bold-text" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                    }" ng-disabled="true" ng-if="heading.scope.leadData.status == \'spamCall\'">{{\'LEADS.SPAM_CALL_REPORTED\' | translate}}</button> </span><span ng-if="heading.scope.leadData.status == \'new\'"><button class="btn btn-primary" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                    }" title="Yeni Teklif" style="background-color: transparent !important;box-shadow: none;color: #db0962 !important" data-track="lead-status" data-item="new">{{\'LEADS.NEW_LEAD\' | translate}}</button> <button class="btn btn-default" ng-class="{\n' +
    '                        \'btn-sm\' : screenWidth !== \'xs\',\n' +
    '                        \'btn-xs\' : screenWidth == \'xs\',\n' +
    '                    }" ng-click="heading.scope.toggleInteractionButtons()" style="background-color: transparent;border-color: transparent;box-shadow: none;color: #777" data-track="lead-status" data-item="new">{{heading.scope.leadData.addedFrom}}</button></span></span></div></div><div class="header-buttons hidden-md hidden-xs"><div class="btn-group mr-10" style="margin-top: 17px"><button class="btn btn-sm btn-blue dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-print"></i> {{\'LEADS.DOWNLOAD\' | translate}} <span class="caret"></span></button><ul class="dropdown-menu" role="menu" style="right: 0;left: auto"><li class="pr-20 pl-20" style="cursor: pointer"><p ng-click="heading.scope.printLead(\'all\')">{{\'LEADS.DOWNLOAD_ALL\' | translate}}</p></li><li class="pr-20 pl-20" style="cursor: pointer"><p class="m-0" ng-click="heading.scope.printLead()">{{\'LEADS.DOWNLOAD\' | translate}}</p></li></ul></div><button ng-if="environment.fieldsSettings.help" class="btn btn-sm btn-default tutorial-button icon-only" ng-click="heading.scope.openLandingPage()"><i class="fa fa-question-circle"></i></button></div>');
}]);
})();
