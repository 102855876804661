(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/lead-messages/components/call.html',
    '<div class="comment"><span class="comment-avatar text-center"><span ng-if="item.type == \'outbound\' || item.type == \'missedOutbound\' || item.type == \'waitingStatusOutbound\'" class="fa-2x" ng-class="{\n' +
    '                \'panel-color\': item.data.extra.successful,\n' +
    '                \'pink-color\': !item.data.extra.successful,\n' +
    '                \'gray\': item.type == \'waitingStatusOutboundCall\'\n' +
    '            }" style="position: relative; display: block"><i class="fas fa-phone fa-stack-1x" style="height: auto; width: auto"></i> <i class="fas fa-long-arrow-alt-up fa-stack-1x" style="transform: rotateZ(45deg);\n' +
    '                margin-left: -11px;\n' +
    '                margin-top: -3px;\n' +
    '                height: auto;\n' +
    '                width: auto"></i> </span><span ng-if="item.type == \'inbound\' || item.type == \'missedInbound\' || item.type == \'waitingStatusInbound\'" ng-class="{\n' +
    '                \'panel-color\': item.data.extra.successful,\n' +
    '                \'pink-color\': !item.data.extra.successful,\n' +
    '                \'gray\': item.type == \'waitingStatusInbound\'\n' +
    '            }" class="fa-2x"><i class="fas fa-phone fa-stack-1x"></i> <i class="fas fa-long-arrow-alt-up fa-stack-1x" style="transform: rotateZ(-135deg);\n' +
    '                margin-left: -11px;\n' +
    '                margin-top: -6px"></i></span></span><div class="comment-body"><div class="comment-text" style="padding: 0;border: none"><div class="comment-header p-0"><label class="m-0 text-bold fs-12">{{checkIsSameYear(item.data.date) ? (item.data.date | date: \'dd MMMM EEEE, HH:mm\') : (item.data.date | date: \'dd MMMM EEEE yyyy, HH:mm\') }}</label></div><i ng-if="(item.type == \'outbound\' || item.type == \'missedOutbound\' || item.type == \'waitingStatusOutbound\') && message.callerNumber" ng-bind="message.callerNumber | phone"></i> <span class="fs-12" ng-bind="item.data.text"></span></div></div></div>');
}]);
})();
