(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('related-providers/list.html',
    '<div cg-busy="loading"><div ng-if="relatedProviders.length == 0" class="text-center"><h2>{{ \'RELATED_PROVIDERS.NO_RELATED_PROVIDERS_DATA\' | translate }}</h2></div><div ng-if="relatedProviders.length > 0" class="row" ng-repeat="item in relatedProviders"><div class="col-md-12"><div class="databox databox-xlg databox-vertical databox-shadowed bg-white radius-bordered panel-card ng-scope" style="height: 100%"><div class="databox-top"><div class="databox-row row-12"><div class="databox-number padding-10 storm-cloud databox-cell cell-12 text-align-left">{{item.relatedProvider.name}} - {{item.relatedProvider.category.name}} / {{item.relatedProvider.city.name}} <button class="btn btn-primary pull-right" ng-click="openAddGallery(item)"><i class="fa fa-plus"></i>{{ \'RELATED_PROVIDERS.ADD_NEW\' | translate }}</button></div></div></div><div class="databox-bottom bg-whitesmoke no-padding" style="height: 100%;color: #555; font-size: 13px"><div class="databox-cell cell-12 p-20"><div ng-if="item.galleries.length == 0" class="text-center"><h4>{{ \'RELATED_PROVIDERS.NO_GALLERY_DATA_FOUND\' | translate }}</h4></div><table ng-if="item.galleries.length > 0" class="table table-striped table-bordered table-hover" cg-busy="loading" style="position: relative"><thead><tr><th>{{ \'RELATED_PROVIDERS.GALLERY_NAME\' | translate }}</th><th>{{ \'RELATED_PROVIDERS.COUPLE_NAME\' | translate }}</th><th>{{ \'RELATED_PROVIDERS.WEDDING_DATE\' | translate }}</th><th></th></tr></thead><tbody><tr ng-repeat="gallery in item.galleries"><td>{{gallery.gallery.name}}</td><td>{{gallery.coupleName}}</td><td>{{gallery.weddingDate | date:\'longDate\'}}</td><td><button class="btn btn-filter btn-sm" ng-click="goToGallery(item.provider.id, gallery.gallery.id, gallery.id)"><i class="fa fa-photo"></i> {{ \'RELATED_PROVIDERS.ADD_PHOTO\' | translate }}</button></td></tr></tbody></table></div></div></div></div></div></div>');
}]);
})();
