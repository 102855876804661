/**
 * Controls Provider
 * @ngdoc controller
 * @memberof dugun.relatedProviders
 * @name RelatedProvidersCtrl
 *
 * @requires $scope
 * @requires $rootScope
 * @requires $route
 * @requires $routeParams
 * @requires $window
 * @requires $location
 * @requires $filter
 * @requires dugun.notifications:dgNotifications
 * @requires RelatedProviders
 *
 */
function RelatedProvidersCtrl($scope, $rootScope, $route, $routeParams, $window, $location, $filter,
                      dgNotifications,
                      RelatedProviders) {

    $scope.id = $window.parseInt($routeParams.providerId) || null;
    $scope.loading = [];

    /**
     * @ngdoc method
     * @memberof RelatedProvidersCtrl
     * @description
     * Gets the related providers
     */
    $scope.loadList = function () {
        var loading = RelatedProviders.get({
                providerId: $scope.id
            },
            function(successResponse) {
                $scope.relatedProviders = $filter('filter')(angular.copy(successResponse.data), function(value){
                    return value.status === 'approved';
                });
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof RelatedProvidersCtrl
     * @description
     * open add gallery modal
     */
    $scope.openAddGallery = function (item) {
        $scope.openModal(
            'related-providers/form.modal.html',
            'RelatedProviderGalleryCtrl',
            null,
            null,
            item
        ).result.then(function(modalResponse) {
            $scope.loadList();
        });
    };

    /**
     * @ngdoc method
     * @memberof RelatedProvidersCtrl
     * @description
     * go to gallery page
     */
    $scope.goToGallery = function (providerId, galleryId, relatedProviderGalleryId) {
        $location.path('/providers/' + providerId + '/related-providers-galleries/' + galleryId + '/' + relatedProviderGalleryId);
    };

    if($scope.id) {
        $scope.loadList();
    }

    $rootScope.heading.name = $route.current.label;
    $rootScope.tutorials = [];
}

RelatedProvidersCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$route',
    '$routeParams',
    '$window',
    '$location',
    '$filter',
    'dgNotifications',
    'RelatedProviders',
];

angular.module('dugun.relatedProviders')
    .controller('RelatedProvidersCtrl', RelatedProvidersCtrl);
