(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/city/city.html',
    '<ui-select ng-model="$parent.model" ui-select-required="{{ required }}" class="single-select full-width" theme="bootstrap"><ui-select-match placaholder="{{ attrs.placeholder || (\'DG_FORMS.CHOOSE_CITY\' | translate) }}" allow-clear="{{ allowClear }}"><p>{{ $select.selected.name }}</p></ui-select-match><ui-select-choices repeat="city[modelParam] as city in $root.getCities()"><p>{{ city.name }}</p></ui-select-choices></ui-select>');
}]);
})();
