function Dashboards(resource, CacheFactory) {

    var resourceService = resource(
        null,
        null,
        {
            estimate: {
                url: 'couple-providers-stats/estimate',
                method: 'GET'
            },
            unreadCount: {
                url: 'leads/unread-counts',
                method: 'GET'
            },
            visitLogs: {
                url: 'visit-logs',
                method: 'GET'
            },
            newsletters: {
                url: 'providerpanel-newsletters',
                method: 'GET'
            },
            providerCategoryActivity: {
                url: 'provider-activity/by-provider-category',
                method: 'GET',
                cache: getCacheFactory()
            }
        }
    );

    function getCacheFactory() {
        return CacheFactory.get('providerCategoryActivityCache') || CacheFactory('providerCategoryActivityCache', {
                maxAge: 24*60*60*1000 // 1 day
            });
    }

    return resourceService;
}

Dashboards.$inject = [
    'resource',
    'CacheFactory'
];

angular.module('dugun.dashboards')
    .factory('Dashboards', Dashboards);
