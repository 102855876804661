/**
 * Controls the gallery image modals
 * @ngdoc controller
 * @memberof dugun.galleries.media
 * @name GalleryImageCtrl
 *
 * @requires $scope {service} controller scope
 * @requires $routeParams {service} $routeParams
 * @requires $uibModalInstance {service} $uibModalInstance
 * @requires $window {service} $window
 * @requires $log {service} $log
 * @requires dgNotifications {service} dgNotifications
 * @requires Analytics
 * @requires $translate
 * @requires Media {factory} Media service
 * @requires GalleryCategoryInfoTags {factory} GalleryCategoryInfoTags service
 *
 * @param itemId {integer=} ID of media to use
 * @param parentId {integer=} ID of gallery to use
 * @param data {Object=} An object with categoryId of gallery
 */
function GalleryImageCtrl($scope, $routeParams, $uibModalInstance, $window, $log,
                      dgNotifications, Analytics, $translate,
                      Media, GalleryCategoryInfoTags,
                      itemId, parentId, data) {

    var self = this;

    /**
     * @memberOf GalleryImageCtrl
     * @description
     * ID of current item if exists
     */
    $scope.id = $window.parseInt(itemId) || null;

    /**
     * @ngdoc method
     * @memberOf GalleryImageCtrl
     * @description
     * Provider Id
     */
    $scope.providerId = $window.parseInt($routeParams.providerId) || null;

    /**
     * @memberOf GalleryImageCtrl
     * @description
     * ID of gallery to add image.
     */
    $scope.galleryId = $window.parseInt(parentId) || null;

    /**
     * @memberOf GalleryImageCtrl
     * @description
     * gallery Object
     */
    $scope.gallery = data.gallery || null;

    /**
     * @memberOf GalleryImageCtrl
     * @description
     * Object that keeps clean form data.
     */
    $scope.cleanData = {};

    /**
     * @memberOf GalleryImageCtrl
     * @description
     * Object that keeps dirty form data.
     */
    $scope.dirtyData = {};

    $scope.loading = [];

    $scope.categoryId = data.categoryId;

    $scope.isMainImage = data.isMainImage;

    $scope.now = new Date().getTime() / 1000;

    $scope.showImage = false;

    $scope.imageTags = {};

    $scope.imageTagsList = [];

    $scope.imageSize = {
        width: null,
        height: null,
        ratio: null
    };

    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('GENERAL.UNSAVED_INFO_WARNING'),
            data = $scope.dirtyData.getDiff($scope.cleanData);
        delete data.bounds;
        if(angular.equals(data, {})) {
            return;
        }
        switch (reason){
            // clicked outside
            case "backdrop click":
                break;

            // cancel button
            case "cancel":
                break;

            // escape key
            case "escape key press":
                break;
        }
        if (!confirm(message)) {
            event.preventDefault();
        }
    });
    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function(data) {
        if(data) $uibModalInstance.close(data);
        else $uibModalInstance.dismiss('cancel');
    };

    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @description
     * Gets the image
     */
    $scope.getImage = function() {
        var loading = Media.get(
            {
                id: $scope.id,
                galleryId: $scope.galleryId,
                'scopes[]': ['base64', 'tags', 'infoTags']
            },
            self.getMediaSuccess,
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @private
     * @param response {object}
     * @description
     * Success callback of getMedia
     */
    self.getMediaSuccess = function(response) {
        var infoTagIds = [],
            i;

        $scope.cleanData = mapData(response.data);
        $scope.dirtyData = angular.copy($scope.cleanData);

        $scope.dirtyData.bounds = {};
        getImageSize($scope.dirtyData.imageUrl.originalBase64);
        $scope.now = new Date().getTime() / 1000;
        $scope.showImage = true;

        for(i in $scope.dirtyData.infoTags) {
            infoTagIds.push($scope.dirtyData.infoTags[i].infoTagId);
        }

        for(i in $scope.categoryInfoTags) {
            if(infoTagIds.indexOf($scope.categoryInfoTags[i].id) === -1) {
                $scope.dirtyData.infoTags.push({
                    infoTagId: $scope.categoryInfoTags[i].id,
                    infoTagOptionId: undefined
                });
            }
        }
    };

    function mapData(data) {
        var tags = [];

        for(var i in data.tags) {
            tags.push(data.tags[i].id);
            $scope.imageTags[data.tags[i].id] = true;
        }
        data.tags = tags;
        return data;
    }

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @param tagId {integer} Id of tag that toggled
     * @description
     * Called when a tag is clicked. If value is true, insert the item
     * to $scope.dirtyData.tags, else remove it.
     */
    $scope.checkboxChanged = function(tagId) {
        var checked = $scope.imageTags[tagId];

        if(checked) {
            if(!angular.isArray($scope.dirtyData.tags)) {
                $scope.dirtyData.tags = [];
            }
            $scope.dirtyData.tags.push(tagId);
        } else {
            var index = $scope.dirtyData.tags.indexOf(tagId);
            $scope.dirtyData.tags.splice(index, 1);
        }
    };

    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @param base64encodedImage {string} Base64 encoded image to get sizes
     * @description
     * Gets the image size and calculates $scope.imageSize object
     */
    function getImageSize(base64encodedImage) {
        var img = new Image();
        img.onload = function () {
            $scope.$apply(function() {
                $scope.imageSize = {
                    width: img.width,
                    height: img.height,
                    ratio: img.width / img.height,
                    canvasWidth: 1000,
                    canvasHeight: 1000
                };

                if(img.width > 700) {
                    $scope.imageSize.canvasWidth = 700;
                } else {
                    $scope.imageSize.canvasWidth = img.width;
                }
                $scope.imageSize.canvasHeight = $scope.imageSize.canvasWidth / $scope.imageSize.ratio;

                $scope.imageSize.canvasRatio = $scope.imageSize.width / $scope.imageSize.canvasWidth;
                $scope.imageSize.canvasMinWidth = $window.parseInt((800 / $scope.imageSize.canvasRatio).toFixed());
                $scope.imageSize.canvasMinHeight = $window.parseInt((600 / $scope.imageSize.canvasRatio).toFixed());
            });
        };
        img.src = base64encodedImage;
    }

    $scope.rotateImage = function(){
        var urlData = {
            galleryId: $scope.galleryId,
            id: $scope.id
        };

        var putData = {
            rotateClockWise : 90
        };

        var loading = Media.update(
            urlData,
            putData,
            function(successResponse) {
                dgNotifications.success($translate.instant('GALLERIES.ROTATED_SUCCESSFULLY'));
                $scope.imageSize = {
                    width: null,
                    height: null,
                    ratio: null
                };
                $scope.getImage();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @description
     * Saves the image
     */
    $scope.setImage = function() {
        var i,
            putData = $scope.dirtyData.getDiff($scope.cleanData),
            urlData = {
                galleryId: $scope.galleryId,
                id: $scope.id
            },
            infoTags = [];

        if(angular.equals(putData, {})) return true;

        putData = processImageBounds(putData);

        for(i in putData.infoTags) {
            if(putData.infoTags[i].infoTagOptionId) {
                infoTags.push(putData.infoTags[i]);
            }
        }

        putData.infoTags = infoTags;

        var loading = Media.update(
            urlData,
            putData,
            function(successResponse) {
                $scope.cleanData = angular.copy($scope.dirtyData);
                dgNotifications.success($translate.instant('GALLERIES.SAVED'));

                putData.success = true;
                Analytics.trackEvent('imageForm', 'edit', 'success');
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);

                putData.success = false;
                putData.error = errorResponse.data.message;
                Analytics.trackEvent('imageForm', 'edit', 'failed : ' + errorResponse.data.message);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @private
     * @param data {Object} $scope.dirtyData.bounds object
     * @description
     * Calculates and moves the image crop data into data object
     */
    function processImageBounds(data) {
        if(!data.bounds) return data;

        var bounds = data.bounds,
            i;

        bounds.cropStartX = data.bounds.left;
        bounds.cropStartY = $scope.imageSize.height - data.bounds.top;
        bounds.cropWidth = data.bounds.right - data.bounds.left;
        bounds.cropHeight = data.bounds.top - data.bounds.bottom;

        for(i in bounds) {
            data[i] = bounds[i];
        }

        delete data.bounds;

        return data;
    }

    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @param infoTagId {integer}
     * @description
     * Get info tag with given id
     */
    $scope.getInfoTag = function(infoTagId) {
        return GalleryCategoryInfoTags.getInfoTag(infoTagId);
    };

    /**
     * @ngdoc method
     * @memberof GalleryImageCtrl
     * @private
     * @description
     * Initializes the controller
     */
    self.init = function() {
        var promise = GalleryCategoryInfoTags.init();
        $scope.loading.push(promise);

        promise.then(function() {
            if($scope.categoryId) {
                $scope.categoryInfoTags = GalleryCategoryInfoTags.getCategoryInfoTags($scope.categoryId);
            }
            if($scope.id) {
                $scope.getImage();
            } else {
                $scope.dirtyData.galleryId = $scope.galleryId;
            }
        });
    };

    self.init();
}

GalleryImageCtrl.$inject = [
    '$scope',
    '$routeParams',
    '$uibModalInstance',
    '$window',
    '$log',
    'dgNotifications',
    'Analytics',
    '$translate',
    'Media',
    'GalleryCategoryInfoTags',
    'itemId',
    'parentId',
    'data',
];

angular.module('dugun.galleries.media')
    .controller('GalleryImageCtrl', GalleryImageCtrl);
