/**
 * @memberof dugun.galleries
 * @ngdoc overview
 * @description
 * Galleries main module
 */
angular.module('dugun.galleries', [
    // app
    'dugun.galleries.galleries',
    'dugun.galleries.media',
]);
