/**
 * @ngdoc directive
 * @name dugun.forms:dgFormProvider
 * @restrict 'E'
 * @scope
 **/
function DgFormProvider(DgFormProviderService) {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            model: '=ngModel',
            allowClear: '@',
            required: '=ngRequired',
            id: '@dgId',
            itemsToPush: '=pushItems',
            ngChange: '&',
            customerId: '=',
            categoryId: '=',
            cityId: '=',
            options: '&',
            ngDisabled: '&'
        },
        templateUrl: 'dg-forms/form-elements/related-provider/provider.html',
        link: function(scope, element, attrs) {
            scope.attrs = attrs;

            scope.searcher = new DgFormProviderService();
            scope.search = function(text) {
                var filters = {},
                    validFilters = ['customerId', 'categoryId', 'cityId'],
                    i;

                for(i in validFilters) {
                    if(scope[validFilters[i]]) {
                        filters[validFilters[i]] = scope[validFilters[i]];
                    }
                }

                return scope.searcher.providers(text || null, filters, scope.options());
            };

            scope.selected = function($item, $model) {
                scope.ngChange({selectedValue: $item, model: $model});
            };

            scope.$watch('customerId', function(newValue, oldValue){
                if(newValue != oldValue){
                    scope.searcher.providers(null, {'customerId': scope.customerId});
                }
            });

            var unwatch = scope.$watch('model', function() {
                if(scope.model) {
                    if(angular.isNumber(scope.model)) {
                        if(scope.searcher.providersResult.length > 0) {
                            for(var i in scope.searcher.providersResult) {
                                if(scope.searcher.providersResult[i].id === scope.model) {
                                    unwatch();
                                    return;
                                }
                            }
                        }
                        scope.searcher.providers(null, {'providerIds[]': scope.model});
                    }
                    unwatch();
                }
            });
        }
    };
}

DgFormProvider.$inject = [
    'DgFormProviderService',
];

angular.module('dugun.forms')
    .directive('dgFormProvider', DgFormProvider);
