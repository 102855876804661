/**
 * SignatureUpload factory
 * @ngdoc factory
 * @memberof dugun.signatures
 * @name SignatureUpload
 * @description
 * Just uploads signature
 * @returns {service} SignatureUpload service
 */
function SignatureUpload(Upload, environmentConfig) {
    var url = environmentConfig.api.url + 'customers/:customerId/users/:customerUserId/providers/:customerUserProviderId/signature';
    var mediaUpload = {};

    mediaUpload.upload = function(file, params) {
        var urlToUpload = url,
            data = {
                image: file
            },
            i;

        for(i in params) {
            urlToUpload = urlToUpload.replace(':' + i, params[i]);
        }

        return Upload.upload({
            url: urlToUpload,
            method: 'POST',
            data: data
        });
    };

    return mediaUpload;
}

SignatureUpload.$inject = [
    'Upload',
    'environmentConfig'
];

angular.module('dugun.signatures')
    .factory('SignatureUpload', SignatureUpload);
