function CustomerMenu(resource) {
    var url = 'customers/:customerId/menus/:customerMenuId';
    var resourceService = resource(
        url,
        {
            customerId: '@customerId',
            customerMenuId: '@customerMenuId'
        },
        {
            query:{
                isArray: false
            },

            get: {
                method: 'GET'
            },

            insert: {
                method: 'POST'
            },

            update: {
                method: 'PUT'
            },

            delete: {
                method: 'DELETE'
            },

            getMenuTypes: {
                url: 'menu-types'
            },

            getMenuSections: {
                url: 'menu-sections'
            },

            getMenuSectionGroups: {
                url: 'menu-section-groups'
            },

            getMenuSectionItems: {
                url: 'menu-sections/:menuSectionId/items'
            },

            getCustomerMenuPricing: {
                url: url + '/pricings',
                method: 'GET'
            },

            customerMenuPricingDetail: {
                url: url + '/pricings/:id',
                method: 'GET'
            },

            insertCustomerMenuPricing: {
                url: url + '/pricings',
                method: 'POST'
            },

            updateCustomerMenuPricing: {
                url: url + '/pricings/:id',
                method: 'PUT'
            },

            deleteCustomerMenuPricing: {
                url: url + '/pricings/:id',
                method: 'DELETE'
            },

            getCustomerMenuSections: {
                url: url + '/sections',
                method: 'GET'
            },

            getCustomerMenuSectionItems: {
                url: url + '/sections/:customerMenuSectionId/items'
            },

            insertCustomerMenuSectionsItems: {
                url: url + '/batch',
                method: 'POST'
            },

            getCustomerMenuTypes: {
                url: url + '/types',
                method: 'GET'
            },

            setCustomerMenuTypes: {
                url: url + '/types',
                method: 'POST'
            },

            updateCustomerMenuTypes: {
                url: url + '/types/:typeId',
                method: 'PUT'
            },

            deleteCustomerMenuTypes: {
                url: url + '/types/:id',
                method: 'DELETE'
            }
        }
    );

    return resourceService;
}

CustomerMenu.$inject = [
    'resource'
];

angular.module('dugun.customerMenu').factory('CustomerMenu', CustomerMenu);
