function dashboardsUserResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

dashboardsUserResolver.$inject = [
    '$rootScope',
    '$q',
];

function DashboardsRoutes($routeProvider) {
    $routeProvider
        .when('/home', {
            templateUrl: 'dashboards/dashboards.html',
            controller: 'DashboardCtrl',
            label: 'DASHBOARDS.HOME',
            resolve: {
                init: dashboardsUserResolver
            }
        });
}

DashboardsRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.dashboards').config(DashboardsRoutes);
