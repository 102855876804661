/**
 * @memberof dugun.relatedProviders
 * @ngdoc route
 * @name RelatedProvidersRoutes
 * @param $routeProvider {provider} $routeProvider
 *
 * @description /providers/:providerId/related-providers
 */
function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function RelatedProvidersRoutes($routeProvider) {
    $routeProvider
        .when('/providers/:providerId/related-providers', {
            templateUrl: 'related-providers/list.html',
            controller: 'RelatedProvidersCtrl',
            controllerAs: 'relatedProvidersCtrl',
            label: 'RELATED_PROVIDERS.REFERENCE_GALLERY',
            resolve: {
                init: userResolver
            }
        })
        .when('/providers/:providerId/related-providers-galleries/:galleryId/:relatedProviderGalleryId', {
            templateUrl: 'galleries/media/list.html',
            controller: 'MediaListCtrl',
            controllerAs: 'mediaListCtrl',
            label: 'RELATED_PROVIDERS.GALLERY',
            resolve: {
                init: userResolver
            }
        }
    );
}

RelatedProvidersRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.relatedProviders').config(RelatedProvidersRoutes);
