function OneSignalService(environmentConfig, Analytics, CustomerUsers, $translate) {

    var OneSignal = window.OneSignal || [],
        appId = environmentConfig.onesignal.appId,
        safariWebId = environmentConfig.onesignal.safariWebId,
        subdomainName = environmentConfig.onesignal.subdomainName;

    function init(customerId, userId) {
        var httpPermissionRequest,
            welcomeNotification,
            promptOptions,
            notifyButtonText = {};
        $translate.onReady().then(function () {
            httpPermissionRequest = {
                modalTitle: $translate.instant('ONE_SIGNAL.HTTP_PERMISSION_REQUEST.MODAL_TITLE'),
                modalMessage: $translate.instant('ONE_SIGNAL.HTTP_PERMISSION_REQUEST.MODAL_MESSAGE'),
                modalButtonText: $translate.instant('ONE_SIGNAL.HTTP_PERMISSION_REQUEST.MODAL_BUTTON_TEXT'),
                enable: false
            };
            welcomeNotification =  {
                title: $translate.instant('ONE_SIGNAL.WELCOME_NOTIFICATION.TITLE'),
                message: $translate.instant('ONE_SIGNAL.WELCOME_NOTIFICATION.MESSAGE')
            };
            promptOptions = {
                /* These prompt options values configure both the HTTP prompt and the HTTP popup. */
                /* Change bold title, limited to 30 characters */
                siteName: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.SITE_NAME'),
                /* Subtitle, limited to 90 characters */
                actionMessage: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.ACTION_MESSAGE'),
                /* Example notification title */
                exampleNotificationTitle: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.EXAMPLE_NOTIFICATION_TITLE'),
                /* Example notification message */
                exampleNotificationMessage: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.EXAMPLE_NOTIFICATION_MESSAGE'),
                /* Text below example notification, limited to 50 characters */
                exampleNotificationCaption: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.EXAMPLE_NOTIFICATION_CAPTION'),
                /* Accept button text, limited to 15 characters */
                acceptButtonText: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.ACCEPT_BUTTON_TEXT'),
                /* Cancel button text, limited to 15 characters */
                cancelButtonText: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.CANCEL_BUTTON_TEXT'),
                /* Change click allow text, limited to 30 characters */
                autoAcceptTitle: $translate.instant('ONE_SIGNAL.PROMPT_OPTIONS.AUTO_ACCEPT_TITLE')
            };
            notifyButtonText = {
                'tip.state.unsubscribed': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.TIP_STATE_UNSUBSCRIBED'),
                'tip.state.subscribed': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.TIP_STATE_SUBSCRIBED'),
                'tip.state.blocked': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.TIP_STATE_BLOCKED'),
                'message.prenotify': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.MESSAGE_PRENOTIFY'),
                'message.action.subscribed': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.MESSAGE_ACTION_SUBSCRIBED'),
                'message.action.resubscribed': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.MESSAGE_ACTION_RESUBSCRIBED'),
                'message.action.unsubscribed': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.MESSAGE_ACTION_UNSUBSCRIBED'),
                'dialog.main.title': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.DIALOG_MAIN_TITLE'),
                'dialog.main.button.subscribe': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.DIALOG_MAIN_BUTTON_SUBSCRIBE'),
                'dialog.main.button.unsubscribe': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.DIALOG_MAIN_BUTTON_UNSUBSCRIBE'),
                'dialog.blocked.title': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.DIALOG_BLOCKED_TITLE'),
                'dialog.blocked.message': $translate.instant('ONE_SIGNAL.NOTIFY_BUTTON.DIALOG_BLOCKED_MESSAGE')
            };

        });

        OneSignal.push(["init", {
            appId: appId,
            safari_web_id: safariWebId,
            autoRegister: false,
            subdomainName: subdomainName,
            /*
             subdomainName: Use the value you entered in step 1.4: http://imgur.com/a/f6hqN
             */
            httpPermissionRequest: httpPermissionRequest,
            welcomeNotification: welcomeNotification,
            promptOptions: promptOptions,
            notifyButton: {
                enable: true, /* Required to use the notify button */
                size: 'medium', /* One of 'small', 'medium', or 'large' */
                theme: 'default', /* One of 'default' (red-white) or 'inverse" (white-red) */
                position: 'bottom-left', /* Either 'bottom-left' or 'bottom-right' */
                displayPredicate: function() {
                    return OneSignal.isPushNotificationsEnabled()
                        .then(function(isPushEnabled) {
                            /* The user is subscribed, so we want to return "false" to hide the notify button */
                            return !isPushEnabled;
                        });
                },
                text: notifyButtonText,
                colors: { // Customize the colors of the main button and dialog popup button
                    'circle.background': 'rgb(219, 9, 98)',
                    'circle.foreground': 'white',
                    'badge.background': 'rgb(219, 9, 98)',
                    'badge.foreground': 'white',
                    'badge.bordercolor': 'white',
                    'pulse.color': 'white',
                    'dialog.button.background.hovering': 'rgb(77, 101, 113)',
                    'dialog.button.background.active': 'rgb(70, 92, 103)',
                    'dialog.button.background': 'rgb(219, 9, 98)',
                    'dialog.button.foreground': 'white'
                }
            }
        }]);
        OneSignal.push(function () {
            OneSignal.on('notificationDisplay', function (event) {
                // console.warn('OneSignal notification displayed:', event);
            });

            OneSignal.on('subscriptionChange', function (isSubscribed) {
                if (isSubscribed) {
                    OneSignal.sendTags({userId: userId});
                    subscribeCustomerUser(customerId, userId);
                }
            });

            OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
                if (isEnabled) {
                    OneSignal.sendTags({userId: userId});
                }
            });
        });

        // if(environmentConfig.name !== 'production') {
        //     OneSignal.log.setLevel('trace');
        // }
    }

    function subscribe(customerId, userId) {
        OneSignal.push(function () {

            OneSignal.on('subscriptionChange', function (isSubscribed) {
                if (isSubscribed) {
                    OneSignal.sendTags({userId: userId});
                    subscribeCustomerUser(customerId, userId);
                }
            });

            OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
                if (!isEnabled) {
                    OneSignal.showHttpPrompt();
                }
                else {
                    OneSignal.sendTags({userId: userId});
                }
            });
        });
    }

    function subscribeCustomerUser(customerId, userId) {

        var urlData = {
            customerId: customerId,
            id: userId
        }, data = {
            isPushNotificationSubscribed: true
        };

        CustomerUsers.update(
            urlData,
            data,
            function(successResponse) {
                Analytics.trackEvent('OneSignal', 'subscribe', true);
            },
            function(errorResponse) {}
        );
    }
    return {
        init: init,
        subscribe: subscribe
    };
}

OneSignalService.$inject = [
    'environmentConfig',
    'Analytics',
    'CustomerUsers',
    '$translate',
];

angular.module('dugun.core')
    .factory('OneSignalService', OneSignalService);