(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('customers/providers/provider/missing-details/modal.html',
    '<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="close()">&times;</button><h4 class="modal-title">📣 {{ \'CUSTOMERS.PROVIDER.COUPLES_WANT_TO_REACH_EASILY\' | translate:{name: missingProviderDetails.provider.name} }}</h4></div><div class="modal-body"><p>{{ \'CUSTOMERS.PROVIDER.COUPLE_REACH_SPEED_INFO\' | translate }}</p><p>{{ \'CUSTOMERS.PROVIDER.MISSING_FIELD_COUNT_INFO\' | translate:{number: missingProviderDetails.details.length} }}</p><ul><li ng-repeat="detail in missingProviderDetails.details">{{detail.fieldName}}</li></ul><p class="text-center mb-20 mt-20"><button type="button" class="btn btn-primary" ng-click="close()">{{ \'CUSTOMERS.PROVIDER.CLICK_FOR_CHOOSE\' | translate }}</button></p><p>{{ \'CUSTOMERS.PROVIDER.FILL_INFO\' | translate }}</p></div>');
}]);
})();
