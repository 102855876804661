/**
 * @memberof dugun.galleries
 * @ngdoc route
 * @name GalleriesRoutes
 * @param $routeProvider {provider} $routeProvider
 *
 * @description /providers/:providerId/info
 */
function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function GalleriesRoutes($routeProvider) {
    $routeProvider
        .when('/providers/:providerId/galleries', {
            templateUrl: 'galleries/galleries/list.html',
            controller: 'GalleryListCtrl',
            controllerAs: 'galleryListCtrl',
            label: 'GALLERIES',
            doNotTrack: true,
            excludeBreadcrumb: true,
            resolve: {
                init: userResolver
            }
        })
        .when('/providers/:providerId/galleries/:galleryId', {
            templateUrl: 'galleries/media/list.html',
            controller: 'MediaListCtrl',
            controllerAs: 'mediaListCtrl',
            label: 'GALLERIES.GALLERY',
            resolve: {
                init: userResolver
            }
        });
}

GalleriesRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.galleries').config(GalleriesRoutes);
