/**
 * @ngdoc directive
 * @name dugun.forms:dgFormSelectProviderMultiple
 * @restrict 'ACE'
 * @scope
 **/
function DgFormSelectProviderMultiple() {
    return {
        restrict: 'ACE',
        transclude: true,
        scope: {
            model: '=ngModel',
            options: '=',
            allowClear: '@',
            required: '=',
            id: '@dgId'
        },
        templateUrl: 'dg-forms/form-elements/provider/multiple.html'
    };
}

angular.module('dugun.forms').directive('dgFormSelectProviderMultiple', DgFormSelectProviderMultiple);
