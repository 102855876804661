/**
 * Attachments factory
 * @ngdoc factory
 * @memberof dugun.attachments
 * @name Attachments
 * @requires dugun.core:resource
 *
 * @returns {service} Attachments resource service
 */
function Attachments(resource) {
    var url = 'customers/:customerId/attachments/:id';

    var resourceService = resource(
        url,
        {
            customerId: '@customerId',
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof Attachments
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get Attachments list
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Attachments
             * @name download
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * download the attachment
             */
            download: {
                url: url + '/download',
                method: 'GET'
            },
            /**
             * @ngdoc method
             * @memberof Attachments
             * @name update
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * update attachment
             */
            update: {
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Attachments
             * @name delete
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * delete attachment
             */
            delete: {
                method: 'DELETE',
            }
        }
    );

    return resourceService;
}

Attachments.$inject = [
    'resource'
];

angular.module('dugun.attachments')
    .factory('Attachments', Attachments);
