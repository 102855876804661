/**
 * Agreements factory
 * @ngdoc service
 * @name Agreements
 * @memberof dugun.agreements
 *
 * @requires dugun.core:resource
 * @requires $q
 * @requires dgPopup
 *
 */
function Agreements(resource, $q, dgPopup) {
    var url = 'customer-user-agreement-signs';

    var resourceService = resource(
        url,
        {},
        {
            /**
             * @ngdoc method
             * @memberof Agreements
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get Agreements
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Agreements
             * @name getAgreements
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get Agreements
             */
            getAgreements: {
                method: 'GET',
                url: 'customer-user-agreement-signs'
            },
            /**
             * @ngdoc method
             * @memberof Agreements
             * @name getAgreementText
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get Agreement Text
             */
            getAgreementText: {
                method: 'GET',
                url: 'agreement-texts/:agreementId',
                params: {
                    agreementId: '@agreementId'
                }
            },
            /**
             * @ngdoc method
             * @memberof Agreements
             * @name setAgreement
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Set Agreement
             */
            setAgreement: {
                method: 'POST',
                url: 'customer-user-agreement-signs'
            },
            /**
             * @ngdoc method
             * @memberof Agreements
             * @name setAgreement
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * delete customer user agreement sign
             */
            deleteAgreementSign: {
                method: 'DELETE',
                url: 'customer-user-agreement-signs/:id'
            }
        }
    );

    return resourceService;
}

Agreements.$inject = [
    'resource',
    '$q',
    'dgPopup',
];

angular.module('dugun.agreements')
    .factory('Agreements', Agreements);
