(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('login/password-reset/password-reset.html',
    '<div class="login-container animated fadeInDown" cg-busy="loading"><a href="javascript:void(0);" class="login-logo" style="background-image: url(\'{{ environment.currentLanguage.loginLogo }}\')"></a><div class="loginbox bg-white"><form ng-submit="getRecoveryKey()" ng-if="!recoveryKeyFound"><div class="loginbox-title">{{\'FORGET_PASSWORD.HEADER\' | translate}}</div><div class="loginbox-or"><div class="or-line"></div></div><div class="loginbox-or" style="height: auto; padding: 10px 40px">{{\'PASSWORD_RESET.RESET_LINK_DESCRIPTION\' | translate}}</div><div class="loginbox-submit"><a href="login" class="btn btn-primary btn-block" ga-track-event="[\'loginForm\', \'click\', \'login\']">{{\'FORGET_PASSWORD.GO_TO_LOGIN\' | translate}}</a></div></form><form ng-submit="setPassword()" ng-if="recoveryKeyFound"><div class="loginbox-title">{{\'PASSWORD_RESET.HEADER\' | translate}}</div><div class="loginbox-or"><div class="or-line"></div></div><div class="loginbox-or" style="height: auto; padding: 10px 40px">{{\'PASSWORD_RESET.DESCRIPTION\' | translate}}</div><div class="loginbox-textbox pt-0"><label class="input-sm text-bold mb-0">{{\'PASSWORD_RESET.NEW_PASSWORD\' | translate}}</label><input type="password" class="form-control" placeholder="{{\'PASSWORD_RESET.NEW_PASSWORD\' | translate}}" ng-model="fields.password" minlength="6" required></div><div class="loginbox-textbox pt-0" ng-class="{\n' +
    '                \'has-error\': fields.passwordRepeat !== fields.password\n' +
    '            }"><label class="input-sm text-bold mb-0">{{\'PASSWORD_RESET.NEW_PASSWORD_CONFIRMATION\' | translate}}</label><input type="password" class="form-control" placeholder="{{\'PASSWORD_RESET.NEW_PASSWORD_CONFIRMATION\' | translate}}" ng-model="fields.passwordRepeat" minlength="6" required></div><div class="loginbox-submit"><input type="submit" class="btn btn-primary btn-block" value="{{\'PASSWORD_RESET.UPDATE_PASSWORD\' | translate}}" ga-track-event="[\'loginForm\', \'click\', \'resetPassword\']"></div><div class="loginbox-forgot"><a href="login" ga-track-event="[\'loginForm\', \'click\', \'login\']">{{\'PASSWORD_RESET.GO_TO_LOGIN\' | translate}}</a></div></form></div></div><style type="text/css">html, body, .page-body{\n' +
    '        height: 100%;\n' +
    '        width: 100%;\n' +
    '        min-height: 100%;\n' +
    '        background: url(images/login-bg-2.jpg) no-repeat center center fixed;\n' +
    '        -webkit-background-size: cover;\n' +
    '        -mox-background-size: cover;\n' +
    '        -o-background-size: cover;\n' +
    '        background-size: cover;\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '    .loading-container{\n' +
    '        display: none;\n' +
    '    }</style>');
}]);
})();
