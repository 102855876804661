/**
 * Controls the gallery list
 * @ngdoc controller
 * @memberof dugun.galleries.galleries
 * @name GalleryListCtrl
 * @scope
 * @requires $scope
 * @requires $route
 * @requires $routeParams
 * @requires $window
 * @requires $location
 * @requires $filter
 * @requires $translate
 * @requires dugun.notifications:dgNotifications
 * @requires Galleries
 */
function GalleryListCtrl($scope, $route, $routeParams, $window, $location, $filter, $translate,
                          dgNotifications,
                          Galleries) {
    /**
     * @memberOf GalleryListCtrl
     * @description
     * If exists, we will list galleries of this provider only
     */
    $scope.providerId = $window.parseInt($routeParams.providerId) || null;

    /**
     * @memberOf GalleryListCtrl
     * @description
     * Gallery list
     */
    $scope.list = [];

    /**
     * @memberOf GalleryListCtrl
     * @description
     * Object that keeps pagination information like items-per-page, total items
     */
    $scope.pagination = {
        limit: 100,
        currentPage: 1
    };

    /**
     * @memberOf GalleryListCtrl
     * @description
     * When provider dosen't have a gallery we will show the add gallery button
     */
    $scope.showAddGallery = false;

    /**
     * @ngdoc method
     * @memberof GalleryListCtrl
     * @description
     * Loads the list from Galleries service and set the list
     */
    $scope.loadList = function() {
        if($window.getPromiseStatus($scope.loading) === 0) return true;
        var data = {
            page: $scope.pagination.currentPage,
            limit: $scope.pagination.limit,
            'providerIds[]': [$scope.providerId],
            status: 1,
            isMainGallery: 1,
        };

        $scope.loading = Galleries.query(
            data,
            function(successResponse) {
                if(successResponse.data.length === 0) {
                    $scope.showAddGallery = true;
                }
                var currentLocation = $location.path();
                var galleries = $filter('filter')(successResponse.data, function (value) {
                    if(value.relatedProviderId) {
                        return false;
                    } else {
                        return true;
                    }
                });
                $location.path(currentLocation + '/' + galleries[0].id);
            }, function(errorResponse) {
                dgNotifications.error(errorResponse);
            });
    };

    /**
     * @ngdoc method
     * @memberof GalleryListCtrl
     * @description
     * Create gallery for provider
     */
    $scope.createGallery = function() {
        if(!$scope.providerId) {
            dgNotifications.error($translate.instant('GALLERIES.ERROR_WHILE_CREATING'));
            return;
        }

        var data = {
            providerId: $scope.providerId,
            name: $scope.$parent.getProvider($scope.providerId).name
        };

        $scope.loading = Galleries.insert(
            data,
            function(successResponse) {
                var currentLocation = $location.path();
                $location.path(currentLocation + '/' + successResponse.id);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof GalleryListCtrl
     * @description
     * Sets the main gallery.
     */
    $scope.setMainGallery = function(gallery) {
        var urlData = {
            id: gallery.id
        };

        var putData = {
            isMainGallery: true
        };

        gallery.loading = Galleries.update(
            urlData,
            putData,
            function(successResponse) {
                for(var i in $scope.list) {
                    $scope.list[i].isMainGallery = false;
                }
                gallery.isMainGallery = true;
                dgNotifications.success($translate.instant('GALLERIES.MAIN_GALLERY_CHANGED'), $translate.instant('GALLERIES.SUCCESSFULLY'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof GalleryListCtrl
     * @description
     * Delete Gallery
     */
    $scope.deleteGallery = function (gallery) {
        var urlData = {
            id: gallery.id
        };

        gallery.loading = Galleries.delete(
            urlData,
            function(successResponse) {
                dgNotifications.success($translate.instant('GALLERIES.SUCCESSFULLY'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof GalleryListCtrl
     * @description
     * Open modal to edit or create gallery.
     */
    $scope.openItemForm = function(item) {
        $scope.openModal(
            'galleries/galleries/form.modal.html',
            'GalleryCtrl',
            item ? item.id : null
        );
    };

    // Set heading of page
    $scope.$root.heading.name = $route.current.label;

    // Load the list after user loaded
    $scope.$watch('user', function(newValue) {
        if(newValue && newValue.id) {
            $scope.loadList();
        }
    });
}

GalleryListCtrl.$inject = [
    '$scope',
    '$route',
    '$routeParams',
    '$window',
    '$location',
    '$filter',
    '$translate',
    'dgNotifications',
    'Galleries'
];

angular.module('dugun.galleries.galleries')
    .controller('GalleryListCtrl', GalleryListCtrl);
