function Items(resource) {
    var url = 'providers/:providerId/items/:id';
    var resourceService = resource(
        url,
        {
            providerId: '@providerId',
            id: '@id'
        },
        {
            query: {
                isArray: false
            },

            get: {
                method: 'GET'
            },

            getProviderItemBrands: {
                url: 'providers/:providerId/brands',
                method: 'GET'
            },

            getItemOptionGroups: {
                url: 'item-option-groups',
                method: 'GET'
            },

            insert: {
                url: url,
                method: 'POST'
            },

            update: {
                url: url,
                method: 'PUT'
            },

            delete: {
                url: url,
                method: 'DELETE'
            },

            moveOrder: {
                url: url + '/move-order',
                method: 'PUT'
            }
        }
    );
    return resourceService;
}

Items.$inject = [
    'resource'
];

angular.module('dugun.items').factory('Items', Items);
