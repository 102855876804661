/**
 * @memberof dugun.galleries.media
 * @ngdoc overview
 * @description
 * Media module (images and videos)
 */
angular.module('dugun.galleries.media', [
    // vendor
    'angularMoment',
    'ui.sortable',
    'dugun.notifications',
    'angular-img-cropper',
    'dugun.queryString',
    'dugun.confirm',
    // app
    'dugun.galleries.media.upload',
    'dugun.galleries.media.tags',
    'dugun.galleries.media.infoTags',
]);
