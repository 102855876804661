(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('login/password-reset/password-renew.html',
    '<div class="login-container animated fadeInDown" cg-busy="loading"><a href="javascript:void(0);" class="login-logo" style="background-image: url(\'{{ environment.currentLanguage.loginLogo }}\')"></a><div class="loginbox bg-white"><form ng-submit="renewPassword()"><div class="loginbox-title">{{\'PASSWORD_RENEW.HEADER\' | translate}}</div><div class="loginbox-or"><div class="or-line"></div></div><div class="loginbox-textbox pt-0"><label class="input-sm text-bold mb-0">{{ \'PASSWORD_RENEW.PASSWORD\' | translate }}</label><input type="password" class="form-control" placeholder="{{ \'PASSWORD_RENEW.PASSWORD\' | translate }}" ng-model="fields.password" minlength="6" required></div><div class="loginbox-textbox pt-0" ng-class="{\n' +
    '                \'has-error\': fields.passwordMatch !== fields.password\n' +
    '            }"><label class="input-sm text-bold mb-0">{{ \'PASSWORD_RENEW.PASSWORD_MATCH\' | translate }}</label><input type="password" class="form-control" placeholder="{{ \'PASSWORD_RENEW.PASSWORD_MATCH\' | translate }}" ng-model="fields.passwordMatch" minlength="6" required></div><div class="loginbox-submit"><input type="submit" class="btn btn-primary btn-block" value=" {{ \'PASSWORD_RENEW.SUBMIT_TEXT\' | translate }}" ga-track-event="[\'loginForm\', \'click\', \'resetPassword\']"></div><div class="loginbox-forgot"><a href="login" ga-track-event="[\'loginForm\', \'click\', \'login\']">{{\'PASSWORD_RESET.GO_TO_LOGIN\' | translate}}</a></div></form></div></div><style type="text/css">html, body, .page-body{\n' +
    '        height: 100%;\n' +
    '        width: 100%;\n' +
    '        min-height: 100%;\n' +
    '        background: url(images/login-bg-2.jpg) no-repeat center center fixed;\n' +
    '        -webkit-background-size: cover;\n' +
    '        -mox-background-size: cover;\n' +
    '        -o-background-size: cover;\n' +
    '        background-size: cover;\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '    .loading-container{\n' +
    '        display: none;\n' +
    '    }</style>');
}]);
})();
