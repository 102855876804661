/**
 * AttachmentUpload factory
 * @ngdoc factory
 * @memberof dugun.attachments
 * @name AttachmentUpload
 * @description
 * Just uploads attachment
 * @returns {service} AttachmentUpload service
 */
function AttachmentUpload(Upload, environmentConfig) {
    var url = environmentConfig.api.url + 'customers/:customerId/attachments';
    var mediaUpload = {};

    mediaUpload.upload = function(file, params) {
        var urlToUpload = url,
            data = {
                attachment: file,
                name: file.name
            },
            i;

        for(i in params) {
            urlToUpload = urlToUpload.replace(':' + i, params[i]);
        }

        if(params.providerId) {
            data.providerId = params.providerId;
        }

        return Upload.upload({
            url: urlToUpload,
            method: 'POST',
            data: data
        });
    };

    return mediaUpload;
}

AttachmentUpload.$inject = [
    'Upload',
    'environmentConfig'
];

angular.module('dugun.attachments')
    .factory('AttachmentUpload', AttachmentUpload);
