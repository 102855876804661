function FirebaseRefCreator() {
    var service = {},
        prefix = 'customers/';

    service.create = function (customerHash, suffix) {
        var fullPath = prefix + customerHash + '/' + suffix;

        return firebase.database().ref(fullPath);
    };

    return service;
}

angular.module('dugun.provider').factory('FirebaseRefCreator', FirebaseRefCreator);
