angular.module('dugun.login')
    .controller('LoginCtrl', ['$scope', '$http', '$location', '$window', 'queryString', 'dgNotifications', 'Auth', 'Facebook', 'GooglePlus', LoginCtrl]);

function LoginCtrl($scope, $http, $location, $window, queryString, dgNotifications, Auth, Facebook, GooglePlus) {

    var self = this;

    $scope.alerts = [];
    self.redirectTo = ($location.search() || {}).redirect;

    $scope.$on('user.got', function(event, user) {
        if(user.id) {
            if(self.redirectTo) {
                $location.url(self.redirectTo)
                .search('redirect', null);
            } else {
                $location.url('/home');
            }
        }
    });

    $scope.login = function()
    {
        $scope.$broadcast('autofill:update');

        $scope.loading = Auth.getAccessToken($scope.email, $scope.password)
        .then(function(response) {
            if (!$scope.environment.fieldsSettings.passwordRenew) {
                response.accessToken.passwordChangeRequired = false;
            }

            if (response.accessToken.passwordChangeRequired) {
                $window.localStorage.setItem('customerId', response.customer.id);
                $window.localStorage.setItem('userId', response.accessToken.userId);
                $window.localStorage.setItem('accessToken', response.accessToken.id);
                $window.location.href = '/password-renew';
            } else {
                $scope.$root.accessToken = response.accessToken.id;
                $scope.$root.getUser();
                dataLayer.push(
                    {
                        event: 'loggedIn',
                        loggedIn: true
                    }
                );
                redirectToHome();
            }
        }, function(response) {
            dgNotifications.error(response.data.message);
            dataLayer.push(
                {
                    event: 'loggedIn',
                    loggedIn: false,
                    error: response.data.message
                }
            );
        });
    };

    function redirectToHome() {
        if(self.redirectTo) {
            $location.url(self.redirectTo)
                .search('redirect', null);
        } else {
            $location.url('/');
        }
    }

    /**
     * Watch for Facebook to be ready.
     * There's also the event that could be used
     */
    $scope.$watch(
        function() {
            return Facebook.isReady();
        },
        function(newVal) {
            if (newVal)
                $scope.facebookReady = true;
        }
    );

    var userIsConnected = false;

    Facebook.getLoginStatus(function(response) {
        if (response.status == 'connected') {
            userIsConnected = response;
        }
    });


    /**
     * FBIntentLogin
     */
    $scope.FBIntentLogin = function() {
        if(!userIsConnected) {
            $scope.fbLogin();
        } else {
            $scope.grantFBLogin(userIsConnected.authResponse.accessToken);
        }
    };

    $scope.grantFBLogin = function (accessToken) {
        $scope.loading = Auth.fbLogin(accessToken)
            .then(function(response) {
                $scope.$root.accessToken = response.id;
                $scope.$root.getUser();
                redirectToHome();
            }, function(response) {
                dgNotifications.error(response);
            });
    };

    /**
     * Facebook Login
     */
    $scope.fbLogin = function() {
        Facebook.login(function(response) {
            if (response.status == 'connected') {
                $scope.grantFBLogin(response.authResponse.accessToken);
            }
        });
    };

    /**
     * Google Login
     */
    $scope.googleLogin = function(extraData) {
        var data = {
            redirectUrl: $scope.environment.url + 'login',
            userType: "customer"
        };

        data = Object.assign({}, data, extraData || {});

        $scope.loading = Auth.googleLogin(data).then(
            function (successResponse) {
                if (successResponse.success && successResponse.data.authUrl) {
                    $window.open(successResponse.data.authUrl, '_blank');
                }

                if (successResponse.success && successResponse.data.id) {
                    $scope.$root.accessToken = successResponse.data.id;
                    $scope.$root.getUser();
                    redirectToHome();
                }
            },
            function (error) {
                dgNotifications.error(error);
            }
        );
    };


    if (queryString.getFilters().code) {
        $scope.googleLogin({
            code: queryString.getFilters().code
        });
    }
}
