// Get changed items
Object.defineProperty(Object.prototype, 'getDiff', {
    enumerable: false,
    writable: true,
    value: function(cleanData, datesToConvert) {
        var self = this,
            dirtyData = angular.copy(self),
            diff = {},
            i;
        cleanData = angular.copy(cleanData);

        for(i in dirtyData) {
            if(Object.prototype.hasOwnProperty(i)) continue;
            if(typeof dirtyData[i] === 'object' && dirtyData[i] instanceof Blob) {
                diff[i] = dirtyData[i];
            } else if(!angular.equals(dirtyData[i], cleanData[i])) {
                diff[i] = typeof(dirtyData[i]) === 'undefined' ? null : dirtyData[i];
            }
        }

        if(angular.isArray(datesToConvert) && datesToConvert.length > 0) {
            for(i in diff) {
                if(datesToConvert.indexOf(i) !== -1) {
                    diff[i] = moment(diff[i]).format('YYYY-MM-DD');
                }
            }
        }

        return diff;
    }
});
Object.defineProperty(Object.prototype, 'clearData', {
    enumerable: false,
    writable: true,
    value: function(valid, extra) {
        extra = extra || {};
        var newData = {}, i;
        for(i in this) {
            if(Object.prototype.hasOwnProperty(i)) continue;
            if(valid.indexOf(i) !== -1) {
                newData[i] = this[i];
            }
        }

        if(extra.convertDates) {
            newData = newData.convertDates(extra.convertDates);
        }

        return newData;
    }
});

Object.defineProperty(Object.prototype, 'convertDates', {
    enumerable: false,
    writable: true,
    value: function(dates) {
        var newData = this, i;
        for(i in dates) {
            if(newData[dates[i]]) {
                newData[dates[i]] = moment(newData[dates[i]]).format('YYYY-MM-DD');
            }
        }

        return newData;
    }
});

// http://stackoverflow.com/questions/1960473/unique-values-in-an-array
// Returns unique values in array
Object.defineProperty(Array.prototype, 'getUnique', {
    enumerable: false,
    writable: true,
    value: function() {
       var u = {}, a = [];
       for(var i = 0, l = this.length; i < l; ++i) {
          if(u.hasOwnProperty(this[i])) {
             continue;
          }
          a.push(this[i]);
          u[this[i]] = 1;
       }
       return a;
    }
});

// If string is longer than limit, cut it and put three dots.
if(!String.prototype.shorten) {
    Object.defineProperty(String.prototype, 'shorten', {
        enumerable: false,
        writable: false,
        value: function(limit) {
            var self = this;
            if(self.length <= limit) {
                return self + '';
            } else {
                return self.substr(0, limit - 3) + '...';
            }
        }
    });
}

// Return values of object properties as array
Object.defineProperty(Object.prototype, 'toArray', {
    enumerable: false,
    writable: true,
    value: function(keysAs) {
        var result = [],
            row;
        for(var i in this) {
            if(Object.prototype.hasOwnProperty(i)) continue;
            row = this[i];
            if(keysAs) {
                row[keysAs] = i;
            }
            result.push(row);
        }

        return result;
    }
});

Object.defineProperty(Array.prototype, 'getDiffArray', {
    enumerable: false,
    writable: true,
    value: function (a2) {
        var a1 = angular.copy(this);
        a2 = angular.copy(a2);

        var a = [], diff = [], i = 0;

        for (i = 0; i < a1.length; i++) {
            a[a1[i]] = true;
        }

        for (i = 0; i < a2.length; i++) {
            if (a[a2[i]]) {
                delete a[a2[i]];
            } else {
                a[a2[i]] = true;
            }
        }

        for (var k in a) {
            diff.push(k);
        }

        return diff;
    }
});

Object.defineProperty(Array.prototype, 'getArrayOfProperties', {
    enumerable: false,
    writable: true,
    value: function (key) {
        var arrayOfObjects = angular.copy(this);

        if (!arrayOfObjects || !arrayOfObjects.length) return [];

        key = angular.copy(key);
        var keys = key.split('.');


        var props = [], i;

        for (i in arrayOfObjects) {
            if (typeof arrayOfObjects[i] !== 'object') {
                return [];
            }
        }

        var newObject = {};
        for (i in arrayOfObjects) {
            newObject = arrayOfObjects[i];
            for (var j in keys) {
                newObject = newObject[keys[j]];
                if (newObject && typeof newObject !== 'object') {
                    props.push(newObject);
                }
            }
        }

        return props;
    }
});

window.getScope = function(element) {
    return angular.element(element).scope();
};

window.getPromiseStatus = function(promise) {
    if(!promise || !promise.$$status) return false;
    return promise.$$status.status;
};

window.getSortData = function(tableState) {
    if(typeof tableState === 'undefined' || tableState === null || !tableState.sort || !tableState.sort.predicate) return {};
    var data = {};
    data.sort = tableState.sort.predicate;
    data.order = tableState.sort.reverse ? 'DESC' : 'ASC';
    return data;
};
