function Resource($resource, $http, CacheFactory, environmentConfig) {

    var version = 1;

    function getBaseUrl() {
        return environmentConfig.api.url;
    }

    function transformResponse(data, headersGetter) {
        return data;
    }

    function appendTransform(defaults, transform, currentTransform) {
        // We can't guarantee that the default transformation is an array
        defaults = angular.isArray(defaults) ? defaults : [defaults];

        // If currentTransform doesn't exist, create it. If not an array,
        // make it array
        currentTransform = currentTransform || [];
        currentTransform = angular.isArray(currentTransform) ? currentTransform : [currentTransform];

        // Merge defaults, currentTransform and transform
        return defaults.concat(transform).concat(currentTransform);
    }

    return function(url, paramDefaults, actions, options) {
        options = options || {};
        if(options && options.version > -1) {
            version = options.version;
        } else {
            version = 1;
        }

        var baseUrl = getBaseUrl();

        // Configure the actions
        actions = actions || {};
        actions.get = actions.get || {};
        actions.save = actions.save || {};
        actions.query = actions.query || {};
        actions.remove = actions.remove || {};
        actions.delete = actions.delete || {};
        actions.update = actions.update || {};

        // Get
        actions.get.transformResponse = appendTransform($http.defaults.transformResponse, transformResponse, actions.get.transformResponse);

        // Insert
        actions.save.transformResponse = appendTransform($http.defaults.transformResponse, transformResponse, actions.save.transformResponse);

        // List
        actions.query.transformResponse = appendTransform($http.defaults.transformResponse, transformResponse, actions.query.transformResponse);
        actions.query.method = 'GET';

        // Delete
        actions.delete.method = 'DELETE';
        actions.remove.method = 'DELETE';

        // Update
        actions.update.method = 'PUT';
        actions.update.transformResponse = appendTransform($http.defaults.transformResponse, transformResponse, actions.update.transformResponse);
        actions.update.params = {
            id: '@id'
        };

        if(options.cache) {
            var cacheFactory;
            if(options.cache.get) {
                // Resource have its own CacheFactory
                cacheFactory = options.cache;
            } else {
                // Resource wants to be cached, but doesn't know where to put
                // it. So store the cache in defaultCache.
                cacheFactory = CacheFactory.get('defaultCache') || CacheFactory('defaultCache', {
                    maxAge: 60*60*1000 // 1 hour
                });
            }

            // Only GET actions can have cache.
            actions.get.cache = cacheFactory;
            actions.query.cache = cacheFactory;
        }

        for(var i in actions) {
            if(actions[i].url) actions[i].url = baseUrl + actions[i].url;
            if(['get', 'save', 'query', 'remove', 'delete', 'update'].indexOf(i) !== -1) continue;
            actions[i].transformResponse = appendTransform($http.defaults.transformResponse, transformResponse, actions[i].transformResponse);
        }


        // Return resource
        return $resource(
            baseUrl + url,
            paramDefaults || null,
            actions || null,
            options || null
        );
    };
}

Resource.$inject = [
    '$resource',
    '$http',
    'CacheFactory',
    'environmentConfig'
];

angular.module('dugun.core').factory('resource', Resource);
