angular.module('dugun.providerCategory')
    .factory('providerCategory', [
        '$filter',
        '$q',
        'resource',
        'CacheFactory',
        ProviderCategory
    ]);

function ProviderCategory($filter, $q, resource, CacheFactory) {
    var url = 'provider-categories';
    var items = [];
    var itemsKeyValue = [];
    var deferred = null;

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            query: {
                isArray: false,
                cache: getCacheFactory()
            }
        }
    );

    function init() {
        deferred = $q.defer();
        resourceService.query({'scopes[]': ['getRoiMinLeadCount']}, initSuccess, initError);
        return deferred.promise;
    }

    function initSuccess(response) {
        response.data = $filter('orderBy')(response.data, 'name');
        items = response.data;
        for(var i in items) {
            itemsKeyValue[items[i].id] = items[i].name;
        }
        deferred.resolve();
        return items;
    }

    function initError(response, a, b, c) {
        deferred.reject();
        console.error(response, a, b, c);
    }

    function getItems() {
        return items;
    }

    function getItem(id) {
        return itemsKeyValue[id] || null;
    }

    function getItemDetail(id) {
        for(var i in items) {
            if(items[i].id === id) {
                return items[i];
            }
        }
    }

    function getCacheFactory() {
        return CacheFactory.get('providerCategories') || CacheFactory('providerCategories', {
            maxAge: 24*60*60*1000, // 1 day
            onExpire: cacheExpired
        });
    }

    function cacheExpired() {
        init();
    }

    return {
        getItems: getItems,
        getItem: getItem,
        getItemDetail: getItemDetail,
        init: init
    };
}
