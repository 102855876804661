(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/header.html',
    '<div class="col-xs-4 col-sm-6 pl-0 pr-0"><div class="header-title leads-header-title" lang="tr"><h3 ng-if="!environment.show" style="margin-top: 0">{{ heading.name }}</h3><h1 ng-if="environment.show">{{ heading.name }}</h1></div></div><div class="col-xs-8 col-sm-6 pr-0 pl-0"><div class="pull-right leads-header-cards text-center" ng-if="heading.data"><div><b>{{ \'LEADS.CATEGORY_AVERAGE_HOURS_RESPONSE\' | translate: { hours: heading.data.responseTime.categoryAverage } }}</b><br>{{ \'LEADS.CATEGORY_AVERAGE_RESPONSE\' | translate }}</div></div><div class="pull-right leads-header-cards text-center pl-0"><span><b><span ng-if="heading.data.responseTime.providerAverage == 0">{{ \'DASHBOARDS.UNDER_HOUR\' | translate}} </span><span ng-if="heading.data.responseTime.providerAverage != 0">{{ \'LEADS.PROVIDER_AVERAGE_HOURS_RESPONSE\' | translate: { hours: heading.data.responseTime.providerAverage } }} </span><i ng-if="heading.data.responseTime.providerAverage > heading.data.responseTime.categoryAverage" class="fa fa-thumbs-down red"></i> <i ng-if="heading.data.responseTime.providerAverage <= heading.data.responseTime.categoryAverage" class="fa fa-thumbs-up panel-color"></i></b><br><span class="banner-inner-text">{{ \'LEADS.PROVIDER_AVERAGE_RESPONSE\' | translate }}</span></span></div></div>');
}]);
})();
