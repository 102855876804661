(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/appointment.modal.html',
    '<div class="modal-header modal-panel-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="closeRemindedAtModal()" ga-track-event="[\'noteLeadForm\', \'click\', \'closeIcon\']">&times;</button><h3 class="modal-title"><span>{{ heading.scope.statuses[heading.scope.remindAt.statusType] }}</span></h3></div><form class="form-horizontal" role="form" cg-busy="loading" ng-submit="setRemindAt()"><div class="modal-body modal-panel-body"><div class="row"><div class="col-md-6 col-xs-6 appointment-date"><label>{{ \'GENERAL.DATE\' | translate }}</label><input class="form-control" ng-model="remindAt.date" uib-datepicker-popup="{{ \'dd MMMM yyyy\' }}" ng-click="appointmentDateOpen = true" is-open="appointmentDateOpen" type="text" datepicker-options="appointmentDateOptions" ng-required="true"></div><div class="col-md-3 col-xs-3 appointment-hour"><label>{{ \'GENERAL.HOUR\' | translate }}</label><select class="form-control" ng-model="remindAt.hour" ng-required="true"><option></option><option ng-repeat="hour in appointmentHours" value="{{hour}}">{{hour}}</option></select></div><div class="col-md-3 col-xs-3 appointment-minute"><label>{{ \'GENERAL.MINUTE\' | translate }}</label><select class="form-control" ng-model="remindAt.minute" ng-required="true"><option></option><option>00</option><option>15</option><option>30</option><option>45</option></select></div></div><hr><div class="pull-right"><button type="button" class="btn btn-default" ng-click="closeRemindedAtModal()" ga-track-event="[\'noteLeadForm\', \'click\', \'closeButton\']">{{\'LEADS.NOTE_FORM.CLOSE\' | translate}}</button> <button type="submit" class="btn btn-filter" ga-track-event="[\'noteLeadForm\', \'click\', \'save\']">{{\'LEADS.NOTE_FORM.SAVE\' | translate}}</button></div><div class="clearfix"></div></div></form>');
}]);
})();
