(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('payments/list.html',
    '<script id="/payment-list.html" type="text/ng-template"><table\n' +
    '        class="table table-striped table-bordered table-hover"\n' +
    '    >\n' +
    '        <thead>\n' +
    '            <tr>\n' +
    '                <th>{{\'PAYMENTS.PAYMENT_STATUS\' | translate}}</th>\n' +
    '                <th>{{\'PAYMENTS.PAYMENT_TYPE\' | translate}}</th>\n' +
    '                <th>{{\'PAYMENTS.PAYMENT_AMOUNT\' | translate}}</th>\n' +
    '                <th>{{\'PAYMENTS.PAYMENT_CREATED_AT\' | translate}}</th>\n' +
    '                <th>{{\'PAYMENTS.PAYMENT_DUE_DATE\' | translate}}</th>\n' +
    '                <th ng-if="selectedTab == \'pending\'"></th>\n' +
    '            </tr>\n' +
    '        </thead>\n' +
    '        <tbody>\n' +
    '            <tr ng-if="list.length === 0">\n' +
    '                <td colspan="100%" class="text-center">\n' +
    '                    {{\'PAYMENTS.NO_RESULTS\' | translate}}\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '            <tr\n' +
    '                ng-repeat="item in list track by $index"\n' +
    '                class="table-row"\n' +
    '            >\n' +
    '                <td ng-bind="getConfigItem(\'payment_status\', item.statusId)"></td>\n' +
    '                <td ng-bind="getConfigItem(\'payment_type\', item.paymentTypeId)"></td>\n' +
    '                <td>\n' +
    '                    <span ng-bind="item.amount"></span>&nbsp;<span ng-bind="item.currencyId"></span>\n' +
    '                </td>\n' +
    '                <td><span ng-bind="item.createdAt | date: \'longDate\'"></span></td>\n' +
    '                <td><span ng-bind="item.paymentDate | date: \'longDate\'"></span></td>\n' +
    '                <td ng-if="selectedTab == \'pending\'">\n' +
    '                    <button ng-if="item.statusId == \'payment_request\' || item.statusId == \'failed\'" ng-click="openItem(item)" class="btn btn-success icon-only white" data-track="payment-open"><i class="fas fa-shopping-cart" data-track="payment-open"></i></button>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '        </tbody>\n' +
    '        <tfoot>\n' +
    '        <tr class="table-row">\n' +
    '            <td colspan="100%" class="text-center">\n' +
    '                <p class="pull-left">\n' +
    '                    {{ \'PAYMENTS.TOTAL\' | translate: {number: pagination.total} }}\n' +
    '                </p>\n' +
    '                <uib-pagination\n' +
    '                    class="pagination-sm"\n' +
    '                    ng-model="pagination.currentPage"\n' +
    '                    max-size="5"\n' +
    '                    boundary-links="true"\n' +
    '                    total-items="pagination.total"\n' +
    '                    items-per-page="pagination.perPage"\n' +
    '                    ng-show="pagination.total > 1"\n' +
    '                ></uib-pagination>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tfoot>\n' +
    '    </table></script><div class="row"><div class="col-xs-12" cg-busy="loading"><uib-tabset justified="false" class="tabs-active-bold" active="selectedTabIndex"><uib-tab select="tabSelected(\'pending\')"><uib-tab-heading>{{\'PAYMENTS.PENDING_PAYMENTS\' | translate}} <span class="badge badge-square badge-success" ng-bind="statusCounts.active"></span></uib-tab-heading><div ng-include="\'/payment-list.html\'"></div></uib-tab><uib-tab select="tabSelected(\'previous\')"><uib-tab-heading>{{\'PAYMENTS.OLD_PAYMENTS\' | translate}} <span class="badge badge-square badge-danger" ng-bind="statusCounts.rejected"></span></uib-tab-heading><div ng-include="\'/payment-list.html\'"></div></uib-tab></uib-tabset></div></div>');
}]);
})();
