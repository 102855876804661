function ItemsListCtrl($scope, $window, $routeParams, $translate, $filter, dgNotifications, queryString, Items, ItemPopup) {

    $scope.providerId = $window.parseInt($routeParams.providerId) || null;
    $scope.loading = [];

    $scope.filters = queryString.getFilters() || {};
    $scope.pagination = {
        currentPage: 1
    };

    $scope.approvedStatus = [
        {
            name: $translate.instant('ITEMS.APPROVED'),
            id: 'approved'
        },
        {
            name: $translate.instant('ITEMS.WAITING'),
            id: 'waiting'
        },
        {
            name: $translate.instant('ITEMS.REJECTED'),
            id: 'rejected'
        }
    ];

    $scope.loadList = function () {
        var data = angular.copy($scope.filters);
        data.providerId = $scope.providerId;
        data.page = $scope.pagination.currentPage;
        data.limit = 25;

        if (typeof data.status !== "undefined") {
            if (data.status) {
                data.status = 'active';
            } else {
                data.status = 'passive';
            }
        }

        if (data.minPrice === '') {
            delete data.minPrice;
        }

        if (data.maxPrice === '') {
            delete data.maxPrice;
        }

        if (typeof data.providerItemBrand !== 'undefined') {
            data.providerItemBrandId = data.providerItemBrand.id;
            delete data.providerItemBrand;
        }

        var loading = Items.query(
            data,
            function (successResponse) {
                $scope.list = $filter('orderBy')(successResponse.data, 'orderNumber');
                $scope.pagination = successResponse.meta;
                angular.forEach(successResponse.data, function (value, index) {
                    value.price = value.minPrice + '~' + value.maxPrice;
                });
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(loading);
    };
    
    $scope.openItemForm = function (item) {
        var modalPromises = ItemPopup.openForm(item ? item.id : null);
        modalPromises.result.finally(function () {
            $scope.list = [];
            $scope.loadList();
        });
    };

    $scope.delete = function (item) {
        var data = {
            id: item.id,
            providerId: $scope.providerId
        };

        var promise = Items.delete(
            data,
            function (succesResponse) {
                $scope.loadList();
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    $scope.getProviderItemBrands = function () {
        Items.getProviderItemBrands(
            {
                providerId: $scope.providerId
            },
            function (successResponse) {
                $scope.itemBrands = successResponse.data;
                angular.forEach($scope.itemBrands, function (value) {
                    value.providerItemBrandId = value.id;
                });
            }
        );
    };

    $scope.changeStatus = function (type, item) {
        var putData = {};
        putData[type] = (item[type] === 'active') ? 'passive' : 'active';
        var urlData = {
            id: item.id,
            providerId: $scope.providerId
        };

        Items.update(
            urlData,
            putData,
            function (successResponse) {
                dgNotifications.success($translate.instant('GENERAL.ADDED_SUCCESSFULLY'));
                item[type] = (item[type] === 'active') ? 'passive' : 'active';
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    $scope.setFilters = function () {
        var data = angular.copy($scope.filters);
        queryString.setFilters(data);
        $scope.loadList();
    };

    $scope.resetFilters = function () {
        $scope.filters = {};
    };

    $scope.move = function (type, index) {
        var newIndex, oldIndex, id = $scope.list[index].id;
        if (type === 'up') {
            oldIndex = $scope.list[index].orderNumber;
            newIndex = index === 0 ? null : $scope.list[index-1].orderNumber;
            if (newIndex) {
                moveItem(id, oldIndex, newIndex);
            } else {
                dgNotifications.error($translate.instant('ITEMS.CANT_MOVE_TOP'));
            }
        }

        if (type === 'down') {
            oldIndex = $scope.list[index].orderNumber;
            newIndex = index === $scope.pagination.total - 1 ? null : $scope.list[index + 1].orderNumber;
            if (newIndex) {
                moveItem(id, oldIndex, newIndex);
            } else {
                dgNotifications.error($translate.instant('ITEMS.CANT_MOVE_DOWN'));
            }
        }
    };

    function moveItem(id, oldIndex, newIndex) {
        var urlData = {
            id: id,
            providerId: $scope.providerId
        }, data = {
            oldIndex: oldIndex,
            newIndex: newIndex
        };
        Items.moveOrder(
            urlData,
            data,
            function (successResponse) {
                $scope.loadList();
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

    }

    $scope.loadList();
}

ItemsListCtrl.$inject = [
    '$scope',
    '$window',
    '$routeParams',
    '$translate',
    '$filter',
    'dgNotifications',
    'queryString',
    'Items',
    'ItemPopup'
];

angular.module('dugun.items').controller('ItemsListCtrl', ItemsListCtrl);
