function CollectionsPaymentListCtrl($scope, $translate, queryString, dgNotifications, CollectionsPayments) {

    $scope.loading = [];
    $scope.pagination = {
        currentPage: 1
    };

    $scope.filters = queryString.getFilters() || {};

    $scope.openForm = function (item) {
        var modalInstance = $scope.openModal(
            'collection/payments/payment.html',
            'CollectionsPaymentCtrl',
            item ? item.id : null,
            null,
            {
                merchantList: $scope.merchantList
            }
        );

        modalInstance.result.then(function (data) {
            if (data) {
                $scope.loadPaymentList();
            }
        });
    };

    $scope.openShowForm = function (item) {
        $scope.openModal(
            'collection/payments/show.html',
            'CollectionsPaymentCtrl',
            item ? item.id : null
        );
    };

    $scope.loadPaymentList = function () {
        var params = angular.copy($scope.filters);
        params.page = $scope.pagination.currentPage;

        var loading = CollectionsPayments.query(
            params,
            function (successResponse) {
                $scope.paymentList = successResponse.data;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.sendNotification = function (item) {
        var params = {
            id: item.id
        };
        var data = {
            type: 'notification'
        };

        var loading = CollectionsPayments.action(
            params,
            data,
            function (successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SUCCESS_RESULT'));
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.refund = function (item) {
        var params = {
            id: item.id
        };
        var data = {
            type: 'refund'
        };

        var loading = CollectionsPayments.action(
            params,
            data,
            function (successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SUCCESS_RESULT'));
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.openConfirmationRow = function (index) {
        $scope.openedItemIndex = index;
    };

    $scope.hideConfirmationRow = function () {
        $scope.openedItemIndex = null;
    };

    $scope.confirmation = function (item, approveFunc) {
        approveFunc(item);
    };

    $scope.createdAtStartOptions = {};
    $scope.$watch('filters.createdAtStart', function (newDate, oldDate) {
        if (newDate !== oldDate) {
            $scope.datePickerPopupStatus = {
                isOpen: newDate
            };
            $scope.createdAtStartOptions.minDate = new Date(newDate);
        }
    });

    $scope.setFilters = function () {
        queryString.setFilters($scope.filters);
    };

    $scope.$watch('pagination.currentPage', $scope.loadPaymentList);
}

CollectionsPaymentListCtrl.$inject = [
    '$scope',
    '$translate',
    'queryString',
    'dgNotifications',
    'CollectionsPayments'
];

angular.module('dugun.collection').controller('CollectionsPaymentListCtrl', CollectionsPaymentListCtrl);
