/**
 * Galleries factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.galleries.galleries
 * @name Galleries
 * @requires dugun.core:resource
 *
 * @returns {service} Galleries resource service
 */
function Galleries(resource) {
    var url = 'galleries/:id';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof Galleries
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get gallery list
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Galleries
             * @name update
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in PUT data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Update gallery
             */
            update: {
                url: url,
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Galleries
             * @name insert
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in POST data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Insert gallery
             */
            insert: {
                url: url,
                method: 'POST'
            }
        }
    );

    return resourceService;
}

Galleries.$inject = [
    'resource'
];

angular.module('dugun.galleries.galleries')
    .factory('Galleries', Galleries);
