function WeddingsStatisticsCtrl($scope, $route, $window, $filter, $q, $sce,
                       dgNotifications, moment, queryString, $translate,
                       Statistics, $rootScope) {
    var self = this;

    $scope.yesterday = new Date(moment().add(-1, 'day'));
    /**
     * @memberOf WeddingsStatisticsCtrl
     * @description
     * Tabs in view. Active status is stored here.
     */

    $scope.weddings = {
        providersLists : {
            providers: []
        },
        estimate: {},
        coupleProviders: {
            pagination: {
                perPage: 25,
                currentPage: 1
            },
            filters: {},
            orderBy: {
                key: 'organizationDate',
                direction: true
            },
        },
        chart:{
            filters: {
                providerId : $scope.providersStatisticsAccess[0].id
            }
        },
        providers: []

    };

    $scope.selectedProviderId = null;

    $scope.filters = queryString.getFilters() || {};

    $scope.dates = queryString.getDatesFromFilters('date', moment().startOf('month'), moment());

    $scope.weddings.load = function() {

        // All all tab contents
        $scope.weddings.coupleProviders.pagination.currentPage = 1;
        $scope.weddings.coupleProviders.load();
        $scope.$watchCollection('user', function (user) {
            if (user) {
                $scope.weddings.estimate.load();
            }
        });
    };

     $scope.weddings.estimate.load = function () {
        if ($window.getPromiseStatus($scope.weddings.estimate.loading) === 0) return true;

        var providerIds = [],
            i,
            j,
            data = [];

        for(i in $scope.providersStatisticsAccess) {
            providerIds.push($scope.providersStatisticsAccess[i].id);
        }

        data['providerIds[]'] = providerIds;

        $scope.weddings.estimate.loading = Statistics.estimate(
            data,
            function (successResponse) {
                $scope.weddings.estimate.data = successResponse.data;
                $scope.weddings.estimate.approximateRevenue = 0;
                $scope.weddings.estimate.approximateRevenueFormula = '';
                $scope.weddings.estimate.leadCoupleCount = 0;
                $scope.weddings.estimate.calledCoupleCount = 0;
                $scope.weddings.estimate.totalCouple = 0;
                $scope.weddings.estimate.reachedCouplePercentage = 0;
                $scope.weddings.estimate.agreedCouplePercentage = 0;
                for(i in $scope.weddings.estimate.data) {
                    $scope.weddings.estimate.leadCoupleCount += $scope.weddings.estimate.data[i].leadCoupleCount;
                    $scope.weddings.estimate.calledCoupleCount += $scope.weddings.estimate.data[i].calledCoupleCount;
                    $scope.weddings.estimate.totalCouple += $scope.weddings.estimate.data[i].totalCouple;
                    $scope.weddings.estimate.approximateRevenue += $scope.weddings.estimate.data[i].approximateRevenue;
                    if($scope.weddings.estimate.data[i].approximateRevenueFormula) {
                        for (j in $scope.providersActive) {
                            if ($scope.weddings.estimate.data[i].providerId == $scope.providersActive[j].id) {
                                if ($scope.weddings.estimate.approximateRevenueFormula !== '') {
                                    $scope.weddings.estimate.approximateRevenueFormula += '<br>';
                                }
                                $scope.weddings.estimate.approximateRevenueFormula += $sce.trustAsHtml('<strong>' + $scope.providersActive[j].name.replace(/['"<>]/g, ' ') + ' - ' + $scope.getProviderCategory($scope.providersActive[j].categoryId) + ' / ' + $scope.getCity($scope.providersActive[j].cityId) + '</strong> ' + 'için ' + $scope.weddings.estimate.data[i].approximateRevenueFormula + '<br>');
                            }
                        }
                    }
                }
                $scope.weddings.estimate.reachedCouplePercentage = $scope.weddings.estimate.calledCoupleCount / $scope.weddings.estimate.leadCoupleCount * 100;
                $scope.weddings.estimate.agreedCouplePercentage = $scope.weddings.estimate.totalCouple / $scope.weddings.estimate.calledCoupleCount * 100;
                if($scope.weddings.estimate.reachedCouplePercentage > 100) {
                    $scope.weddings.estimate.reachedCouplePercentage = 100;
                }
                if($scope.weddings.estimate.agreedCouplePercentage > 100) {
                    $scope.weddings.estimate.agreedCouplePercentage = 100;
                }
                $scope.weddings.chart.load();
                dataLayer.push(
                    {
                        event: 'estimate',
                        customer: {
                            approximateRevenue: $scope.weddings.estimate.data[0].approximateRevenue,
                            calledCoupleCount: $scope.weddings.estimate.data[0].calledCoupleCount,
                            leadCoupleCount: $scope.weddings.estimate.data[0].leadCoupleCount,
                            providerId: $scope.weddings.estimate.data[0].providerId,
                            totalCouple: $scope.weddings.estimate.data[0].totalCouple
                        }
                    }
                );
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });
    };

    $scope.weddings.openTestimonialRequestForm = function(item) {
        var data = {
            id: item.coupleProvider.id,
            name: item.coupleProvider.couple.name,
            email: item.coupleProvider.couple.email,
            phone: item.coupleProvider.couple.phone,
            organizationDate: item.coupleProvider.organization,
            provider: item.coupleProvider.provider
        };
        dataLayer.push(
            {
                event: 'requestComment',
                provider: {
                    id: item.coupleProvider.provider.id,
                    name: item.coupleProvider.provider.name,
                    category: $scope.getProviderCategory(item.coupleProvider.provider.categoryId),
                    city: item.coupleProvider.provider.city.name,
                },
                couple: {
                    id: item.coupleProvider.couple.id,
                },
                lead: {
                    id: item.leadId,
                }
            }
        );
        $scope.openModal(
            'testimonials/request/modal.html',
            'TestimonialRequestCtrl',
            item.coupleProvider.id,
            null,
            data
        ).result.then(function(modalResponse) {
            if(modalResponse.success) {
                item.mailSent = new Date();
                dataLayer.push(
                    {
                        event:'sendCommentRequestEmail',
                        provider: {
                            id: item.coupleProvider.provider.id,
                            name: item.coupleProvider.provider.name,
                            category: $scope.getProviderCategory(item.coupleProvider.provider.categoryId),
                            city: item.coupleProvider.provider.city.name,
                        },
                        couple: {
                            id: item.coupleProvider.couple.id,
                        },
                        lead: {
                            id: item.leadId,
                        }
                    }
                );
            }


        });
    };

    $scope.weddings.openTestimonial = function(testimonial, item) {
        $scope.openModal(
            'testimonials/show.modal.html',
            'TestimonialCtrl',
            testimonial.id
        );
        dataLayer.push({
            event: 'openTestimonial',
            provider: {
                id: item.coupleProvider.provider.id,
                name: item.coupleProvider.provider.name,
                category: $scope.getProviderCategory(item.coupleProvider.provider.categoryId),
                city: item.coupleProvider.provider.city.name,
            },
            couple: {
                id: item.coupleProvider.couple.id,
            },
            lead: {
                id: item.leadId,
            }
        });
    };

    $scope.weddings.chart.load = function () {
        if (typeof $window.getPromiseStatus($scope.user) === "undefined") return true;
        var providerIds = [],
            i;


        for(i in $scope.providersStatisticsAccess) {
            providerIds.push($scope.providersStatisticsAccess[i].id);
        }

        $scope.weddings.providers = [];
        for(i in $scope.user.providers) {
            if(providerIds.indexOf($scope.user.providers[i].id) != -1) {
                var provider = angular.copy($scope.user.providers[i]),
                    data = angular.copy($scope.filters);

                data.id = provider.id;
                provider.lists = Statistics.chart(
                    data,
                    self.setPieData,
                    errorCallback
                );

                $scope.weddings.providers.push(provider);
            }
        }

        function errorCallback(errorResponse) {
            dgNotifications.error(errorResponse);
        }
    };

    /**
     * @ngdoc method
     * @memberof WeddingsStatisticsCtrl
     * @private
     * @description
     * Sets the pyramide chart data
     * @deprecated
     */
    self.setPyramideData = function() {
        var provider, i, j;

        for(i in $scope.weddings.providers) {
            provider = $scope.weddings.providers[i];

            if(provider.lists && provider.lists.data && provider.lists.data.pyramide) {
                provider.lists.data.pyramideChart = {
                    title: '',
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'pyramid',
                        marginRight: 100
                    },
                    series: [
                        {
                            name: $translate.instant('STATISTICS.CHART.SERIES_NAME'),
                            data: [],
                            tooltip: {
                                headerFormat: $translate.instant('STATISTICS.CHART.HEADER_FORMAT'),
                                pointFormat: '',
                                shared: true
                            },
                        }
                    ],
                    exporting: {
                        enabled: false
                    }
                };
                for(j in provider.lists.data.pyramide) {
                    provider.lists.data.pyramideChart.series[0].data.push([
                        provider.lists.data.pyramide[j].budgetText,
                        $window.parseInt(provider.lists.data.pyramide[j].coupleRatio)
                    ]);
                }

                provider.lists.data.pyramideChart.series[0].data.reverse();
            }

            $scope.weddings.providers[i] = provider;
        }
    };

    /**
     * @ngdoc method
     * @memberof WeddingsStatisticsCtrl
     * @private
     * @description
     * Sets the pie chart data
     */
    self.setPieData = function() {
        var provider,
            colors = [],
            providerBudgetLevel,
            i, j, k,
            baseColor = Highcharts.getOptions().colors[0],
            sameBudgetColor = 'red';

        for(i in $scope.weddings.providers) {
            provider = $scope.weddings.providers[i];
            colors = [];
            providerBudgetLevel = '';

            if(provider.lists && provider.lists.data && provider.lists.data.pyramide) {
                // switch pyramide to pie
                provider.lists.data.pie = provider.lists.data.pyramide;

                provider.lists.data.pieChart = {
                    title: '',
                    credits: {

                        enabled: false
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            showInLegend: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '{point.percentage:.0f} %',
                                style: {
                                    fontWeight: 'bold',
                                    color: 'black',
                                    textShadow: '0px 1px 1px #CCCCCC'
                                }
                            }
                        },
                    },
                    series: [
                        {
                            name: $translate.instant('STATISTICS.CHART.SERIES_NAME'),
                            data: [],
                            tooltip: {
                                headerFormat: $translate.instant('STATISTICS.CHART.HEADER_FORMAT'),
                                pointFormat: '',
                                shared: true
                            },
                        }
                    ],
                    exporting: {
                        enabled: false
                    }
                };

                for(k in $scope.weddings.estimate.data) {
                    if(provider.id == $scope.weddings.estimate.data[k].providerId) {
                        providerBudgetLevel = $scope.weddings.estimate.data[k].budgetLevel;
                    }
                }

                for(j in provider.lists.data.pie) {
                    var currentBudgetColor = Highcharts.Color(baseColor).brighten((j - 3) / 7).get();
                    if(provider.lists.data.pie[j].budgetLevel == providerBudgetLevel) {
                        currentBudgetColor = Highcharts.Color(sameBudgetColor).get();
                    }
                    colors.push(currentBudgetColor);

                    provider.lists.data.pieChart.series[0].data.push([
                        provider.lists.data.pie[j].budgetText,
                        !$window.parseInt(provider.lists.data.pie[j].coupleRatio) ? Math.ceil(provider.lists.data.pie[j].coupleRatio) : Math.floor(provider.lists.data.pie[j].coupleRatio)
                    ]);
                }

                provider.lists.data.pieChart.plotOptions.pie.colors = colors.reverse();
                provider.lists.data.pieChart.series[0].data.reverse();
            }

            $scope.weddings.providers[i] = provider;
        }
    };

    /**
     * @ngdoc method
     * @memberof WeddingsStatisticsCtrl
     * @param key {string} The key to use when sorting
     * @description
     * Called from view. Sets the properties to sort.
     */
    $scope.weddings.coupleProviders.sortBy = function(key) {
        if($scope.weddings.coupleProviders.orderBy.key === key) {
            $scope.weddings.coupleProviders.orderBy.direction = !$scope.weddings.coupleProviders.orderBy.direction;
        } else {
            $scope.weddings.coupleProviders.orderBy.key = key;
            $scope.weddings.coupleProviders.orderBy.direction = false;
        }

        $scope.weddings.coupleProviders.load();
    };

    $scope.weddings.coupleProviders.load = function(tableState) {
        if ($window.getPromiseStatus($scope.loading) === 0) return true;

        if(!tableState) {
            tableState = $scope.weddings.coupleProviders.tableStateTemp;
        } else {
            $scope.weddings.coupleProviders.tableStateTemp = tableState;
            var currentPage = $scope.weddings.coupleProviders.pagination.currentPage;
            $scope.weddings.coupleProviders.pagination.currentPage = 1;
            if(currentPage !== 1) {
                return true;
            }
        }

        var data = angular.copy($scope.weddings.coupleProviders.filters);

        if(data.coupleName === '') {
            delete data.coupleName;
        }

        data = angular.merge(data, $window.getSortData(tableState));

        data.paginate = 1;
        data.page = $scope.weddings.coupleProviders.pagination.currentPage;
        data.limit = $scope.weddings.coupleProviders.pagination.perPage;

        if(!data['providerIds[]']) {
            data['providerIds[]'] = $scope.user.providersStatisticsAccessIds;
        }

        data.sort = $scope.weddings.coupleProviders.orderBy.key;
        data.order = $scope.weddings.coupleProviders.orderBy.direction ? 'DESC' : 'ASC';

        if(data.coupleName) {
            if(data.coupleName === '') {
                delete data.coupleName;
            }
        }

        $scope.weddings.coupleProviders.loading = Statistics.coupleProviders(
            data,
            function (successResponse) {
                $scope.weddings.coupleProviders.list = successResponse.data;
                $scope.weddings.coupleProviders.pagination = successResponse.meta;
                $scope.weddings.coupleProviders.listDisplayed = [].concat($scope.weddings.coupleProviders.list);
                var provider = $filter('filter')($scope.providersActive, {id: data['providerIds[]'][0]}, true)[0];
                dataLayer.push(
                    {
                        event: 'getProvider',
                        provider: {
                            id: provider.id,
                            name: provider.name,
                            category: $scope.getProviderCategory(provider.categoryId),
                            city: $scope.getCity(provider.cityId)
                        }
                    }
                );
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });
    };

    $scope.$watch('date', dateChanged, true);

    function dateChanged(newValue) {
        datesChanged(newValue, 'date');
    }

    function datesChanged(newValue, type) {
        if (!newValue) return;
        if (newValue.startDate) {
            $scope.filters[type + 'Start'] = newValue.startDate.format('YYYY-MM-DD');
        } else {
            delete $scope.filters[type + 'Start'];
        }
        if (newValue.endDate) {
            $scope.filters[type + 'End'] = newValue.endDate.format('YYYY-MM-DD');
        } else {
            delete $scope.filters[type + 'End'];
        }
    }

    $scope.weddings.load();

    $scope.$watch('weddings.coupleProviders.pagination.currentPage', function (newValue, oldValue) {
        if (newValue != oldValue) {
            $scope.weddings.coupleProviders.load();
        }
    });

    $translate.onReady().then(function () {
        if ($translate.instant('STATISTICS.WEDDINGS.HELPER_VIDEO.IS_EXIST') == 'true') {
            $scope.$root.tutorials.push(
                {
                    type: 'video',
                    data: {
                        video_title: $translate.instant('STATISTICS.WEDDINGS.HELPER_VIDEO.VIDEO_TITLE'),
                        video_code: $translate.instant('STATISTICS.WEDDINGS.HELPER_VIDEO.VIDEO_CODE')
                    }
                }
            );
        }

        if ($translate.instant('STATISTICS.WEDDINGS.HELPER_PAGE.IS_EXIST') == 'true') {
            $scope.$root.tutorials.push(
                {
                    type: 'page',
                    data: {
                        page_title: $translate.instant('STATISTICS.WEDDINGS.HELPER_PAGE.PAGE_TITLE'),
                        page_url: 'statistics/weddings/helper.html'
                    }
                }
            );
        }
    });

    $scope.$root.heading.name = $route.current.label;
}

WeddingsStatisticsCtrl.$inject = [
    '$scope',
    '$route',
    '$window',
    '$filter',
    '$q',
    '$sce',
    'dgNotifications',
    'moment',
    'queryString',
    '$translate',
    'Statistics',
    '$rootScope'
];

angular.module('dugun.provider.statistics')
    .controller('WeddingsStatisticsCtrl', WeddingsStatisticsCtrl);
