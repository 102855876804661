function CustomerMenuRoutes($routeProvider) {
    $routeProvider
        .when('/providers/:providerId/customer-menu', {
            templateUrl: 'customer-menu/list.html',
            controller: 'CustomerMenuListCtrl',
            label: 'CUSTOMER_MENU.CUSTOMER_MENU'
        });
}

CustomerMenuRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.customerMenu').config(CustomerMenuRoutes);
