function BusyTranslate($translate, cgBusyDefaults) {
    var service = {};

    service.translate = function () {
        $translate.onReady().then(function () {
            cgBusyDefaults.message = $translate.instant('GENERAL.PLEASE_WAIT');
        });
    };

    return service;
}

BusyTranslate.$inject = [
    '$translate',
    'cgBusyDefaults'
];

angular.module('dugun.provider').factory('BusyTranslate', BusyTranslate);
