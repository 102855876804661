/**
 * @ngdoc controller
 * @memberof dugun.payments
 * @name PaymentsListCtrl
 *
 * @requires $scope
 * @requires ngRoute:$route
 * @requires $location
 * @requires dugun.notifications:dgNotifications
 * @requires dugun.queryString:queryString
 * @requires Analytics
 * @requires Payments
 */
function PaymentsListCtrl(
    $scope, $route, $location,
    dgNotifications, queryString, Analytics,
    Payments
) {
    $scope.$root.heading.name = $route.current.label;
    $scope.tabs = {
        pending: {index: 0},
        previous: {index: 1}
    };
    $scope.list = [];
    $scope.filters = queryString.getFilters() || {};
    $scope.loading = [];

    $scope.pagination = {
        limit: 25,
        currentPage: 1,
        total: 0
    };

    $scope.orderBy = {
        key: 'createdAt',
        direction: true
    };

    $scope.loadList = function () {
        if (window.getPromiseStatus($scope.loading) === 0) return true;

        $scope.list = [];

        var data = angular.copy($scope.filters),
            promise;

        data.page = $scope.pagination.currentPage;

        if($scope.selectedTab == 'previous') {
            data['statusIds[]'] = ['success'];
        } else {
            data['statusIds[]'] = ['payment_request', 'failed'];
        }

        promise = Payments.query(
            data,
            function (successResponse) {
                $scope.list = successResponse.data;
                $scope.pagination = successResponse.meta;
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };


    $scope.setFilters = function () {
        $scope.pagination.currentPage = 1;
        queryString.setFilters(angular.copy($scope.filters));
        $scope.loadList();
    };

    $scope.resetFilters = function() {
        $scope.filters = {};
        $scope.setFilters();
    };

    $scope.$watch('pagination.currentPage', function (newValue, oldValue) {
        if (newValue != oldValue) {
            $scope.loadList();
        }
    });

    $scope.openItem = function (item) {
        queryString.setHash(null);
        $location.path('payments/' + item.paymentId);
    };

    $scope.tabSelected = function(newTab) {
        if(!newTab || newTab === $scope.selectedTab) return;
        $scope.selectedTab = newTab;
        queryString.setHash(newTab);
        $scope.setFilters();
    };

    if(queryString.getHash() && (queryString.getHash() == 'pending' || queryString.getHash() == 'previous')) {
        $scope.loadList();
        $scope.selectedTab = queryString.getHash() || null;
        $scope.selectedTabIndex = $scope.selectedTab ? $scope.tabs[$scope.selectedTab].index : 0;
    } else {
        $scope.filters = {};
        $scope.tabSelected('pending');
        $scope.selectedTabIndex = 0;
    }
}

PaymentsListCtrl.$inject = [
    '$scope',
    '$route',
    '$location',
    'dgNotifications',
    'queryString',
    'Analytics',
    'Payments',
];

angular.module('dugun.payments')
    .controller('PaymentsListCtrl', PaymentsListCtrl);
