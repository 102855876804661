(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('tutorials/page.modal.html',
    '<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="close()">&times;</button><h4 class="modal-title">{{page_title}}</h4></div><div class="modal-body"><div ng-include="page_url"></div></div>');
}]);
})();
