(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('login/index.html',
    '<div class="login-container animated fadeInDown" cg-busy="loading"><a href="javascript:void(0);" class="login-logo" style="background-image: url(\'{{ environment.currentLanguage.loginLogo }}\')"></a><div class="loginbox bg-white"><form ng-submit="login()"><div class="loginbox-title">{{\'LOGIN.HEADER\' | translate}}</div><div class="loginbox-or"><div class="or-line"></div></div><div class="loginbox-textbox pt-0"><label class="input-sm text-bold mb-0">{{\'LOGIN.USERNAME\' | translate}}</label><input type="text" class="form-control" id="email" ng-model="email" autofill required autofocus></div><div class="loginbox-textbox pt-0"><label class="input-sm text-bold mb-0">{{\'LOGIN.PASSWORD\' | translate}}</label><input type="password" class="form-control" id="password" ng-model="password" autofill required></div><div class="loginbox-submit"><input type="submit" class="btn btn-primary btn-block" value="{{\'LOGIN.LOGIN_BUTTON\' | translate}}" ga-track-event="[\'loginForm\', \'click\', \'login\']"></div><div class="loginbox-forgot"><a href="forgot-password" ga-track-event="[\'loginForm\', \'click\', \'resetPassword\']">{{\'LOGIN.FORGOT_USERNAME_PASSWORD\' | translate}}</a></div><div class="loginbox-submit"><hr><button type="button" class="btn btn-blue btn-block btn-large btn-facebook" data-ng-disabled="!facebookReady" data-ng-click="FBIntentLogin()"><i class="fab fa-facebook-f"></i>&nbsp;{{\'LOGIN.LOGIN_FACEBOOK\' | translate}}</button> <button type="button" class="btn btn-white btn-block btn-large btn-google white" data-ng-click="googleLogin()"><i class="fab fa-google"></i>&nbsp;{{\'LOGIN.LOGIN_GOOGLE\' | translate}}</button></div></form></div></div><style type="text/css">html, body, .page-body{\n' +
    '        height: 100%;\n' +
    '        width: 100%;\n' +
    '        min-height: 100%;\n' +
    '        background: url(images/login-bg-2.jpg) no-repeat center center fixed;\n' +
    '        -webkit-background-size: cover;\n' +
    '        -mox-background-size: cover;\n' +
    '        -o-background-size: cover;\n' +
    '        background-size: cover;\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '    .loading-container{\n' +
    '        display: none;\n' +
    '    }</style>');
}]);
})();
