function StepProgressBar() {
    return {
        restrict: 'E',
        scope: {
            options: '='
        },
        templateUrl:'collection/step-progress-bar/step-progress-bar.html',
        link: function (scope) {
            scope.progressStep = [];
        }
    };
}

angular.module('dugun.forms').directive('stepProgressBar', StepProgressBar);
