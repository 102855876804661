function AppCtrl($rootScope, $window, $location, $route, $localStorage, $templateCache, $filter, $q,
                 $cookies, $timeout, queryString, dgNotifications, dgPopup, tmhDynamicLocale,
                 moment, Analytics, heading, CustomerUsersPopup, ProviderUpgradePopups, TutorialsService,
                 OneSignalService, Auth, dugunConfig, providerCategory, city, district, Leads,
                 ProvidersDetailsService, environmentConfig, PaginationTranslate, DatePickerTranslate,
                 DateRangePickerTranslate, TextAngularTranslate, BusyTranslate, DgNotificationsTranslate,
                 FirebaseListener, CollectionsMerchants, Providers, UsercomService
) {
    var self = this;

    $rootScope.screenWidth = '';

    angular.element($window).bind('resize', function() {
        self.windowResized();
        $rootScope.$apply();
    });

    $rootScope.heading = heading;
    $rootScope.heading.name = 'Ana Sayfa';
    $rootScope.heading.url = 'layout/header.html';
    $rootScope.heading.data = {};

    $rootScope.tutorials = [];

    $rootScope.user = null;
    $rootScope.userLogged = false;
    $rootScope.unsignedAgreementIds = [];
    $rootScope.pendingPaymentIds = [];
    $rootScope.limitedAccess = false;

    $rootScope.loadingBase = [];
    $rootScope.loadingLeadCounts = null;
    $rootScope.title = null;
    $rootScope.angular = angular;
    $rootScope.settings = {
        fixed: {}
    };

    $rootScope.cookieLang = $cookies.get('lang');
    environmentConfig.currentLanguage = environmentConfig.languages.cases[environmentConfig.languages.default];
    if ($rootScope.cookieLang) {
        environmentConfig.currentLanguage = environmentConfig.languages.cases[$rootScope.cookieLang];
    } else {
        $cookies.put('lang', environmentConfig.currentLanguage.code);
    }

    $rootScope.environment = environmentConfig;
    $rootScope.rtl = environmentConfig.currentLanguage.rtl;

    if( $rootScope.rtl ){
        angular.element('head').append('<link rel="stylesheet" href="bootstrap-rtl.min.css?v=' + environmentConfig.appVersion + '">');
        angular.element('head').append('<link rel="stylesheet" href="panel-rtl.min.css?v=' + environmentConfig.appVersion + '">');
    }

    moment.locale(environmentConfig.currentLanguage.code);
    tmhDynamicLocale.set(environmentConfig.currentLanguage.code);

    $rootScope.allLeadsCount = {
        leads: {
            pending: 0,
            processed: 0,
            total: 0
        },
        infoRequests: {
            pending: 0,
            processed: 0,
            total: 0
        },
        calls: {
            pending: 0,
            processed: 0,
            total: 0
        },
    };

    $rootScope.missingProviderDetails = {};
    $rootScope.allowedRelatedProvidersCategories = [23, 18, 9, 27, 80];

    if (angular.isDefined($localStorage.settings))
        $rootScope.settings = $localStorage.settings;
    else
        $localStorage.settings = $rootScope.settings;

    self.windowResized = function() {
        if($window.innerWidth < 768) {
            $rootScope.screenWidth = 'xs';
        } else if($window.innerWidth < 992) {
            $rootScope.screenWidth = 'sm';
        } else if($window.innerWidth < 1200) {
            $rootScope.screenWidth = 'md';
        } else {
            $rootScope.screenWidth = 'lg';
        }
    };

    $rootScope.$watch('settings', function() {
        if ($rootScope.settings.fixed.header) {
            $rootScope.settings.fixed.navbar = true;
            $rootScope.settings.fixed.sidebar = true;
        }
        if ($rootScope.settings.fixed.sidebar)
            $rootScope.settings.fixed.navbar = true;

        $localStorage.settings = $rootScope.settings;
    }, true);

    $rootScope.openUsercom = function() {
        UsercomService.open();
    };

    $rootScope.changeLanguage = function (lang) {
        if ($rootScope.cookieLang === lang) return;

        var currentPath = $location.path();
        $cookies.put('lang', lang);
        $window.localStorage.clear();
        $window.location.href = currentPath;
    };

    $rootScope.openModal = function(name, controllerName, id, parentId, data, options) {
        data = data || {};
        options = options || { backdrop: 'true' };

        var modalData = {
            templateUrl: name,
            controller: controllerName
        };

        if(id) {
            modalData.itemId = id;
        }

        if(parentId) {
            modalData.parentId = parentId;
        }

        if(angular.isObject(data)) {
            modalData.data = data;
        }

        if(angular.isObject(options)) {
            modalData.modalOptions = options;
        }

        var modalInstance = dgPopup.openModal(modalData);

        modalInstance.result.then(function(modalResponse) {
            if(modalResponse.type) {
                if(modalResponse.type === 'openModal') {
                    $rootScope.openModal(
                        modalResponse.data.templateUrl,
                        modalResponse.data.controller,
                        modalResponse.data.resolve.itemId
                    );
                }
            }
            return true;
        }, function () {
        });

        return modalInstance;
    };

    /**
     * @ngdoc method
     * @memberof AppCtrl
     * @description
     * Opens profile modal
     */
    $rootScope.openProfileModal = function() {
        return CustomerUsersPopup.openProfile($rootScope.user.id, {showSocialLogin: true});
    };

    $rootScope.openTutorialModal = function(tutorial) {
        if(tutorial.type == 'video') {
            return TutorialsService.openVideo(tutorial.data.video_title, tutorial.data.video_code);
        } else {
            return TutorialsService.openPage(tutorial.data.page_title, tutorial.data.page_url);
        }
    };

    $rootScope.getPromiseStatus = function(promise) {
        return $window.getPromiseStatus(promise);
    };

    $rootScope.getConfig = function(type) {
        return dugunConfig.getItems(type);
    };

    $rootScope.getConfigItem = function(type, id) {
        return dugunConfig.getItem(type, id);
    };


    $rootScope.getProviderCategories = function() {
        return providerCategory.getItems();
    };

    $rootScope.getProviderCategory = function(id) {
        return providerCategory.getItem(id);
    };

    $rootScope.getProviderCategoryDetail = function(id) {
        return providerCategory.getItemDetail(id);
    };


    $rootScope.getCities = function() {
        return city.getItems();
    };

    $rootScope.getCity = function(location1Id) {
        return city.getItem(location1Id);
    };

    $rootScope.getDistricts = function(cityId) {
        return district.getItems(cityId);
    };

    $rootScope.getDistrict = function(districtId) {
        return district.getItem(districtId);
    };

    $rootScope.getProvider = function(providerId) {
        if(!providerId || !$rootScope.user) return null;

        for(var i in $rootScope.user.providers) {
            if($rootScope.user.providers[i].id === providerId) {
                return $rootScope.user.providers[i];
            }
        }

        return null;
    };

    $rootScope.getFormInputClass = function(item) {
        return {
            'has-warning': item.$error.required,
            'has-error': item.$error.url || item.$error.email
        };
    };

    $rootScope.checkIsToday = function (date) {
        if(moment(date).isSame(moment(), 'd')) {
            return true;
        } else {
            return false;
        }
    };

    $rootScope.checkIsSameYear = function (date) {
        if(moment(date).isSame(moment(), 'y')) {
            return true;
        } else {
            return false;
        }
    };

    $rootScope.getUser = function() {
        var deferred = $q.defer();

        $rootScope.loadingUser = Auth.getUser(
            { scopes: 'user' },
            function(successResponse) {
                var page = $location.url();
                if(page.indexOf('forgot-username') !== -1 ||
                    page.indexOf('forgot-password') !== -1 ||
                    page.indexOf('login') !== -1 ||
                    page.indexOf('password-reset') !== -1 ||
                    page.indexOf('register') !== -1 ||
                    page.indexOf('password-renew') !== -1
                ) {
                    $location.url('/home');
                }

                $rootScope.user = successResponse.user;
                UsercomService.init($rootScope.user);
                $rootScope.userLogged = true;
                $rootScope.unsignedAgreementIds = successResponse.accessToken.unsignedAgreementIds;
                $rootScope.pendingPaymentIds = successResponse.accessToken.pendingPaymentIds;

                $rootScope.providersActive = $filter('filter')($rootScope.user.providers, function (value) {
                    if(value.onHold) {
                        return false;
                    } else {
                        return true;
                    }
                });

                $rootScope.providersWithProduct = $filter('filter')($rootScope.providersActive, function (value) {
                    if(!value.hasPromotion && !value.paidStatus) {
                        return false;
                    } else {
                        return true;
                    }
                });
                $rootScope.providersWithDiscount = $filter('hasPermission')($rootScope.providersActive, 'discountOnProfile');
                $rootScope.providersStatisticsAccess = $filter('hasPermission')($rootScope.providersActive, 'statsAccess');

                if($cookies.get('switch_user') && $cookies.get('admin_access_token')) {
                    changeAccess($rootScope.user.providers, ['statsAccess']);
                    $rootScope.providersActive = $rootScope.user.providers;
                    $rootScope.providersWithProduct = $rootScope.user.providers;
                    $rootScope.providersWithDiscount = $rootScope.user.providers;
                    $rootScope.providersStatisticsAccess = $rootScope.user.providers;
                }

                $rootScope.$broadcast('user.got', $rootScope.user);

                // Send customer user id to analytics
                Analytics.set('&uid', $rootScope.user.id);

                $rootScope.user.providerIds = [];
                $rootScope.user.providerCitiesIds = [];
                $rootScope.user.providersWithProductIds = [];
                $rootScope.user.providersStatisticsAccessIds = [];
                $rootScope.user.providersProductFeatures = {};
                for(var i in $rootScope.providersActive) {
                    $rootScope.user.providersProductFeatures[$rootScope.providersActive[i].id] = $rootScope.providersActive[i].productFeatures || {};
                    $rootScope.user.providerIds.push($rootScope.providersActive[i].id);
                    if($rootScope.providersActive[i].cityId){
                        if($rootScope.user.providerCitiesIds.indexOf($rootScope.providersActive[i].cityId) == -1) {
                            $rootScope.user.providerCitiesIds.push($rootScope.providersActive[i].cityId);
                        }
                    }
                }
                for(var j in $rootScope.providersWithProduct) {
                    $rootScope.user.providersWithProductIds.push($rootScope.providersWithProduct[j].id);
                }

                for(var k in $rootScope.providersStatisticsAccess) {
                    $rootScope.user.providersStatisticsAccessIds.push($rootScope.providersStatisticsAccess[k].id);
                }

                if(!$cookies.get('switch_user') && !$cookies.get('admin_access_token')) {
                    if (window.navigator.userAgent == 'providerDugunAppAndroid' && typeof Android !== 'undefined') {
                        Android.processData($rootScope.user.customerId, $rootScope.user.id);
                    }
                    if (window.navigator.userAgent == 'providerDugunAppIos') {
                        window.location = "iosApp://userLogin?customerId=" + $rootScope.user.customerId + "&userId=" + $rootScope.user.id;
                    }
                    OneSignalService.init($rootScope.user.customerId, $rootScope.user.id);
                }
                FirebaseListener.listenLead();
                FirebaseListener.listenLeadMessage();
                FirebaseListener.listenReminder();
                deferred.resolve($rootScope.user);

                dataLayer.push(
                    {
                        customer: {
                            countOfProviders: $rootScope.providersActive.length,
                            id: $rootScope.user.customerId,
                            name: $rootScope.user.customer.name
                        }
                    }
                );
            },
            function(errorResponse) {
                $rootScope.user = false;
                $rootScope.userLogged = false;
                UsercomService.destroy();
                var redirectPage = $location.search().redirect || $location.url();
                if(redirectPage.indexOf('forgot-username') == -1 &&
                    redirectPage.indexOf('forgot-password') == -1 &&
                    redirectPage.indexOf('login') == -1 &&
                    redirectPage.indexOf('password-reset') == -1 &&
                    redirectPage.indexOf('register') == -1 &&
                    redirectPage.indexOf('password-renew') == -1
                ) {
                    $location.url('/login');
                    if(redirectPage !== '/') {
                        $location.search({
                            redirect: redirectPage
                        });
                    }
                }
                deferred.reject();
            }
        );
        $rootScope.loadingUser = deferred.promise;
        $rootScope.loadingBase.push($rootScope.loadingUser);
    };

    $rootScope.logout = function() {
        if($cookies.get('switch_user') && $cookies.get('admin_access_token')) {
            $cookies.remove('access_token');
            $cookies.remove('switch_user', {domain: environmentConfig.domain});
            $cookies.remove('admin_access_token', {domain: environmentConfig.domain});
            $cookies.remove('__ca__chat', {domain: environmentConfig.domain});
            $rootScope.user = null;
            $rootScope.userLogged = false;
            UsercomService.destroy();
            $window.localStorage.clear();
            $window.location.href = '/login';
            return;
        }
        $rootScope.loading = Auth.deleteAccessToken(
            function(successResponse) {
                $rootScope.user = null;
                $rootScope.userLogged = false;
                dgNotifications.success('Çıkış yaptınız.');
                UsercomService.destroy();
                $window.localStorage.clear();
                $cookies.remove('__ca__chat', {domain: environmentConfig.domain});
                $window.location.href = '/login';
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof AppCtrl
     * @param permissionName {string} The permission to check if exists
     * @param providerId {int=} If exists, only this provider will be checked
     * else any provider with specified permission will make function return true
     * @description
     * Check if the provider or any of the providers has permission
     */
    $rootScope.hasPermission = function(permissionName, providerId) {
        if(!$rootScope.user) {
            return true;
        }
        var i;
        if(providerId) {
            for(i in $rootScope.user.providers) {
                if($rootScope.user.providers[i].id == providerId) {
                    return $filter('hasPermission')($rootScope.user.providers[i], permissionName);
                }
            }
        } else {
            for(i in $rootScope.user.providers) {
                if($filter('hasPermission')($rootScope.user.providers[i], permissionName)) {
                    return true;
                }
            }
        }

        return false;
    };

    /**
     * @ngdoc method
     * @memberof AppCtrl
     * @description
     * If user has not access to a page, this modal is used
     */
    $rootScope.openUpgradeModal = function(page, message) {
        return ProviderUpgradePopups.openRequestUpgrade(page, message);
    };

    /**
     * @ngdoc method
     * @memberof AppCtrl
     * @param widgetName {string} The name of widget to toggle
     * @param widgetVersion {number=1} Version of the widget
     * @description
     * Toggles the widget status in cookie
     */
    $rootScope.toggleWidget = function(widgetName, widgetVersion) {
        if(!angular.isNumber(widgetVersion)) {
            widgetVersion = 1;
        }

        var widgets = $cookies.getObject('widgets') || {};

        if(widgets[widgetName + widgetVersion]) {
            widgets[widgetName + widgetVersion] = false;
            Analytics.trackEvent(widgetName, 'close', 'helper');
        } else {
            widgets[widgetName + widgetVersion] = true;
            Analytics.trackEvent(widgetName, 'open', 'helper');
        }

        $cookies.putObject('widgets', widgets);
    };

    /**
     * @ngdoc method
     * @memberof AppCtrl
     * @param widgetName {string} The name of widget to toggle
     * @param widgetVersion {number=1} Version of the widget
     * @description
     * Toggles the widget status in cookie
     */
    $rootScope.getWidget = function(widgetName, widgetVersion) {
        if(!angular.isNumber(widgetVersion)) {
            widgetVersion = 1;
        }

        var widgets = $cookies.getObject('widgets') || {};
        return !!widgets[widgetName + widgetVersion] || widgets[widgetName + widgetVersion + 'Count'] > 3;
    };

    /**
     * @ngdoc method
     * @memberof AppCtrl
     * @param widgetName {string} The name of widget to toggle
     * @param widgetVersion {number=1} Version of the widget
     * @description
     * Increases the read count of widget
     */
    $rootScope.widgetRead = function(widgetName, widgetVersion) {
        if(!angular.isNumber(widgetVersion)) {
            widgetVersion = 1;
        }

        var widgets = $cookies.getObject('widgets') || {};

        if(angular.isUndefined(widgets[widgetName + widgetVersion + 'Count'])) {
            widgets[widgetName + widgetVersion + 'Count'] = 0;
        }

        widgets[widgetName + widgetVersion + 'Count']++;

        $cookies.putObject('widgets', widgets);
    };

    /**
     * @ngdoc method
     * @memberof AppCtrl
     * @private
     * @description
     * Sets the minimum ROI
     */
    self.calculateMinimumROI = function() {
        if($rootScope.user && $rootScope.getProviderCategories().length > 0 && $rootScope.user.providers.length > 0) {
            var minimumROI = 0,
                roi,
                i;

            for(i in $rootScope.user.providers) {
                roi = providerCategory.getItemDetail($rootScope.user.providers[i].categoryId).providerCategoryExtra.roiMinLeadCount;
                $rootScope.user.providers[i].minimumROI = roi;
                if(roi > minimumROI) {
                    minimumROI = roi;
                }
            }

            $rootScope.minimumROI = minimumROI;
        }
    };

    $rootScope.openMenu = function () {
        if(($rootScope.screenWidth === 'xs' || $rootScope.screenWidth === 'sm') && $rootScope.user){
            if(!angular.element('.sidebar-toggler').hasClass('active')) {
                angular.element('.sidebar-toggler').click();
            }
        }
    };

    $rootScope.closeMenu = function () {
        if(($rootScope.screenWidth === 'xs' || $rootScope.screenWidth === 'sm') && $rootScope.user){
            if(angular.element('.sidebar-toggler').hasClass('active')) {
                angular.element('.sidebar-toggler').click();
            }
        }
    };

    $rootScope.goToleads = function () {
        queryString.setHash(null);
        $location.path('/leads').search({});
        $route.reload();
    };

    $rootScope.initTraking = function () {
        if(!$cookies.get('switch_user') && !$cookies.get('admin_access_token')) {
            for (var x in $rootScope.user.providers) {
                if($rootScope.user.providers[x].productFeatures) {
                    var trackString = '';
                    trackString += city.getItemDetail($rootScope.user.providers[x].cityId).slug;
                    trackString += '_';
                    trackString += providerCategory.getItemDetail($rootScope.user.providers[x].categoryId).slug;
                    trackString += '_';
                    trackString += $rootScope.user.providers[x].paidStatus ? 'paid' : 'notpaid';
                    trackString += '_';
                    var today = moment();
                    var productendDate = moment($rootScope.user.providers[x].productFeatures.productDateEnd);
                    var monthsLeft = productendDate.diff(today, 'months');
                    var weeksLeft = productendDate.diff(today, 'weeks');
                    if(monthsLeft === 0) {
                        trackString += weeksLeft + 'weeks';
                    } else {
                        trackString += monthsLeft + 'months';
                    }
                    fbq('track', ' AddPaymentInfo', {content_category : trackString});
                    Analytics.trackPage($location.path(), $rootScope.user.providers[x].name, { dimension1: trackString });
                }
            }
        }
    };

    $rootScope.$on('$routeChangeStart', function($event, $route) {
        if($rootScope.screenWidth === 'xs' && $rootScope.user){
            angular.element('.sidebar-toggler').removeClass('active');
        }

        $rootScope.heading.url = 'layout/header.html';
        $rootScope.heading.data = {};

        if(!$route) {
            $event.preventDefault();
            $location.path("/home");
            return false;
        }

        if($route.$$route) {
            if ($rootScope.user) {
                updateLeadCount();
            }
        }

        if ($route.$$route.controller === "LeadsListCtrl") {
            $timeout(function () {
                $('#ue_widget').css({display: 'none'});
            }, 2500);
        } else {
            $('#ue_widget').css({display: 'block'});
        }

        // Permission check
        if($route.$$route) {

            if ($rootScope.user) {
                if ($rootScope.unsignedAgreementIds.length > 0) {
                    $rootScope.limitedAccess = true;
                    if ($route.$$route.originalPath !== '/agreements/:agreementId') {
                        var agreementPage = '/agreements/' + $rootScope.unsignedAgreementIds[0];
                        $location.path(agreementPage).search({});
                        $event.preventDefault();
                        return false;
                    }
                } else {
                    if ($rootScope.pendingPaymentIds.length > 0) {
                        if ($route.$$route.originalPath !== '/payments' && $route.$$route.originalPath !== '/payments/:paymentId') {
                            $location.path('payments');
                            $event.preventDefault();
                            return false;
                        }
                    }
                }
            }

            // for stats
            if($route.$$route.originalPath === '/visits-statistics') {
                if(!$rootScope.hasPermission('statsAccess')) {
                    $rootScope.openUpgradeModal('visits-statistics');
                    $event.preventDefault();
                    return false;
                }
            }

            if($route.$$route.originalPath === '/weddings-statistics') {
                if(!$rootScope.hasPermission('statsAccess')) {
                    $rootScope.openUpgradeModal('weddings-statistics');
                    $event.preventDefault();
                    return false;
                }
            }
        }

        if($route && $route.templateUrl && $route.templateUrl.indexOf('modals') === -1) {
            $rootScope.heading.name = '';
            $rootScope.heading.total = null;
            $rootScope.tutorials = [];
        }
    });

    $rootScope.loadingCollectionMerchant = [];
    $rootScope.loadCollectionMerchants = function () {
        var params = {};

        var defer = $q.defer();
        var loading = CollectionsMerchants.query(
            params,
            function (successResponse) {
                $rootScope.merchantList = successResponse.data;
                $rootScope.rejectedMerchantList = $filter('filter')(successResponse.data, {status: 'rejected'}, true);
                $rootScope.activeMerchantList = $filter('filter')(successResponse.data, {status: 'approved'}, true);
                $rootScope.pendingMerchantList = $filter('filter')(successResponse.data, {status: 'pending'}, true);
                $rootScope.activePendingMerchantList = $rootScope.activeMerchantList.concat($rootScope.pendingMerchantList);
                $rootScope.availableProvidersForMerchant = getAvailableProviders($rootScope.user.providers);
                defer.resolve($rootScope.activeMerchantList);
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
                defer.reject(errorResponse);
            }
        );

        $rootScope.loadingCollectionMerchant.push(defer.promise);
    };

    function getAvailableProviders(providers) {
        var availableProviders = [],
            filtered = [];

        angular.forEach(providers, function (provider) {
            filtered = $filter('filter')($rootScope.activePendingMerchantList, { provider: {id: provider.id} }, true);
            if (filtered.length === 0) {
                availableProviders.push(provider);
            }
        });

        return  availableProviders;
    }

    $rootScope.$on('$routeChangeSuccess', routeIsChanging);
    $rootScope.$on('user.got', initializeBaseData);

    $rootScope.$watchCollection(function() {
        return $rootScope.getProviderCategories().concat($rootScope.user ? $rootScope.user.providers : []);
    }, self.calculateMinimumROI);

    $rootScope.urlContains = function(path) {
        return $rootScope.currentUrl ? $rootScope.currentUrl.indexOf(path) > -1 : false;
    };

    $rootScope.submenu = {
        providers: {openStatus: $window.localStorage.getItem('currentMenu') === 'providers'},
        templates: {openStatus: $window.localStorage.getItem('currentMenu') === 'templates'},
        statistics: {openStatus: $window.localStorage.getItem('currentMenu') === 'statistics'},
        support: {openStatus: $window.localStorage.getItem('currentMenu') === 'support'},
    };

    $rootScope.setOpenedMenu = function (id)
    {
        $window.localStorage.setItem('currentMenu', id);

        for (var i in $rootScope.submenu) {
            if (i === id) {
                $rootScope.submenu[i].openStatus = true;
            } else {
                $rootScope.submenu[i].openStatus = false;
            }
        }
    };

    $rootScope.openedSubMenu = {key: $window.localStorage.getItem('currentSubMenu')};
    $rootScope.setOpenedSubmenu = function (providerId) {
        $window.localStorage.setItem('currentSubMenu', providerId);
        $rootScope.openedSubMenu.key = providerId;
    };

    function routeIsChanging(a, newRoute, c) {
        $rootScope.currentUrl = $location.path();
        $rootScope.currentPage = $location.url();
        clearTemplateCache(newRoute.loadedTemplateUrl);
        if(newRoute.$$route && newRoute.$$route.label) {
            $rootScope.title = newRoute.$$route.label;
            if(!newRoute.$$route.doNotTrack) {
                Analytics.trackPage($location.path(), newRoute.$$route.label);
            }
        }
        if(newRoute.$$route) {
            // onesignal subscribe
            if(newRoute.$$route.originalPath === '/leads' && $rootScope.user) {
                if(!$cookies.get('switch_user') && !$cookies.get('admin_access_token')) {
                    OneSignalService.subscribe($rootScope.user.customerId, $rootScope.user.id);
                }
            }

            if($rootScope.user) {
                UsercomService.init($rootScope.user);
            }

            if($rootScope.unsignedAgreementIds.length > 0) {
                $rootScope.limitedAccess = true;
                if(newRoute.$$route.originalPath !== '/agreements/:agreementId') {
                    var agreementPage = '/agreements/' + $rootScope.unsignedAgreementIds[0];
                    $location.path(agreementPage).search({});
                }
            } else {
                if ($rootScope.pendingPaymentIds.length > 0) {
                    if (newRoute.$$route.originalPath !== '/payments'  && newRoute.$$route.originalPath !== '/payments/:paymentId') {
                        $location.path('payments');
                    }
                }
            }

            if(newRoute.$$route) {
                if(newRoute.$$route.originalPath === '/providers/:providerId/info' && $rootScope.user) {
                    if($rootScope.user.providersWithProductIds.indexOf(parseInt(newRoute.params.providerId)) == -1){
                        $location.path('home').search({});
                    }
                }
            }

            // for stats
            if(newRoute.$$route.originalPath === '/visits-statistics') {
                if(!$rootScope.hasPermission('statsAccess')) {
                    $rootScope.openUpgradeModal('visits-statistics');
                    queryString.setHash(null);
                    $location.path('/home');
                }
            }

            if(newRoute.$$route.originalPath === '/weddings-statistics') {
                if(!$rootScope.hasPermission('statsAccess')) {
                    $rootScope.openUpgradeModal('weddings-statistics');
                    queryString.setHash(null);
                    $location.path('/home');
                }
            }

            if(!$cookies.get('switch_user') && !$cookies.get('admin_access_token')) {
                if (newRoute.$$route.originalPath !== '/providers/:providerId/galleries' && newRoute.$$route.originalPath !== '/') {
                    if (newRoute.$$route.originalPath !== '/providers/:providerId/info') {
                        ProvidersDetailsService.checkProviderMissingDetails($rootScope);
                    }
                }
            }
        }
        // Hide sidebar after change route
        angular.element('.page-sidebar.hide').removeClass('hide');
    }

    function initializeBaseData() {
        $rootScope.loadingBase.push(dugunConfig.init());
        $q.all([
            providerCategory.init(),
            city.init()
        ]).then(function() {
            $rootScope.initTraking();
        });
        $rootScope.loadingBase.push(district.init());
        $rootScope.loadingBase.push(updateLeadCount());
    }

    function updateLeadCount() {
        var data = {};

        data.createdAtEnd = moment().format('YYYY-MM-DD');
        data.createdAtStart = moment().add(1, 'days').add(-3, 'month').format('YYYY-MM-DD');

        if($rootScope.user) {
            $rootScope.loadingLeadCounts = Leads.countsByReadStatus(
                data,
                function (successResponse) {
                    $rootScope.allLeadsCount = successResponse.data;
                },
                function (response) {
                });
        }
    }

    function clearTemplateCache(templateUrl) {
        // Remove the loaded template from $templateCache
        if(['office', 'local'].indexOf(environmentConfig.name) > -1) {
            $templateCache.remove(templateUrl);
        }
    }

    function changeAccess(providers, keys) {
        for (var j in providers) {
            if (!providers[j].productFeatures) {
                providers[j].productFeatures = {};
            }

            for (var i in keys) {
                providers[j].productFeatures[keys[i]] = true;
            }
        }

        return providers;
    }

    PaginationTranslate.translate();

    DatePickerTranslate.translate();

    DateRangePickerTranslate.translate();

    TextAngularTranslate.translate();

    BusyTranslate.translate();

    DgNotificationsTranslate.translate();

    $rootScope.imageUrl = environmentConfig.imgUrl;

    $rootScope.getUser();
    self.windowResized();

    function prepareGtmProviderData(providers) {
        var modifiedProviders = [];
        angular.forEach(providers, function (provider) {
            modifiedProviders.push({
                id: provider.id,
                name: provider.name,
            });
        });

        return modifiedProviders;
    }

    function getProvidersActiveProducts() {
        var loading = Providers.getProducts(
            {
                'providerIds[]': $rootScope.user.providerIds,
                activeDate: moment().format('YYYY-MM-DD')
            },
            function (successResponse) {
                $rootScope.providersActiveProducts = successResponse.data;
                $rootScope.modifiedProvidersActiveProducts = [];
                for (var i in $rootScope.providersActiveProducts) {
                    $rootScope.modifiedProvidersActiveProducts.push(
                        {
                            name: $rootScope.providersActiveProducts[i].productPrice.product.name,
                            dateStart: moment($rootScope.providersActiveProducts[i].dateStart).format('YYYY-MM-DD'),
                            dateEnd: moment($rootScope.providersActiveProducts[i].dateEnd).format('YYYY-MM-DD')
                        }
                    );
                }
                dataLayer.push(
                    {
                        customer: {
                            countOfProducts: $rootScope.modifiedProvidersActiveProducts.length
                        }
                    }
                );
            }
        );

        $rootScope.loadingBase.push(loading);
    }

    $rootScope.$watch(function() { return $cookies.get('access_token'); }, function(newValue, oldValue) {
        if (!newValue && oldValue) {
            $window.location.href = '/login';
        }
    });


    $rootScope.$watchCollection('user', function (value) {
        if (value) {
            $rootScope.loadCollectionMerchants();
            getProvidersActiveProducts();

            if ($rootScope.environment.fieldsSettings.usercom && !($cookies.get('switch_user') && $cookies.get('admin_access_token'))) {
                $("body").append('<script data-cfasync="false" src="https://' + $rootScope.environment.userCom.domain + '.user.com/widget.js"></script>');
            }

            if ($route.current.$$route.controller === "LeadsListCtrl") {
                $('#ue_widget').css({display: 'none'});
            }
        }
    });
}

AppCtrl.$inject = [
    '$rootScope',
    '$window',
    '$location',
    '$route',
    '$localStorage',
    '$templateCache',
    '$filter',
    '$q',
    '$cookies',
    '$timeout',
    'queryString',
    'dgNotifications',
    'dgPopup',
    'tmhDynamicLocale',
    'moment',
    'Analytics',
    'heading',
    'CustomerUsersPopup',
    'ProviderUpgradePopups',
    'TutorialsService',
    'OneSignalService',
    'Auth',
    'dugunConfig',
    'providerCategory',
    'city',
    'district',
    'Leads',
    'ProvidersDetailsService',
    'environmentConfig',
    'PaginationTranslate',
    'DatePickerTranslate',
    'DateRangePickerTranslate',
    'TextAngularTranslate',
    'BusyTranslate',
    'DgNotificationsTranslate',
    'FirebaseListener',
    'CollectionsMerchants',
    'Providers',
    'UsercomService'
];

angular
    .module('dugun.provider')
    .controller('AppCtrl', AppCtrl);
