function LoginRoutes($routeProvider) {
    $routeProvider
        .when('/login', {
            templateUrl: 'login/index.html',
            controller: 'LoginCtrl',
            controllerAs: 'loginCtrl',
            label: 'LOGIN.HEADER'
        });
}

LoginRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.provider').config(LoginRoutes);
