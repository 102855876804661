(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/provider/multiple.html',
    '<ui-select class="three-line full-width" ng-attr-id="id || \'providerId\'" ng-model="$parent.model" theme="select2" multiple="multiple"><ui-select-match placeholder="{{ attrs.placeholder || (\'DG_FORMS.CHOOSE_PROVIDER\' | translate) }}" allow-clear="{{ allowClear }}"><p><strong ng-bind="$item.name"></strong></p><p><span ng-bind="$root.getProviderCategory($item.categoryId)"></span></p><p><small ng-bind="$root.getCity($item.cityId)"></small></p></ui-select-match><ui-select-choices repeat="item.id as item in options"><p><strong ng-bind-html="item.name | highlight: $select.search"></strong></p><p><span ng-bind="$root.getProviderCategory(item.categoryId)"></span></p><p><small ng-bind="$root.getCity(item.cityId)"></small></p></ui-select-choices></ui-select>');
}]);
})();
