/**
 * @ngdoc overview
 * @memberof dugun.provider.upgrade
 * @requires ui.bootstrap.modal
 * @description
 * If user doesn't have a permission, we show a modal to upgrade
 */
angular.module('dugun.provider.upgrade', [
    'ui.bootstrap.modal',
]);
