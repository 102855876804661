/**
 * @ngdoc controller
 * @memberof dugun.provider.upgrade
 * @name ProviderUpgradeRequestCtrl
 *
 * @requires $scope
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires $cookies
 * @requires $translate
 * @requires Analytics
 * @requires ProviderUpgradePopups
 * @requires DugunLeads
 * @requires data passed to the controller
 *
 */
function ProviderUpgradeRequestCtrl($scope, $uibModalInstance, $cookies, $translate, Analytics, ProviderUpgradePopups, DugunLeads, data) {

    $scope.page = data ? data.page : '';
    $scope.loading = [];
    /**
     * @ngdoc method
     * @memberof ProviderUpgradeRequestCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.dismiss();
    };

    /**
     * @ngdoc method
     * @memberof ProviderUpgradeRequestCtrl
     * @description
     * Send Request for provider upgrade
     */
    $scope.requestUpgrade = function () {

        var data = {
            companyName: $scope.user.customer.name,
            customer: {
                id: $scope.user.customerId
            },
            customerUser: {
                id: $scope.user.id
            },
            name: $scope.user.name,
            campaign: {
                id: $scope.getConfigItem('campaign_id', $scope.page)
            }
        };

        $scope.loading = DugunLeads.insert(
            data,
            function (successResponse) {
                var weekAfter = new Date();
                weekAfter.setDate(weekAfter.getDate() + 7);
                $cookies.put('request_upgrade', true, { 'expires': weekAfter });

                Analytics.trackEvent('upgradeModal', 'sent', $scope.page);
                $scope.close();
                ProviderUpgradePopups.openSentRequestUpgrade($scope.page);
            },
            function (errorResponse) {
                Analytics.trackEvent('upgradeModal', 'failed', $scope.page);
                $scope.close();
                ProviderUpgradePopups.openFailedRequestUpgrade();
            }
        );
    };


    $scope.header = $translate.instant('PROVIDER_UPGRADE.REQUEST.HEADER');
    $scope.icon = '';
    $scope.bodyText = '';
    $scope.ctaButtonText = '';
    $scope.closeButtonText = '';

    if(data.page == 'discounts') {
        $scope.icon = 'fa-tags';
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST.DISCOUNTS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.DISCOUNTS.CTA_BUTTON_TEXT');
        $scope.closeButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.DISCOUNTS.CLOSE_BUTTON_TEXT');
    } else if(data.page == 'visits-statistics') {
        $scope.icon = 'fa-bar-chart';
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST.VISITS_STATISTICS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.VISITS_STATISTICS.CTA_BUTTON_TEXT');
        $scope.closeButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.VISITS_STATISTICS.CLOSE_BUTTON_TEXT');
    } else if(data.page == 'weddings-statistics') {
        $scope.icon = 'fa-bar-chart';
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST.WEDDINGS_STATISTICS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.WEDDINGS_STATISTICS.CTA_BUTTON_TEXT');
        $scope.closeButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.WEDDINGS_STATISTICS.CLOSE_BUTTON_TEXT');
    } else if(data.page == 'testimonial-reply') {
        $scope.icon = 'fa-comments';
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST.TESTIMONIAL_REPLY.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.TESTIMONIAL_REPLY.CTA_BUTTON_TEXT');
        $scope.closeButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.TESTIMONIAL_REPLY.CLOSE_BUTTON_TEXT');
    } else if(data.page == 'galleries') {
        $scope.icon = 'fa-file-photo-o';
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST.GALLERIES.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.GALLERIES.CTA_BUTTON_TEXT');
        $scope.closeButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.GALLERIES.CLOSE_BUTTON_TEXT');
    } else if (data.page === 'leads') {
        $scope.icon = '';
        $scope.bodyText = data.message + $translate.instant('PROVIDER_UPGRADE.REQUEST.LEADS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.LEADS.CTA_BUTTON_TEXT');
        $scope.closeButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST.LEADS.CLOSE_BUTTON_TEXT');
    }

    Analytics.trackEvent('upgradeModal', 'open', $scope.page);
}

ProviderUpgradeRequestCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$cookies',
    '$translate',
    'Analytics',
    'ProviderUpgradePopups',
    'DugunLeads',
    'data'
];

angular.module('dugun.provider.upgrade')
    .controller('ProviderUpgradeRequestCtrl', ProviderUpgradeRequestCtrl);
