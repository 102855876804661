function GoogleLoginConfig(GooglePlusProvider, environmentConfig) {
    GooglePlusProvider.init({
        clientId: environmentConfig.googlePlusAppId,
    });
}

GoogleLoginConfig.$inject = [
    'GooglePlusProvider',
    'environmentConfig',
];

angular.module('dugun.provider').config(GoogleLoginConfig);