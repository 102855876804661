/**
 * Controls forgot password page
 * @ngdoc object
 * @memberof dugun.login.passwordReset
 * @name PasswordForgotCtrl
 * @scope
 * @requires $scope {service} controller scope
 * @requires $routeParams
 * @requires $location
 * @requires $translate
 * @requires dgNotifications {service} dgNotifications
 * @requires PasswordReset {factory} PasswordReset service
 */
function PasswordForgotCtrl($scope, $routeParams, $location, $window, $translate, $http, $timeout,
                            dgNotifications, PasswordReset) {
    /**
     * @memberOf PasswordForgotCtrl
     * @description
     * Recovery key sent via e-mail
     */
    $scope.recoveryKey = $routeParams.recoveryKey || null;

    /**
     * @memberOf PasswordForgotCtrl
     * @description
     * Fields that controlled from view
     */
    $scope.fields = {};

    /**
     * @memberOf PasswordForgotCtrl
     * @description
     * Array of promises, used for cgBusy directive
     */
    $scope.loading = [];

    /**
     * @memberOf PasswordForgotCtrl
     * @private
     * @description
     * Field Type are used in forgetting proccess
     */
    $scope.fieldType = 'email';

    /**
     * @memberOf PasswordForgotCtrl
     * @private
     * @description
     * Recovery Key Found flag
     */
    $scope.recoveryKeyFound = false;


    $scope.showError = false;

    /**
     * @ngdoc method
     * @memberof PasswordForgotCtrl
     * @description
     * send email / phone / username to get password
     */
    $scope.send = function() {
        var data = {
            userType: 'customer'
        };
        data[$scope.fieldType] = angular.copy($scope.fields[$scope.fieldType]);

        $scope.showError = false;

        var loading = PasswordReset.insert(
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('FORGET_PASSWORD.EMAIL_SENT_SUCCESSFULLY'));
                $location.path('/password-reset');
            },
            function(errorResponse) {
                $scope.showError = true;
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof PasswordForgotCtrl
     * @description
     * send email / phone to get username
     */
    $scope.forgotUsername = function() {
        var data = {
            userType: 'customer'
        };

        data[$scope.fieldType] = angular.copy($scope.fields[$scope.fieldType]);
        
        $scope.showError = false;

        var loading = PasswordReset.forgotUsername(
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('FORGET_PASSWORD.EMAIL_SENT_SUCCESSFULLY'));
                $location.path('/login');
            },
            function(errorResponse) {
                $scope.showError = true;
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof PasswordForgotCtrl
     * @description
     * Get the recovery key details
     */
    $scope.getRecoveryKey = function() {
        $scope.recoveryKeyFound = false;
        var loading = PasswordReset.get(
            { recoveryKey: $scope.recoveryKey || $scope.fields.recoveryKey },
            function(successResponse) {
                $scope.recoveryKey = $scope.recoveryKey || $scope.fields.recoveryKey;
                // Nothing to do. We know this is a valid recoveryKey.
                $scope.recoveryKeyFound = true;
            },
            function(errorResponse) {
                $scope.recoveryKeyFound = false;
                dgNotifications.error(errorResponse);
                if(errorResponse.status === 422 && errorResponse.data.errors) {

                } else {
                    $location.path('/password-reset');
                }
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof PasswordForgotCtrl
     * @description
     * Sets the new password
     */
    $scope.setPassword = function() {

        if($scope.fields.password !== $scope.fields.passwordRepeat) {
            dgNotifications.error($translate.instant('FORGET_PASSWORD.PASSWORDS_DOSNT_MATCH'));
            return;
        }

        var postData = {
            userType: 'customer',
            password: $scope.fields.password
        };

        var urlParams = {
            recoveryKey: $scope.recoveryKey
        };

        var loading = PasswordReset.update(
            urlParams,
            postData,
            function(successResponse) {
                dgNotifications.success($translate.instant('FORGET_PASSWORD.PASSWORD_SET_SUCCESSFULLY'));
                $location.path('/login');
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof PasswordForgotCtrl
     * @description
     * Sets the new password
     */
    $scope.renewPassword = function() {
        if($scope.fields.password !== $scope.fields.passwordMatch) {
            dgNotifications.error($translate.instant('FORGET_PASSWORD.PASSWORDS_DOSNT_MATCH'));
            return;
        }

        var putData = angular.copy($scope.fields),
            customerId = parseInt($window.localStorage.getItem('customerId')),
            userId = parseInt($window.localStorage.getItem('userId')),
            accessToken = $window.localStorage.getItem('accessToken');

        var url = $scope.environment.api.url + 'customers/' + customerId + '/users/me';

        var config = {
            method: 'PUT',
            url: url,
            headers: {
                'X-Access-Token': accessToken
            },
            data: putData
        };

        var loading = $http(config).then(
            function (successResponse) {
                dgNotifications.success($translate.instant('FORGET_PASSWORD.PASSWORD_SET_SUCCESSFULLY'));
                $timeout(function () {
                    $window.location.href = '/login';
                    $window.localStorage.clear();
                }, 1500);
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    if($scope.recoveryKey) {
        $scope.getRecoveryKey();
    }
}

PasswordForgotCtrl.$inject = [
    '$scope',
    '$routeParams',
    '$location',
    '$window',
    '$translate',
    '$http',
    '$timeout',
    'dgNotifications',
    'PasswordReset'
];

angular.module('dugun.login.passwordReset')
    .controller('PasswordForgotCtrl', PasswordForgotCtrl);
