/**
 * SetCustomerUser factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.login.setCustomerUser
 * @name SetCustomerUser
 * @requires dugun.core:resource
 * @requires angular-cache:CacheFactory
 *
 * @returns {service} SetCustomerUser resource service
 */
function SetCustomerUser(resource) {
    var url = 'customers/:customerId/users/:customerUserId/:hash';

    var resourceService = resource(
        url,
        {
            customerId: '@customerId',
            customerUserId: '@customerUserId',
            hash: '@hash',
        },
        {
            get: {
                url: url,
                method: 'GET'
            },
            update: {
                url: url,
                method: 'PUT'
            }
        }
    );

    return resourceService;
}

SetCustomerUser.$inject = [
    'resource'
];

angular.module('dugun.login.setCustomerUser')
    .factory('SetCustomerUser', SetCustomerUser);
