/**
 * @ngdoc controller
 * @memberof dugun.attachments
 * @name AttachmentCtrl
 *
 * @requires $scope
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires dugun.notifications:dgNotifications
 * @requires Attachments
 *
 * @param data
 */
function AttachmentCtrl(
    $scope, $uibModalInstance,
    dgNotifications,
    Attachments,
    data,
    $translate
) {

    /**
     * @memberOf AttachmentCtrl
     * @description
     * Object that keeps clean form data.
     */
    $scope.cleanData = angular.copy(data);

    /**
     * @memberOf AttachmentCtrl
     * @description
     * Object that keeps dirty form data.
     */
    $scope.dirtyData = angular.copy(data);

    /**
     * @ngdoc method
     * @memberof AttachmentCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function(data) {
        if(data) $uibModalInstance.close(data);
        else $uibModalInstance.dismiss('cancel');
    };

    /**
     * @ngdoc method
     * @memberof AttachmentCtrl
     * @description
     * Set Attachments
     */
    $scope.setAttachment = function() {
        var postData = $scope.dirtyData.getDiff($scope.cleanData),
            urlData = {
                customerId: $scope.cleanData.customerId,
                id: $scope.cleanData.id
            };

        if(angular.equals(postData, {})) return true;

        $scope.loading = Attachments.update(
            urlData,
            postData,
            function(successResponse) {
                dgNotifications.success($translate.instant('ATTACHMENTS.ATTACHMENT_ADDED_SUCCESSFULLY'));
                $scope.close();
                 dataLayer.push(
                    {
                        event: 'addAttachment',
                        providers: {
                            id: $scope.cleanData.id,
                            name: $scope.cleanData.name,
                            category: $rootScope.getProviderCategory($scope.cleanData.categoryId),
                            cityId: $rootScope.getCity($scope.cleanData.cityId)
                        }
                    }
                );
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };
}

AttachmentCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'dgNotifications',
    'Attachments',
    'data',
    '$translate',
];

angular.module('dugun.attachments')
    .controller('AttachmentCtrl', AttachmentCtrl);
