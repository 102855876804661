function FirebaseListener($rootScope, $q, $timeout, $translate, $http, $filter, moment,
                          dgNotifications, FirebaseRefCreator, PushNotification) {
    var service = {};
    $rootScope.firebaseData = {};

    service.listenLead = function () {
        var ref = FirebaseRefCreator.create($rootScope.user.customerHash, 'lead');
        var first = true;
        ref.orderByChild('type').equalTo('Lead').limitToLast(1).on('child_added', function (snapshot) {
            if (first) {
                first = false;
            } else {
                var deferred = $q.defer();
                $timeout(function () {
                    var incomingData = angular.copy(snapshot.val().data);
                    if (!checkUserProviderAccess(incomingData.providerId)) return;
                    if (!snapshot.val().changes) {
                        $rootScope.allLeadsCount.leads.pending ++;
                        dgNotifications.success($translate.instant('LEADS.NEW_LEAD_RECEIVED', {name: incomingData.coupleName}));
                        $rootScope.newLead = {
                            id: incomingData.leadId,
                            leadContact: {
                                name: incomingData.coupleName
                            },
                            status: incomingData.status,
                            updatedAt: incomingData.updatedAt,
                            createdAt: incomingData.createdAt,
                            provider: {
                                id: incomingData.providerId
                            },
                            isRead: false
                        };
                        deferred.resolve();
                    }
                }, 2000);
            }
        });
    };

    service.listenLeadMessage = function () {
        var ref = FirebaseRefCreator.create($rootScope.user.customerHash, 'lead');
        var first = true;
        ref.orderByChild('type').equalTo('LeadMessage').limitToLast(1).on('child_added', function (snapshot) {
            if (first) {
                first = false;
            } else {
                var deferred = $q.defer();
                $timeout(function () {
                    var incomingData = angular.copy(snapshot.val().data);
                    if (!checkUserProviderAccess(incomingData.providerId)) return;
                    if ((incomingData.senderType === 'provider' && incomingData.customerUserId !== $rootScope.user.id) ||
                        incomingData.senderType === 'couple') {
                        dgNotifications.success($translate.instant('LEADS.NEW_LEAD_MESSAGE_RECEIVED', {name: incomingData.coupleName}));
                        $rootScope.firebaseData.leadMessage = {
                            messageType: 'message',
                            createdAt: incomingData.createdAt,
                            messageSubject: incomingData.subject,
                            messageBody: incomingData.body,
                            senderType: incomingData.senderType,
                            senderMethod: incomingData.senderMethod,
                            senderName: incomingData.customerUser ? incomingData.customerUser.name : ''
                        };
                        deferred.resolve();
                    }
                }, 2000);
            }
        });
    };

    service.listenReminder = function () {
        var ref = FirebaseRefCreator.create($rootScope.user.customerHash, 'reminder');
        var first = true;
        ref.limitToLast(1).orderByChild('type').on('child_added', function (snapshot) {
            if (first) {
                first = false;
            } else {
                var deferred = $q.defer();
                $timeout(function () {
                    var incomingData = angular.copy(snapshot.val().data);
                    if (!checkUserProviderAccess(incomingData.providerId)) return;
                    $rootScope.firebaseData.processStatesReminders = incomingData;
                    $http.get('leads/notifications/process-states-reminder.html').success(function (htmlResponse) {
                        htmlResponse = htmlResponse
                            .replace('ICON_CLASS', $rootScope.firebaseData.processStatesReminders.processState === 'appointment' ? 'fa-calendar-alt' : 'fa-envelope');
                        htmlResponse = htmlResponse
                            .replace('PROCESS_STATE', $rootScope.getConfigItem('lead_process_state', $rootScope.firebaseData.processStatesReminders.processState));
                        htmlResponse = htmlResponse
                            .replace('REMIND_AT', moment($rootScope.firebaseData.processStatesReminders.remindAt).format('YYYY-MM-DD HH:mm'));
                        htmlResponse = htmlResponse
                            .replace('COUPLE_NAME', $rootScope.firebaseData.processStatesReminders.coupleName);

                        PushNotification.init('info', htmlResponse);
                        PushNotification.create();
                    });
                    deferred.resolve();
                }, 2000);
            }
        });
    };

    function checkUserProviderAccess(providerId) {
        var provider = $filter('filter')($rootScope.providersActive, {id: providerId}, true);
        return provider.length;
    }

    return service;
}

FirebaseListener.$inject = [
    '$rootScope',
    '$q',
    '$timeout',
    '$translate',
    '$http',
    '$filter',
    'moment',
    'dgNotifications',
    'FirebaseRefCreator',
    'PushNotification'
];

angular.module('dugun.provider').factory('FirebaseListener', FirebaseListener);
