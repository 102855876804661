/**
 * Controls the media list
 * @ngdoc controller
 * @memberof dugun.galleries.media
 * @name MediaListCtrl
 *
 * @requires $scope {service} controller scope
 * @requires $rootScope
 * @requires $route {service} $route service
 * @requires $routeParams {service} $routeParams service
 * @requires $window {service} $window
 * @requires $filter
 * @requires $translate
 * @requires queryString
 * @requires dgNotifications {service} dgNotifications
 * @requires Analytics
 * @requires Galleries {factory} Galleries service
 * @requires RelatedProviders {factory} RelatedProviders service
 * @requires Media {factory} Media service
 * @requires MediaPopups
 */
function MediaListCtrl($scope, $rootScope, $route, $routeParams, $window, $filter, $translate, queryString,
                       dgNotifications,
                       Galleries, RelatedProviders, Media, MediaPopups) {

    var self = this;

    /**
     * @ngdoc property
     * @memberOf GalleryListCtrl
     * @description
     * Provider Id
     */
    $scope.providerId = $window.parseInt($routeParams.providerId) || null;

    /**
     * @ngdoc property
     * @memberOf GalleryListCtrl
     * @description
     * We will list media of this gallery only
     */
    $scope.galleryId = $window.parseInt($routeParams.galleryId);

    /**
     * @ngdoc property
     * @memberOf GalleryListCtrl
     * @description
     * We will get the related providers gallery details
     */
    $scope.relatedProviderGalleryId = $window.parseInt($routeParams.relatedProviderGalleryId) || null;

    $scope.relatedProviderData = null;

    /**
     * @ngdoc property
     * @memberOf MediaListCtrl
     * @description
     * Gallery list
     */
    $scope.list = [];
    /**
     * @ngdoc property
     * @memberOf MediaListCtrl
     * @description
     * Single gallery item
     */
    $scope.gallery = [];

    /**
     * @ngdoc property
     * @memberOf MediaListCtrl
     * @description
     * loading object
     */
    $scope.loading = [];

    /**
     * @ngdoc property
     * @memberOf MediaListCtrl
     * @description
     * no images flag
     */
    $scope.noImages = false;

    /**
     * @ngdoc property
     * @memberOf MediaListCtrl
     * @description
     * show search input text
     */
    $scope.showSearch = false;

    self.mainImageId = null;

    $scope.mediaSortOptions = {
        items: '.sortable',
        placeholder: 'col-xs-12 col-sm-4 col-md-3',
        helper: 'clone',
        appendTo: 'body',
        forcePlaceholderSize: true,
        'ui-floating': true,
        start: function(e, ui){

            if(ui.item.index() !== 0) {
                if (!angular.isUndefined(angular.element('.gallery-image')[0])){
                    ui.placeholder.height(angular.element('.gallery-image')[0].clientHeight);
                }
            } else {
                if (!angular.isUndefined(angular.element('.gallery-image')[1])){
                    ui.placeholder.height(angular.element('.gallery-image')[1].clientHeight);
                }
            }
        }
    };

    $scope.pagination = {
        currentPage: 1
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Toggle showing the serach input test
     */
    $scope.toggleShowSearch = function () {
        $scope.showSearch = !$scope.showSearch;
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Loads the gallery details from Galleries service and set the gallery
     */
    $scope.loadGallery = function () {
        var providerName = '',
            data = {
                id: $scope.galleryId
            };

        var promise = Galleries.get(
            data,
            function(successResponse) {
                // Set heading of page
                for(var i in $rootScope.user.providers){
                    if($rootScope.user.providers[i].id == successResponse.data.providerId) {
                        providerName = $rootScope.user.providers[i].name;
                    }
                }
                if(providerName) {
                    $rootScope.heading.name = $translate.instant($route.current.label) + ' - ' + providerName;
                }

                $scope.gallery = successResponse.data;

                if(self.mainImageId && self.mainImageId !== $scope.gallery.mainImageId) {
                    dgNotifications.success($translate.instant('GALLERIES.MAIN_IMAGE_CHANGED'));
                }
                self.mainImageId = $scope.gallery.mainImageId;
                $scope.getMedias();
                $scope.getWholeMedias();
                if($scope.gallery.relatedProviderId && $scope.relatedProviderGalleryId) {
                    $scope.loadRelatedProviderGalleries();
                }
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.loadRelatedProviderGalleries = function () {
        var data = {
                providerId: $scope.providerId,
                relatedProviderId: $scope.gallery.relatedProviderId,
                relatedProviderGalleryId: $scope.relatedProviderGalleryId,
            };

        var promise = RelatedProviders.getGallery(
            data,
            function(successResponse) {
                $scope.relatedProviderData = successResponse.data;
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * check if media count reached the limit of allowed media
     */
    $scope.checkMediaCount = function () {
        return true;
        // var activeMedia = $filter('filter')($scope.list, { status: true}).length,
        //     mediaLimit = 0;
        // for(var i in $scope.user.providers){
        //     if($scope.user.providers[i].id == $scope.providerId) {
        //         mediaLimit = $rootScope.user.providers[i].productFeatures.mediaCount;
        //     }
        // }
        // if(activeMedia <= mediaLimit) {
        //     return true;
        // } else {
        //     return false;
        // }
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Loads the media list from Media service and set the list. Runs after loadGallery method
     */
    $scope.passiveCountByPerPage = [];
    $scope.getMedias = function () {
        if ($window.getPromiseStatus($scope.loading) === 0) return true;
        var data = {
            galleryId: $scope.galleryId,
            limit: 100,
            page: $scope.pagination.currentPage
        };

        var promise = Media.query(
            data,
            function (successResponse) {
                $scope.pagination = successResponse.meta;
                $scope.list = setMainImageKeys(
                    successResponse.data,
                    $scope.gallery.mainImageId
                );
                if($scope.list.length === 0) {
                    $scope.noImages = true;
                } else {
                    $scope.noImages = false;
                }
                var count = 0;
                angular.forEach($scope.list, function (value, index) {
                    if (!value.status) {
                        count++;
                    }
                });
                if (!$scope.passiveCountByPerPage[$scope.pagination.currentPage]) {
                    $scope.passiveCountByPerPage[$scope.pagination.currentPage] = count;
                }
                var provider = $filter('filter')($scope.providersActive, {id: $scope.providerId}, true)[0];
                dataLayer.push(
                    {
                        event: 'getProvider',
                        provider: {
                            id: provider.id,
                            name: provider.name,
                            category: $scope.getProviderCategory(provider.categoryId),
                            city: $scope.getCity(provider.cityId)
                        }
                    }
                );
            }, function(errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.getWholeMedias = function () {
        if ($window.getPromiseStatus($scope.loading) === 0) return true;
        var data = {
            galleryId: $scope.galleryId,
        };

        var promise = Media.query(
            data,
            function (successResponse) {
                $scope.wholeList = successResponse.data;
            }, function(errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.$watch('pagination.currentPage', function (newVal, oldValue) {
        if (typeof newVal === 'undefined') return;
        if (newVal !== oldValue) {
            $scope.getMedias();
            $scope.getWholeMedias();
        }
    });

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Open modal to edit or create media.
     */
    $scope.openItemForm = function(type, item) {
        if(type === 'video' && !item) {
            if(!$scope.checkMediaCount()) {
                $scope.openUpgradeModal('galleries');
                return;
            }
        }
        $scope.openModal(
            'galleries/media/' + type + '.form.html',
            type === 'video' ? 'GalleryVideoCtrl' : 'GalleryImageCtrl',
            item ? item.id : null,
            $scope.galleryId,
            {
                categoryId: $scope.gallery.galleryCategoryId,
                isMainImage: type === 'image' ? item.isMainImage : false,
                gallery: $scope.gallery
            },
            {
                size: type === 'image' ? 'xxlg' : 'md',
                backdrop: 'true'
            }
        ).result.finally(
            function() {
                $scope.loadGallery();
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Show paginate position
     */
    $scope.paginatePosition = function(passiveCountByPerPage, pagination, key) {
        return (passiveCountByPerPage[pagination.currentPage] === 0 && passiveCountByPerPage[pagination.currentPage - 1] > 0) ? (key - passiveCountByPerPage[pagination.currentPage - 1]) + ((pagination.currentPage - 1) * 100) + 1 : key + ((pagination.currentPage - 1) * 100) + 1;
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Open modal to edit or create media.
     */
    $scope.openUpload = function(type, item) {
        if(!$scope.checkMediaCount()) {
            $scope.openUpgradeModal('galleries');
            return;
        }
        MediaPopups.openUpload($scope.galleryId).result.then(
            function() {
                $scope.loadGallery();
            },
            function() {
                $scope.loadGallery();
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @param media {Object} Media object to update in list
     * @description
     * Set the main image.
     */
    $scope.updateMediaMainImage = function(media) {
        var urlData = {
            id: $scope.galleryId
        };

        if(!media.status) {
            media.isMainImage = !media.isMainImage;
            dgNotifications.error($translate.instant('GALLERIES.MAIN_IMAGE_MUST_BE_ACTIVE'));
            return false;
        }

        var putData = {
            mainImageId: media.id
        };

        media.loading = Galleries.update(
            urlData,
            putData,
            function(successResponse) {
                dgNotifications.success($translate.instant('GALLERIES.MAIN_IMAGE_CHANGED'));
                for(var i in $scope.list) {
                    $scope.list[i].isMainImage = false;
                }
                media.isMainImage = true;
                $scope.gallery.mainImageId = media.id;
                self.mainImageId = $scope.gallery.mainImageId;

                $scope.loadGallery();
            },
            function(errorResponse) {
                media.isMainImage = false;
                dgNotifications.error(errorResponse);
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @param media {Object} Media object to update in list
     * @description
     * Update media status (active/passive).
     */
    $scope.updateMediaStatus = function(media) {
        if(media.status) {
            if(!$scope.checkMediaCount()) {
                $scope.openUpgradeModal('galleries');
                media.status = !media.status;
                return;
            }
        }
        var urlData = {
            galleryId: $scope.galleryId,
            id: media.id
        };

        var putData = {
            status: media.status
        };

        media.loading = Media.update(
            urlData,
            putData,
            function(successResponse) {
                dgNotifications.success($translate.instant('GALLERIES.STATUS_UPDATED'));
            },
            function(errorResponse) {
                media.status = !media.status;
                dgNotifications.error(errorResponse);
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @param media {Object} Media object to update in list
     * @description
     * Rotate image 90d .
     */
    $scope.rotateImage = function(media){
        var urlData = {
            galleryId: $scope.galleryId,
            id: media.id
        };

        var putData = {
            rotateClockWise : 90
        };

        var loading = Media.update(
            urlData,
            putData,
            function(successResponse) {
                dgNotifications.success($translate.instant('GALLERIES.ROTATED_SUCCESSFULLY'));

                if(media.imageUrl.thumbnail.indexOf('?') > -1){
                    media.imageUrl.thumbnail = media.imageUrl.thumbnail.split('?')[0];
                }

                media.imageUrl.thumbnail = media.imageUrl.thumbnail + '?' + new Date().getTime();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @param media {Object} Media object to update in list
     * @description
     * Open modal to edit or create media.
     */
    $scope.deleteMedia = function(media) {
        var urlData = {
            galleryId: $scope.galleryId,
            id: media.id
        };

        $index = $scope.list.indexOf(media);

        media.loading = Media.delete(
            urlData,
            function(successResponse) {
                $scope.loadGallery();
                if(media.type === 'image'){
                    dgNotifications.success($translate.instant('GALLERIES.IMAGE_DELETED'));
                }else{
                    dgNotifications.success($translate.instant('GALLERIES.VIDEO_DELETED'));
                }
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse.data.message);
            }
        );
    };

    $scope.showMoveTo = function (media, index) {
        $scope.selectedImageIndex = index;
        if(!media.changeOrder) {
            media.changeOrder = true;
            media.newOrder = '' + angular.copy(media.orderNum);
        }
    };

    $scope.moveMedia = function (media, index) {
        moveMediaAction(media.id, $scope.selectedImageIndex, index);
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @param event {Object} Event got from sortable
     * @param ui {Object} jquery ui object
     * @description
     * Reorders the media
     */
    $scope.mediaSortOptions.update = function(event, ui) {
        var oldIndex = ui.item.sortable.index,
            newIndex = ui.item.sortable.dropindex,
            item = $scope.list[oldIndex];

        moveMediaAction(item.id, oldIndex, newIndex);
    };

    function moveMediaAction(id, oldIndex, newIndex) {
        var urlData = {
                id: id,
                galleryId: $scope.galleryId
            }, postData = {
                oldIndex: oldIndex,
                newIndex: newIndex
            };

        var promise = Media.order(
            urlData,
            postData,
            function(successResponse) {
                $scope.loadGallery();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    }

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @param medias {Array} media list
     * @param mainImageId {integer} mainImageId of gallery
     * @private
     * @description
     * There is no isMainImage property in media objects. We need it to show
     * and update this boolean value. So we create it virtually.
     */
    function setMainImageKeys(medias, mainImageId) {
        for(var i in medias) {
            if(medias[i].id === mainImageId) {
                medias[i].isMainImage = true;
            } else {
                medias[i].isMainImage = false;
            }
        }

        return medias;
    }

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @private
     * @description
     * Orders the list
     */

    if(queryString.getHash() == 'newPhoto') {
        $scope.openUpload();
    }
    if(queryString.getHash() == 'newVideo') {
        $scope.openItemForm('video');
    }

    // Load the list after user loaded
    $scope.$watch('user', function(newValue) {
        if(newValue && newValue.id) {
            $scope.loadGallery();
        }
    });

    $translate.onReady().then(function () {
        if ($translate.instant('GALLERIES.HELPER_VIDEO.IS_EXIST') == 'true') {
            $rootScope.tutorials.push(
                {
                    type: 'video',
                    data: {
                        video_title: $translate.instant('GALLERIES.HELPER_VIDEO.VIDEO_TITLE'),
                        video_code: $translate.instant('GALLERIES.HELPER_VIDEO.VIDEO_CODE')
                    },
                    buttonText: $translate.instant('GALLERIES.HELPER_VIDEO.BUTTON_TEXT')
                }
            );
        }
        if ($translate.instant('GALLERIES.HELPER_VIDEO_2.IS_EXIST') == 'true') {
            $rootScope.tutorials.push(
                {
                    type: 'video',
                    data: {
                        video_title: $translate.instant('GALLERIES.HELPER_VIDEO_2.VIDEO_TITLE'),
                        video_code: $translate.instant('GALLERIES.HELPER_VIDEO_2.VIDEO_CODE')
                    },
                    buttonText: $translate.instant('GALLERIES.HELPER_VIDEO_2.BUTTON_TEXT')
                }
            );
        }
        if ($translate.instant('GALLERIES.HELPER_PAGE.IS_EXIST') == 'true') {
            $rootScope.tutorials.push(
                {
                    type: 'page',
                    data: {
                        page_title: $translate.instant('GALLERIES.HELPER_PAGE.PAGE_TITLE'),
                        page_url: 'galleries/media/helper.html'
                    }
                }
            );
        }

    });

}

MediaListCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$route',
    '$routeParams',
    '$window',
    '$filter',
    '$translate',
    'queryString',
    'dgNotifications',
    'Galleries',
    'RelatedProviders',
    'Media',
    'MediaPopups'
];

angular
    .module('dugun.galleries.media')
    .controller('MediaListCtrl', MediaListCtrl);