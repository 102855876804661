(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('collection/agreement.html',
    '<div class="modal-body"><div class="row"><div class="col-xs-12"><iframe width="100%" height="400px" ng-src="{{ trustSrc(data.agreementLink)}}"></iframe></div></div></div><div class="modal-footer"><button type="button" class="btn btn-default" ng-click="close()">{{ \'GENERAL.CLOSE\' | translate }}</button></div>');
}]);
})();
