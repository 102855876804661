(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('testimonials/list.html',
    '<div class="row" ng-if="showAddTestimonial"><div class="col-lg-12 col-sm-12 col-xs-12 mb-20"><button class="btn btn-filter btn-md pull-left" ng-click="openItemForm();">{{ \'TESTIMONIALS.ADD_NEW_TESTIMONIAL\' | translate }}</button></div></div><div class="well well-sm panel-card"><form class="form-inline" role="form" name="filtersForm" ng-submit="setFilters()"><div class="form-group col-xs-12 col-sm-6 col-md-3 mb-10" ng-if="providersActive.length > 1"><label class="input-sm text-bold mb-0">{{ \'TESTIMONIALS.ACCORDING_TO_PROVIDER\' | translate }}</label><dg-form-select-provider ng-model="filters.providerId" options="providersActive" allow-clear="true" f-get-provider-category="getProviderCategory" f-get-city="getCity" all-option="true"></dg-form-select-provider></div><div class="form-group col-xs-12 col-sm-6 col-md-3 mb-10"><label class="input-sm text-bold mb-0">{{ \'TESTIMONIALS.ACCORDING_TO_COUPLE_NAME\' | translate }}</label><input class="form-control" type="text" ng-model="filters.coupleName" clearable="true"></div><div class="col-md-2"><button class="btn btn-filter pull-right full-width" type="submit" style="margin-top: 31px" data-track="testimonial-filter"><i class="glyphicon glyphicon-search"></i> {{ \'GENERAL.FILTER\' | translate }}</button></div><div class="col-md-1 pr-0"><button class="btn btn-default pull-right full-width" type="button" style="margin-top: 31px" ng-click="resetFilters()" ng-if="filters.providerId || filters.coupleName || filters.dateStart || filters.dateEnd" data-track="testimonial-reset" ga-track-event="[\'testimonialsFilter\', \'reset\', \'click\']">{{ \'GENERAL.RESET\' | translate }}</button></div></form><div class="clearfix"></div></div><script id="/testimonial-list.html" type="text/ng-template"><table\n' +
    '        st-table="listDisplayed"\n' +
    '        st-safe-src="list"\n' +
    '        class="table table-striped table-bordered table-hover"\n' +
    '    >\n' +
    '        <thead>\n' +
    '            <tr>\n' +
    '                <th\n' +
    '                    dg-table-sort="groomName"\n' +
    '                    order-by="orderBy"\n' +
    '                    ng-click="sortBy(\'groomName\')"\n' +
    '                >{{ \'GENERAL.COUPLE_NAME\' | translate }}</th>\n' +
    '                <th><nobr>{{ \'GENERAL.ADDED_BY\' | translate }}</nobr></th>\n' +
    '                <th ng-if="user.providers.length > 1"><nobr>{{ \'GENERAL.PROVIDER\' | translate }}</nobr></th>\n' +
    '                <th\n' +
    '                    dg-table-sort="createdAt"\n' +
    '                    order-by="orderBy"\n' +
    '                    ng-click="sortBy(\'createdAt\')"\n' +
    '                ><nobr>{{ \'GENERAL.CREATED_AT\' | translate }}</nobr></th>\n' +
    '                <th\n' +
    '                    dg-table-sort="organizationDate"\n' +
    '                    order-by="orderBy"\n' +
    '                    ng-click="sortBy(\'organizationDate\')"\n' +
    '                ><nobr>{{ \'GENERAL.ORGANIZATION_DATE\' | translate }}</nobr></th>\n' +
    '                <th>{{ \'TESTIMONIALS.TESTIMONIAL\' | translate }}</th>\n' +
    '                <th style="width: 92px;">\n' +
    '                    <span ng-if="selectedTab !== \'rejected\'">{{ \'TESTIMONIALS.REPLY_STATUS\' | translate }}</span>\n' +
    '                    <span ng-if="selectedTab == \'rejected\'">{{ \'TESTIMONIALS.REJECT_CAUSE\' | translate }}</span>\n' +
    '                </th>\n' +
    '                <th></th>\n' +
    '            </tr>\n' +
    '        </thead>\n' +
    '        <tbody>\n' +
    '            <tr ng-if="listDisplayed.length === 0">\n' +
    '                <td colspan="100%" class="text-center">\n' +
    '                    {{ \'GENERAL.RESULT_NOT_FOUND\' | translate }}\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '            <tr\n' +
    '                ng-repeat="item in listDisplayed track by $index"\n' +
    '                style="cursor:pointer;"\n' +
    '                class="table-row"\n' +
    '                data-track="testimonial-comment"\n' +
    '            >\n' +
    '                <td ng-click="openItem(item)">\n' +
    '                    <strong class="visible-xs-inline-block col-xs-3 text-right">{{ \'GENERAL.COUPLE_NAME\' | translate }}</strong>\n' +
    '                    <span class="mobile-only-col-xs-9 word-break">\n' +
    '                        <a>{{item.groomName}} <span ng-if="item.groomName && item.brideName ">&</span> {{item.brideName}}</a>\n' +
    '                    </span>\n' +
    '                </td>\n' +
    '                <td ng-click="openItem(item)">\n' +
    '                    <strong class="visible-xs-inline-block col-xs-3 text-right">Ekleyen</strong>\n' +
    '                    <span class="mobile-only-col-xs-9 word-break">\n' +
    '                        <span ng-if="item.addedBy == \'call\'" ng-bind="getConfigItem(\'provider_testimonial_added_by\', \'couple\')"></span>\n' +
    '                        <span ng-if="item.addedBy !== \'call\'" ng-bind="getConfigItem(\'provider_testimonial_added_by\', item.addedBy)"></span>\n' +
    '                    </span>\n' +
    '                </td>\n' +
    '                <td ng-click="openItem(item)" ng-if="user.providers.length > 1">\n' +
    '                    <strong class="visible-xs-inline-block col-xs-3 text-right">{{ \'GENERAL.PROVIDER\' | translate }}</strong>\n' +
    '                    <span class="mobile-only-col-xs-9 word-break">\n' +
    '                        <span ng-bind="item.provider.name" class=""></span> -\n' +
    '                        <span ng-bind="getProviderCategory(item.provider.categoryId)"></span> /\n' +
    '                        <span ng-bind="getCity(item.provider.cityId)"></span>\n' +
    '                    </span>\n' +
    '                </td>\n' +
    '                <td ng-click="openItem(item)">\n' +
    '                    <strong class="visible-xs-inline-block col-xs-3 text-right">{{ \'GENERAL.CREATED_AT\' | translate }}</strong>\n' +
    '                    <span class="mobile-only-col-xs-9 word-break">\n' +
    '                        <span ng-bind="item.createdAt | date: \'longDate\'"></span>\n' +
    '                    </span>\n' +
    '                </td>\n' +
    '                <td ng-click="openItem(item)">\n' +
    '                    <strong class="visible-xs-inline-block col-xs-3 text-right">{{ \'GENERAL.ORGANIZATION_DATE\' | translate }}</strong>\n' +
    '                    <span class="mobile-only-col-xs-9 word-break">\n' +
    '                        <span ng-bind="item.organizationDate | date: \'longDate\'"></span>\n' +
    '                    </span>\n' +
    '                </td>\n' +
    '                <td ng-click="openItem(item)">\n' +
    '                    <strong class="visible-xs-inline-block col-xs-3 text-right">{{ \'TESTIMONIALS.TESTIMONIAL\' | translate }}</strong>\n' +
    '                    <span class="mobile-only-col-xs-9 word-break">\n' +
    '                        <span ng-if="item.isFeatured" class="fa fa-star is-featured"></span>\n' +
    '                        {{item.testimonial.shorten(85)}}...\n' +
    '                    </span>\n' +
    '                </td>\n' +
    '                <td class="text-center">\n' +
    '                    <span\n' +
    '                        ng-if="item.approvalStatus === \'3\' && item.rejectedReasonId"\n' +
    '                        ng-bind="getConfigItem(\'providerTestimonialRejectedReason\', item.rejectedReasonId)"\n' +
    '                    ></span>\n' +
    '                    <button\n' +
    '                        type="button"\n' +
    '                        class="btn btn-sm full-width"\n' +
    '                        ng-class="{\n' +
    '                            \'btn-filter\': !item.providerTestimonialComment,\n' +
    '                            \'btn-default\': item.providerTestimonialComment.status == 1,\n' +
    '                            \'btn-warning\': item.providerTestimonialComment.status == 0,\n' +
    '                            \'btn-danger\': item.providerTestimonialComment.status == 2\n' +
    '                        }"\n' +
    '                        ng-if="item.addedBy !== \'provider\' && item.approvalStatus !== \'3\'"\n' +
    '                        ng-click="openItem(item)"\n' +
    '                        data-track="testimonial-reply"\n' +
    '                    >\n' +
    '                        <i class="fa fa-comment"></i>\n' +
    '                        <span\n' +
    '                            ng-if="item.providerTestimonialComment.status == 0">\n' +
    '                            {{ \'TESTIMONIALS.PENDING\' | translate }}\n' +
    '                        </span>\n' +
    '                        <span\n' +
    '                            ng-if="item.providerTestimonialComment.status == 2">\n' +
    '                            {{ \'TESTIMONIALS.REPLY_REJECTED\' | translate }}\n' +
    '                        </span>\n' +
    '                        <span\n' +
    '                            ng-if="!item.providerTestimonialComment">\n' +
    '                            {{ \'TESTIMONIALS.REPLY_TO_TESTIMONIAL\' | translate }}\n' +
    '                        </span>\n' +
    '                        <span\n' +
    '                            ng-if="item.providerTestimonialComment.status == 1">\n' +
    '                            {{ \'TESTIMONIALS.VIEW_REPLY\' | translate }}\n' +
    '                        </span>\n' +
    '                    </button>\n' +
    '                </td>\n' +
    '                <td>\n' +
    '                    <button\n' +
    '                        class="btn btn-sm btn-palegreen"\n' +
    '                        ng-if="!item.provenStatus && item.rejectedReasonId === 4 && item.coupleId"\n' +
    '                        ng-click="changeTestimonialStatus(item)"\n' +
    '                    >\n' +
    '                        {{ \'TESTIMONIALS.APPROVE_BUTTON\' | translate }}\n' +
    '                    </button>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '        </tbody>\n' +
    '        <tfoot>\n' +
    '        <tr class="table-row">\n' +
    '            <td colspan="100%" class="text-center">\n' +
    '                <p class="pull-left"\n' +
    '                ng-bind-html="\'TESTIMONIALS.TOTAL_TESTIMONIAL_COUNT\' | translate:{number:pagination.total}"\n' +
    '                >\n' +
    '                </p>\n' +
    '                <uib-pagination\n' +
    '                    class="pagination-sm"\n' +
    '                    ng-model="pagination.currentPage"\n' +
    '                    max-size="5"\n' +
    '                    boundary-links="true"\n' +
    '                    total-items="pagination.total"\n' +
    '                    items-per-page="pagination.perPage"\n' +
    '                    ng-show="pagination.total > 1"\n' +
    '                ></uib-pagination>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tfoot>\n' +
    '    </table></script><div class="row"><div class="col-xs-12" cg-busy="loading"><uib-tabset justified="false" class="tabs-active-bold" active="selectedTabIndex"><uib-tab select="tabSelected(\'active\')" data-item="testimonials-active" data-track="testimonial-tabs"><uib-tab-heading>{{ \'TESTIMONIALS.ACTIVE\' | translate }} <span class="badge badge-square badge-success" ng-bind="statusCounts.active"></span></uib-tab-heading><div ng-include="\'/testimonial-list.html\'"></div></uib-tab><uib-tab select="tabSelected(\'rejected\')" data-item="testimonials-rejected" data-track="testimonial-tabs"><uib-tab-heading>{{ \'TESTIMONIALS.REJECTED\' | translate }} <span class="badge badge-square badge-danger" ng-bind="statusCounts.rejected"></span></uib-tab-heading><div ng-include="\'/testimonial-list.html\'"></div></uib-tab></uib-tabset></div></div>');
}]);
})();
