(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/country-phone/country-phone.html',
    '<input type="tel" ng-model="model" class="{{ isValid ? \'bordered-success\' : \'bordered-danger\' }} form-control {{ isCustomerUser ? \'input-sm\' : \'\' }}" international-phone-number ng-disabled="disabled"> <span ng-show="!isValid">{{ warningMessage ? warningMessage : \'COUNTRY_PHONE.WRONG_PHONE_WARNING\' | translate }} </span>');
}]);
})();
