/**
 * @memberof dugun.leads
 * @ngdoc module
 * @description
 * Leads mean info requsts and inbound calls
 */
angular.module('dugun.leads', [
    // vendor
    'ngRoute',
    'dugun.notifications',
    'dugun.queryString',
    'dugun.search',
    // app
    'dugun.helpers.tableSort',
    'dugun.forms',
    'dugun.leads.couples'
]);
