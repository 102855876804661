/**
 * @ngdoc directive
 * @name dugun.forms:dgFormCategory
 * @restrict 'ACE'
 * @requires dugun.providerCategory:providerCategory
 * @scope
 **/
function DgFormCategory(providerCategory) {
    return {
        restrict: 'ACE',
        transclude: true,
        scope: {
            model: '=ngModel',
            allowClear: '@',
            required: '=ngRequired',
            activeOnly: '=',
            ngDisabled: '='
        },
        templateUrl: 'dg-forms/form-elements/category/single.html',
        link: function(scope, element, attrs) {
            scope.attrs = attrs;

            providerCategory.init().then(function() {
                var options = providerCategory.getItems(),
                    result = [],
                    i;

                if(scope.activeOnly) {
                    for(i in options) {
                        if(options[i].isActive === true) {
                            result.push({
                                id: options[i].id,
                                name: options[i].name
                            });
                        }
                    }
                } else {
                    for(i in options) {
                        result.push({
                            id: options[i].id,
                            name: options[i].name
                        });
                    }
                }

                scope.options = result;
            });
        }
    };
}

DgFormCategory.$inject = [
    'providerCategory',
];

angular.module('dugun.forms')
    .directive('dgFormCategory', DgFormCategory);
