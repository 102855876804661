/**
 * @ngdoc factory
 * @memberof dugun.discounts
 * @name DiscountsUpload
 *
 * @requires ngFileUpload:Upload
 * @requires dugun.provider:environmentConfig
 *
 * @returns {service} DiscountsUpload service
 *
 * @description
 * Sends multipart put or post requests
 */
function DiscountsUpload(Upload, environmentConfig) {
    var url = environmentConfig.api.url + 'discounts/:id';
    var service = {};

    /**
     * @ngdoc method
     * @memberof DiscountsUpload
     * @param data {object} Data to send
     * @param params {object=} Parameters for url
     * @description
     * Update or add discount with files
     */
    service.upload = function(data, params) {
        var urlToUpload = url,
            i;

        params = params || {};

        for(i in params) {
            urlToUpload = urlToUpload.replace(':' + i, params[i]);
        }

        urlToUpload = urlToUpload.replace('/:id', '');

        return Upload.upload({
            url: urlToUpload,
            method: params.id ? 'PUT' : 'POST',
            data: data
        });
    };

    return service;
}

DiscountsUpload.$inject = [
    'Upload',
    'environmentConfig',
];

angular.module('dugun.discounts')
    .factory('DiscountsUpload', DiscountsUpload);
