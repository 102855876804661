(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/info-tags/info-tag.html',
    '<ui-select class="full-width" theme="select2" ng-model="$parent.model" ui-select-required="{{ required ? true : false }}" form="{{ form ? form : \'\' }}" search-enabled="false"><ui-select-match placeholder="{{ placeholder }}" allow-clear="false">{{$select.selected.name}}</ui-select-match><ui-select-choices repeat="item.id as item in options | propsFilter: {name: $select.search}" refresh="search($select)" refresh-delay="100"><p ng-class="item.isUserCreated ? \'text-underline\' : \'\'" ng-bind-html="item.name | highlight: $select.search"></p></ui-select-choices></ui-select>');
}]);
})();
