function RequestInterceptor($q, $rootScope, $cookies, environmentConfig) {
    return {
        'request': function (request) {
            if(request.url.substr(0, 7) === 'search/') {
                request.url = environmentConfig.api.url + request.url;
            }

            if(request.url.indexOf('api') !== -1) {
                if($rootScope.accessToken) {
                    request.headers['X-Access-Token'] = $rootScope.accessToken;
                }

                if($cookies.get('switch_user') && $cookies.get('admin_access_token')) {
                    request.headers['X-Access-Token'] = $cookies.get('admin_access_token');
                    request.headers['switch-user'] = $cookies.get('switch_user');
                }

                if (request.method === 'GET') {
                    if ($cookies.get('lang')) {
                        request.headers['Accept-Language'] = environmentConfig.languages.cases[$cookies.get('lang')].acceptLanguage.interfaceLanguage;
                    } else {
                        request.headers['Accept-Language'] = environmentConfig.languages.cases[environmentConfig.languages.default].acceptLanguage.interfaceLanguage;
                    }
                } else {
                    request.headers['Accept-Language'] = environmentConfig.languages.cases[environmentConfig.languages.default].acceptLanguage.projectDefaultLanguage;
                }
            }

            if(/translations(.+)\.json$/.test(request.url) && environmentConfig.name.indexOf('production') > -1) {
                request.url += '?v=' + environmentConfig.appVersion;
            }

            return request || $q.when(request);
        }
    };
}

function ResponseInterceptors($window, $cookies, $q) {

    return {
        'response': function (response) {
            return response;
        },
        'responseError': function (response) {
            if(response.status === 401) {
                if($cookies.get('access_token')) {
                    $cookies.remove('access_token');
                }
                $window.localStorage.clear();
            }

            return $q.reject(response);
        }
    };
}

function DugunHttpProviderConfig($httpProvider, environmentConfig) {
    $httpProvider.defaults.useXDomain = true;
    $httpProvider.defaults.headers.common['X-Consumer-Key'] = environmentConfig.api.consumerKey;
    $httpProvider.interceptors.push(RequestInterceptor);
    $httpProvider.interceptors.push(ResponseInterceptors);
}

RequestInterceptor.$inject = [
    '$q',
    '$rootScope',
    '$cookies',
    'environmentConfig'
];

ResponseInterceptors.$inject = [
    '$window',
    '$cookies',
    '$q'
];

DugunHttpProviderConfig.$inject = [
    '$httpProvider',
    'environmentConfig'
];

angular.module('dugun.provider')
    .config(DugunHttpProviderConfig);
