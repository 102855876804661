/**
 * @ngdoc controller
 * @memberof dugun.agreements
 * @name AgreementCtrl
 *
 * @requires $scope
 * @requires $route
 * @requires $routeParams
 * @requires $window
 * @requires dugun.notifications:dgNotifications
 * @requires $translate
 * @requires Agreements
 */
function AgreementCtrl($scope, $route, $routeParams, $window,
                        dgNotifications, $translate,
                        Agreements
) {
    $scope.$root.heading.name = $route.current.label;
    $scope.id = $window.parseInt($routeParams.agreementId) || null;
    $scope.agreement = {};
    $scope.loading = [];

    $scope.getAgreement = function() {
        var data = {
            agreementId: $scope.id
        };

        var promise = Agreements.getAgreementText(
            data,
            function(successResponse) {
                $scope.agreement = successResponse.data;
                $scope.$root.heading.name = successResponse.data.header;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.setAgreement = function () {
        var data = {
            agreementTextId: $scope.id
        };

        var promise = Agreements.setAgreement(
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('AGREEMENTS.AGREED'));
                $window.location.href = '/home';
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    if($scope.id) {
        $scope.getAgreement();
    }
}

AgreementCtrl.$inject = [
    '$scope',
    '$route',
    '$routeParams',
    '$window',
    'dgNotifications',
    '$translate',
    'Agreements'
];

angular.module('dugun.agreements')
    .controller('AgreementCtrl', AgreementCtrl);
