(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('customer-menu/menu-copy.html',
    '<div class="modal-body" cg-busy="loading"><div class="row"><div class="col-xs-12"><h4>{{ \'CUSTOMER_MENU.MENU_COPY_CONFIRMATION\' | translate }}</h4></div></div></div><div class="modal-footer"><div class="col-xs-6"><button class="btn btn-default btn-lg full-width" ng-click="close(isChanged)">{{ \'GENERAL.CLOSE\' | translate }}</button></div><div class="col-xs-6"><button class="btn btn-success btn-lg full-width" ng-click="copyCustomerMenu()">{{ \'GENERAL.COPY\' | translate }}</button></div></div>');
}]);
})();
