(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('provider-upgrade/request-already-sent.modal.html',
    '<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="close()" ga-track-event="[\'alreadySentUpgradeModal\', \'click\', \'closeIcon\']">&times;</button><h4 class="modal-title text-center request-upgrade-header">{{header}}</h4></div><div class="modal-body text-center"><span class="fa-stack fa-5x sent-request-upgrade-icon"><i class="fas fa-circle fa-stack-2x dugun-panel"></i> <i class="fas fa-thumbs-up fa-stack-1x fa-inverse"></i></span><p class="mb-20 mt-20 request-upgrade-text">{{bodyText}}</p><p class="mb-20 mt-20"><button type="button" class="btn btn-primary request-upgrade-cta" ng-click="close()" ga-track-event="[\'alreadySentUpgradeModal\', \'click\', \'closeButton\']">{{ctaButtonText}}</button></p></div>');
}]);
})();
