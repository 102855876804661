/**
 * @ngdoc directive
 * @name dugun.forms:dgFormSelectProvider
 * @restrict 'E'
 * @scope
 **/
function DgFormSelectProvider($translate) {
    return {
        restrict: 'E',
        scope: {
            model: '=ngModel',
            options: '=',
            allowClear: '&',
            required: '=',
            getCity: '=fGetCity',
            ngChange: '&',
            getProviderCategory: '=fGetProviderCategory',
            id: '@dgId',
            ngDisabled: '&'
        },
        templateUrl: 'dg-forms/form-elements/provider/provider.html',
        link: function(scope, element, attrs) {
            tmpOptions = angular.copy(scope.options);

            scope.selected = function ($item, $model) {
                scope.ngChange({selectedValue: $item, model: $model});
            };

            if(attrs.allOption){
                tmpOptions.unshift({
                    id: 0,
                    name: $translate.instant('DG_FORMS.ALL')
                });

                scope.allowClear = function() {
                    return false;
                };
            }

            scope.finalOptions = tmpOptions;

            // Select first item if model is an array
            if(scope.model instanceof Array && scope.model.length == 1){
                scope.model = scope.model[0];
            } else if (scope.model instanceof Array && scope.model.length > 1){  // Select All option
                scope.model = tmpOptions[0].id;
            }

            scope.attrs = attrs;
        }
        
    };
}

DgFormSelectProvider.$inject = [
    '$translate'
];

angular.module('dugun.forms').directive('dgFormSelectProvider', DgFormSelectProvider);
