function CollectionsPaymentCtrl($scope, $uibModalInstance, $translate, $filter, dgNotifications, CollectionsPayments, itemId, data) {
    $scope.paymentId = itemId || null;
    $scope.coupleType = false;
    $scope.dirtyData = {};
    $scope.cleanData = {};
    $scope.merchantList = data.merchantList || null;
    $scope.activeMerchantList = $filter('filter')($scope.merchantList, {status: 'approved'}, true);
    $scope.loading = [];
    $scope.isChanged = false;

    $scope.close = function(data) {
        if(data) $uibModalInstance.close(data);
        $uibModalInstance.dismiss('cancel');
    };

    $scope.coupleChange = function (lead) {
        var name = lead.couple.name.split(' ');
        $scope.dirtyData.firstName = name[0];
        $scope.dirtyData.lastName = name[1] ? name[1] : '';
        $scope.dirtyData.phone = lead.unmaskPhone ? lead.unmaskPhone.number : null;
        $scope.dirtyData.email = lead.unmaskEmail;
    };

    $scope.getPayment = function () {
        var urlParams = {
            id: $scope.paymentId
        };

        var loading = CollectionsPayments.get(
            urlParams,
            function (successResponse) {
                $scope.cleanData = successResponse.data;
                $scope.dirtyData = angular.copy($scope.cleanData);
                $scope.coupleType = $scope.dirtyData.couple !== null;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.setPayment = function () {
        var method = 'insert',
            params = {},
            data = $scope.dirtyData.getDiff($scope.cleanData);

        if ($scope.paymentId) {
            method = 'update';
            params.id = $scope.paymentId;
        }

        if ($scope.activeMerchantList.length === 1) {
            data.merchant = $scope.activeMerchantList[0];
        }

        data.provider = {
            id: data.merchant.provider.id
        };
        data.merchant = {
            id: data.merchant.id
        };

        if (data.couple) {
            data.couple = {
                id: data.couple.coupleId
            };
        }

        data.paymentGateway = {
            id: 1
        };
        data.status = 'pending';

        var loading = CollectionsPayments[method](
            params,
            data,
            function (successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
                $scope.isChanged = true;
                $scope.close(true);
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    if ($scope.paymentId) {
        $scope.getPayment();
    }
}

CollectionsPaymentCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$translate',
    '$filter',
    'dgNotifications',
    'CollectionsPayments',
    'itemId',
    'data'
];

angular.module('dugun.collection').controller('CollectionsPaymentCtrl', CollectionsPaymentCtrl);
