(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/lead-messages/components/warning-message.html',
    '<div class="top attached well bordered-top bordered-danger"><span ng-if="item.type == \'message\'"><p class="danger"><i class="fa fa-exclamation-triangle danger"></i> {{ \'LEADS.CANT_SENT_MESSAGE\' | translate }}</p><p>{{ \'LEADS.CANT_SENT_MESSAGE_INFO\' | translate }}</p></span></div><div class="well attached" ng-if="leadHistoryMeta.outboundNumber && environment.fieldsSettings.outboundNumber"><div class="lead-details-contact-area"><h3 class="couple-header-text m-0 mb-10"><i class="fa fa-phone"></i> {{\'LEADS.CALL_COUPLE_INFO\' | translate}}</h3><label class="control-label inforequest-details">{{\'LEADS.OUTBOUND_NUMBER\' | translate}}:</label><a class="form-control-static v-align-top phone-input" href="tel:{{leadHistoryMeta.outboundNumberCall}},{{leadData.extensionNumber}}" target="_top">{{leadHistoryMeta.outboundNumber | phone}}</a><br><label class="control-label inforequest-details">{{\'LEADS.EXTENSION_NUMBER\' | translate}}:</label><a class="form-control-static v-align-top" href="tel:{{leadHistoryMeta.outboundNumberCall}},{{leadData.extensionNumber}}" target="_top">{{leadData.extensionNumber}}</a></div></div>');
}]);
})();
