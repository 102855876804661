/**
 * @ngdoc directive
 * @name dugun.forms:dgFormCouple
 * @restrict 'E'
 * @scope
 **/
function DgFormCouple(DgFormCoupleService) {
    return {
        restrict: 'E',
        scope: {
            model: '=ngModel',
            allowClear: '@',
            required: '=ngRequired',
            placeholder: '@',
            id: '@dgId',
            ngChange: '&',
            statusIds: '=statusIds'
        },
        templateUrl: 'dg-forms/form-elements/couple/couple.html',
        link: function(scope) {
            scope.searcher = DgFormCoupleService;
            scope.selected = function ($item, $model) {
                scope.ngChange({selectedValue: $item, model: $model});
            };
        }
    };
}

DgFormCouple.$inject = [
    'DgFormCoupleService',
];

angular.module('dugun.forms')
    .directive('dgFormCouple', DgFormCouple);
