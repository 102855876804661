/**
 * @ngdoc controller
 * @memberof dugun.testimonials
 * @name TestimonialsListCtrl
 *
 * @requires $scope
 * @requires ngRoute:$route
 * @requires $routeParams
 * @requires dugun.notifications:dgNotifications
 * @requires dugun.queryString:queryString
 * @requires Analytics
 * @requires Testimonials
 * @requires Providers
 */
function TestimonialsListCtrl(
    $scope, $route, $routeParams, $filter,
    dgNotifications, queryString, Analytics,
    Testimonials, Providers, $translate
) {
    $scope.$root.heading.name = $route.current.label;
    $scope.selectedTab = queryString.getHash() || null;
    $scope.tabs = {
        active: {index: 0},
        rejected: {index: 1}
    };
    $scope.selectedTabIndex = $scope.selectedTab ? $scope.tabs[$scope.selectedTab].index : null;
    $scope.list = [];
    $scope.filters = queryString.getFilters() || {};
    $scope.loading = [];

    $scope.pagination = {
        limit: 25,
        currentPage: 1
    };

    $scope.orderBy = {
        key: 'createdAt',
        direction: true
    };

    $scope.statusCounts = {
        active: 0,
        rejected: 0
    };

    $scope.showAddTestimonial = false;

    $scope.loadList = function () {
        if (window.getPromiseStatus($scope.loading) === 0) return true;

        $scope.list = [];

        var data = angular.copy($scope.filters),
            gaFilters = [],
            promise;

        data.page = $scope.pagination.currentPage;
        data.listingType = queryString.getHash() ? queryString.getHash() : 'active';
        data.sort = $scope.orderBy.key;
        data.order = $scope.orderBy.direction ? 'DESC' : 'ASC';
        if(data.providerId) {
            data['providerIds[]'] = [data.providerId];
            delete data.providerId;
        }
        if(data.coupleName === '') {
            delete data.coupleName;
        }

        angular.forEach($scope.filters, function(value, key) {
            if(value !== '' && value !== null && !key.startsWith('utm')){
                gaFilters.push(key);
            }
        });
        if(gaFilters.length > 0) {
            Analytics.trackEvent('testimonialsFilter', 'filter', gaFilters.sort().join('|'));
        }

        promise = Testimonials.query(
            data,
            function (successResponse) {
                $scope.list = successResponse.data;
                $scope.pagination = successResponse.meta;
                $scope.listDisplayed = [].concat($scope.list);
                $scope.filters.listingType = data.listingType;

                if (data['providerIds[]']) {
                    var provider = $filter('filter')($scope.providersActive, {id: data['providerIds[]'][0]}, true)[0];
                    dataLayer.push({
                        event: 'testimonialFilter',
                        provider: {
                            id: provider.id,
                            name: provider.name,
                            city: $scope.getCity(provider.cityId),
                            category: $scope.getProviderCategory(provider.categoryId),
                            totalReviewCount: $scope.statusCounts.active
                        }
                    });
                }
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            });
        $scope.loading.push(promise);
    };

    $scope.loadStatusCounts = function() {
        if (window.getPromiseStatus($scope.loading) === 0) return true;

        var data = angular.copy($scope.filters),
            promise;

        delete data.listingType;
        if(data.providerId) {
            data['providerIds[]'] = [data.providerId];
            delete data.providerId;
        }
        if(data.coupleName === '') {
            delete data.coupleName;
        }

        promise = Testimonials.countsByStatus(
            data,
            function(successResponse) {
                $scope.statusCounts = successResponse.data;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.changeTestimonialStatus = function (item) {
        var data = {
            weddingMade: 1
        };

        var params = {
            id: item.id
        };

        Testimonials.update(
            params,
            data,
            function (successResponse) {
                dgNotifications.success($translate.instant("GENERAL.ADDED_SUCCESSFULLY"));
                $scope.loadList();
                $scope.loadStatusCounts();
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    /*jshint loopfunc: true */
    $scope.loadTestimonialCounts = function () {
        if(!$scope.user) {
           return;
        }
        if(angular.isUndefined($scope.providersWithProduct)) {
            return;
        }

        $scope.showAddTestimonial = false;

        angular.forEach($scope.providersWithProduct, function(provider, key){
            if(!provider.hasPromotion) {
                var promise = Providers.get({
                        'id': provider.id
                    },
                    function(successResponse) {
                        if(successResponse.data.permissions.addTestimonial) {
                            $scope.showAddTestimonial = true;
                        }
                        $scope.providersWithProduct[key].canAddTestimonials = successResponse.data.permissions.addTestimonial;
                    },
                    errorCallback
                );
                $scope.loading.push(promise);
            }
        });

        function errorCallback(errorResponse) {
            dgNotifications.error(errorResponse);
        }
    };

    $scope.setFilters = function () {
        $scope.pagination.currentPage = 1;
        queryString.setFilters(angular.copy($scope.filters));
        $scope.loadList();
        $scope.loadStatusCounts();
        $scope.loadTestimonialCounts();
    };

    $scope.resetFilters = function() {
        var currentListingType = $scope.filters.listingType;
        $scope.filters = {
            listingType: currentListingType
        };
        $scope.setFilters();
    };

    $scope.$watch('pagination.currentPage', function (newValue, oldValue) {
        if (newValue != oldValue) {
            $scope.loadList();
        }
    });

    $scope.openItem = function (item) {
        $scope.openModal(
            'testimonials/show.modal.html',
            'TestimonialCtrl',
            item ? item.id : null
        ).result.then(function(data) {
            item.providerTestimonialComment = data.providerTestimonialComment;
        });
        dataLayer.push({
            event: !item.providerTestimonialComment ? 'openTestimonial' : 'viewTestimonialReply',
            provider: {
                id: item.provider.id,
                name: item.provider.name,
                category: $scope.getProviderCategory(item.provider.categoryId),
                city: $scope.getCity(item.provider.cityId),
                totalReviewCount: $scope.statusCounts.active
            },
            couple: {
                id: item.coupleId,
            },
            lead: {
                id: item.id,
            }
        });
    };

    $scope.openItemForm = function (item) {
        $scope.openModal(
            'testimonials/form.modal.html',
            'TestimonialCtrl',
            item ? item.id : null
        ).result.finally(
            function() {
                $scope.loadList();
                $scope.loadStatusCounts();
                $scope.loadTestimonialCounts();
            }
        );
    };

    $scope.deleteTestimonial = function(item) {
        var urlData = {
            id: item.id
        };

        var promise = Testimonials.delete(
            urlData,
            function(successResponse) {
                dgNotifications.success($translate.instant('TESTIMONIALS.TESTIMONIAL_DELETED'));
                $scope.loadList();
                $scope.loadStatusCounts();
                $scope.loadTestimonialCounts();

                urlData.success = true;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);

                urlData.success = false;
                urlData.error = errorResponse.data.message;
            }
        );

        $scope.loading.push(promise);
    };

    $scope.sortBy = function(key) {
        if($scope.orderBy.key === key) {
            $scope.orderBy.direction = !$scope.orderBy.direction;
        } else {
            $scope.orderBy.key = key;
            $scope.orderBy.direction = false;
        }

        $scope.loadList();
    };

    $scope.tabSelected = function(newTab) {
        if(!newTab || newTab === $scope.selectedTab) return;
        $scope.selectedTab = newTab;
        $scope.filters.listingType = newTab;
        queryString.setHash(newTab);
        $scope.setFilters();
    };

    if(queryString.getHash()) {
        $scope.loadList();
        $scope.loadStatusCounts();
    } else {
        $scope.filters = {
            listingType: 'active'
        };
        $scope.tabSelected('active');
        $scope.selectedTabIndex = $scope.tabs.active.index;
    }

    if($routeParams.id) {
        $scope.openModal(
            'testimonials/show.modal.html',
            'TestimonialCtrl',
            $routeParams.id
        ).result.then(function(data) {
            if(!$scope.filters.listingType) {
                $scope.filters = {
                    listingType: 'active'
                };
                $scope.tabSelected('active');
                $scope.selectedTabIndex = $scope.tabs.active.index;
            }
        });
    }

    $scope.$watch('user', $scope.loadTestimonialCounts);

    $translate.onReady().then(function () {
        var isHelperExist = $translate.instant('TESTIMONIALS.HELPER_PAGE.IS_EXIST');
        if (isHelperExist == 'true') {
            $scope.$root.tutorials = [
                {
                    type: 'page',
                    data: {
                        page_title: $translate.instant('TESTIMONIALS.HELPER_PAGE.TITLE'),
                        page_url: 'testimonials/helper.html'
                    }
                }
            ];
        }
    });

}

TestimonialsListCtrl.$inject = [
    '$scope',
    '$route',
    '$routeParams',
    '$filter',
    'dgNotifications',
    'queryString',
    'Analytics',
    'Testimonials',
    'Providers',
    '$translate'
];

angular.module('dugun.testimonials')
    .controller('TestimonialsListCtrl', TestimonialsListCtrl);
