(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/notifications/process-states-reminder.html',
    '<div><div class="row"><i class="fa fa-2x ICON_CLASS" style="float: left"></i><h4 style="margin: 2% 10%">PROCESS_STATE</h4></div><div class="or-line"></div><div class="row"><p class="reminder-font mt-0 mb-0">COUPLE_NAME</p><p class="reminder-font mt-0 mb-0">REMIND_AT</p></div></div><style>#toast-container>.toast-info {\n' +
    '        background-image: none !important;\n' +
    '    }\n' +
    '\n' +
    '    .or-line {\n' +
    '        height: 1px;\n' +
    '        margin: 3% -6%;\n' +
    '        background-color: #ccc;\n' +
    '        width: 100%;\n' +
    '    }\n' +
    '\n' +
    '    .reminder-font {\n' +
    '        color: black;\n' +
    '        font-weight: bold;\n' +
    '        font-size: 15px;\n' +
    '    }</style>');
}]);
})();
