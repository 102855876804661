/**
 * @param $translate
 * @param uibDatepickerPopupConfig
 * @constructor
 */
function DatePickerTranslate($translate, uibDatepickerPopupConfig) {
    var service = {};
    
    service.translate = function () {
        $translate.onReady().then(function () {
            uibDatepickerPopupConfig.closeText = $translate.instant('DATEPICKER.CLOSE_TEXT');
            uibDatepickerPopupConfig.clearText = $translate.instant('DATEPICKER.CLEAR_TEXT');
            uibDatepickerPopupConfig.currentText = $translate.instant('DATEPICKER.CURRENT_TEXT');
        });
    };

    return service;
}

DatePickerTranslate.$inject = [
    '$translate',
    'uibDatepickerPopupConfig',
];

angular.module('dugun.provider').factory('DatePickerTranslate', DatePickerTranslate);
