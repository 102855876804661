/**
 * @memberof dugun.leads
 * @ngdoc route
 * @name LeadRoutes
 * @param $routeProvider {provider} $routeProvider
 *
 * @description /providers/:providerId/info
 */
function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function LeadRoutes($routeProvider) {
    $routeProvider
        .when('/leads', {
            templateUrl: 'leads/list.html',
            controller: 'LeadsListCtrl',
            controllerAs: 'LeadsListCtrl',
            label: 'LEADS.LEADS',
            reloadOnSearch: false,
            resolve: {
                init: userResolver
            }
        });
}

LeadRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.leads').config(LeadRoutes);
