(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('login/set-customer-user/index.html',
    '<div class="login-container animated fadeInDown" cg-busy="loading"><a href="javascript:void(0);" class="login-logo"></a><div class="loginbox bg-white"><form ng-submit="setCustomerUser()"><div class="loginbox-title">{{ \'USERNAME_RESET.WELCOME\' | translate:{name: customerUser.name} }}</div><div class="loginbox-or"><div class="or-line"></div></div><div class="loginbox-or" style="height: auto; padding: 10px 40px">{{\'USERNAME_RESET.DESCRIPTION\' | translate}}</div><div class="loginbox-or alert alert-warning" style="height: auto; padding: 10px 40px" ng-if="errorMessage">{{errorMessage}}</div><div class="loginbox-textbox pt-0"><label class="input-sm text-bold mb-0">{{\'USERNAME_RESET.USERNAME\' | translate}}</label><input type="text" class="form-control" ng-model="fields.username" minlength="6" required></div><div class="loginbox-textbox pt-0"><label class="input-sm text-bold mb-0">{{\'USERNAME_RESET.PASSWORD\' | translate}}</label><input type="password" class="form-control" ng-model="fields.password" minlength="6" required></div><div class="loginbox-textbox pt-0" ng-class="{\n' +
    '                \'has-error\': fields.passwordRepeat !== fields.password\n' +
    '            }"><label class="input-sm text-bold mb-0">{{\'USERNAME_RESET.PASSWORD_CONFIRMATION\' | translate}}</label><input type="password" class="form-control" ng-model="fields.passwordRepeat" minlength="6" required></div><div class="loginbox-submit"><input type="submit" class="btn btn-primary btn-block" value="{{\'USERNAME_RESET.SAVE\' | translate}}" ga-track-event="[\'loginForm\', \'click\', \'setCustomerUser\']"></div><div class="loginbox-forgot"><a href="login" ga-track-event="[\'loginForm\', \'click\', \'login\']">{{\'USERNAME_RESET.GO_TO_LOGIN\' | translate}}</a></div></form></div></div><style type="text/css">html, body, .page-body{\n' +
    '        height: 100%;\n' +
    '        width: 100%;\n' +
    '        min-height: 100%;\n' +
    '        background: url(images/login-bg-2.jpg) no-repeat center center fixed;\n' +
    '        -webkit-background-size: cover;\n' +
    '        -mox-background-size: cover;\n' +
    '        -o-background-size: cover;\n' +
    '        background-size: cover;\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '    .loading-container{\n' +
    '        display: none;\n' +
    '    }</style>');
}]);
})();
