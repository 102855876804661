function CollectionCtrl($scope, $translate, $filter, $location, $timeout, queryString, $q,
                        dgNotifications, CollectionsMerchants, dugunConfig, Agreements) {
    $scope.loading = [dugunConfig.loading];
    $scope.data = {};
    $scope.providers = [];

    $scope.options = {};

    $translate.onReady().then(function () {
        $scope.options.steps = [
            $translate.instant('COLLECTION.STEPS.INFORMATION'),
            $translate.instant('COLLECTION.STEPS.BUSINESS_TYPE'),
            $translate.instant('COLLECTION.STEPS.APPLICATION_FORM'),
            $translate.instant('COLLECTION.STEPS.AGREEMENT')
        ];
        $scope.merchantStatuses = {
            approved: $translate.instant('COLLECTION.APPROVED'),
            passive: $translate.instant('COLLECTION.PENDING'),
            rejected: $translate.instant('COLLECTION.REJECTED')
        };
    });
    
    $scope.options.doneSteps = parseInt($location.hash()) || 1;

    $scope.data.merchantType = $location.search().merchantType || null;
    $scope.data.providerId = parseInt($location.search().providerId) || null;
    dugunConfig.init().then(function (configs) {
        $scope.merchantTypes = configs.collection_merchant_type;
        $scope.merchantTypes = $filter('orderBy')($scope.merchantTypes, 'orderNum');
    });

    $scope.currentStep = $location.hash() || 1;
    $location.hash($scope.currentStep);

    $scope.next = function (data) {
        if ($scope.currentStep < $scope.options.steps.length) {
            $scope.currentStep++;
        }
        $location.hash($scope.currentStep);

        if (data && data.hasOwnProperty('merchantType')) {
            $scope.data.merchantType = data.merchantType;
        }

        $scope.options.doneSteps = $scope.currentStep;
    };

    $scope.setMerchant = function () {
        var data = angular.copy($scope.data);

        if (data.iban) {
            data.iban = $scope.environment.countryCode.toUpperCase() + data.iban;
        }

        if (data.birthDate) {
            data.birthDate = data.birthDate.split('-');
            data.birthDate = data.birthDate[2] + '/' +data.birthDate[1] + '/' + data.birthDate[0];
        }

        if (data.bankCode) {
            if (data.bankCode.toString().length === 2) {
                data.bankCode = '00' + data.bankCode;
            }

            if (data.bankCode.toString().length === 3) {
                data.bankCode = '0' + data.bankCode;
            }
        }

        data.paymentGatewayId = 1;

        var loading = CollectionsMerchants.insert(
            data,
            function (successResponse) {
                $scope.next();
                $scope.successMerchantResponse = successResponse.data;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };


    $scope.loadMerchants = function () {
        var params = {};

        var loading = CollectionsMerchants.query(
            params,
            function (successResponse) {
                $scope.merchantList = successResponse.data;
                $scope.activeMerchantList = $filter('filter')(successResponse.data, {status: 'approved'}, true);
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.prev = function () {
        if ($scope.currentStep <= 3) {
            $scope.currentStep--;
        }
        $location.hash($scope.currentStep);
        $scope.options.doneSteps = $scope.currentStep;
    };
    
    $scope.$watch('data.merchantType', function (newVal, oldVal) {
        if (newVal) {
            $location.search('merchantType', newVal);
        }
    });

    $scope.$watch('data.providerId', function (newVal, oldVal) {
        if (newVal) {
            $location.search('providerId', newVal);
        }
    });

    $scope.$watchCollection('user', function (user) {
        $scope.phoneLoading = $q.defer();
        if (user) {
            var indexOfEmpty = user.name.indexOf(' ');
            if (indexOfEmpty > -1) {
                $scope.data.firstName = user.name.substr(0, indexOfEmpty);
                $scope.data.lastName = user.name.substr(indexOfEmpty, user.name.length - 1);
            }
            $scope.data.email = user.email;
            $timeout(function () {
                $scope.data.mobilePhone = user.phone.replace(' ', '');
                $scope.phoneLoading.resolve();
            }, 1000);
        }
    });

    $scope.openAgreementPopup = function(agreementLink){
        var modalInstance = $scope.openModal(
            'collection/agreement.html',
            agreementModal,
            null,
            null,
            {
                agreementLink: agreementLink
            },
            {
                size: 'lg'
            }
        );

        modalInstance.closed.then(function () {
            $scope.loadCollectionMerchants();
        });
    };

    var agreementModal = function ($scope, $uibModalInstance, $sce, data) {
        $scope.close = function(data) {
            if(data) $uibModalInstance.close(data);
            $uibModalInstance.dismiss('cancel');
        };

        $scope.data = data || null;

        $scope.trustSrc = function(src) {
            return $sce.trustAsResourceUrl(src);
        };
    };

    agreementModal.$inject = [
        '$scope',
        '$uibModalInstance',
        '$sce',
        'data'
    ];

    var getAgreementText = function () {
        var loading = Agreements.getAgreementText(
            {
                type: 'collection_merchant'
            },
            function (successResponse) {
                $scope.agreementText = successResponse.data[0];
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.getValueByObjectKey = function (array, key) {
        return $filter('filter')(array, {key: key}, true)[0].value;
    };

    $scope.loadMerchants();
    $scope.$watchCollection('availableProvidersForMerchant', function (value) {
        if (value) {
            if ($scope.currentStep == 1 && $scope.availableProvidersForMerchant.length) {
                getAgreementText();
            }
        }
    });

}

CollectionCtrl.$inject = [
    '$scope',
    '$translate',
    '$filter',
    '$location',
    '$timeout',
    'queryString',
    '$q',
    'dgNotifications',
    'CollectionsMerchants',
    'dugunConfig',
    'Agreements'
];

angular.module('dugun.collection').controller('CollectionCtrl', CollectionCtrl);
