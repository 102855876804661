angular.module('dugun.provider')
    .config([
        'dateRangePickerConfig',
        'moment',
        DateRangePickerConfig
    ]);

function DateRangePickerConfig(dateRangePickerConfig, moment) {
    dateRangePickerConfig.linkedCalendars = false;
    dateRangePickerConfig.autoApply = false;
    dateRangePickerConfig.alwaysShowCalendars = true;
    dateRangePickerConfig.clearLabel = 'Sıfırla';
    dateRangePickerConfig.showDropdowns = true;
    dateRangePickerConfig.applyLabel = 'Tamam';
    dateRangePickerConfig.locale = {
        applyLabel: 'Tamam',
        fromLabel: 'Başlangıç',
        toLabel: 'Bitiş',
        format: 'DD MMM YYYY',
        separator: ' - ',
        customRangeLabel: 'Özel aralık'
    };

    dateRangePickerConfig.ranges = {
        'Bugün': [moment(), moment()],
        'Dün': [moment().add(-1, 'days'), moment().add(-1, 'days')],
        'Son 7 gün': [moment().add(-6, 'days'), moment()],
        'Son 30 gün': [moment().add(-29, 'days'), moment()],
        'Bu ay': [moment().startOf('month'), moment().endOf('month')],
        'Geçen ay': [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')],
        'Bu yıl': [moment().startOf('year'), moment().endOf('year')],
        'Geçen yıl': [moment().add(-1, 'year').startOf('year'), moment().add(-1, 'year').endOf('year')]
    };
}
