/**
 *
 * @param $translate
 * @param dateRangePickerConfig
 * @param moment
 * @constructor
 */
function DateRangePickerTranslate($translate, dateRangePickerConfig, moment) {
    var service = {};

    service.translate = function () {
        $translate.onReady().then(function () {
            dateRangePickerConfig.clearLabel = $translate.instant('DATE_RANGE_PICKER.CLEAR_LABEL');
            dateRangePickerConfig.applyLabel = $translate.instant('DATE_RANGE_PICKER.APPLY_LABEL');

            dateRangePickerConfig.locale = {
                applyLabel: $translate.instant('DATE_RANGE_PICKER.APPLY_LABEL'),
                fromLabel: $translate.instant('DATE_RANGE_PICKER.FROM_LABEL'),
                toLabel: $translate.instant('DATE_RANGE_PICKER.TO_LABEL'),
                format: $translate.instant('DATE_RANGE_PICKER.FORMAT'),
                customRangeLabel: $translate.instant('DATE_RANGE_PICKER.CUSTOM_RANGE_LABEL'),
                clear: 'yeh'
            };

            dateRangePickerConfig.ranges = {};

            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.TODAY')] = [moment(), moment()];
            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.YESTERDAY')] = [moment().add(-1, 'days'), moment().add(-1, 'days')];
            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.LAST_SEVEN_DAYS')] = [moment().add(-6, 'days'), moment()];
            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.LAST_THIRTY_DAYS')] = [moment().add(-29, 'days'), moment()];
            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.THIS_MONTH')] = [moment().startOf('month'), moment().endOf('month')];
            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.LAST_MONTH')] = [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')];
            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.THIS_YEAR')] = [moment().startOf('year'), moment().endOf('year')];
            dateRangePickerConfig.ranges[$translate.instant('DATE_RANGE_PICKER.LAST_YEAR')] = [moment().add(-1, 'year').startOf('year'), moment().add(-1, 'year').endOf('year')];

        });
    };

    return service;
}

DateRangePickerTranslate.$inject = [
    '$translate',
    'dateRangePickerConfig',
    'moment',
];

angular.module('dugun.provider').factory('DateRangePickerTranslate', DateRangePickerTranslate);
