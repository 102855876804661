/**
 * @memberof dugun.customers.providers.provider
 * @ngdoc route
 * @name ProviderRoutes
 * @param $routeProvider {provider} $routeProvider
 *
 * @description /providers/:providerId/info
 */

function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function ProviderRoutes($routeProvider) {
    $routeProvider
        .when('/providers/:providerId/info', {
            templateUrl: 'customers/providers/provider/provider.html',
            controller: 'ProviderCtrl',
            controllerAs: 'providerCtrl',
            label: 'CUSTOMERS.PROVIDER.INFORMATION',
            resolve: {
                init: userResolver
            }
        });

    $routeProvider.when('/providers/:providerId/badges', {
        templateUrl: 'customers/providers/provider/badges/badges.html',
        controller: 'BadgesCtrl',
        label: 'CUSTOMERS.PROVIDER.BADGES.OUR_BADGES'
    });
}

ProviderRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.customers.providers.provider').config(ProviderRoutes);
