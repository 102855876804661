(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('testimonials/helper.html',
    '<p translate="TESTIMONIALS.HELPER_PAGE.TEXT"></p>');
}]);
})();
