/**
 * @ngdoc overview
 * @memberof dugun.customers.providers.provider
 */
angular.module('dugun.customers.providers.provider', [
    // vendor
    'cgBusy',
    'angular-cache',
    'toaster',
    'dugun.queryString'
]);
