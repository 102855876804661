/**
 * @memberof dugun.signatures
 * @ngdoc route
 * @name SignaturesRoutes
 *
 * @param $routeProvider {provider} $routeProvider
 * @param $q
 *
 * @description /signatures
 */
function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function SignaturesRoutes($routeProvider) {
    $routeProvider
        .when('/signatures', {
            templateUrl: 'signatures/list.html',
            controller: 'SignaturesListCtrl',
            controllerAs: 'SignaturesListCtrl',
            label: 'SIGNATURES.LABEL',
            reloadOnSearch: false,
            resolve: {
                init: userResolver
            }
        });
}

SignaturesRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.signatures').config(SignaturesRoutes);
