/**
 * Controls the gallery image modals
 * @ngdoc controller
 * @memberof dugun.galleries.media
 * @name GalleryVideoCtrl
 * @requires $scope {service} controller scope
 * @requires $routeParams
 * @requires $uibModalInstance {service} $uibModalInstance
 * @requires $window {service} $window
 * @requires $translate
 * @requires dgNotifications {service} dgNotifications
 * @requires Analytics
 * @requires Media {factory} Media service
 *
 * @param itemId {integer=} ID of media to use
 * @param parentId {integer=} ID of gallery to use
 */
function GalleryVideoCtrl($scope, $routeParams, $uibModalInstance, $window, $translate,
                      dgNotifications, Analytics,
                      Media,
                      itemId, parentId) {
    /**
     * @memberOf GalleryVideoCtrl
     * @description
     * ID of current item if exists
     */
    $scope.id = $window.parseInt(itemId) || null;

    /**
     * @ngdoc method
     * @memberOf GalleryVideoCtrl
     * @description
     * Provider Id
     */
    $scope.providerId = $window.parseInt($routeParams.providerId) || null;

    /**
     * @memberOf GalleryVideoCtrl
     * @description
     * ID of gallery to add image.
     */
    $scope.galleryId = $window.parseInt(parentId) || null;

    /**
     * @memberOf GalleryVideoCtrl
     * @description
     * Object that keeps clean form data.
     */
    $scope.cleanData = {};

    /**
     * @memberOf GalleryVideoCtrl
     * @description
     * Object that keeps dirty form data.
     */
    $scope.dirtyData = {};

    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('GENERAL.UNSAVED_INFO_WARNING'),
            data = $scope.dirtyData.getDiff($scope.cleanData);
        if(angular.equals(data, {})) {
            return;
        }
        switch (reason){
            // clicked outside
            case "backdrop click":
                break;

            // cancel button
            case "cancel":
                break;

            // escape key
            case "escape key press":
                break;
        }
        if (!confirm(message)) {
            event.preventDefault();
        }
    });

    /**
     * @ngdoc method
     * @memberof GalleryVideoCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function(data) {
        if(data) $uibModalInstance.close(data);
        else $uibModalInstance.dismiss('cancel');
    };

    /**
     * @ngdoc method
     * @memberof GalleryVideoCtrl
     * @description
     * Gets the video
     */
    $scope.getVideo = function() {
        $scope.loading = Media.get(
            {
                id: $scope.id,
                galleryId: $scope.galleryId
            },
            function(successResponse) {
                $scope.cleanData = successResponse.data;
                $scope.dirtyData = angular.copy($scope.cleanData);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof GalleryVideoCtrl
     * @description
     * Saves the video
     */
    $scope.setVideo = function() {
        var postData = $scope.dirtyData.getDiff($scope.cleanData),
            urlData = {
                galleryId: $scope.galleryId
            },
            method = 'insert',
            actionLabel = 'Add',
            videoCount = 0;

        if($scope.id) {
            urlData.id = $scope.id;
            method = 'update';
            actionLabel = 'Edit';
        }

        if(angular.equals(postData, {})) return true;

        $scope.loading = Media[method](
            urlData,
            postData,
            function(successResponse) {
                if(urlData.id){
                    dgNotifications.success($translate.instant('GALLERIES.SAVED'));
                }else{
                    dgNotifications.success($translate.instant('GALLERIES.VIDEO_ADDED'));
                }
                $scope.cleanData = angular.copy($scope.dirtyData);
                postData.success = true;

                Analytics.trackEvent('videoForm', actionLabel, 'success');
                if (actionLabel === 'Add') {
                    videoCount++;
                    dataLayer.push({
                        event: 'addVideo',
                        addedVideoCount: videoCount
                    });
                }
                $scope.close();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);

                postData.success = false;
                postData.error = errorResponse.data.message;

                Analytics.trackEvent('videoForm', actionLabel, 'failed : ' + errorResponse.data.message);
            }
        );
    };

    if($scope.id) {
        $scope.getVideo();
    } else {
        //$scope.dirtyData.galleryId = $scope.galleryId;
        $scope.dirtyData.type = 'video';
    }
}

GalleryVideoCtrl.$inject = [
    '$scope',
    '$routeParams',
    '$uibModalInstance',
    '$window',
    '$translate',
    'dgNotifications',
    'Analytics',
    'Media',
    'itemId',
    'parentId',
];

angular.module('dugun.galleries.media')
    .controller('GalleryVideoCtrl', GalleryVideoCtrl);
