/**
 * @ngdoc directive
 * @name dugun.forms:dgFormInfoTag
 * @restrict 'ACE'
 * @scope
 **/
function DgFormInfoTag() {
    return {
        restrict: 'ACE',
        scope: {
            model: '=ngModel',
            required: '=ngRequired',
            placeholder: '@',
            options: '='
        },
        templateUrl: 'dg-forms/form-elements/info-tags/info-tag.html',
        link: function(scope) {
            scope.search = function($select){
                var search = $select.search,
                    list = angular.copy($select.items);

                //remove last user input
                list = list.filter(function(item) {
                    return !item.isUserCreated;
                });

                if(!search) {
                    //use the predefined list
                    $select.items = list;
                } else {
                    //manually add user input
                    var userInputItem = {
                        id: search,
                        name: search,
                        isUserCreated: true
                    };
                    $select.items = [userInputItem].concat(list);
                }
            };
        }
    };
}

angular.module('dugun.forms').directive('dgFormInfoTag', DgFormInfoTag);
