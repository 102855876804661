/**
 * @memberof dugun.login.passwordReset
 * @ngdoc overview
 * @description
 * Module to send password reset email and change password
 */
angular.module('dugun.login.passwordReset', [
    // vendor
    'ngRoute',
    'toaster'
]);
