function SetCustomerUserRoutes($routeProvider) {
    $routeProvider
        .when('/register/:customerId/:customerUserId/:hash', {
            templateUrl: 'login/set-customer-user/index.html',
            controller: 'SetCustomerUserCtrl',
            controllerAs: 'setCustomerUserCtrl'
        });
}

SetCustomerUserRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.login.setCustomerUser').config(SetCustomerUserRoutes);
