(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('collection/payments/show.html',
    '<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="close()">&times;</button><h3 class="modal-title">{{ \'COLLECTION.PAYMENTS.PAYMENT_DETAILS\' | translate }}</h3></div><div class="modal-body"><div class="row"><div class="col-xs-12"><table class="table table-striped table-bordered table-hover"><thead><th></th><th></th></thead><tbody><tr ng-if="paymentList.length === 0"><td colspan="100%" class="text-center">{{ \'GENERAL.RESULT_NOT_FOUND\' | translate }}</td></tr><tr><td>{{ \'COLLECTION.PAYMENTS.FIRST_NAME\' | translate }}</td><td ng-bind="cleanData.firstName"></td></tr><tr><td>{{ \'COLLECTION.PAYMENTS.LAST_NAME\' | translate }}</td><td ng-bind="cleanData.lastName"></td></tr><tr><td>{{ \'COLLECTION.PAYMENTS.DESCRIPTION\' | translate }}</td><td ng-bind="cleanData.description"></td></tr><tr><td>{{ \'COLLECTION.PAYMENTS.DUGUN_COUPLE\' | translate }}</td><td ng-bind="cleanData.couple ? (\'COLLECTION.YES\' | translate) : \'COLLECTION.NO\' | translate"></td></tr><tr><td>{{ \'COLLECTION.PAYMENTS.WEDDING_DATE\' | translate }}</td><td ng-bind="cleanData.couple.weddingDate | date:\'longDate\'"></td></tr><tr><td>{{ \'COLLECTION.PAYMENTS.PAYMENT_STATUS\' | translate }}</td><td ng-bind="getConfigItem(\'collection_payment_status\', cleanData.status)"></td></tr><tr><td>{{ \'COLLECTION.PAYMENTS.PRICE\' | translate }}</td><td ng-bind="cleanData.price + environment.currency"></td></tr></tbody><tfoot><tr class="table-row"></tr></tfoot></table></div></div></div><div class="modal-footer"><button type="button" class="btn btn-default" ng-click="close()">{{ \'GENERAL.CLOSE\' | translate }}</button></div>');
}]);
})();
