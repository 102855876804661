/**
 * Controls the couple lead modals
 * @constructor
 * @ngdoc controller
 * @memberof dugun.leads.couples
 * @name CoupleLeadCtrl
 * @param $scope {service} controller scope
 * @param $uibModalInstance {service} $uibModalInstance
 * @param $window {service} $window
 * @param $timeout
 * @param $q
 * @param $translate
 * @param dgNotifications {service} dgNotifications
 * @param Leads {factory} Leads service
 * @param itemId {integer=} ID of CustomerUser to use
 */
function CoupleLeadCtrl($scope, $uibModalInstance, $window, $timeout, $q, $translate,
                             dgNotifications,
                             Leads,
                             itemId, data) {

    $scope.id = $window.parseInt(itemId);
    $scope.cleanData = angular.copy(data.leadContact);
    $scope.leadDetails = angular.copy(data.leadDetails);
    $scope.phoneLoading = $q.defer();
    $timeout(function () {
        $scope.dirtyData = angular.copy($scope.cleanData);
        $scope.phoneLoading.resolve();
    }, 1000);
    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('GENERAL.UNSAVED_INFO_WARNING'),
            data = $scope.dirtyData.getDiff($scope.cleanData);
        if(angular.equals(data, {})) {
            return;
        }
        switch (reason){
            // clicked outside
            case "backdrop click":
                break;

            // cancel button
            case "cancel":
                break;

            // escape key
            case "escape key press":
                break;
        }
        if (!confirm(message)) {
            event.preventDefault();
        }
    });

    /**
     * @ngdoc method
     * @memberof CoupleLeadCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.close(angular.copy($scope.cleanData));
    };

    /**
     * @ngdoc method
     * @memberof CoupleLeadCtrl
     * @description
     * Sets the couple lead from service
     */
    $scope.setCouple = function() {
        var data = $scope.dirtyData.getDiff($scope.cleanData);
        if(angular.equals(data, {})) return true;

        data = data.convertDates(['organizationDate']);

        $scope.loading = Leads.update(
            { id: $scope.id },
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
                $scope.cleanData = angular.copy($scope.dirtyData);
                $scope.close();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

}

CoupleLeadCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    '$timeout',
    '$q',
    '$translate',
    'dgNotifications',
    'Leads',
    'itemId',
    'data'
];

angular.module('dugun.leads.couples')
    .controller('CoupleLeadCtrl', CoupleLeadCtrl);
