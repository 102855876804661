/**
 * @param toaster
 * @constructor
 */
function PushNotification(toaster) {
    var service = {}, notType, notBody;

    service.init = function (type, body) {
        notBody = body;
        notType = type;
    };
    
    service.create = function () {
        toaster.pop({
            type: notType,
            body: notBody,
            bodyOutputType: 'trustedHtml',
            timeout: 0
        });
    };

    return service;
}

PushNotification.$inject = [
    'toaster'
];

angular.module('dugun.provider').factory('PushNotification', PushNotification);
