function TranslateConfig($translateProvider, environmentConfig) {
    var langCookie = document.cookie.match('(^|;) ?lang=([^;]*)(;|$)');
    var lang = langCookie ? langCookie[2] : null;

    $translateProvider.preferredLanguage(lang ? lang : environmentConfig.languages.default);

    $translateProvider.useStaticFilesLoader({
        files: [
            {
                prefix: 'translations/',
                suffix: '.json'
            },
        ]
    });
}

TranslateConfig.$inject = [
    '$translateProvider',
    'environmentConfig'
];

angular.module('dugun.provider').config(TranslateConfig);
