function GoogleMapConfig(environmentConfig, uiGmapGoogleMapApiProvider) {
    var langCookie = document.cookie.match('(^|;) ?lang=([^;]*)(;|$)');
    var lang = langCookie ? langCookie[2] : null;
    uiGmapGoogleMapApiProvider.configure({
        key: environmentConfig.googleMapKey,
        libraries: 'weather,geometry,visualization',
        v: '3.36',
        language: lang ? lang : environmentConfig.languages.default

    });
}

GoogleMapConfig.$inject = [
    'environmentConfig',
    'uiGmapGoogleMapApiProvider'
];

angular.module('dugun.provider').config(GoogleMapConfig);
