/**
 * @ngdoc factory
 * @memberof dugun.provider.upgrade
 * @name ProviderUpgradePopups
 *
 * @requires dugun.popup:dgPopup
 * @requires $cookies
 *
 * @description
 * ProviderUpgradePopups factory
 */
function ProviderUpgradePopups(dgPopup, $cookies) {
    var service = {};

    /**
     * @ngdoc method
     * @memberof ProviderUpgradePopups
     * @param page
     * @description
     * Opens request upgrade modal
     */
    service.openRequestUpgrade = function(page, message) {
        if($cookies.get('request_upgrade')) {
            return dgPopup.openModal({
                templateUrl: 'provider-upgrade/request-already-sent.modal.html',
                controller: 'ProviderUpgradeRequestAlreadySentCtrl'
            });
        } else {
            return dgPopup.openModal({
                templateUrl: 'provider-upgrade/request.modal.html',
                controller: 'ProviderUpgradeRequestCtrl',
                data: { page: page, message: message }
            });
        }
    };

    /**
     * @ngdoc method
     * @memberof ProviderUpgradePopups
     * @param page
     * @description
     * Opens sent request upgrade modal
     */
    service.openSentRequestUpgrade = function(page) {
        return dgPopup.openModal({
            templateUrl: 'provider-upgrade/request-sent.modal.html',
            controller: 'ProviderUpgradeRequestSentCtrl',
            data: { page: page }
        });
    };

    /**
     * @ngdoc method
     * @memberof ProviderUpgradePopups
     * @param page
     * @description
     * Opens failed request upgrade modal
     */
    service.openFailedRequestUpgrade = function() {
        return dgPopup.openModal({
            templateUrl: 'provider-upgrade/request-failed.modal.html',
            controller: 'ProviderUpgradeRequestFailedCtrl'
        });
    };

    return service;
}

ProviderUpgradePopups.$inject = [
    'dgPopup',
    '$cookies'
];

angular.module('dugun.galleries.media')
    .factory('ProviderUpgradePopups', ProviderUpgradePopups);
