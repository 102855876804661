(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/status/slider.html',
    '<div class="full-width"><label><input ng-model="model" class="checkbox-slider colored-success" ng-disabled="ngDisabled" type="checkbox"> <span class="text" data-before-content="{{ label }}"></span></label></div><style>input[type=checkbox].checkbox-slider ~ .text:before {\n' +
    '        content: attr(data-before-content);\n' +
    '        width: 62px;\n' +
    '        font-size: 13px;\n' +
    '        line-height: 22px;\n' +
    '        height: 26px;\n' +
    '        font-weight: bolder;\n' +
    '    }\n' +
    '    input[type=checkbox].checkbox-slider ~ .text:after {\n' +
    '        width: 28px;\n' +
    '        height: 28px;\n' +
    '    }</style>');
}]);
})();
