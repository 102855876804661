/**
 * @ngdoc object
 * @memberof dugun.attachments
 * @name AttachmentsListCtrl

 * @requires $scope {service} controller scope
 * @requires $route {service} $route
 * @requires dgNotifications {service} dgNotifications
 * @requires Attachments {factory} Attachments service
 * @requires AttachmentsPopups
 */
function AttachmentsListCtrl($scope, $route, dgNotifications,
                      Attachments, AttachmentsPopups) {

    $scope.list = [];
    $scope.loading = [];

    $scope.pagination = {
        perPage: 10,
        currentPage: 1
    };

    $scope.loadList = function () {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.user.customerId) return true;

        var data = {
            page: $scope.pagination.currentPage,
            limit: $scope.pagination.perPage,
            customerId: $scope.user.customerId
        };

        var promise = Attachments.query(
            data,
            function (successResponse) {
                $scope.list = successResponse.data;
                $scope.pagination = successResponse.meta;
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.openUpload = function() {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.user.customerId) return true;

        AttachmentsPopups.openUpload($scope.user.customerId).result.then(
            function() {
                $scope.loadList();
            },
            function() {
                $scope.loadList();
            }
        );
    };

    $scope.openEdit = function(item) {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.user.customerId) return true;

        item.customerId = $scope.user.customerId;
        AttachmentsPopups.openForm(item).result.then(
            function() {
                $scope.loadList();
            },
            function() {
                $scope.loadList();
            }
        );
    };

    $scope.download = function (item) {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.user.customerId) return true;

        var data = {
            id: item.id,
            customerId: $scope.user.customerId
        };

        var promise = Attachments.download(
            data,
            function (successResponse) {

            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.delete = function(item) {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.user.customerId) return true;

        var data = {
            id: item.id,
            customerId: $scope.user.customerId
        };

        var promise = Attachments.delete(
            data,
            function (successResponse) {
                $scope.loadList();
                dgNotifications.success($translate.instant('ATTACHMENTS.ATTACHMENT_DELETED_SUCCESSFULLY'));
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.$watch('pagination.currentPage', function (newValue, oldValue) {
        $scope.loadList();
    });

    $scope.$root.heading.name = $route.current.label;
}

AttachmentsListCtrl.$inject = [
    '$scope',
    '$route',
    'dgNotifications',
    'Attachments',
    'AttachmentsPopups'
];

angular.module('dugun.attachments')
    .controller('AttachmentsListCtrl', AttachmentsListCtrl);