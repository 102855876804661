(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/category/single.html',
    '<dg-form-select2 ng-model="model" options="options" placeholder="{{ attrs.placeholder || (\'DG_FORMS.CHOOSE_CATEGORY\' | translate) }}" allow-clear="{{ allowClear }}" ng-required="required ? true : false" ng-attr-form="{{ attrs.form ? attrs.form : null }}" class="single-select" ng-disabled="ngDisabled ? true : false"></dg-form-select2>');
}]);
})();
