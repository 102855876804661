function ItemsRoutes($routeProvider) {
    $routeProvider
        .when('/providers/:providerId/items', {
            templateUrl: '/items/list.html',
            controller: 'ItemsListCtrl',
            label: 'ITEMS.ITEMS'
        });
}

ItemsRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.items').config(ItemsRoutes);
