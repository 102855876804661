/**
 * MediaUpload factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.galleries.media
 * @name MediaUpload
 * @description
 * Just uploads images
 * @returns {service} MediaUpload service
 */
function MediaUpload(Upload, environmentConfig) {
    var url = environmentConfig.api.url + 'gallery/:galleryId/medias';
    var mediaUpload = {};

    mediaUpload.upload = function(file, params, urlData) {
        var urlToUpload = url,
            i;

        var data = {
            file: file,
            type: 'image'
        };

        for(i in params) {
            urlToUpload = urlToUpload.replace(':' + i, params[i]);
        }

        if (urlData) {
            for (i in urlData) {
                data[i] = urlData[i];
            }
        }

        return Upload.upload({
            url: urlToUpload,
            method: 'POST',
            data: data
        });
    };

    return mediaUpload;
}

MediaUpload.$inject = [
    'Upload',
    'environmentConfig',
];

angular.module('dugun.galleries.media.upload')
    .factory('MediaUpload', MediaUpload);
