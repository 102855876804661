(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('galleries/galleries/list.html',
    '<div class="row visible-md-block visible-lg-block"><div class="col-lg-12 col-sm-12 col-xs-12"><div class="widget" ng-class="getWidget(\'mediaHelper\', 1) === true ? \'collapsed\' : \'\'" ng-init="widgetRead(\'mediaHelper\', 1)"><div class="widget-header bordered-bottom bordered-sky"><span class="widget-caption">{{ \'GALLERIES.GALLERIES_PAGE.WIDGET.CAPTION\' | translate }}</span><div class="widget-buttons" ng-if="getWidget(\'mediaHelper\', 1) === true"><a ng-click="toggleWidget(\'mediaHelper\', 1)" widget-expand></a></div><div class="widget-buttons" ng-if="getWidget(\'mediaHelper\', 1) === false"><a ng-click="toggleWidget(\'mediaHelper\', 1)" widget-collapse></a></div></div><div class="widget-body"><span ng-bind-html="(\'GALLERIES.GALLERIES_PAGE.WIDGET.BODY\' | translate)"></span></div></div></div></div><div cg-busy="loading"><div ng-if="showAddGallery" class="text-center">{{ \'GALLERIES.GALLERIES_PAGE.ADD_GALLERY.TITLE\' | translate }}<br><button type="button" class="btn btn-primary mt-10" ng-click="createGallery()">{{ \'GALLERIES.GALLERIES_PAGE.ADD_GALLERY.BUTTON_TEXT\' | translate }}</button></div></div>');
}]);
})();
