/**
 * Media factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.galleries.media
 * @name Media
 * @requires dugun.core:resource
 *
 * @returns {service} Media resource service
 */
function Media(resource) {
    var url = 'galleries/:galleryId/medias/:id';

    var resourceService = resource(
        url,
        {
            galleryId: '@galleryId',
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof Media
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get media list
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Media
             * @name update
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in PUT data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Update media
             */
            update: {
                url: url,
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Media
             * @name insert
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in POST data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Insert media
             */
            insert: {
                url: url,
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof Media
             * @name order
             * @param urlParams {Object=} Data in url
             * @param data {Object=} Data to send
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Orders the media object
             */
            order: {
                url: url + '/move-order',
                method: 'PUT'
            }
        }
    );

    return resourceService;
}

Media.$inject = [
    'resource'
];

angular.module('dugun.galleries.media')
    .factory('Media', Media);
