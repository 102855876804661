/**
 * @memberof dugun.mailTemplates
 * @ngdoc route
 * @name MailTemplatesRoutes
 *
 * @param $routeProvider {provider} $routeProvider
 * @param $q
 *
 * @description /mail-templates
 */
function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function MailTemplatesRoutes($routeProvider) {
    $routeProvider
        .when('/mail-templates', {
            templateUrl: 'mail-templates/list.html',
            controller: 'MailTemplatesListCtrl',
            controllerAs: 'MailTemplatesListCtrl',
            label: 'MAIL_TEMPLATES.TITLE',
            reloadOnSearch: false,
            resolve: {
                init: userResolver
            }
        })
    .when('/mail-templates/:templateId', {
        templateUrl: 'mail-templates/form.html',
        controller: 'MailTemplatesFormCtrl',
        controllerAs: 'MailTemplatesFormCtrl',
        label: 'MAIL_TEMPLATES.TITLE',
        reloadOnSearch: false,
        resolve: {
            init: userResolver
        }
    });
}

MailTemplatesRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.mailTemplates').config(MailTemplatesRoutes);
