/**
 * @ngdoc object
 * @memberof dugun.mailTemplates
 * @name MailTemplatesListCtrl

 * @requires $scope {service} controller scope
 * @requires $route {service} $route
 * @requires $location {service} $location
 * @requires dgNotifications {service} dgNotifications
 * @requires MailTemplates {factory} MailTemplates service
 */
function MailTemplatesListCtrl($scope, $route, $location, dgNotifications,
                      MailTemplates, $translate) {

    $scope.list = [];
    $scope.loading = [];

    $scope.loadList = function () {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.user.customerId) return true;

        var data = {
            customerId: $scope.user.customerId
        };

        var promise = MailTemplates.query(
            data,
            function (successResponse) {
                $scope.list = successResponse.data;
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.openItem = function (param) {
        $location.path('/mail-templates/' + param).search({});
    };

    $scope.delete = function(item) {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.user.customerId) return true;

        var data = {
            templateId: item.id,
            customerId: $scope.user.customerId
        };

        var promise = MailTemplates.delete(
            data,
            function (successResponse) {
                $scope.loadList();
                dgNotifications.success($translate.instant('MAIL_TEMPLATES.TEMPLATE_DELETED'));
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    $scope.loadList();
    $scope.$root.heading.name = $route.current.label;
}

MailTemplatesListCtrl.$inject = [
    '$scope',
    '$route',
    '$location',
    'dgNotifications',
    'MailTemplates',
    '$translate'
];

angular.module('dugun.mailTemplates')
    .controller('MailTemplatesListCtrl', MailTemplatesListCtrl);
