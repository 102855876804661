(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('customers/providers/helpers/general.html',
    '<span ng-bind-html="\'CUSTOMERS.PROVIDER.RELATED_PROVIDERS.HELPER_PAGE.TEXT\' | translate"></span>');
}]);
})();
