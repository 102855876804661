/**
 * @ngdoc directive
 * @name dugun.forms:dgFormCity
 * @restrict 'E'
 * @scope
 **/
function DgFormCity() {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            model: '=ngModel',
            allowClear: '@',
            required: '=ngRequired',
            modelParam: '=modelParam'
        },
        templateUrl: 'dg-forms/form-elements/city/city.html',
        link: function(scope, element, attrs) {
            scope.attrs = attrs;
        }
    };
}

angular.module('dugun.forms').directive('dgFormCity', DgFormCity);
