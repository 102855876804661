function ProvidersDetailsService($q, dgNotifications, dgPopup, Providers) {

    function getProviderDetails(providerId, _scope) {
        return Providers.getAdditionalData({
                id: providerId
            },
            function(successResponse) {
                _scope.details.cleanData = formatDetails(successResponse.data, _scope);
                _scope.details.dirtyData = angular.copy(_scope.details.cleanData);
                _scope.getProviderForm();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    }

    function getProviderForm(providerId, _scope) {
        return Providers.getForm({
                id: providerId,
                status: true
            },
            function(successResponse) {
                _scope.formData = formatFormData(successResponse.data, _scope);
                _scope.setMap();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    }

    function getProviderMissingDetails(provider, _rootScope) {
        var deferred = $q.defer();
        Providers.getMissingAdditionalData({
                id: provider.id
            },
            function(successResponse) {
                if(successResponse.data.length > 0) {
                    _rootScope.missingProviderDetails = {
                        'provider': provider,
                        'details': successResponse.data
                    };
                }
                deferred.resolve();
            },
            function(errorResponse) {
                deferred.reject();
            });

        return deferred.promise;
    }

    function checkProviderMissingDetails(_rootScope) {
        if(!_rootScope.user) {
            return;
        }
        if(_rootScope.user.customerId == 2109) { // stupied customer (╯°□°）╯︵ ┻━┻
            return;
        }

        _rootScope.missingProviderDetails = {};
        _rootScope.providersWithProduct.reduce(function(p, provider) {
            return p.then(function() {
                if(angular.equals({}, _rootScope.missingProviderDetails)) {
                    return getProviderMissingDetails(provider, _rootScope);
                } else {
                    return {};
                }
            });
        }, $q.when(true)).then(function(finalResult) {
            if(!angular.equals({}, _rootScope.missingProviderDetails)) {
                handleProviderMissingDetails();
            }
        }, function(err) {
        });
    }

    function handleProviderMissingDetails() {
        dgPopup.openModal({
            templateUrl: 'customers/providers/provider/missing-details/modal.html',
            controller: 'ProvidersMissingDetailsCtrl'
        });
    }
    /**
     * @ngdoc method
     * @private
     * @memberof ProvidersDetailsService
     * @param details {Array} Array of provider form values.
     * @param _scope angular scope
     * @description
     * Converts the array to key-value _scopeect. Also workaround for checkbox
     * binding problem. See detailsCheckboxes property.
     */
    function formatDetails(details, _scope) {
        var result = {},
            i, j;

        for(i in details) {
            result[details[i].providerFormId] = typeof details[i].value !== 'undefined' ?
                details[i].value : null;
            if(angular.isArray(details[i].value)) {
                _scope.detailsCheckboxes.cleanData[details[i].providerFormId] = {};
                for(j in details[i].value) {
                    _scope.detailsCheckboxes.cleanData[details[i].providerFormId][details[i].value[j]] = true;
                }
            }
        }

        _scope.detailsCheckboxes.dirtyData = angular.copy(_scope.detailsCheckboxes.cleanData);

        return result;
    }

    /**
     * @ngdoc method
     * @private
     * @memberof ProvidersDetailsService
     * @param formData {Array} Array of provider form elements.
     * @description
     * Groups provider form elements by their groupId
     */
    function formatFormData(formData, _scope) {
        var result = [],    // result to return
            groups = {},    // form groups key-value {groupId, indexInResult}
            groupIndex,
            i;

        for(i in formData) {
            groupIndex = groups[formData[i].groupId];
            if(typeof groupIndex === 'undefined') {
                result.push({
                    groupId: formData[i].groupId,
                    items: []
                });
                groupIndex = result.length - 1;
                groups[formData[i].groupId] = groupIndex;
            }

            result[groupIndex].items.push(formData[i]);
            if (formData[i].fieldExtra === 'map') {
                _scope.mapProviderFormId = formData[i].id;
            }
            
            if (formData[i].fieldExtra === 'address') {
                _scope.addressProviderFormId = formData[i].id;
            }
        }

        return result;
    }

    return {
        getProviderDetails: getProviderDetails,
        getProviderForm: getProviderForm,
        checkProviderMissingDetails: checkProviderMissingDetails
    };
}

ProvidersDetailsService.$inject = [
    '$q',
    'dgNotifications',
    'dgPopup',
    'Providers'
];

angular.module('dugun.customers.providers.provider')
    .factory('ProvidersDetailsService', ProvidersDetailsService);