function DiscountImages(resource) {
    var url = 'discounts/:discountId/images';

    var resourceService = resource(
        url,
        {
            discountId: '@discountId'
        },
        {
            query: {
                isArray: false
            }
        }
    );

    return resourceService;
}

DiscountImages.$inject = [
    'resource'
];

angular.module('dugun.discounts').factory('DiscountImages', DiscountImages);
