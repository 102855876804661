function statisticsUserResolver($rootScope, $q) {
    var defer = $q.defer();

    if ($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

statisticsUserResolver.$inject = [
    '$rootScope',
    '$q',
];

function StatisticsRoutes($routeProvider) {
    $routeProvider
        .when('/visits-statistics', {
            templateUrl: 'statistics/visits/visits.html',
            controller: 'VisitsStatisticsCtrl',
            label: 'STATISTICS.VISITS_STATISTICS',
            resolve: {
                init: statisticsUserResolver
            }
        })
        .when('/weddings-statistics', {
            templateUrl: 'statistics/weddings/weddings.html',
            controller: 'WeddingsStatisticsCtrl',
            label: 'STATISTICS.WEDDINGS_STATISTICS',
            resolve: {
                init: statisticsUserResolver
            }
        })
        .when('/magnet-statistics', {
            templateUrl: 'statistics/magnet/magnet.html',
            controller: 'MagnetStatisticsCtrl',
            label: 'STATISTICS.MAGNET_STATISTICS',
            resolve: {
                init: statisticsUserResolver
            }
        });
}

StatisticsRoutes.$inject = [
    '$routeProvider',
];

angular.module('dugun.provider.statistics')
    .config(StatisticsRoutes);