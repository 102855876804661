(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/lead-messages/components/info-request.html',
    '<span><div class="name-circle"><div class="name-text"><span title="{{ leadData.leadDetails.name }}">{{getFirstLetters(leadData.leadDetails.name)}}</span></div></div><div class="name-message"><label ng-if="!leadData.couple.partnerName" ng-bind="leadData.leadDetails.name" class="text-bold" style="display: block"></label><label ng-if="leadData.couple.partnerName" ng-bind="leadData.leadDetails.name + \' & \' + leadData.couple.partnerName" class="text-bold" style="display: block"></label><span ng-if="item.data.extra.comment" ng-bind-html="item.data.extra.comment | nl2br"></span></div></span>');
}]);
})();
