function Statistics(resource, CacheFactory) {
    var url = 'visit-logs';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            query: {
                isArray: false
            },
            activityHistory: {
                url: 'providers/provider-activity-counts',
                method: 'GET',
                cache: getCacheFactory()
            },
            categoryAverages: {
                url: 'visit-logs/providers-category-averages',
                method: 'GET',
                cache: getCacheFactory()
            },
            getVisitLogs: {
                url: 'visit-logs/providers',
                method: 'GET'
            },
            chart: {
                url: 'couple-providers-stats/:id/couples-not-worked-together',
                method: 'GET',
                cache: getCacheFactory()
            },
            coupleProviders: {
                url: 'couple-providers',
                method: 'GET'
            },
            estimate: {
                url: 'couple-providers-stats/estimate',
                method: 'GET'
            },
            coupleVisitLog: {
                url: 'couple-visit-log',
                method: 'GET'
            },
            magnetStatistics: {
                url: 'provider-ad-stats',
                method: 'GET'
            }
        }
    );

    function getCacheFactory() {
        return CacheFactory.get('statsCache') || CacheFactory('statsCache', {
            maxAge: 60*60*1000 // 1 hour
        });
    }

    return resourceService;
}

Statistics.$inject = [
    'resource',
    'CacheFactory',
];

angular.module('dugun.provider.statistics')
    .factory('Statistics', Statistics);
