function LogConfig($provide) {
    $provide.decorator('$exceptionHandler', ExceptionHandlerDecorator);
}

function ExceptionHandlerDecorator($window, $delegate, $injector, environmentConfig) {
    return function(exception, cause) {
        // Calls the original $exceptionHandler.
        $delegate(exception, cause);

        //Custom error handling code here.
        if(environmentConfig.name !== 'test' && environmentConfig.name !== 'production') {
            return;
        }

        var Analytics = $injector.get('Analytics');
        var $route = $injector.get('$route');

        var errorStack = exception.stack.toString();

        Analytics.trackEvent('errors', $route.current.$$route.label, errorStack);
    };
}

LogConfig.$inject = [
    '$provide',
];

ExceptionHandlerDecorator.$inject = [
    '$window',
    '$delegate',
    '$injector',
    'environmentConfig',
];

angular.module('dugun.provider').config(LogConfig);
