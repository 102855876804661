angular.module('dugun.helpers.dgVisible')
    .directive('dgVisible', DgVisible);

function DgVisible() {
    return function (scope, element, attr) {
        scope.$watch(attr.dgVisible, function (visible) {
            element.css('visibility', visible ? 'visible' : 'hidden');
        });
    };
}
