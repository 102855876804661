function AngularLocaleConfig(tmhDynamicLocaleProvider, environmentConfig) {
    tmhDynamicLocaleProvider.localeLocationPattern('https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.7.3/angular-locale_{{locale}}.js');
}

AngularLocaleConfig.$inject = [
    'tmhDynamicLocaleProvider',
    'environmentConfig',
];

angular.module('dugun.provider').config(AngularLocaleConfig);
