(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('tutorials/video.modal.html',
    '<div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="close()">&times;</button><h4 class="modal-title">{{video_title}}</h4></div><div class="modal-body"><iframe width="100%" height="400" ng-src="{{video_url}}" frameborder="0" allowfullscreen></iframe></div>');
}]);
})();
