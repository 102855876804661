(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('login/password-reset/username-forgot.html',
    '<div class="login-container animated fadeInDown" cg-busy="loading"><a href="javascript:void(0);" class="login-logo" style="background-image: url(\'{{ environment.currentLanguage.loginLogo }}\')"></a><div class="loginbox bg-white"><form ng-submit="forgotUsername()"><div class="loginbox-title">{{\'FORGET_USERNAME.HEADER\' | translate}}</div><div class="loginbox-or"><div class="or-line"></div></div><div class="loginbox-or" style="height: auto; padding: 10px 40px">{{\'FORGET_USERNAME.DESCRIPTION\' | translate}}</div><div class="loginbox-or alert alert-warning" style="height: auto; padding: 10px 40px" ng-if="showError" ng-bind-html="\'FORGET_USERNAME.ERROR\' | translate"></div><div class="loginbox-textbox pt-0"><label class="input-sm text-bold mb-0">{{\'FORGET_USERNAME.CHOSE\' | translate}}</label><select class="form-control" ng-model="fieldType"><option value="email">{{\'FORGET_USERNAME.EMAIL\' | translate}}</option><option value="phone">{{\'FORGET_USERNAME.TELEPHONE\' | translate}}</option></select></div><div class="loginbox-textbox pt-0" ng-if="fieldType == \'email\'"><label class="input-sm text-bold mb-0">{{\'FORGET_USERNAME.EMAIL\' | translate}}</label><input type="email" class="form-control" ng-model="fields.email" required></div><div class="loginbox-textbox pt-0" ng-if="fieldType == \'phone\'"><label class="input-sm text-bold mb-0">{{\'FORGET_USERNAME.TELEPHONE\' | translate}}</label><dg-country-phone ng-model="fields.phone" ng-required="true"></dg-country-phone></div><div class="loginbox-submit"><input type="submit" class="btn btn-primary btn-block" value="{{\'FORGET_USERNAME.SEND\' | translate}}" ga-track-event="[\'loginForm\', \'click\', \'forgetPassword\']"></div><div class="loginbox-forgot"><a href="login" ga-track-event="[\'loginForm\', \'click\', \'login\']">{{\'FORGET_USERNAME.GO_TO_LOGIN\' | translate}}</a></div></form></div></div><style type="text/css">html, body, .page-body{\n' +
    '        height: 100%;\n' +
    '        width: 100%;\n' +
    '        min-height: 100%;\n' +
    '        background: url(images/login-bg-2.jpg) no-repeat center center fixed;\n' +
    '        -webkit-background-size: cover;\n' +
    '        -mox-background-size: cover;\n' +
    '        -o-background-size: cover;\n' +
    '        background-size: cover;\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '    .loading-container{\n' +
    '        display: none;\n' +
    '    }</style>');
}]);
})();
