(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('customers/providers/provider/badges/badges.html',
    '<div class="row" cg-busy="loading"><div class="col-lg-12 col-sm-12 col-xs-12"><div class="widget"><div class="widget-header bordered-bottom"><span class="widget-caption">{{ \'CUSTOMERS.PROVIDER.OUR_LINKS\' | translate }}</span><div class="widget-buttons"><a href="javascript:void(0)" widget-collapse=""></a></div></div><div class="widget-body clearfix"><div class="well"><h4>{{ \'CUSTOMERS.PROVIDER.CAN_PUT_OUR_LINKS\' | translate }}</h4><p class="ml-10 mb-0" ng-bind-html="\'CUSTOMERS.PROVIDER.BADGES_HELPER\' | translate"></p></div><span ng-repeat="i in [1, 2, 3, 4, 5, 6]"><div class="row"><div class="col-sm-3 col-xs-4 vcenter text-center"><img ng-src="{{ \'images/badges/badge_\' + i +\'.png\'}}" class="img-responsive" style="display: inline-block"></div><div class="col-sm-6 col-xs-7 vcenter"><textarea id="badge_{{i}}" class="input-sm form-control" readonly="readonly" onclick="this.select()" style="direction: ltr !important;background-color: #fbfbfb !important">&lt;a href="{{environment.domainUrl}}/redirect/provider/{{cleanData.id}}/bl" alt="{{cleanData.name | plainText}} Dugun.com Sayfasi"&gt;&lt;img src="{{environment.domainUrl}}/px/profile/{{cleanData.id}}/{{i}}.png" /&gt;&lt;/a&gt;</textarea></div><div class="col-sm-2 hidden-xs vcenter text-center"><button ng-click="addGtmDatas(i)" class="btn" ngclipboard data-clipboard-action="copy" data-clipboard-target="#badge_{{i}}">{{ \'CUSTOMERS.PROVIDER.COPY_LINK\' | translate }}</button></div></div><hr class="wide" ng-hide="$last"></span></div></div></div></div>');
}]);
})();
