angular.module('dugun.discounts', [
    // vendor
    'angularMoment',
    'toaster',
    'ngFileUpload',
    'dugun.queryString',
    'dugun.notifications',
    'dugun.confirm',
    // app
    'dugun.discounts.providerImages',
    'dugun.discounts.documents',
]);
