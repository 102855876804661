function AnalyticsConfig(AnalyticsProvider, environmentConfig) {
    var $cookies;
    angular.injector(['ngCookies']).invoke(['$cookies', function(_$cookies_) {
        $cookies = _$cookies_;
    }]);
    if($cookies.get('switch_user') && $cookies.get('admin_access_token')) {
        AnalyticsProvider.disableAnalytics(true);
    }

    AnalyticsProvider.setAccount({
        tracker: environmentConfig.googleAnalyticsAppId,
        name: 'tracker',
        trackEvent: true
    });

    AnalyticsProvider.trackPages(false);

    if(environmentConfig.name !== 'production') {
        // AnalyticsProvider.enterDebugMode(true);
    }
}

AnalyticsConfig.$inject = [
    'AnalyticsProvider',
    'environmentConfig',
];

angular.module('dugun.provider').config(AnalyticsConfig);
