/**
 * @memberof dugun.login.setCustomerUser
 * @ngdoc overview
 * @description
 * Module to set customer user
 */
angular.module('dugun.login.setCustomerUser', [
    // vendor
    'ngRoute',
    'toaster'
]);
