function DashboardCtrl($scope, $window, $location, $route, $filter, $sce,
    moment, dgNotifications, $translate,
    Dashboards, Leads
) {

    $scope.$root.heading.name = $route.current.label;
    $scope.dashboards = {
        estimate: {},
        visitLogs: {},
    };
    $scope.leadsStats = {
        categoryAverage: 7,
        providerAverage: 0
    };
    $scope.snowFall = {
        status: parseInt($scope.getConfigItem('panel_snow_fall', 'status'))
    };
    $scope.goToWeddingStatistics = function () {
        if($scope.screenWidth !== 'xs') {
            $location.path('weddings-statistics');
        }
    };
    $scope.dashboards.load = function() {
        if($scope.user) {
            $scope.dashboards.estimate();
            $scope.dashboards.loadResponseTime();
            $scope.dashboards.providerCategoryActivity();
        } else {
            $scope.$watch('user', $scope.dashboards.estimate, true);
            $scope.$watch('user', $scope.dashboards.loadResponseTime, true);
            $scope.$watch('user', $scope.dashboards.providerCategoryActivity, true);
        }
        $scope.dashboards.visitLogs();
    };

    $scope.dashboards.estimate = function () {
        if ($window.getPromiseStatus($scope.dashboards.estimate.loading) === 0) return true;

        var data = [],
            providerIds = [],
            i,
            j;


        for(i in $scope.providersStatisticsAccess) {
            providerIds.push($scope.providersStatisticsAccess[i].id);
        }

        if(providerIds.length === 0) {
            return;
        }

        data['providerIds[]'] = providerIds;
        $scope.providerIds = providerIds;

        $scope.dashboards.estimate.loading = Dashboards.estimate(
            data,
            function (successResponse) {
                $scope.dashboards.estimate.data = successResponse.data;
                $scope.dashboards.estimate.approximateRevenue = 0;
                $scope.dashboards.estimate.approximateRevenueFormula = '';
                $scope.dashboards.estimate.totalCouple = 0;
                for(i in $scope.dashboards.estimate.data) {
                    $scope.dashboards.estimate.totalCouple += $scope.dashboards.estimate.data[i].totalCouple;
                    $scope.dashboards.estimate.approximateRevenue += $scope.dashboards.estimate.data[i].approximateRevenue;
                    if($scope.dashboards.estimate.data[i].approximateRevenueFormula) {
                        for(j in $scope.providersActive) {
                            if($scope.dashboards.estimate.data[i].providerId == $scope.providersActive[j].id) {
                                if($scope.dashboards.estimate.approximateRevenueFormula !== '') {
                                    $scope.dashboards.estimate.approximateRevenueFormula += '<br>';
                                }
                                $scope.dashboards.estimate.approximateRevenueFormula += $sce.trustAsHtml('<strong>' + $scope.providersActive[j].name.replace(/['"<>]/g, ' ') + ' - ' + $scope.getProviderCategory($scope.providersActive[j].categoryId) + ' / ' + $scope.getCity($scope.providersActive[j].cityId) + '</strong> ' + 'için ' + $scope.dashboards.estimate.data[i].approximateRevenueFormula + '<br>');
                            }
                        }
                    }
                }
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });
    };

    $scope.dashboards.loadResponseTime = function () {
        if($window.getPromiseStatus($scope.dashboards.loadingResponseTime) === 0) return true;
        $scope.dashboards.loadingResponseTime = Leads.providerAverageResponseTime(
            function(successResponse) {
                $scope.leadsStats.providerAverage = successResponse.data;
                if ($scope.leadsStats.providerAverage) {
                    $scope.leadsStats.providerAverage = Math.floor($scope.leadsStats.providerAverage / 60);
                }
                dataLayer.push({
                    customer: {
                        averageResponse: $scope.leadsStats.providerAverage
                    }
                });
            },
            function(response) {
                dgNotifications.error(response);
            });

    };

    $scope.dashboards.visitLogs = function () {
        if ($window.getPromiseStatus($scope.dashboards.visitLogs.loading) === 0) return true;

        var data = {};

        data.dateEnd = moment().add(-1, 'days').format('YYYY-MM-DD');
        data.dateStart = moment().add(-1, 'month').format('YYYY-MM-DD');

        $scope.dashboards.visitLogs.loading = Dashboards.visitLogs(
            data,
            function (successResponse) {
                $scope.dashboards.visitLogs.data = fillMissingDates(successResponse.data, data.dateStart, data.dateEnd);
                $scope.dashboards.visitLogs.totals = successResponse.totals;
                $scope.dashboards.visitLogs.generate();
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        function fillMissingDates(data, fromDate, toDate) {
            var newData = angular.copy(data);
            if(newData.length > 0) {
                var firstDate = moment(newData[0].visitDate).format('YYYY-MM-DD');
                var newPoint = angular.copy(newData[0]);
                while(firstDate !== fromDate) {
                    firstDate = moment(firstDate).add(-1, 'day').format('YYYY-MM-DD');
                    newPoint.day = moment(firstDate).format('D');
                    newPoint.inboundCalls = 0;
                    newPoint.infoRequest = 0;
                    newPoint.profileView = 0;
                    newPoint.visitDate = firstDate;
                    newData = [angular.copy(newPoint)].concat(newData);
                }
            }
            return newData;
        }
    };

    $scope.dashboards.visitLogs.generate = function() {
        var rawData = $scope.dashboards.visitLogs.data,
            chartDataGrouped = {},
            chartData = [],
            row = null,
            typeId = 'profileView',
            i,
            providerId = null,
            totalLeads = 0,
            totalInfoRequests = 0,
            totalInboundCalls = 0,
            totalPageViews = 0,
            totalsByProvider = {},
            daysCount = 0;

        // We clear the data array to hide chart. It should be reloaded after
        // data change.
        $scope.dashboards.visitLogs.dataDisplayed = [];

        // Generate data for selected typeId, filter providers, get totals
        for(i in rawData) {
            if(providerId && rawData[i].providerId !== providerId) continue;
            row = {
                visitDate: moment(rawData[i].visitDate).format('YYYY-MM-DD'),
                profileView: rawData[i].profileView,
                infoRequest: rawData[i].infoRequest,
                inboundCalls: rawData[i].inboundCalls
            };
            chartData.push(row);

            totalInfoRequests += rawData[i].infoRequest;
            totalInboundCalls += rawData[i].inboundCalls;
            totalPageViews += rawData[i].profileView;
            totalLeads += rawData[i].infoRequest + rawData[i].inboundCalls;
        }

        // Get sums by date
        for(i in chartData) {
            if(typeof chartDataGrouped[chartData[i].visitDate] === 'undefined') {
                chartDataGrouped[chartData[i].visitDate] = {
                    visitDate: chartData[i].visitDate,
                    profileView: 0,
                    infoRequest: 0,
                    inboundCalls: 0
                };
            }

            chartDataGrouped[chartData[i].visitDate].profileView += chartData[i].profileView;
            chartDataGrouped[chartData[i].visitDate].infoRequest += chartData[i].infoRequest;
            chartDataGrouped[chartData[i].visitDate].inboundCalls += chartData[i].infoRequest;
        }

        // Empty the array,
        // New values are on the way!
        chartData = [];

        // Revert it back to array form
        for(i in chartDataGrouped) {
            chartData.push(chartDataGrouped[i]);
        }

        daysCount = chartData.length;

        $scope.dashboards.visitLogs.dataDisplayed = chartData;

        // Set the displayed totals
        $scope.dashboards.visitLogs.totalsDisplay = {
            daysCount: daysCount,
            totalLeads: totalLeads,
            totalInfoRequests: totalInfoRequests,
            totalInboundCalls: totalInboundCalls,
            totalPageViews: totalPageViews
        };

        $scope.dashboards.visitLogs.totalsByProvider = totalsByProvider.toArray();
    };

    $scope.dashboards.visitLogs.formatDate = function(date) {
        return moment(date).format('DD MMMM YYYY');
    };

    $scope.dashboards.providerCategoryActivity = function () {
        if ($window.getPromiseStatus($scope.dashboards.providerCategoryActivity.loading) === 0) return true;

        var data = [],
            providerIds = [],
            i;

        data.createdAtStart = moment().add(-1, 'month').format('YYYY-MM-DD');
        if($scope.user.providerCitiesIds.length > 0) {
            data['cityIds[]'] = $scope.user.providerCitiesIds;
        }

        $scope.dashboards.providerCategoryActivity.loading = Dashboards.providerCategoryActivity(
            data,
            function (successResponse) {
                $scope.dashboards.providerCategoryActivity.data = successResponse.data;
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });
    };

    $scope.getActivityDetails = function(item) {

        var activity = {
            providerName: item.providerName,
            providerUrl: item.providerUrl,
            date: moment(item.createdAt).calendar(null, {
                sameDay: '[' + $translate.instant('GENERAL.TODAY') + ']',
                nextDay: '[' + $translate.instant('GENERAL.TOMORROW') + ']',
                nextWeek: 'DD MMMM YYYY',
                lastDay: '[' + $translate.instant('GENERAL.YESTERDAY') + ']',
                lastWeek: 'DD MMMM YYYY',
                sameElse: 'DD MMMM YYYY'
            }),
            time: moment(item.createdAt).format('LT'),
            activities: [],
        };

        if(item.aboutUsChangeCount > 0) {
            activity.activities.push({ text : $translate.instant('DASHBOARDS.ABOUT_US_TEXT_CHANGED'), icon : 'fa-file' });
        }

        if(item.discountStartCount > 0) {
            activity.activities.push({ text : $translate.instant('DASHBOARDS.ANNOUNCE_NEW_CAMPAIGN'), icon : 'fa-tags' });
        }

        if(item.dopingStartCount > 0) {
            activity.activities.push({ text : $translate.instant('DASHBOARDS.USED_DOPING_PRODUCT'), icon : 'fa-bookmark' });
        }

        if(item.galleryImageCount > 0) {
            activity.activities.push({ text : $translate.instant('DASHBOARDS.ADDED_IMAGE_COUNT', {count: item.galleryImageCount}), icon : 'fa-camera' });
        }

        if(item.testimonialsCount > 0) {
            activity.activities.push({ text : $translate.instant('DASHBOARDS.ADDED_TESTIMONIALS_COUNT', {count: item.testimonialsCount}), icon : 'fa-comments' });
        }

        return activity;
    };

    $scope.dashboards.load();

    $scope.$root.tutorials = [
        // {
        //     type: 'video',
        //     data: {
        //         video_title: 'Düğün.com Yönetim Paneline Hoşgeldiniz!',
        //         video_code: 'Q7LXpKFBWqY'
        //     }
        // }
    ];
}

DashboardCtrl.$inject = [
    '$scope',
    '$window',
    '$location',
    '$route',
    '$filter',
    '$sce',
    'moment',
    'dgNotifications',
    '$translate',
    'Dashboards',
    'Leads'
];

angular.module('dugun.dashboards')
    .controller('DashboardCtrl', DashboardCtrl);
