(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dashboards/snowflakes.html',
    '<div class="snowflakes" aria-hidden="true"><div class="snowflake" ng-repeat="i in [1,2,3,4,5,6,7,8,9,10,11,12,13]">❅</div></div><style>.snowflake {\n' +
    '        color: #fff;\n' +
    '        font-size: 1.5em;\n' +
    '        font-family: Arial, sans-serif;\n' +
    '        text-shadow: 0 0 4px #82868b;\n' +
    '    }</style>');
}]);
})();
