/**
 * @memberof dugun.provider
 */

angular.module('dugun.provider', [
    // vendor
    'ngRoute',
    'ngSanitize',
    'ngAnimate',
    'ngStorage',
    'ngCookies',
    'ngResource',
    'ngTouch',
    'ui.bootstrap',
    'ui.select',
    'ui.utils',
    'ui.bootstrap.datepicker',
    'cgBusy',
    'daterangepicker',
    'beyond',
    'ngCsv',
    'angular-cache', // for localStorage cache
    'toaster', // toaster notifications wrapper for angular
    'angular.morris', // morris charts, angular wrapper
    'angularMoment', // momentjs wrapper for angular
    'angular-google-analytics', // Google Analytics wrapper for angular
    'dugun.popup', // modal opener
    'firebase',
    'ngclipboard', // copy to clipboard
    'dugun.forms',
    'facebook', // https://github.com/Ciul/angular-facebook
    'googleplus', // https://github.com/mrzmyr/angular-google-plus
    'videosharing-embed', // https://github.com/erost/ng-videosharing-embed
    'luegg.directives', // https://github.com/Luegg/angularjs-scroll-glue
    'checklist-model', // https://vitalets.github.io/checklist-model
    'angulartics', // https://github.com/angulartics/angulartics
    'angulartics.facebook.pixel', // https://www.npmjs.com/package/angulartics-facebook-pixel
    'textAngular', // https://github.com/textAngular/textAngular
    'pascalprecht.translate',
    'internationalPhoneNumber',
    'tmh.dynamicLocale', // https://github.com/lgalfaso/angular-dynamic-locale
    'ngCropper',
    // helpers
    'dugun.helpers.dgVisible',
    'dugun.helpers.dgSentenceCase',
    'dugun.helpers.dgConvertToNumbers',
    // app
    'dugun.core',
    'dugun.filters',
    'dugun.login',
    'dugun.customers',
    'dugun.customers.customerUsers',
    'dugun.dashboards',
    'dugun.payments',
    'dugun.testimonials',
    'dugun.testimonials.request',
    'dugun.provider.tutorials',
    'dugun.discounts',
    'dugun.leads',
    'dugun.attachments',
    'dugun.signatures',
    'dugun.mailTemplates',
    'dugun.provider.statistics',
    'dugun.galleries',
    'dugun.agreements',
    'dugun.relatedProviders',
    'dugun.provider.home',
    'dugun.provider.templates',
    'dugun.provider.permissions',
    'dugun.provider.upgrade',
    'dugun.items',
    'dugun.collection',
    'dugun.customerMenu'
]);
