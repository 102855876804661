function VideoTutorialCtrl($scope, $uibModalInstance, $sce, data) {

    $scope.video_url = $sce.trustAsResourceUrl('https://www.youtube.com/embed/' + data.video_code + '?rel=0');
    $scope.video_title = data.video_title;

    $scope.close = function() {
        $uibModalInstance.dismiss('cancel');
    };
}

VideoTutorialCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$sce',
    'data'
];

angular.module('dugun.provider.tutorials')
    .controller('VideoTutorialCtrl', VideoTutorialCtrl);