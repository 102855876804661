/**
 * @memberof dugun.galleries.media.upload
 * @ngdoc overview
 * @description
 * Image upload module
 */
angular.module('dugun.galleries.media.upload', [
    // vendor
    'dugun.notifications',
    'ngFileUpload',
]);
