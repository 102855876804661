/**
 * @ngdoc directive
 * @name dugun.forms:dgCountryPhone
 * @restrict 'E'
 * @scope
 **/
function DgCountryPhone() {
    return {
        restrict: 'E',
        scope: {
            model: '=ngModel',
            allowClear: '@',
            required: '=ngRequired',
            disabled: '=ngDisabled',
            placeholder: '@',
            id: '@dgId',
            warningMessage: '=warningMessage'
        },
        templateUrl: 'dg-forms/form-elements/country-phone/country-phone.html',
        link: function(scope, element, attrs) {
            if (attrs.isCustomerUser) {
                scope.isCustomerUser = attrs.isCustomerUser;
            }

            if (scope.model) {
                scope.model = scope.model.replace(/\s/g, '');
            }

            scope.$watch('model', function () {
                if (typeof scope.model == 'undefined') {
                    scope.isValid = false;
                } else {
                    scope.model = '+' + scope.model.replace('+', '');
                    scope.isValid = true;
                }
            });
        }
    };
}

DgCountryPhone.$inject = [];

angular.module('dugun.forms')
    .directive('dgCountryPhone', DgCountryPhone);
