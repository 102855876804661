/**
 * DiscountDocuments factory
 * @ngdoc factory
 * @memberof dugun.discounts.documents
 * @name DiscountDocuments
 *
 * @requires dugun.core:resource
 *
 * @returns {service} DiscountDocuments resource service
 */
function DiscountDocuments(resource) {
    var self = this;

    self.url = 'discounts/:discountId/documents/:id';

    self.resourceService = resource(
        self.url,
        {
            id: '@id',
            discountId: '@discountId'
        },
        {
            /**
             * @ngdoc method
             * @memberof DiscountDocuments
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get list
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof DiscountDocuments
             * @name delete
             * @param params {Object} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Insert
             */
            delete: {
                method: 'DELETE'
            }
        }
    );

    return self.resourceService;
}

DiscountDocuments.$inject = [
    'resource',
];

angular.module('dugun.discounts.documents')
    .factory('DiscountDocuments', DiscountDocuments);
