/**
 * LeadInteractions factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.leads
 * @name LeadInteractions
 * @requires dugun.core:resource
 *
 * @returns {service} LeadInteractions resource service
 */
function LeadInteractions(resource, moment) {
    var url = 'leads/:leadId/interactions/:interactionId';

    var resourceService = resource(
        url,
        {
            leadId: '@leadId',
            interactionId: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof LeadInteractions
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get all info requests of provider
             */
            query: {
                isArray: false,
                transformResponse: function (response) {
                    var result = angular.copy(response.data);
                    angular.forEach(result, function (value) {
                        value.formattedExtraData = moment(value.formattedExtraData).format('LLL');
                    });
                    response.data = result;

                    return response;
                }
            },
            /**
             * @ngdoc method
             * @memberof LeadInteractions
             * @name insert
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in POST data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Insert lead-interactions
             */
            insert: {
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof LeadInteractions
             * @name update
             * @param params {Object} Parameters to send in url
             * @param params {Object} Parameters to send in PUT data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * update lead-interactions
             */
            update: {
                method: 'PUT'
            }
        }
    );

    return resourceService;
}

LeadInteractions.$inject = [
    'resource',
    'moment'
];

angular.module('dugun.leads')
    .factory('LeadInteractions', LeadInteractions);
