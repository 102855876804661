function VisitsStatisticsCtrl($scope, $route, $window, $filter, $q, $sce,
                              dgNotifications, moment, queryString,
                              Statistics, Providers, $translate) {
    var self = this;

    $scope.yesterday = new Date(moment().add(-1, 'day'));
    /**
     * @memberOf VisitsStatisticsCtrl
     * @description
     * Tabs in view. Active status is stored here.
     */


    $scope.visits = {
        chart: {
            typeId: 'profileView'
        },
        logs: {
            data: [],
            averages: {
                totalProviders: 0,
                profileView: 0,
                leads: 0,
                leadsConversion: 0,
                coupleProviderCount: 0
            },
            estimate: {},
            loading: []
        },
        products: {},
        filters: {
            providerId: $scope.providersStatisticsAccess[0].id
        },
        coupleLogs: {
            pagination: {
                perPage: 25,
                currentPage: 1
            },
            data: [],
            loading: []
        }
    };

    /**
     * @ngdoc method
     * @memberof VisitsStatisticsCtrl
     * @description
     * Gets the products of the provider
     */
    $scope.getProducts = function() {
        if(!$scope.visits.filters.providerId) {
            return;
        }

        $scope.visits.loading = Providers.getProducts(
            {
                providerId: $scope.visits.filters.providerId,
                activeDate: moment().format('YYYY-MM-DD')
            },
            function(successResponse) {
                $scope.visits.products.data = successResponse.data;
                setProductDate($scope.visits.products.data);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof VisitsStatisticsCtrl
     * @private
     * @description
     * set the active Product Dates to filters
     */
    function setProductDate(products) {
        var active = [],
            i;
        for(i in products) {
            if(providerProductIsActive(products[i])) {
                if(products[i].productPrice.product.productFeature.listingTypeId) {
                    active.push(products[i]);
                }
            }
        }

        $scope.isProviderChanged = true;

        if(active.length > 0) {
            $scope.visits.filters.endDate = moment().format('YYYY-MM-DD');
            $scope.visits.filters.startDate = moment(active[0].dateStart).format('YYYY-MM-DD');

            $scope.visits.date = {
                startDate: moment(active[0].dateStart).format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            };
        } else {
            $scope.visits.filters.endDate =  moment().add(-1, 'days').format('YYYY-MM-DD');
            $scope.visits.filters.startDate = moment().add(-1, 'months').format('YYYY-MM-DD');
            $scope.visits.date = {
                startDate: moment().add(-1, 'months').format('YYYY-MM-DD'),
                endDate: moment().add(-1, 'days').format('YYYY-MM-DD')
            };
        }

        $scope.visits.load();
    }

    /**
     * @ngdoc method
     * @memberof VisitsStatisticsCtrl
     * @description
     * Checks if provider product is active
     */
    function providerProductIsActive(product) {
        if(!product.status) return false;

        if(!product.dateStart || !product.dateEnd) return false;

        if(moment().isAfter(product.dateEnd)) {
            return false;
        }

        if(moment().isBefore(product.dateStart)) {
            return false;
        }

        return true;
    }

    $scope.getProducts();

    $scope.selectedProviderId = null;

    $scope.filters = queryString.getFilters() || {};

    $scope.dates = queryString.getDatesFromFilters('date', moment().startOf('month'), moment());

    $scope.orderBy = {
        key: 'leads',
        direction: true
    };

    $scope.visits.chart.types = {
        dugun: [],
        zafaf: []
    };

    $scope.visits.chart.selectedType = 'profileView';
    $scope.visits.chart.changeType = function (type) {
        $scope.visits.chart.generate();
    };

    $scope.visits.load = function() {
        var chartLoading = $scope.visits.chart.load(),
            chartCompareLoading;

        $scope.visits.chart.dataCompare = null;

        if($scope.visits.chart.compare) {
            chartCompareLoading = $scope.visits.chart.loadCompare();
            $q.all([
                chartLoading,
                chartCompareLoading
            ]).then(function() {
                $scope.visits.chart.generate();
            });
        } else {
            $q.all([
                chartLoading
            ]).then(function() {
                $scope.visits.chart.generate();
            });
        }

        if ($scope.environment.fieldsSettings.coupleProviderStatsEstimate) {
            $scope.visits.logs.populateData();
        } else {
            $scope.visits.logs.load();
            $scope.visits.coupleLogs.load();
        }
    };

    $scope.visits.logs.populateData = function () {
        var data = [];

        if($scope.user === null) {
            return;
        }

        data['providerIds[]'] = [$scope.visits.filters.providerId];

        $scope.visits.logs.loading = Statistics.estimate(
            data,
            function (successResponse) {
                $scope.visits.logs.estimate = successResponse.data;
                $scope.visits.logs.load();
                $scope.visits.coupleLogs.load();
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });
    };

    $scope.sortBy = function(key) {
        if($scope.orderBy.key === key) {
            $scope.orderBy.direction = !$scope.orderBy.direction;
        } else {
            $scope.orderBy.key = key;
            $scope.orderBy.direction = false;
        }

        $scope.visits.logs.load();
    };

    $scope.visits.logs.load = function() {
        var providerId = $scope.visits.filters.providerId,
            i, providerBudgetLevel,
            data = {
                dateStart: $scope.visits.date.startDate,
                dateEnd: $scope.visits.date.endDate,
            };

        $scope.visits.logs.data = [];

        if(!providerId) {
            return;
        }

        data['categoryIds[]'] = [$scope.getProvider(providerId).categoryId];
        data['cityIds[]'] = [$scope.getProvider(providerId).cityId];

        data.sort = $scope.orderBy.key;
        data.order = $scope.orderBy.direction ? 'DESC' : 'ASC';

        for(i in $scope.visits.logs.estimate) {
            if(providerId == $scope.visits.logs.estimate[i].providerId) {
                providerBudgetLevel = $scope.visits.logs.estimate[i].budgetLevel;
            }
        }

        if ($scope.environment.fieldsSettings.coupleProviderStatsEstimate) {
            if(providerBudgetLevel) {
                data['budgetLevels[]'] = [providerBudgetLevel];
            } else {
                return;
            }
        }

        $scope.visits.logs.loading = Statistics.getVisitLogs(
            data,
            function(successResponse) {
                var tempList = [],
                    tempProviderObject = {};
                $scope.visits.logs.averages.totalProviders = successResponse.data.length;
                $scope.visits.logs.averages.profileView = 0;
                $scope.visits.logs.averages.leads = 0;
                $scope.visits.logs.averages.leadsConversion = 0;
                $scope.visits.logs.averages.coupleProviderCount = 0;
                angular.forEach(successResponse.data, function(provider, providerKey) {
                    $scope.visits.logs.averages.profileView += provider.profileView;
                    $scope.visits.logs.averages.leads += provider.leads;
                    $scope.visits.logs.averages.leadsConversion += provider.leadsConversion;
                    $scope.visits.logs.averages.coupleProviderCount += provider.coupleProviderCount;
                    if(providerKey < 10) {
                        tempList.push(provider);
                    }
                    if (provider.provider.id == providerId) {
                        tempProviderObject = provider;
                        tempProviderObject.orderNumber = providerKey + 1;
                    }
                });
                angular.forEach(tempList, function(provider, providerKey) {
                    if($scope.visits.logs.data.indexOf(provider) == -1 && $scope.visits.logs.data.length < 10){
                        if(angular.equals(tempProviderObject, {})) {
                            provider.orderNumber = angular.copy($scope.visits.logs.data.length) + 1;
                            $scope.visits.logs.data.push(provider);
                        } else {
                            if(provider.provider.id == tempProviderObject.provider.id) {
                                tempProviderObject.orderNumber = angular.copy($scope.visits.logs.data.length) + 1;
                                $scope.visits.logs.data.push(tempProviderObject);
                                tempProviderObject = {};
                            } else {
                                if($scope.orderBy.direction) {
                                    if (provider[data.sort] <= tempProviderObject[data.sort]) {
                                        tempProviderObject.orderNumber = angular.copy($scope.visits.logs.data.length) + 1;
                                        $scope.visits.logs.data.push(tempProviderObject);
                                        tempProviderObject = {};
                                    }
                                }
                                if($scope.visits.logs.data.length < 10) {
                                    provider.orderNumber = angular.copy($scope.visits.logs.data.length) + 1;
                                    $scope.visits.logs.data.push(provider);
                                }
                            }
                        }
                    }
                });
                if(!angular.equals(tempProviderObject, {})) {
                    $scope.visits.logs.data.push(tempProviderObject);
                    tempProviderObject = {};
                }

                if ($scope.visits.filters.providerId) {
                    var provider = $filter('filter')($scope.providersActive, {id: $scope.visits.filters.providerId}, true)[0];
                    dataLayer.push(
                        {
                            event: 'statisticsProviderVisit',
                            visitProviders: {
                                id: provider.id,
                                name: provider.name,
                                category: $scope.getProviderCategory(provider.categoryId),
                                city: $scope.getCity(provider.cityId)
                            }
                        }
                    );
                }
            },
            function(errorResponse) {
                switch (errorResponse.status){
                    case 504:
                        dgNotifications.error('STATISTICS.ERRORS.ANOTHER_DATE_RANGE');
                        break;
                }
                dgNotifications.error(errorResponse);
            }
        );
    };

    $scope.visits.chart.load = function (filters) {
        if ($window.getPromiseStatus($scope.visits.chart.loading) === 0) return true;
        var deferred = $q.defer();

        var data = {
            dateStart: $scope.visits.date.startDate,
            dateEnd: $scope.visits.date.endDate,
        };

        $scope.selectedProviderId = $scope.visits.filters.providerId;

        $scope.visits.chart.loading = Statistics.query(
            data,
            function (successResponse) {
                $scope.visits.chart.data = successResponse.data;
                $scope.visits.chart.totals = successResponse.totals;
                deferred.resolve();
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
                deferred.reject();
            });

        return deferred.promise;
    };

    /**
     * @ngdoc method
     * @memberof VisitsStatisticsCtrl
     * @description
     * Loads the second line if compare exists
     */
    $scope.visits.chart.loadCompare = function() {
        if ($window.getPromiseStatus($scope.visits.chart.loadingCompare) === 0) return true;
        var data = angular.copy($scope.visits.filters),
            deferred = $q.defer();

        if(data.providerId) delete data.providerId;
        delete data.dateStart;
        delete data.dateEnd;
        data.dateStart = $scope.visits.chart.compareDates.startDate;
        data.dateEnd = $scope.visits.chart.compareDates.endDate;

        $scope.visits.chart.loadingCompare = Statistics.query(
            data,
            function (successResponse) {
                $scope.visits.chart.dataCompare = successResponse.data;
                deferred.resolve();
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
                deferred.reject();
            });

        return deferred.promise;
    };

    $scope.visits.chart.generate = function() {
        var typeId = $scope.visits.chart.selectedType,
            rawData = $filter('filter')($scope.visits.chart.data, {providerId: $scope.visits.filters.providerId}),
            rawCompareData,
            i;
        $scope.visits.chart.totalsDisplay = {
            daysCount: 0,
            totalLeads: 0,
            totalInfoRequests: 0,
            totalInboundCalls: 0,
            totalPageViews: 0
        };

        if ($scope.visits.chart.dataCompare) {
            rawCompareData = $filter('filter')($scope.visits.chart.dataCompare, {providerId: $scope.visits.chart.dataCompare.providerId}, true);
        }

        // We clear the data array to hide chart. It should be reloaded after
        // data change.
        $scope.visits.chart.dataDisplayed = [];

        // Generate data for selected typeId, filter providers, get totals
        for(i in rawData) {
            $scope.visits.chart.dataDisplayed.push(
                {
                    visitDate: moment(rawData[i].visitDate).format('YYYY-MM-DD'),
                    value: 0
                }
            );

            if(typeId == 'defence') {
                $scope.visits.chart.dataDisplayed[i].value += (rawData[i].infoRequest + rawData[i].inboundCalls) - rawData[i].plusInfoRequestCount;
            } else if (typeId == 'leads') {
                $scope.visits.chart.dataDisplayed[i].value += rawData[i].infoRequest + rawData[i].inboundCalls;
            } else {
                $scope.visits.chart.dataDisplayed[i].value += rawData[i][typeId];
            }

            $scope.visits.chart.totalsDisplay.totalInfoRequests += rawData[i].infoRequest;
            $scope.visits.chart.totalsDisplay.totalInboundCalls += rawData[i].inboundCalls;
            $scope.visits.chart.totalsDisplay.totalPageViews += rawData[i].profileView;
            $scope.visits.chart.totalsDisplay.totalLeads += rawData[i].infoRequest + rawData[i].inboundCalls;
        }

        /* Compare */
        if(rawCompareData) {
            for(i in rawCompareData) {
                $scope.visits.chart.dataDisplayed[i].value2 += rawCompareData[i][typeId];
                $scope.visits.chart.dataDisplayed[i].visitDate2 = moment(rawCompareData[i].visitDate).format('YYYY-MM-DD');
            }
        }

        $scope.visits.chart.dataDisplayed = $filter('orderBy')($scope.visits.chart.dataDisplayed, 'visitDate');
        $scope.visits.chart.totalsDisplay.daysCount = $scope.visits.chart.dataDisplayed.length;
    };

    $scope.visits.chart.formatDate = function(date) {
        if(typeof date === 'object' && date.label) {
            return moment(date.label).format('DD MMMM YYYY');
        } else {
            return moment(date).format('DD MMMM YYYY');
        }
    };

    $scope.visits.chart.formatPoint = function(y) {
        return y != Math.round(y) ? '' : y;
    };

    $scope.$watch('visits.filters.providerId', function (newValue, oldValue) {
        if(newValue !== oldValue) {
            $scope.getProducts();
        }

        $scope.visits.chart.types[$scope.environment.code] = [];
        if ($scope.user.providersProductFeatures[$scope.visits.filters.providerId].recommendedPriority) {
            $scope.visits.chart.types[$scope.environment.code].push(
                {
                    itemId: 'profileView',
                    value: $translate.instant('STATISTICS.PROFILE_VIEW')
                },
                {
                    itemId: 'defence',
                    value: $translate.instant('STATISTICS.COUPLE_DEFENSE')
                },
                {
                    itemId: 'plusInfoRequestCount',
                    value: $translate.instant('STATISTICS.COUPLE_ATTACK')
                }
            );
        } else {
            $scope.visits.chart.types[$scope.environment.code].push(
                {
                    itemId: 'profileView',
                    value: $translate.instant('STATISTICS.PROFILE_VIEW')
                },
                {
                    itemId: 'leads',
                    value: $translate.instant('STATISTICS.COUPLE')
                }
            );
        }

        $scope.visits.chart.types.zafaf.push(
            {
                itemId: 'phoneView',
                value: $translate.instant('STATISTICS.PHONE_VIEW')
            }
        );
    });

    $scope.$watch('visits.date', visitsDateChanged, true);

    function visitsDateChanged(newValue) {
        if(!newValue) {
            return;
        }

        var diffDays = null;

        if(newValue.startDate && newValue.endDate) {
            var startDate = moment(newValue.startDate);
            var endDate = moment(newValue.endDate);
            diffDays = endDate.diff(startDate, 'days');
        }

        $scope.visits.diffDays = diffDays;
    }

    $scope.visits.coupleLogs.load = function () {
        if(!$scope.visits.filters.providerId) {
            return;
        }

        var data = {
            providerId: $scope.visits.filters.providerId,
            startDate: moment($scope.visits.filters.startDate).format('YYYY-MM-DD'),
            endDate: moment($scope.visits.filters.endDate).format('YYYY-MM-DD'),
        };

        data.page = $scope.visits.coupleLogs.pagination.currentPage;
        data.limit = $scope.visits.coupleLogs.pagination.perPage;

        $scope.visits.coupleLogs.data = [];

        $scope.visits.coupleLogs.loading = Statistics.coupleVisitLog(
            data,
            function(successResponse) {
                $scope.visits.coupleLogs.pagination = successResponse.meta;
                $scope.visits.coupleLogs.data = successResponse.coupleVisitLogs;
                var provider = $filter('filter')($scope.providersActive, {id: $scope.visits.filters.providerId}, true)[0];
                dataLayer.push(
                    {
                        event: 'getProvider',
                        provider: {
                            id: provider.id,
                            name: provider.name,
                            category: $scope.getProviderCategory(provider.categoryId),
                            city: $scope.getCity(provider.cityId)
                        }
                    }
                );
            });
    };

    $scope.$watch('visits.coupleLogs.pagination.currentPage', function (newValue, oldValue) {
        if (newValue != oldValue) {
            $scope.visits.coupleLogs.load();
        }
    });

    $translate.onReady().then(function () {
        if ($translate.instant('STATISTICS.VISITS.HELPER_PAGE.IS_EXIST') == 'true') {
            $scope.$root.tutorials.push(
                {
                    type: 'page',
                    data: {
                        page_title: $translate.instant('STATISTICS.VISITS.HELPER_PAGE.PAGE_TITLE'),
                        page_url: 'statistics/visits/helper.html'
                    }
                }
            );
        }
    });

    $scope.$root.heading.name = $route.current.label;
}

VisitsStatisticsCtrl.$inject = [
    '$scope',
    '$route',
    '$window',
    '$filter',
    '$q',
    '$sce',
    'dgNotifications',
    'moment',
    'queryString',
    'Statistics',
    'Providers',
    '$translate'
];

angular.module('dugun.provider.statistics')
    .controller('VisitsStatisticsCtrl', VisitsStatisticsCtrl);
