(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/warning-popup/content.html',
    '<div class="modal-header"><i class="fa fa-exclamation-triangle orange fs-18"></i></div><div class="modal-body"><div class="warning"><ul><li ng-bind-html="\'LEADS.MAX_LEAD_LIMIT_WARNING\' | translate"></li><li ng-bind-html="\'LEADS.CONTACT_CUSTOMER_SERVICE_WARNING\' | translate"></li></ul><div class="text-center"><button class="btn btn-primary" ng-click="return;">{{ \'LEADS.UPGRADE_BUTTON_TEXT\' | translate }}</button></div></div></div><style>.warning {\n' +
    '        font-size: 16px;\n' +
    '        color: #606060 !important;\n' +
    '        border: 2px solid orange;\n' +
    '        padding: 10px;\n' +
    '    }</style>');
}]);
})();
