(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('customer-menu/menu-section.html',
    '<form ng-submit="setSectionsItems()"><div class="row"><div class="col-xs-12 mb-10"><button type="button" class="btn btn-default pull-right icon-only btn-sm" ng-click="collapseSections(selectedTab.data, collapsedSections.length ? \'down\' : \'up\')" ng-if="showCollapseButton(selectedTab.data)"><i class="fa fa-expand gray"></i></button></div><div class="col-xs-12"><div class="widget" ng-repeat="menuSection in selectedTab.data"><div class="widget-header bordered-bottom"><span class="widget-caption" style="cursor: pointer" ng-if="selectedTab.properties.fieldType === \'radio\'" ng-click="checkSection(menuSection, selectedTab.groupId)"><button class="icon-only btn btn-default btn-xs" type="button" style="border-radius: 13px" ng-class="{\n' +
    '                                \'btn-default\': !dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id],\n' +
    '                                \'btn-success\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id]\n' +
    '                            }"><i class="fa" aria-hidden="true" ng-class="{\'fa-check\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id]}"></i></button> {{ menuSection.name }} </span><span class="widget-caption" ng-click="checkSections(menuSection, selectedTab.groupId)" style="cursor: pointer" ng-if="selectedTab.properties.fieldType !== \'radio\'"><button class="icon-only btn btn-xs" type="button" ng-class="{\n' +
    '                                \'btn-success\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id],\n' +
    '                                \'btn-default\': !dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id]\n' +
    '                            }"><i class="fa" aria-hidden="true" ng-class="{\'fa-check\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id]}"></i></button> {{ menuSection.name }}</span><div class="widget-buttons"><a ng-click="collapseSection(menuSection.id)" data-toggle="collapse" ng-if="dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id]"><i class="fa gray" ng-class="{\n' +
    '                                    \'fa-arrow-down\': collapsedSections.indexOf(menuSection.id) !== -1,\n' +
    '                                    \'fa-arrow-up\': collapsedSections.indexOf(menuSection.id) === -1\n' +
    '                                }"></i></a></div></div><div class="widget-body clearfix" ng-if="dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id]" ng-hide="collapsedSections.indexOf(menuSection.id) !== -1"><div class="row margin-bottom-30"><div class="col-xs-12"><textarea ng-model="dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].description" class="form-control" style="border-radius: 0 !important" id="menu-section-input-{{menuSection.id}}">\n' +
    '                            </textarea></div></div><div class="row margin-bottom-20" ng-repeat="menuSectionItem in menuSection.menuSectionItems"><div class="col-xs-12 mb-10" ng-if="menuSection.menuSectionType !== \'radio\'" ng-click="checkSectionItems(menuSection, menuSectionItem, selectedTab.properties.fieldType, selectedTab.groupId)" style="cursor: pointer"><button class="icon-only btn btn-default btn-xs" type="button" ng-class="{\n' +
    '                                    \'btn-default\': !dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id],\n' +
    '                                    \'btn-success\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id]\n' +
    '                                }"><i class="fa" aria-hidden="true" ng-class="{\'fa-check\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id]}"></i></button><label class="bold-text" style="cursor: pointer">{{ menuSectionItem.name }}</label></div><div class="col-xs-12 mb-10" ng-if="menuSection.menuSectionType === \'radio\'" ng-click="checkSectionItem(menuSection, menuSectionItem, selectedTab.properties.fieldType, selectedTab.groupId)" style="cursor: pointer"><button class="icon-only btn btn-default btn-xs" type="button" style="border-radius: 13px" ng-class="{\n' +
    '                                    \'btn-default\': !dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id],\n' +
    '                                    \'btn-success\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id]\n' +
    '                                }"><i class="fa" aria-hidden="true" ng-class="{\'fa-check\': dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id]}"></i></button><label class="bold-text" style="cursor: pointer">{{ menuSectionItem.name }}</label></div><div class="col-xs-12"><input type="text" class="form-control" id="menu-section-item-input-{{menuSectionItem.id}}" placeholder="{{ \'CUSTOMER_MENU.MENU_SECTION.DESCRIPTION\' | translate }}" style="border-radius: 0 !important" ng-model="dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id].description" ng-disabled="!dirtyData.details[selectedTab.properties.fieldType][selectedTab.groupId][menuSection.id].items[menuSectionItem.id]"></div></div></div></div></div><div class="col-xs-12"><button class="btn btn-labeled btn-success pull-right" ng-if="!stepProgress"><i class="btn-label glyphicon glyphicon-ok"></i> {{ \'GENERAL.SAVE\' | translate }}</button> <button class="btn btn-labeled btn-success pull-right" type="button" ng-click="next()" ng-if="stepProgress"><i class="btn-label glyphicon glyphicon-circle-arrow-right"></i> {{ \'CUSTOMER_MENU.NEXT\' | translate }}</button> <a class="pull-right mt-5 mr-10" ng-click="skip()" ng-if="stepProgress">{{ \'CUSTOMER_MENU.SKIP\' | translate }}</a> <button class="btn btn-labeled btn-default pull-left" type="button" ng-click="prev()" ng-if="stepProgress"><i class="btn-label glyphicon glyphicon-circle-arrow-left"></i> {{ \'CUSTOMER_MENU.PREV\' | translate }}</button></div></div></form>');
}]);
})();
