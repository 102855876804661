/**
  * @memberof dugun.provider.templates
  * @ngdoc overview
  * @description
  * Define templates module to work properly in development environment
 */
try {
    angular.module('dugun.provider.templates');
} catch(e) {
    angular.module('dugun.provider.templates', []);
}