function DgFormCoupleService($http, environmentConfig) {
    var self = {};

    self.couples = function(searchText, filters, options) {
        if(!searchText) {
            self.couplesResult = [];
            return;
        }

        filters = filters || {};
        filters.coupleName = searchText;

        $http
            .get(environmentConfig.api.url + 'leads', {params: filters})
            .then(function(response) {
                self.couplesResult = response.data.data;
            });
    };

    self.couplesResult = [];

    return self;
}

DgFormCoupleService.$inject = [
    '$http',
    'environmentConfig'
];

angular.module('dugun.forms')
    .factory('DgFormCoupleService', DgFormCoupleService);
