/**
 * @ngdoc controller
 * @memberof dugun.payments
 * @name PaymentCtrl
 *
 * @requires $scope
 * @requires $route
 * @requires $routeParams
 * @requires $window
 * @requires $location
 * @requires $translate
 * @requires dugun.notifications:dgNotifications
 * @requires Payments
 *
 * @param itemId
 */
function PaymentCtrl($scope, $route, $routeParams, $window, $location, $translate,
                    dgNotifications, $filter,
                    Payments
) {
    $scope.$root.heading.name = $route.current.label;
    $scope.id = $window.parseInt($routeParams.paymentId) || null;
    $scope.cleanData = {};
    $scope.dirtyData = {};
    $scope.loading = [];

    $scope.years = [];

    for (var i = 2018; i < 2030; i++) {
        $scope.years.push({
            'id': i,
            'name': i
        });
    }

    $scope.month = [];

    for (var j = 1; j <= 12; j++) {
        $scope.month.push({
            'id': j,
            'name': j
        });
    }

    $scope.getPayment = function() {
        var params = {
                id: $scope.id,
            };

        $scope.loading = Payments.get(
            params,
            function(successResponse) {
                $scope.paymentData = successResponse.data;
                if($scope.paymentData.statusId !== 'payment_request' && $scope.paymentData.statusId !== 'failed') {
                    dgNotifications.error($translate.instant('PAYMENTS.ALREADY_PAID'));
                    $scope.goBackToList();
                }
            },
            function(errorResponse) {
                if(errorResponse.data.errors.length > 0) {
                    dgNotifications.error(errorResponse);
                } else {
                    dgNotifications.error(errorResponse.data.message);
                }
                $scope.goBackToList();
            }
        );
    };

    $scope.setPayment = function() {
        var data = $scope.dirtyData.getDiff($scope.cleanData),
            method = 'update',
            params = {
                id: $scope.id,
            };

        data.pay = 1;
        $scope.loading = Payments[method](
            params,
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('PAYMENTS.PAID_SUCCESSFULLY'));
                $scope.goBackToList();
            },
            function(errorResponse) {
                if(errorResponse.data.errors.length > 0) {
                    dgNotifications.error(errorResponse);
                } else {
                    dgNotifications.error(errorResponse.data.message);
                }
            }
        );
    };

    $scope.goBackToList = function () {
        $location.path('payments');
    };

    if($scope.id) {
        $scope.getPayment();
    }
}

PaymentCtrl.$inject = [
    '$scope',
    '$route',
    '$routeParams',
    '$window',
    '$location',
    '$translate',
    'dgNotifications',
    '$filter',
    'Payments'
];

angular.module('dugun.payments')
    .controller('PaymentCtrl', PaymentCtrl);
