/**
 * Controls forgot password page
 * @ngdoc object
 * @memberof dugun.login.setCustomerUser
 * @name SetCustomerUserCtrl
 * @scope
 * @requires $scope {service} controller scope
 * @requires $routeParams {service} controller routeParams
 * @requires $location {service} location
 * @requires $translate
 * @requires dgNotifications {service} dgNotifications
 * @requires SetCustomerUser {factory} SetCustomerUser service
 */
function SetCustomerUserCtrl($scope, $routeParams, $location, $translate,
                            dgNotifications,
                            SetCustomerUser) {

    /**
     * @memberOf SetCustomerUserCtrl
     * @description
     * customerId
     */
    $scope.customerId = $routeParams.customerId || null;

    /**
     * @memberOf SetCustomerUserCtrl
     * @description
     * customerUserId
     */
    $scope.customerUserId = $routeParams.customerUserId || null;

    /**
     * @memberOf SetCustomerUserCtrl
     * @description
     * hash
     */
    $scope.hash = $routeParams.hash || null;

    /**
     * @memberOf SetCustomerUserCtrl
     * @description
     * customerUser
     */
    $scope.customerUser = {};

    /**
     * @memberOf SetCustomerUserCtrl
     * @description
     * array of promises
     */
    $scope.loading = [];

    /**
     * @memberOf SetCustomerUserCtrl
     * @description
     * fields to set for customer user
     */
    $scope.fields = {};

    /**
     * @memberOf SetCustomerUserCtrl
     * @description
     * error message to show
     */
    $scope.errorMessage = '';

    /**
     * @ngdoc method
     * @memberof SetCustomerUserCtrl
     * @description
     * Get the customerUser
     */
    $scope.getCustomerUser = function() {

        var loading = SetCustomerUser.get(
            {
                customerId: $scope.customerId,
                customerUserId: $scope.customerUserId,
                hash: $scope.hash
            },
            function(successResponse) {
                $scope.customerUser = successResponse.data;
                $scope.fields.username = successResponse.data.username;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                $location.path('/login');
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof SetCustomerUserCtrl
     * @description
     * Sets customer user
     */
    $scope.setCustomerUser = function() {

        if($scope.fields.password !== $scope.fields.passwordRepeat) {
            dgNotifications.error($translate.instant('USERNAME_RESET.PASSWORDS_DOSNT_MATCH'));
            return;
        }

        var postData = {
            username: $scope.fields.username,
            password: $scope.fields.password
        };

        var urlParams = {
            customerId: $scope.customerId,
            customerUserId: $scope.customerUserId,
            hash: $scope.hash
        };

        var loading = SetCustomerUser.update(
            urlParams,
            postData,
            function(successResponse) {
                dgNotifications.success($translate.instant('USERNAME_RESET.USERNAME_PASSWORD_SET_SUCCESSFULLY'));
                $location.path('/login');
            },
            function(errorResponse) {
                console.log(errorResponse.data.errors[0].message);
                if(errorResponse) {
                    if(errorResponse.data) {
                        if(errorResponse.data.errors) {
                            if (errorResponse.data.errors.length > 0) {
                                $scope.errorMessage = errorResponse.data.errors[0].message ? errorResponse.data.errors[0].message : '';
                            }
                        }
                    }
                }
            }
        );

        $scope.loading.push(loading);
    };

    if($scope.hash) {
        $scope.getCustomerUser();
    }
}

SetCustomerUserCtrl.$inject = [
    '$scope',
    '$routeParams',
    '$location',
    '$translate',
    'dgNotifications',
    'SetCustomerUser'
];

angular.module('dugun.login.setCustomerUser')
    .controller('SetCustomerUserCtrl', SetCustomerUserCtrl);
