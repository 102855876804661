function CollectionsMerchants(resource) {
    var url = 'collections/merchants/:id';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            query: {

            },

            get: {

            },

            insert: {
                method: 'POST'
            },

            update: {
                method: 'PUT'
            },

            delete: {
                method: 'DELETE'
            }
        }
    );

    return resourceService;
}

CollectionsMerchants.$inject = [
    'resource'
];

angular.module('dugun.collection').factory('CollectionsMerchants', CollectionsMerchants);
