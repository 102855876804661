function HasPermissionFilter() {
    function providerHasPermission(provider, permissionType) {
        if(!provider || !provider.productFeatures) return null;
        return !!provider.productFeatures[permissionType];
    }

    return function(provider, permissionType) {
        // Not enough data to check
        if(!provider || !permissionType) {
            return null;
        }

        if(angular.isArray(provider)) {
            var result = [];
            for(var i in provider) {
                if(providerHasPermission(provider[i], permissionType)) {
                    result.push(provider[i]);
                }
            }
            return result;
        } else if(typeof provider === 'object' && !provider.productFeatures) {
            return null;
        }

        if(typeof provider.productFeatures[permissionType] === 'undefined') {
            return null;
        }

        if(!providerHasPermission(provider, permissionType)) {
            return false;
        }

        return true;
    };
}

angular.module('dugun.provider.permissions')
    .filter('hasPermission', HasPermissionFilter);
