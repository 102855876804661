function DugunLeads(resource) {
    var url = 'dugun-leads';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            insert: {
                method: 'POST'
            }
        }
    );

    return resourceService;
}

DugunLeads.$inject = [
    'resource'
];

angular.module('dugun.provider.upgrade').factory('DugunLeads', DugunLeads);
