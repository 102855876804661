function UsercomService($translate, dgNotifications) {
    var userCom = window.UE;
    var service = {};

    service.init = function (user) {
        window.civchat.user_id = user.id;
        window.civchat.name = user.name;
        window.civchat.status = user.status;
        window.civchat.company = {
            name: user.providers[0].name,
            company_id: user.providers[0].id
        };

        delete window.civchat.userKey;
    };

    service.destroy = function () {
        if (userCom) {
            userCom.destroy();
        }
    };

    service.open = function () {
        userengage('widget.open');
    };

    return service;
}

UsercomService.$inject = [
    '$translate',
    'dgNotifications'
];

angular.module('dugun.core').factory('UsercomService', UsercomService);
