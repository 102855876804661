(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('discounts/provider-images/form.modal.html',
    '<form class="form-horizontal" role="form" ng-submit="submit()" cg-busy="loading"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="close()" ga-track-event="[\'discuntProviderImagesForm\', \'click\', \'closeIcon\']">&times;</button><h4 class="modal-title">{{ \'DISCOUNTS.CHOOSE_DISCOUNT_IMAGE\' | translate }}</h4></div><div class="modal-body"><div class="well well-sm" ng-repeat="gallery in galleries" ng-if="gallery.medias.length > 0"><h3 ng-bind="gallery.name"></h3><div class="row"><div class="col-xs-6 col-lg-2 text-center" ng-repeat="image in gallery.medias"><div class="mb-10" ng-class="{\'bg-gray\': image.selected}"><img class="img-responsive center-block" ng-src="{{ image.imageUrl.thumbnail }}"> <button type="button" class="btn btn-default" ng-model="image.selected" uib-btn-checkbox ng-bind="image.selected ? (\'GENERAL.REMOVE\' | translate) : (\'GENERAL.CHOOSE\' | translate)" ng-click="imageToggled(image)"></button></div></div></div></div></div><div class="modal-footer"><button type="button" class="btn btn-default" ng-click="close()" ga-track-event="[\'discuntProviderImagesForm\', \'click\', \'closeButton\']">{{ \'GENERAL.CLOSE\' | translate }}</button> <button type="submit" class="btn btn-primary" ga-track-event="[\'discuntProviderImagesForm\', \'click\', \'save\']">{{ \'GENERAL.SAVE\' | translate }}</button></div></form>');
}]);
})();
