function PageTutorialCtrl($scope, $uibModalInstance, data) {

    $scope.page_title = data.page_title;
    $scope.page_url = data.page_url;

    $scope.close = function() {
        $uibModalInstance.dismiss('cancel');
    };
}

PageTutorialCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'data'
];

angular.module('dugun.provider.tutorials')
    .controller('PageTutorialCtrl', PageTutorialCtrl);