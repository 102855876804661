angular.module('dugun.location')
    .factory('district', [
        'resource',
        'CacheFactory',
        District
    ]);

function District(resource, CacheFactory) {
    var url = 'cities/districts';
    var items = [];
    var itemsByCity = {};
    var itemsKeyValue = [];

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            query: {
                isArray: false,
                cache: getCacheFactory()
            }
        }
    );

    function init() {
        return resourceService.query(null, initSuccess, initError);
    }

    function initSuccess(response) {
        items = response.data;
        for(var i in response.data) {
            if(typeof itemsByCity[response.data[i].cityId] === 'undefined') {
                itemsByCity[response.data[i].cityId] = [];
            }
            itemsByCity[response.data[i].cityId].push(response.data[i]);
            itemsKeyValue[response.data[i].id] = response.data[i];
        }
        return items;
    }

    function initError(response, a, b, c) {
        console.error(response, a, b, c);
    }

    function getItems(cityId) {
        if(cityId) {
            return itemsByCity[cityId];
        }
        return items;
    }

    function getItem(id) {
        return itemsKeyValue[id] || null;
    }

    function getCacheFactory() {
        return CacheFactory.get('districts') || CacheFactory('districts', {
            maxAge: 24*60*60*1000, // 1 day
            onExpire: cacheExpired
        });
    }

    function cacheExpired() {
        init();
    }

    return {
        getItems: getItems,
        getItem: getItem,
        init: init
    };
}
