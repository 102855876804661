(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/datepicker/datepicker.html',
    '<div class="date-picker-container"><div style="width: 50%"><div class="form-group-start-date"><label class="control-label mb-5">{{ \'DG_FORMS.START\' | translate }} :</label><div class="input-icon text-center"><input type="text" class="form-control" uib-datepicker-popup="{{format}}" ng-model="date.start" is-open="popup[id].opened.start" ng-click="popup[id].opened.start = true;" datepicker-options="options.start" close-text="Close" alt-input-formats="altInputFormats" placeholder="{{ \'DG_FORMS.START\' | translate }}"> <i class="fa fa-calendar icon"></i></div></div></div><div style="width: 50%"><div class="form-group-end-date"><label class="control-label mb-5">{{ \'DG_FORMS.END\' | translate }} :</label><div class="input-icon text-center"><input type="text" class="form-control" uib-datepicker-popup="{{format}}" ng-model="date.end" is-open="popup[id].opened.end" ng-click="popup[id].opened.end = true;" datepicker-options="options.end" ng-required="required[id].end" close-text="Close" alt-input-formats="altInputFormats" placeholder="{{ \'DG_FORMS.END\' | translate }}"> <i class="fa fa-calendar icon"></i></div></div></div></div><style>.date-picker-container input {\n' +
    '        border-radius: 0 !important;\n' +
    '    }\n' +
    '    .date-picker-container {\n' +
    '        display: flex;\n' +
    '        padding: 5px 5px;\n' +
    '        border: 1px solid #f0f0f0;\n' +
    '        background: #f6f6f6;\n' +
    '    }\n' +
    '    .icon {\n' +
    '        color: #00ABAA !important;\n' +
    '    }\n' +
    '    .form-group-start-date, form-group-end-date {\n' +
    '        margin: 0;\n' +
    '    }</style>');
}]);
})();
