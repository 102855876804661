(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('dg-forms/form-elements/couple/couple.html',
    '<ui-select ng-model="$parent.model" theme="bootstrap" on-select="selected($item, $model)"><ui-select-match placeholder="{{ placeholder ? placeholder : \'DG_FORMS.COUPLE_NAME\' | translate }}"><div>{{ $select.selected.couple.name }}</div></ui-select-match><ui-select-choices repeat="item in searcher.couplesResult" refresh="searcher.couples($select.search, {\'statusIds[]\': statusIds})"><div>{{ item.couple.name }}</div></ui-select-choices></ui-select>');
}]);
})();
