/**
 * @ngdoc directive
 * @name dugun.forms:dgFormConfig
 * @restrict 'E'
 * @requires dugun.core:dugunConfig
 * @scope
 **/
function DgFormConfig(dugunConfig) {
    return {
        restrict: 'E',
        scope: {
            model: '=ngModel',
            typeId: '@type',
            allowClear: '@',
            required: '=ngRequired',
            placeholder: '@'
        },
        templateUrl: 'dg-forms/form-elements/config/config.html',
        link: function(scope) {
            dugunConfig.loading.then(function() {
                var options = dugunConfig.getItems(scope.typeId),
                    result = [],
                    i;

                for (i in options) {
                    result.push({
                        id: options[i].itemId,
                        name: options[i].value
                    });
                }

                scope.options = result;
            });
        }
    };
}

DgFormConfig.$inject = [
    'dugunConfig',
];

angular.module('dugun.forms').directive('dgFormConfig', DgFormConfig);
