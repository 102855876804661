/**
 * @ngdoc controller
 * @memberof dugun.discounts
 * @name DiscountCtrl
 *
 * @requires $scope
 * @requires $uibModalInstance
 * @requires $window
 * @requires $log
 * @requires dugun.notifications:dgNotifications
 * @requires Analytics
 * @requires moment
 * @requires $translate
 * @requires Discounts
 * @requires DiscountsUpload
 * @requires DiscountDocuments
 * @requires Providers
 *
 * @param itemId
 * @param data
 */
function DiscountCtrl($scope, $uibModalInstance, $window, $log,
                      dgNotifications, moment, $translate,
                      Discounts, DiscountsUpload, DiscountDocuments, Providers,
                      itemId, data, $timeout, Cropper) {
    var self = this;

    $scope.id = $window.parseInt(itemId);
    $scope.editable = data.editable;
    $scope.discountTotal = data.discountTotal || null;
    $scope.cleanData = {};
    $scope.dirtyData = {};
    $scope.dirtyData.discountImages = [];
    $scope.cleanDataDocuments = [];
    $scope.dirtyDataDocument = [];
    $scope.loading = [];

    $scope.validUntilOptions = {
        minDate: $scope.dirtyData.validFrom
    };

    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('GENERAL.UNSAVED_INFO_WARNING'),
            data = $scope.dirtyData.getDiff($scope.cleanData);
        delete data.discountImages;
        if(angular.equals(data, {}) || reason == 'success' || $scope.cleanData.listingType == 'past') {
            return;
        }
        switch (reason){
            // clicked outside
            case "backdrop click":
                break;

            // cancel button
            case "cancel":
                break;

            // escape key
            case "escape key press":
                break;
        }
        if (!confirm(message)) {
            event.preventDefault();
        }
    });

    $scope.close = function(param) {
        if(param) $uibModalInstance.close(param);
        $uibModalInstance.dismiss('cancel');
    };

    $scope.getDiscount = function() {
        var promise = Discounts.get(
            {id: $scope.id},
            function(successResponse) {
                successResponse.data.priceAfter = successResponse.data.priceAfter ? parseFloat(successResponse.data.priceAfter) : '';
                successResponse.data.priceBefore = successResponse.data.priceBefore ? parseFloat(successResponse.data.priceBefore) : '';
                $scope.cleanData = successResponse.data;
                $scope.dirtyData = angular.copy($scope.cleanData);
                //$scope.dirtyData.isPopupShowcase = true;

                var provider = $scope.getProvider($scope.dirtyData.provider.id),
                    value;

                if ($scope.dirtyData.discountType === 'discount') {
                    value = $scope.dirtyData.discountRatio;
                } else if ($scope.dirtyData.discountType === 'installment') {
                    value = $scope.dirtyData.installmentCount;
                } else {
                    value = '';
                }

                dataLayer.push(
                    {
                        event: 'openDiscount',
                        discount: {
                            title: $scope.dirtyData.intro,
                            type: $scope.getConfigItem('discount_type', $scope.dirtyData.discountType),
                            isPrimary: $scope.dirtyData.isPrimary,
                            isPopupShowcase: $scope.dirtyData.isPopupShowcase,
                            value: value,
                        },
                        provider: {
                            id: $scope.dirtyData.provider.id,
                            name: provider.name,
                            city: $scope.getCity(provider.cityId),
                            category: $scope.getProviderCategory(provider.categoryId),
                            discountTotal: $scope.discountTotal
                        },
                    }
                );
            },
            function(errorResponse) {
                $log.error(JSON.stringify(errorResponse.data));
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );

        $scope.loading.push(promise);
    };

    $scope.getProviderMainImage = function() {
        if(!$scope.dirtyData.provider) {
            return false;
        }
        var providerId = $scope.dirtyData.provider.id;
        if(!providerId) {
            return false;
        }

        var promise = Providers.getMainImage(
            {id: providerId},
            function(successResponse) {
                $scope.cleanData.imageUrl = successResponse.imageUrl ? successResponse.imageUrl.thumbnail : '';
            },
            function(errorResponse) {
                $log.error(JSON.stringify(errorResponse.data));
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );

        $scope.loading.push(promise);
    };

    $scope.setDiscount = function () {
        var data = $scope.dirtyData.getDiff($scope.cleanData),
            image = null,
            document = null,
            urlData = {},
            loading,
            actionLabel = 'Add',
            discountType = '';

        if (data.image) {
            image = $scope.dirtyData.image;
            delete data.image;
        }

        if (data.showcasePopupImage) {
            data.showcasePopupImage = $scope.dirtyData.showcasePopupImage;
        }

        if ($scope.dirtyDataDocument) {
            document = $scope.dirtyDataDocument;
        }

        if (angular.equals(data, {}) && !image && !document) {
            return true;
        }

        if (image) {
            data.image = image;
        }

        if (document) {
            data.document = document;
        }

        if (data.discountRatio) {
            delete data.discountRatio;
        }

        if (data.discountType) {
            if (data.discountType === 'gift') {
                data.priceBefore = null;
                data.priceAfter = null;
                data.installmentCount = null;
                discountType = 'gift';
            } else if (data.discountType === 'discount') {
                data.labelText = null;
                data.installmentCount = null;
                discountType = 'discount';
            } else if (data.discountType === 'installment') {
                data.labelText = null;
                data.priceBefore = null;
                data.priceAfter = null;
                discountType = 'installment';
            }
        }

        data = data.convertDates([
            'validFrom',
            'validUntil'
        ]);

        if ($scope.id) {
            urlData.id = $scope.id;
            actionLabel = 'Edit';
        }

        loading = DiscountsUpload.upload(
            data,
            urlData
        );

        loading.then(
            function (successResponse) {
                $scope.cleanData = angular.copy($scope.dirtyData);
                $scope.dirtyDataDocument = [];
                dgNotifications.success($translate.instant('DISCOUNTS.DISCOUNT_PUBLISH_DURATION'));

                delete data.image;
                delete data.document;
                delete data.discountImages;
                data.success = true;
                data.discountType = discountType;

                var provider = $scope.getProvider($scope.dirtyData.provider.id),
                    value;

                if ($scope.dirtyData.discountType === 'discount') {
                    value = $scope.dirtyData.discountRatio;
                } else if ($scope.dirtyData.discountType === 'installment') {
                    value = $scope.dirtyData.installmentCount;
                } else {
                    value = '';
                }

                if (typeof $scope.dirtyData.isPrimary === 'undefined') {
                    $scope.dirtyData.isPrimary = false;
                }

                if (typeof $scope.dirtyData.isPopupShowcase === 'undefined') {
                    $scope.dirtyData.isPopupShowcase = false;
                }

                dataLayer.push({
                    event: 'setDiscount',
                    discount: {
                        action: actionLabel,
                        title: $scope.dirtyData.intro,
                        type: $scope.getConfigItem('discount_type', $scope.dirtyData.discountType),
                        isPrimary: $scope.dirtyData.isPrimary,
                        isPopupShowcase: $scope.dirtyData.isPopupShowcase,
                        value: value
                    },
                    provider: {
                        id: $scope.dirtyData.provider.id,
                        name: provider.name,
                        city: $scope.getCity(provider.cityId),
                        category: $scope.getProviderCategory(provider.categoryId),
                        discountTotal: $scope.discountTotal
                    },
                });

                $scope.close('success');
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);

                data.success = false;
                data.error = errorResponse.data.message;
                data.discountType = discountType;
            },
            function (event) {
                $scope.progress = $window.parseInt(100.0 * event.loaded / event.total);
            }
        );

        $scope.loading.push(loading);
    };

    self.getDiscountDocuments = function() {
        var urlData = {
            discountId: $scope.id
        };

        var promise = DiscountDocuments.query(
            urlData,
            function(successResponse) {
                $scope.cleanDataDocuments = successResponse.data;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.deleteDocument = function(discountDocument) {
        var index = $scope.cleanDataDocuments.indexOf(discountDocument),
            urlData = {
                discountId: $scope.id,
                id: discountDocument.id
            },
            promise;

        promise = DiscountDocuments.delete(
            urlData,
            function(successResponse) {
                $scope.cleanDataDocuments.splice(index, 1);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.openProviderImages = function() {
        if(!$scope.dirtyData.provider) {
            return false;
        }
        var providerId = $scope.dirtyData.provider.id,
            modalInstance;

        if(!providerId) {
            return false;
        }

        modalInstance = $scope.openModal(
            'discounts/provider-images/form.modal.html',
            'ProviderImageSelectionCtrl',
            providerId,
            null,
            {
                discountImages: $scope.dirtyData.discountImages || []
            },
            {
                backdrop: 'static'
            }
        );

        modalInstance.result.then(function(response) {
            if(response.discountImages) {
                $scope.dirtyData.discountImages = response.discountImages;
            } else {
                delete $scope.dirtyData.discountImages;
            }
        });
    };

    $scope.manageDiscountValues = function(changedInput) {
        if(changedInput === 'priceBefore' || changedInput === 'priceAfter') {
            if(
                $scope.dirtyData.priceAfter > 0 &&
                $scope.dirtyData.priceBefore > 0 &&
                $scope.dirtyData.priceAfter < $scope.dirtyData.priceBefore
            ) {
                $scope.dirtyData.discountRatio = parseInt((
                    ($scope.dirtyData.priceBefore - $scope.dirtyData.priceAfter) * 100
                ) / $scope.dirtyData.priceBefore);
            } else {
                $scope.dirtyData.discountRatio = null;
            }
        } else if(changedInput === 'discountRatio') {
            if(
                $scope.dirtyData.priceBefore > 0 &&
                $scope.dirtyData.discountRatio > 0 &&
                $scope.dirtyData.discountRatio < 100
            ) {
                $scope.dirtyData.priceAfter = $scope.dirtyData.priceBefore -
                    ($scope.dirtyData.priceBefore *
                        ($scope.dirtyData.discountRatio / 100)
                    );
            } else {
                $scope.dirtyData.priceAfter = null;
            }
        }
    };

    $scope.$watch('dirtyData.provider.id', function(newValue, oldValue) {
        if(!$scope.id) {
            if(!$scope.dirtyData.image) {
                $scope.getProviderMainImage();
            }
        }
    });

    $scope.$watch('dirtyData.image', function(newValue, oldValue) {
        if(!$scope.id) {
            if(newValue) {
                $scope.cleanData.imageUrl = '';
            }
        }
    });

    $scope.$watch('dirtyData.validFrom', function(newValue, oldValue) {
        if(!$scope.id) {
            if(angular.isUndefined(oldValue) && !angular.isUndefined(newValue)) {
                $scope.dirtyData.validUntil = moment(newValue).add(4, 'month').format('YYYY-MM-DD');
            }
        }
    });

    $scope.setTargetPopup = function () {
        if (!$scope.dirtyData.isPopupShowcase) return;

        var params = {
            id: $scope.dirtyData.provider.id,
        };
        Discounts.getProducts(
            params,
            function (successResponse) {
                var currentDate = moment();
                var twoMonthLater = moment().add('2', 'M').endOf('day');
                $scope.activeProducts = successResponse.data;
                angular.forEach($scope.activeProducts.providerProductPrices, function (value, index) {
                    if (value.productPrice.productFeature &&
                        value.productPrice.productFeature.showTargetPopup &&
                        moment(value.dateEnd).isBetween(currentDate, twoMonthLater)) {
                        $scope.targetProduct = value;
                        $scope.hasTargetPopup = true;
                    }
                });
                if (!$scope.hasTargetPopup) {
                    dgNotifications.error($translate.instant('DISCOUNTS.TARGET_NOT_FOUND_WARNING'));
                    $scope.dirtyData.isPopupShowcase = false;
                } else {
                    $scope.dirtyData.popupShowcaseTitle = $scope.activeProducts.name;
                    if ($scope.dirtyData.intro && $scope.dirtyData.intro.length <= 75) {
                        $scope.dirtyData.popupShowcaseDescription = $scope.dirtyData.intro;
                    }
                }
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    $scope.providerChange = function () {
        $scope.dirtyData.isPopupShowcase = false;
        $scope.hasTargetPopup = false;
    };

    var file, imageData, fileName;
    $scope.cropper = {};
    $scope.cropperProxy = 'cropper.first';
    $scope.options = {
        maximize: true,
        aspectRatio: 1/1,
        crop: function (dataNew) {
            imageData = dataNew;
        }
    };

    $scope.showEvent = 'show';
    $scope.hideEvent = 'hide';

    $scope.targetImageChange = function ($file) {
        if (!$file) return;
        Cropper.encode((file = $file)).then(function (dataUrl) {
            $scope.hideCropArea = true;
            $scope.dataUrl = dataUrl;
            fileName = $file.name;
            $timeout(showCropper);
        });
    };

    $scope.preview = function () {
        if (!file || !imageData) {
            Cropper.crop(file,imageData).then(Cropper.encode).then(function (dataUrl) {
                ($scope.preview || ($scope.preview = {})).dataUrl = dataUrl;
            });
        }
    };

    $scope.clear = function(degrees) {
        if (!$scope.cropper.first) return;
        $scope.cropper.first('clear');
    };

    $scope.scale = function(width) {
        Cropper.crop(file, imageData)
            .then(function(blob) {
                $scope.closeCropper();
                $scope.dirtyData.showcasePopupImage = blob;
                $scope.dirtyData.showcasePopupImage.name = fileName;
                return Cropper.scale(blob, {width: width ? width : imageData.width});
            })
            .then(Cropper.encode).then(function(dataUrl) {
            ($scope.preview || ($scope.preview = {})).dataUrl = dataUrl;
        });
    };

    function showCropper () {
        $scope.$broadcast($scope.showEvent);
    }

    function hideCropper() {
        $scope.$broadcast($scope.hideEvent);
    }

    $scope.closeCropper = function () {
        hideCropper();
        $scope.hideCropArea = false;
    };

    if($scope.id) {
        $scope.getDiscount();
        self.getDiscountDocuments();
    } else {
        if($scope.providersWithDiscount.length == 1) {
            $scope.dirtyData.provider = { id :$scope.providersWithDiscount[0].id };
        }
    }
}

DiscountCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    '$log',
    'dgNotifications',
    'moment',
    '$translate',
    'Discounts',
    'DiscountsUpload',
    'DiscountDocuments',
    'Providers',
    'itemId',
    'data',
    '$timeout',
    'Cropper'
];

angular.module('dugun.discounts')
    .controller('DiscountCtrl', DiscountCtrl);
