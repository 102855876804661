function MagnetStatisticsCtrl($scope, $route, dgNotifications, queryString, Statistics, $rootScope) {
    $scope.list = [];
    $scope.pagination = {
        currentPage: 1
    };
    $scope.loading = [];
    $scope.filters = queryString.getFilters() || {};
    $scope.providerIds = $rootScope.user.providerIds;
    $scope.orderBy = {
        key: 'leads',
        direction: true
    };
    $scope.loadList = function () {
        var data = angular.copy($scope.filters);
        data['providerIds[]'] = $scope.providerIds;
        if (data.period === true) {
            data.period = 'monthly';
        } else {
            data.period = 'weekly';
        }
        data.sort = $scope.orderBy.key;
        data.order = $scope.orderBy.direction ? 'DESC' : 'ASC';
        $scope.newList = {};
        var loading = Statistics.magnetStatistics(
            data,
            function (successResponse) {
                $scope.list = successResponse.data;
                $scope.pagination = successResponse.meta;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.sortBy = function (key) {
        if ($scope.orderBy.key === key) {
            $scope.orderBy.direction = !$scope.orderBy.direction;
        } else {
            $scope.orderBy.key = key;
            $scope.orderBy.direction = false;
        }

        $scope.loadList();
    };

    $scope.setFilters = function () {
        var data = angular.copy($scope.filters);
        queryString.setFilters(data);
    };

    $scope.$root.heading.name = $route.current.label;
    $scope.loadList();

}

MagnetStatisticsCtrl.$inject = [
    '$scope',
    '$route',
    'dgNotifications',
    'queryString',
    'Statistics',
    '$rootScope'
];

angular.module('dugun.provider.statistics')
    .controller('MagnetStatisticsCtrl', MagnetStatisticsCtrl);