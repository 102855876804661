(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('agreements/form.html',
    '<div class="widget no-header"><div class="widget-body clearfix strong-labels pl-20 pr-20" cg-busy="loading"><label class="mb-10 form-title fs-24">{{ \'AGREEMENTS.AGREEMENT_HEADER\' | translate: { name: agreement.header } }}</label><div class="row"><div class="col-xs-12 mb-10" ng-bind-html="agreement.content"></div><div class="col-md-12 mt-10"><label class="input-sm"><input type="checkbox" ng-model="isApproved" ng-required="true"> <span class="text text-bold">{{ \'AGREEMENTS.READ_AGREE\' | translate }}</span></label></div><div class="col-xs-12 mb-10"><button ng-disabled="!isApproved" type="button" ng-click="setAgreement()" class="btn btn-primary pull-right" ga-track-event="[\'agreementsForm\', \'click\', \'save\']">{{ \'AGREEMENTS.SAVE\' | translate }}</button></div></div></div></div>');
}]);
})();
