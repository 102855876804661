/**
 * @ngdoc controller
 * @memberof dugun.provider.upgrade
 * @name ProviderUpgradeRequestSentCtrl
 *
 * @requires $scope
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires $translate
 * @requires Analytics
 * @requires data passed to the controller
 *
 */
function ProviderUpgradeRequestSentCtrl($scope, $uibModalInstance, $translate, Analytics, data) {

    $scope.page = data ? data.page : '';

    /**
     * @ngdoc method
     * @memberof ProviderUpgradeRequestSentCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.dismiss();
    };

    $scope.header = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.HEADER');
    $scope.bodyText = '';
    $scope.ctaButtonText = '';

    if(data.page == 'discounts') {
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.DISCOUNTS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.DISCOUNTS.CTA_BUTTON_TEXT');
    } else if(data.page == 'visits-statistics') {
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.VISITS_STATISTICS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.VISITS_STATISTICS.CTA_BUTTON_TEXT');
    } else if(data.page == 'weddings-statistics') {
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.WEDDINGS_STATISTICS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.WEDDINGS_STATISTICS.CTA_BUTTON_TEXT');
    } else if(data.page == 'testimonial-reply') {
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.TESTIMONIAL_REPLY.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.TESTIMONIAL_REPLY.CTA_BUTTON_TEXT');
    } else if(data.page == 'galleries') {
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.GALLERIES.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.GALLERIES.CTA_BUTTON_TEXT');
    } else if (data.page === 'leads') {
        $scope.bodyText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.LEADS.BODY_TEXT');
        $scope.ctaButtonText = $translate.instant('PROVIDER_UPGRADE.REQUEST_SENT.LEADS.CTA_BUTTON_TEXT');
    }

    Analytics.trackEvent('sentUpgradeModal', 'open', 'modal');
}

ProviderUpgradeRequestSentCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$translate',
    'Analytics',
    'data'
];

angular.module('dugun.provider.upgrade')
    .controller('ProviderUpgradeRequestSentCtrl', ProviderUpgradeRequestSentCtrl);
