/**
 * Controls the gallery modals
 * @ngdoc controller
 * @memberof dugun.galleries.galleries
 * @name GalleryCtrl
 *
 * @requires $scope {service} controller scope
 * @requires $uibModalInstance {service} $uibModalInstance
 * @requires $window {service} $window
 * @requires $translate
 * @requires dgNotifications {service} dgNotifications
 * @requires Galleries {factory} Galleries service
 *
 * @param itemId {integer=} ID of gallery to use
 * @param parentId {integer=} ID of provider to use
 */
function GalleryCtrl($scope, $uibModalInstance, $window, $translate,
                      dgNotifications,
                      Galleries,
                      itemId, parentId) {
    $scope.id = $window.parseInt(itemId);
    $scope.providerId = $window.parseInt(parentId) || null;
    $scope.cleanData = {};
    $scope.dirtyData = {};

    /**
     * @ngdoc method
     * @memberof GalleryCtrl
     * @param data {Object=} data to send modalInstance
     * @description
     * Closes the modal
     */
    $scope.close = function(data) {
        if(data) $uibModalInstance.close(data);
        else $uibModalInstance.dismiss('cancel');
    };

    /**
     * @ngdoc method
     * @memberof GalleryCtrl
     * @description
     * Gets the gallery
     */
    $scope.getGallery = function() {
        $scope.loading = Galleries.get(
            {
                id: $scope.id
            },
            function(successResponse) {
                $scope.cleanData = successResponse.gallery;
                $scope.dirtyData = angular.copy($scope.cleanData);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );
    };

    /**
     * @ngdoc method
     * @memberof GalleryCtrl
     * @description
     * Saves the gallery
     */
    $scope.setGallery = function() {
        var data = $scope.dirtyData.getDiff($scope.cleanData),
            method = 'insert';

        if(angular.equals(data, {})) return true;

        var urlData = {};

        if($scope.id) {
            method = 'update';
            urlData.id = $scope.id;
        }

        $scope.loading = Galleries[method](
            urlData,
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('GALLERIES.SAVED_SUCCESSFULLY'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    if($scope.id) {
        $scope.getGallery();
    }
}

GalleryCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    '$translate',
    'dgNotifications',
    'Galleries',
    'itemId',
    'parentId',
];

angular.module('dugun.galleries.galleries')
    .controller('GalleryCtrl', GalleryCtrl);
