(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('attachments/form.html',
    '<form class="form-horizontal" role="form" ng-submit="setAttachment()" cg-busy="loading"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="close()" ga-track-event="[\'attachmentForm\', \'click\', \'closeIcon\']">&times;</button><h3 class="modal-title"><span>{{ \'ATTACHMENTS.ATTACHMENT_EDIT\' | translate }}</span></h3></div><div class="modal-body strong-labels"><div class="row"><div class="col-xs-12"><div class="form-group"><div class="col-xs-12"><label for="attachment_name" class="input-sm text-bold mb-0">{{ \'ATTACHMENTS.NAME\' | translate }}</label><input type="text" class="form-control" id="attachment_name" name="attachment_name" maxlength="70" ng-model="dirtyData.name" required></div></div></div></div></div><div class="modal-footer"><button type="button" class="btn btn-default" ng-click="close()" ga-track-event="[\'attachmentForm\', \'click\', \'closeButton\']">{{ \'GENERAL.CLOSE\' | translate }}</button> <button type="submit" class="btn btn-primary" ga-track-event="[\'attachmentForm\', \'click\', \'save\']">{{ \'GENERAL.SAVE\' | translate }}</button></div></form>');
}]);
})();
