function ProviderGallery(resource) {
    var url = 'providers/:providerId/galleries/:id';

    var resourceService = resource(
        url,
        {
            id: '@id',
            providerId: '@providerId'
        },
        {
            query: {
                isArray: false
            }
        }
    );

    return resourceService;
}

ProviderGallery.$inject = [
    'resource'
];

angular.module('dugun.customers.providers.galleries')
    .factory('ProviderGallery', ProviderGallery);
