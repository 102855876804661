function BadgesCtrl($scope, $window, $routeParams, Providers, dgNotifications) {
    $scope.cleanData = {};
    $scope.id = $window.parseInt($routeParams.providerId) || null;
    $scope.loading = [];

    $scope.getProvider = function () {
        var loading = Providers.get(
            {id: $scope.id},
            function (successResponse) {
                $scope.cleanData = successResponse.data;
                dataLayer.push(
                    {
                        event: 'getProvider',
                        provider: {
                            id: $scope.cleanData.id,
                            name: $scope.cleanData.name,
                            category: $scope.getProviderCategory($scope.cleanData.categoryId),
                            city: $scope.getCity($scope.cleanData.cityId)
                        }
                    }
                );
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(loading);
    };

    $scope.addGtmDatas = function (badgeNumber) {
        dataLayer.push(
            {
                event: 'chooseBadge',
                badgeNumber: badgeNumber
            }
        );
    };

    if ($scope.id) {
        $scope.getProvider();
    }
}

BadgesCtrl.$inject = [
    '$scope',
    '$window',
    '$routeParams',
    'Providers',
    'dgNotifications'

];

angular.module("dugun.customers.providers.provider")
        .controller('BadgesCtrl', BadgesCtrl);