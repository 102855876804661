/**
 * RelatedProviders factory
 * @ngdoc service
 * @name RelatedProviders
 * @memberof dugun.relatedProviders
 * @requires dugun.core:resource
 *
 * @returns {service} RelatedProviders resource service
 */
function RelatedProviders(resource) {
    var url = 'providers/:providerId/related-providers/:id';

    var resourceService = resource(
        url,
        {
            providerId: '@providerId',
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof RelatedProviders
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get all related providers to this provider
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof RelatedProviders
             * @name insertGallery
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Add related provider gallery
             */
            insertGallery: {
                method: 'POST',
                url: 'providers/:providerId/related-providers/:id/galleries'
            },
            /**
             * @ngdoc method
             * @memberof RelatedProviders
             * @name getGallery
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Add related provider gallery
             */
            getGallery: {
                method: 'GET',
                url: 'providers/:providerId/related-providers/:relatedProviderId/galleries/:relatedProviderGalleryId'
            }
        }
    );

    return resourceService;
}

RelatedProviders.$inject = [
    'resource'
];

angular.module('dugun.relatedProviders')
    .factory('RelatedProviders', RelatedProviders);
