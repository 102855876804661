angular.module('dugun.testimonials')
    .config(['$routeProvider', TestimonialsRoutes]);

function TestimonialsRoutes($routeProvider) {
    $routeProvider
        .when('/provider-testimonials', {
            templateUrl: 'testimonials/list.html',
            controller: 'TestimonialsListCtrl',
            label: 'TESTIMONIALS.COUPLE_TESTIMONIALS',
            reloadOnSearch: false
        });
}
