/**
 * @memberof dugun.login.passwordReset
 * @ngdoc route
 * @name PasswordResetRoutes
 * @param $routeProvider {provider} $routeProvider
 *
 * @description /providers/:providerId/info
 */
function PasswordResetRoutes($routeProvider) {
    $routeProvider
        .when('/forgot-password', {
            templateUrl: 'login/password-reset/password-forgot.html',
            controller: 'PasswordForgotCtrl',
            controllerAs: 'passwordForgotCtrl',
            label: 'FORGET_PASSWORD.HEADER'
        })
        .when('/forgot-username', {
            templateUrl: 'login/password-reset/username-forgot.html',
            controller: 'PasswordForgotCtrl',
            controllerAs: 'passwordForgotCtrl',
            label: 'FORGET_USERNAME.HEADER'
        })
        .when('/password-reset/:recoveryKey?', {
            templateUrl: 'login/password-reset/password-reset.html',
            controller: 'PasswordForgotCtrl',
            controllerAs: 'passwordForgotCtrl',
            label: 'PASSWORD_RESET.HEADER'
        })
        .when('/password-renew', {
            templateUrl: 'login/password-reset/password-renew.html',
            controller: 'PasswordForgotCtrl',
            controllerAs: 'passwordForgotCtrl',
            label: 'PASSWORD_RENEW.HEADER'
        });
}

PasswordResetRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.login.passwordReset').config(PasswordResetRoutes);
