/**
 * @constructor
 * @ngdoc controller
 * @memberof dugun.provider.home
 * @name HomeCtrl
 * @param $scope {service} controller scope
 * @param $location {service} $location
 */
function HomeCtrl($scope, $location) {
    if($scope.user) {
        redirectToHome();
    } else {
        $scope.$watch('user.id', redirectToHome, true);
    }

    function redirectToHome() {
        if($scope.$root.user.id) {
            $location.path('/home');
        } else {
            $location.path('/login');
        }
    }
}

HomeCtrl.$inject = [
    '$scope',
    '$location',
];

angular.module('dugun.provider.home')
    .controller('HomeCtrl', HomeCtrl);
