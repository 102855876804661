/**
 * Controls Provider
 * @ngdoc controller
 * @memberof dugun.customers.providers.provider
 * @name ProviderCtrl
 *
 * @requires $scope
 * @requires $rootScope
 * @requires $route
 * @requires $routeParams
 * @requires $window
 * @requires $timeout
 * @requires $filter
 * @requires moment
 * @requires dugun.notifications:dgNotifications
 * @requires dugun.queryString:queryString
 * @requires geolocation:geolocation
 * @requires Analytics
 * @requires Providers
 * @requires ProvidersDetailsService
 * @requires CustomerUsers
 * @requires CustomerUserProviders
 *
 * @property {number} id - ID of current provider
 * @property {number} customerId - ID of provider's customer
 * @property {object} cleanData - Saved data
 * @property {object} dirtyData - Not-saved data
 * @property {object} details - Provider details
 * @property {object} redirectPhone - Redirect phone number form
 * @property {object} customerUsers - Customer Users list
 * @property {object} detailsCheckboxes - Checkbox groups' values stored as key-boolean
 * Checkbox group values are received as array of ids. For example:
 *
 *     "value": [2,3,13,15]
 *
 * However we cannot bind multiple checkboxes to this data. So we convert
 * it to an object like:
 *
 *     {2: true, 3: true, 13: true, 15: true}
 *
 * This variable is filled in formatFormData method.
 * @property {array} loading - Array of promises, used for cgBusy directive
 * @property {object} tabs - Tabs in view. Active status is stored here.
 */
function ProviderCtrl($scope, $rootScope, $route, $routeParams, $window, $timeout, $filter, $translate, $q, moment,
                      dgNotifications, queryString, geolocation, uiGmapGoogleMapApi,
                      Providers, ProvidersDetailsService, CustomerUsers, CustomerUserProviders, Agreements) {

    $scope.id = $window.parseInt($routeParams.providerId) || null;
    $scope.customerId = $window.parseInt($routeParams.customerId) || getCustomerId();
    $scope.cleanData = {};
    $scope.dirtyData = {};
    $scope.details = {
        cleanData: {},
        dirtyData: {}
    };
    $scope.socialAccounts = {
        cleanData: {},
        dirtyData: {}
    };
    $scope.voiceRecord = {
        cleanData: {},
        dirtyData: {}
    };
    $scope.redirectPhone = {
        cleanData: {},
        dirtyData: {}
    };
    $scope.products = {
        data: [],
        grouped: []
    };
    $scope.customerUsers = {
        cleanData: {},
        dirtyData: {}
    };

    $scope.detailsCheckboxes = {
        cleanData: {},
        dirtyData: {}
    };
    $scope.loading = [];
    $scope.tabs = {
        general: {
            index: 0
        },
        detail: {
            index: 1
        }
    };

    $scope.map = {
        zoom: 13,
        center : {
            latitude: $scope.environment.mapCenter.lat,
            longitude: $scope.environment.mapCenter.lng
        }
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Gets the provider from Providers service
     */
    $scope.getProvider = function() {
        var providerName = '';
        var loading = Providers.get({
                id: $scope.id,
                fieldSet: 'customerUser'
            },
            function(successResponse) {
                $scope.cleanData = successResponse.data;
                $scope.dirtyData = angular.copy($scope.cleanData);
                $scope.voiceRecord.cleanData = {
                    canVoiceRecord: angular.copy($scope.cleanData.canVoiceRecord)
                };
                $scope.voiceRecord.dirtyData = angular.copy($scope.voiceRecord.cleanData);
                providerName = successResponse.data.name;
                if(providerName) {
                    $rootScope.heading.name = $translate.instant($route.current.label) + ' - ' + providerName;
                }
                $scope.socialAccounts.cleanData = {
                    facebook: '',
                    twitter: '',
                    instagram: ''
                };
                if($scope.cleanData.facebook) {
                    var facebook = $scope.cleanData.facebook.split('facebook.com/');
                    if(facebook.length == 2) {
                        $scope.socialAccounts.cleanData.facebook = facebook[1];
                    }
                }
                if($scope.cleanData.twitter) {
                    var twitter = $scope.cleanData.twitter.split('twitter.com/');
                    if(twitter.length == 2) {
                        $scope.socialAccounts.cleanData.twitter = twitter[1];
                    }
                }
                if($scope.cleanData.instagram) {
                    var instagram = $scope.cleanData.instagram.split('instagram.com/');
                    if(instagram.length == 2) {
                        $scope.socialAccounts.cleanData.instagram = instagram[1];
                    }
                }
                $scope.socialAccounts.dirtyData = angular.copy($scope.socialAccounts.cleanData);
                $scope.getProviderDetails();
                dataLayer.push(
                    {
                        event: 'getProvider',
                        provider: {
                            id: $scope.cleanData.id,
                            name: $scope.cleanData.name,
                            category: $scope.getProviderCategory($scope.cleanData.categoryId),
                            city: $scope.getCity($scope.cleanData.cityId),
                            district: $scope.getDistrict($scope.cleanData.districtId)
                        }
                    }
                );
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Gets the provider details
     */
    $scope.getProviderDetails = function() {
        var loading = ProvidersDetailsService.getProviderDetails($scope.id, $scope);

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Sets the provider details
     */
    $scope.setProviderDetails = function() {
        var data = $scope.details.dirtyData.getDiff($scope.details.cleanData);
        if(angular.equals(data, {})) return true;

        var loading = Providers.setAdditionalData({
                id: $scope.id
            }, {
                additionalData: data
            },
            function(successResponse) {
                angular.extend($scope.details.cleanData, data);
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    function prepareProviderFormGtmData(itemId, optionValue , isChecked, formData) {
        var allFormDatas = [], chosenProviderForm, gtmData = [];

        angular.forEach(formData, function (value) {
            allFormDatas = allFormDatas.concat(value.items);
        });

        chosenProviderForm = $filter('filter')(allFormDatas, {id: parseInt(itemId)}, true)[0];

        gtmData = {
            label: chosenProviderForm.fieldName,
            selectedValues: '',
            isChecked: isChecked
        };

        if (chosenProviderForm.formOptions.length) {
            gtmData.selectedValues = $filter('filter')(chosenProviderForm.formOptions, {optionValue: optionValue}, true)[0].optionText;
        } else {
            gtmData.selectedValues = itemId;
        }

        dataLayer.push({
            event: 'providerFormData',
            providerDetails: gtmData
        });
    }

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Gets the redirect number of provider
     */
    $scope.getRedirectNumber = function() {
        var loading = Providers.getRedirectNumber({
                id: $scope.id
            },
            function(successResponse) {
                $scope.redirectPhone.cleanData = successResponse.data || {
                    providerNumber: '',
                    inboundNumber: '',
                    providerNoWeddingNumber: '',
                    hasNoWeddingNumber: false
                };
                $scope.redirectPhone.cleanData.inboundNumber = $scope.redirectPhone.cleanData.inboundNumber.replace(/^009/, '');
                if($scope.redirectPhone.cleanData.providerNoWeddingNumber) {
                    $scope.redirectPhone.cleanData.hasNoWeddingNumber = true;
                }

                $scope.phoneLoading = $q.defer();
                $timeout(function () {
                    $scope.redirectPhone.dirtyData = angular.copy($scope.redirectPhone.cleanData);
                    $scope.redirectPhone.dirtyData.hasNoWeddingNumber = $scope.redirectPhone.cleanData.hasNoWeddingNumber;
                    $scope.phoneLoading.resolve();
                }, 1000);

            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Sets the redirect number of provider
     */
    $scope.setRedirectNumber = function() {
        var data = $scope.redirectPhone.dirtyData.getDiff($scope.redirectPhone.cleanData);

        if(!$scope.redirectPhone.dirtyData.hasNoWeddingNumber) {
            data.providerNoWeddingNumber = '';
        }

        if (data.providerNumber) {
            data.providerNumber = '+' + data.providerNumber;
        } else if (data.providerNumber === '' || data.providerNumber === null) {
            delete data.providerNumber;
        }

        if (data.providerNoWeddingNumber === '' || data.providerNoWeddingNumber === null) {
            delete data.providerNoWeddingNumber;
        }

        if (data.hasNoWeddingNumber === false) {
            data.providerNoWeddingNumber = '';
        }

        if(angular.equals(data, {})) return true;

        delete data.hasNoWeddingNumber;

        var loading = Providers.setRedirectNumber({
                id: $scope.id,
                inboundNumderId: $scope.redirectPhone.cleanData.id
            },
            data,
            function(successResponse) {
                dgNotifications.success($translate.instant('CUSTOMERS.PROVIDER.REDIRECT_NUMBER_SAVED'));
                $scope.redirectPhone.cleanData = angular.copy($scope.redirectPhone.dirtyData);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Sets the provider social media accounts
     */
    $scope.setProviderSocialAccounts = function () {
        var data = $scope.socialAccounts.dirtyData.getDiff($scope.socialAccounts.cleanData);

        if(typeof data.facebook !== "undefined") {
            if(data.facebook !== '') {
                data.facebook = 'https://facebook.com/' + data.facebook;
            }
        }
        if(typeof data.twitter !== "undefined") {
            if(data.twitter !== '') {
                data.twitter = 'https://twitter.com/' + data.twitter;
            }
        }
        if(typeof data.instagram !== "undefined") {
            if(data.instagram !== '') {
                data.instagram = 'https://instagram.com/' + data.instagram;
            }
        }

        if(angular.equals(data, {})) return true;

        var loading = Providers.update({
                id: $scope.id
            },
            data,
            function(successResponse) {
                $scope.socialAccounts.cleanData = angular.copy($scope.socialAccounts.dirtyData);
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.setProviderVoiceRecord = function () {
        var data = $scope.voiceRecord.dirtyData.getDiff($scope.voiceRecord.cleanData);

        if(angular.equals(data, {})) return true;

        var loading = Providers.update({
                id: $scope.id
            },
            data,
            function(successResponse) {
                $scope.voiceRecord.cleanData = angular.copy($scope.voiceRecord.dirtyData);
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Gets the products of the provider
     */
    $scope.getProducts = function() {
        var loading = Providers.getProducts(
            {
                providerId: $scope.id,
                activeDate: moment().format('YYYY-MM-DD')
            },
            function(successResponse) {
                $scope.products.data = successResponse.data;
                $scope.products.grouped = productsGrouped($scope.products.data);
                angular.forEach($scope.products.data, function (value, index) {
                    dataLayer.push(
                        {
                            event: 'providerProducts',
                            product: {
                                name: value.productPrice.product.name,
                                dateStart: moment(value.dateStart).format('YYYY-MM-DD'),
                                dateEnd: moment(value.dateEnd).format('YYYY-MM-DD')
                            },
                            provider: {
                                id: $scope.products.data[0].provider.id,
                                name: $scope.products.data[0].provider.name,
                                category: $scope.products.data[0].provider.categoryId,
                                city: $scope.products.data[0].provider.cityId,
                                district: $scope.getDistrict($scope.cleanData.districtId)
                            }
                        }
                    );
        
                });
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @private
     * @description
     * Group provider products by their status
     */
    function productsGrouped(products) {
        var listing = [],
            doping = [],
            i,
            periodText;
        for(i in products) {
            periodText = $filter('date')(products[i].dateStart, 'longDate') + ' - ' + $filter('date')(products[i].dateEnd, 'longDate');
            if(products[i].productPrice.product.productFeature.listingTypeId) {
                if(products[i].provider.isPromotion) {
                    periodText = $translate.instant('CUSTOMERS.PROVIDER.IS_PROMOTION_INFO');
                }
                products[i].periodText = periodText;
                listing.push(products[i]);
            } else {
                products[i].periodText = periodText;
                doping.push(products[i]);
            }
        }

        return {
            listing: listing,
            doping: doping
        };
    }

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Gets the related customer users
     */
    $scope.getCustomerUsers = function() {
        var customerUsers = [];
        var loading = CustomerUsers.query({
                customerId: $scope.customerId
            },
            function(successResponse) {
                var users = angular.copy(successResponse.data);

                for (var i in users){
                    if (users[i].statusId === 3) continue;

                    var item = {};
                    item.id = users[i].id;
                    item.statusId = users[i].statusId;
                    item.name = users[i].name;
                    item.emails = [];
                    angular.forEach(users[i].contacts, function(contact, contactKey) {
                        if(contact.type == 'email') {
                            item.emails.push(contact.value);
                        }
                    });
                    item.customerId = users[i].customerId;
                    angular.forEach(users[i].customerUserProviders, function(provider, providerKey) {
                        if($scope.id == provider.providerId) {
                            item.providerId = provider.providerId;
                            item.canReceiveLeads = provider.canReceiveLeads ? true : false;
                            item.customerUserProviderId = provider.id;
                        }
                    });
                    if(item.providerId && item.canReceiveLeads) {
                        customerUsers.push(item);
                    }
                }

                $scope.customerUsers.cleanData = customerUsers;
                $scope.customerUsers.dirtyData = angular.copy($scope.customerUsers.cleanData);
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Sets the contacts (redirect number + emails)
     */
    $scope.setReceiveLeadsEmails = function () {
        var diff = angular.copy($scope.customerUsers.dirtyData.getDiff($scope.customerUsers.cleanData));
        if(angular.equals(diff, {})) return true;

        var loading,
            success = true,
            customerUserProviderSuccess = function(successResponse) {
            },
            customerUserProviderError = function(errorResponse) {
                dgNotifications.error(errorResponse);
                success = false;
            };

        angular.forEach(diff, function(user, userKey) {
            urlData = {
                id: user.customerUserProviderId,
                customerId: user.customerId,
                customerUserId: user.id
            };

            data = {
                canReceiveLeads: user.canReceiveLeads
            };

            loading = CustomerUserProviders.update(
                urlData,
                data,
                customerUserProviderSuccess,
                customerUserProviderError
            );
            $scope.loading.push(loading);
        });

        if(success) {
            dgNotifications.success($translate.instant('CUSTOMERS.PROVIDER.EMAILS_SAVED'));
        }

        $scope.customerUsers.cleanData = angular.copy($scope.customerUsers.dirtyData);

    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Gets the related provider form elements
     */
    $scope.getProviderForm = function() {
        var loading = ProvidersDetailsService.getProviderForm($scope.id, $scope);

        $scope.loading.push(loading);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Called when selected tab is changed; then writes it to URL hash using
     * queryString service.
     */
    $scope.tabSelected = function(newTab) {
        if(!newTab || newTab === $scope.selectedTab) return;

        if(!confirmLocationChange()) {
            $timeout(function() {
                $scope.tabs[newTab].active = false;
                $scope.tabs[$scope.selectedTab].active = true;
            });
            return false;
        }
        $scope.selectedTab = newTab;
        queryString.setHash(newTab);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Called when a checkbox is clicked. If value is true, insert the item
     * to $scope.details.dirtyData, else remove it.
     */
    $scope.checkboxChanged = function(itemId, optionValue) {
        var checked = $scope.detailsCheckboxes.dirtyData[itemId][optionValue];

        if(checked) {
            if(!angular.isArray($scope.details.dirtyData[itemId])) {
                $scope.details.dirtyData[itemId] = [];
            }
            $scope.details.dirtyData[itemId].push(optionValue);
        } else {
            var index = $scope.details.dirtyData[itemId].indexOf(optionValue);
            $scope.details.dirtyData[itemId].splice(index, 1);
        }

        prepareProviderFormGtmData(itemId, optionValue, checked, $scope.formData);
    };

    /**
     * @ngdoc method
     * @memberof ProviderCtrl
     * @description
     * Retrieves the user location using geolocation service
     */
    $scope.getUserLocation = function() {
        $scope.loadingMap = geolocation.getLocation().then(
            function(successData) {
                var location = [
                    successData.coords.latitude,
                    successData.coords.longitude,
                ];
                setMapMarker(location[0], location[1], true);
                $scope.details.dirtyData[$scope.mapProviderFormId] = location.join(',');
            },
            function(errorData) {
                dgNotifications.error($translate.instant('CUSTOMERS.PROVIDER.LOCATION_HAS_BEEN_TAKEN'));
            }
        );
    };

    /**
     * @ngdoc method
     * @private
     * @memberof ProviderCtrl
     * @description
     * Watch $rootScope.user.customerId and set $scope.customerId to newValue.
     */
    function getCustomerId() {
        $scope.$watch('user.customerId', function(newValue) {
            if(newValue) {
                $scope.customerId = newValue;
                $scope.getCustomerUsers();
            }
        });
    }

    /**
     * @ngdoc method
     * @private
     * @memberof ProviderCtrl
     * @param event {Object} Location change event
     * @param newUrl {string} New URL
     * @param oldUrl {string} Old URL
     * @description
     * Fired on $locationChangeStart event. Checks if user changes URL or
     * search path, if there is any unsaved data, confirm the path change.
     *
     * Also fired on hash change but does not checks unsaved data.
     */
    function locationChanges(event, newUrl, oldUrl) {
        if(newUrl.split('#')[0] === oldUrl.split('#')[0]) {
            return;
        }

        if(!confirmLocationChange()) {
            event.preventDefault();
        }
    }

    /**
     * @ngdoc method
     * @private
     * @memberof ProviderCtrl
     * @returns {Boolean} true if user accepts location change or there is no
     * unsaved data; false otherwise.
     * @description
     * Checks if there is any unsaved data. If yes, show confirmation.
     */
    function confirmLocationChange() {
        if(
            !angular.equals($scope.dirtyData, $scope.cleanData) ||
            !angular.equals($scope.details.dirtyData, $scope.details.cleanData)
        ) {
            var answer = $window.confirm($translate.instant('CUSTOMERS.PROVIDER.LOST_DATA_CONFIRMATION_QUESTION'));
            if(!answer) {
                return false;
            }
        }

        return true;
    }

    $scope.setMap = function() {
        var city = $rootScope.getCity($scope.cleanData.cityId) ? $rootScope.getCity($scope.cleanData.cityId) : '',
            district = $rootScope.getDistrict($scope.cleanData.districtId) ? $rootScope.getDistrict($scope.cleanData.districtId).name : '',
            address = city,
            location = $scope.details.cleanData[$scope.mapProviderFormId] ? $scope.details.cleanData[$scope.mapProviderFormId].split(',') : [],
            lat = parseFloat($scope.environment.mapCenter.lat),
            lng = parseFloat($scope.environment.mapCenter.lng);

        if(district) {
            address = district + ', ' + address;
        }

        if(location.length === 2) {
            lat = location[0] ? $window.parseFloat(location[0]) : parseFloat($scope.environment.mapCenter.lat);
            lng = location[1] ? $window.parseFloat(location[1]) : parseFloat($scope.environment.mapCenter.lng);
            setMapMarker(lat, lng, true);
        } else if(address) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': address}, function(results, status) {
                if (status === 'OK') {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    setMapMarker(lat, lng, true);
                } else {
                    setMapMarker(lat, lng, true);
                }
            });
        } else {
            setMapMarker(lat, lng, true);
        }
    };

    $scope.setAddress = function(){
        var address = $scope.details.dirtyData[$scope.addressProviderFormId];
        var geocoder = new google.maps.Geocoder(),
        lat = parseFloat($scope.environment.mapCenter.lat),
        lng = parseFloat($scope.environment.mapCenter.lng);

        geocoder.geocode({ "address": address}, function(results, status) {
            if (status === 'OK') {
                lat = results[0].geometry.location.lat();
                lng = results[0].geometry.location.lng();
                setMapMarker(lat, lng, true);
                $scope.details.dirtyData[$scope.mapProviderFormId] = lat + ',' + lng;       
             }else{
                setMapMarker(lat, lng, true);
                $scope.details.dirtyData[$scope.mapProviderFormId] = lat + ',' + lng;
             }
        });
    };

    $scope.agreements = {
        instagram_upload: {
            status: false,
            agreementText: null,
            signedAgreement: null,
            agreementId: null
        }
    };

    function importInstagramImages () {
        var urlData = {
            id: $scope.id
        },
        params = {
            type: 'instagram'
        };

        var loading = Providers.actions(
            urlData,
            params,
            function(successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SUCCESS_RESULT'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(loading);
    }

    $scope.getAgreement = function (type) {
        var params = {
            type: type
        };

        var loading = Agreements.getAgreementText(
            params,
            function(successResponse) {
                $scope.agreements[type].agreementText = successResponse.data[0];
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(loading);
    };

    $scope.getCustomerUserAgreement = function (type) {
        var params = {
            agreementType: type,
            'customerUserIds[]': $scope.user.id
        };

        var loading = Agreements.query(
            params,
            function(successResponse) {
                $scope.agreements[type].signedAgreement = successResponse.data;
                $scope.agreements.instagram_upload.status = !!successResponse.data.length;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(loading);
    };

    $scope.setCustomerUserAgreement = function (agreementTextId, type) {
        var defer = $q.defer();
        var loading = Agreements.setAgreement(
            {
                agreementTextId: agreementTextId
            },
            function(successResponse) {
                $scope.agreements[type].signedAgreement = [{
                    id: successResponse.id
                }];
                defer.resolve();
            },
            function(errorResponse) {
                defer.reject();
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(loading);

        return defer.promise;
    };

    $scope.deleteCustomerUserAgreement = function (type) {
        var urlData = {
            id: $scope.agreements[type].signedAgreement[0].id
        };

        var loading = Agreements.deleteAgreementSign(
            urlData,
            function(successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SUCCESS_RESULT'));
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(loading);
    };

    $scope.signInstagramImportAgreement = function (checked) {
        if (checked) {
            $q.all([
                $scope.setCustomerUserAgreement($scope.agreements.instagram_upload.agreementText.id, 'instagram_upload')
            ]).then(function () {
                importInstagramImages();
            });
        } else {
            $scope.deleteCustomerUserAgreement('instagram_upload');
        }
    };

    function setMapMarker(latitude, longitude, isCentered) {
        uiGmapGoogleMapApi.then(function(maps) {
            $scope.map.marker = {
                id: 1,
                coords: {
                    latitude: latitude,
                    longitude: longitude,
                },
                options: {
                    icon: 'images/map-marker.png',
                    draggable: true
                },
                events: {
                    dragend: function (marker) {
                        var newLat = marker.getPosition().lat();
                        var newLng = marker.getPosition().lng();

                        $scope.details.dirtyData[$scope.mapProviderFormId] = newLat + ',' + newLng;
                    }
                }
            };

            if (isCentered) {
                $scope.map.center = {
                    latitude: latitude,
                    longitude: longitude,
                };
            }
        });
    }

    $scope.mapClick = function (map, eventName, events) {
        if (eventName !== 'click') return;

        var newLat = events[0].latLng.lat();
        var newLng = events[0].latLng.lng();

        $scope.details.dirtyData[$scope.mapProviderFormId] = newLat + ',' + newLng;
        setMapMarker(newLat, newLng, false);
    };

    $scope.$on('$locationChangeStart', locationChanges);

    if($scope.id) {
        $scope.getProvider();
        if(queryString.getHash() == 'general') {
            $scope.getRedirectNumber();
            $scope.getProducts();
        }
        if(queryString.getHash() == 'relatedProviders') {
            $scope.getRelatedProviders();
        }

        $rootScope.tutorials = [];
        if(queryString.getHash() == 'detail') {
            ///detail
            $translate.onReady().then(function () {
                if ($translate.instant('CUSTOMERS.PROVIDER.DETAIL.HELPER_VIDEO.IS_EXIST') == 'true') {

                    $rootScope.tutorials.push({
                        type: 'video',
                        data: {
                            video_title: $translate.instant('CUSTOMERS.PROVIDER.DETAIL.HELPER_VIDEO.TITLE'),
                            video_code: $translate.instant('CUSTOMERS.PROVIDER.DETAIL.HELPER_VIDEO.CODE')
                        }
                    });
                }

                if ($translate.instant('CUSTOMERS.PROVIDER.DETAIL.HELPER_PAGE.IS_EXIST') == 'true') {
                    $rootScope.tutorials.push({
                        type: 'page',
                        data: {
                            page_title: $translate.instant('CUSTOMERS.PROVIDER.DETAIL.HELPER_PAGE.TITLE'),
                            page_url: 'customers/providers/helpers/details.html'
                        }
                    });
                }
            });
        } else if(queryString.getHash() !== 'relatedProviders') {
            ///general
            if ($translate.instant('CUSTOMERS.PROVIDER.RELATED_PROVIDERS.HELPER_VIDEO.IS_EXIST') == 'true') {
                $rootScope.tutorials.push({
                    type: 'video',
                    data: {
                        video_title: $translate.instant('CUSTOMERS.PROVIDER.RELATED_PROVIDERS.HELPER_VIDEO.TITLE'),
                        video_code: $translate.instant('CUSTOMERS.PROVIDER.RELATED_PROVIDERS.HELPER_VIDEO.CODE')
                    }
                });
            }

            if ($translate.instant('CUSTOMERS.PROVIDER.RELATED_PROVIDERS.HELPER_PAGE.IS_EXIST') == 'true') {
                $rootScope.tutorials.push({
                    type: 'page',
                    data: {
                        page_title: $translate.instant('CUSTOMERS.PROVIDER.RELATED_PROVIDERS.HELPER_PAGE.TITLE'),
                        page_url: 'customers/providers/helpers/general.html'
                    }
                });
            }
        } else {
            $rootScope.tutorials = [];
        }
    }

    $scope.change = function (itemId, optionValue) {
        prepareProviderFormGtmData(itemId, optionValue, true, $scope.formData);
    };

    if(queryString.getHash() && (queryString.getHash() == 'general' || queryString.getHash() == 'detail' || queryString.getHash() == 'relatedProviders')) {
        $scope.tabs[queryString.getHash()].active = true;
        $scope.selectedTab = queryString.getHash();
        $scope.selectedTabIndex = $scope.selectedTab ? $scope.tabs[$scope.selectedTab].index : null;
    } else {
        $scope.tabSelected('general');
        $scope.selectedTabIndex = $scope.tabs.general.index;
    }

    if (queryString.getHash() && (queryString.getHash() == 'general')) {
        $scope.getAgreement('instagram_upload');
        $scope.getCustomerUserAgreement('instagram_upload');
    }

    $rootScope.heading.name = $translate.instant($route.current.label);
}

ProviderCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$route',
    '$routeParams',
    '$window',
    '$timeout',
    '$filter',
    '$translate',
    '$q',
    'moment',
    'dgNotifications',
    'queryString',
    'geolocation',
    'uiGmapGoogleMapApi',
    'Providers',
    'ProvidersDetailsService',
    'CustomerUsers',
    'CustomerUserProviders',
    'Agreements'
];

angular.module('dugun.customers.providers.provider')
    .controller('ProviderCtrl', ProviderCtrl);
