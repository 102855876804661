function  DgNotificationsTranslate($translate, dgNotificationsConfig) {
    var service = {};

    service.translate = function () {

        $translate.onReady(function () {
            dgNotificationsConfig.translations.SUCCESS = $translate.instant('DG_NOTIFICATIONS.SUCCESS');
            dgNotificationsConfig.translations.ERROR = $translate.instant('DG_NOTIFICATIONS.ERROR');
            dgNotificationsConfig.translations.HTTP_500 = $translate.instant('DG_NOTIFICATIONS.HTTP_500');
            dgNotificationsConfig.translations.HTTP_403 = $translate.instant('DG_NOTIFICATIONS.HTTP_403');
            dgNotificationsConfig.translations.HTTP_404 = $translate.instant('DG_NOTIFICATIONS.HTTP_404');
        });
    };

    return service;
}

DgNotificationsTranslate.$inject = [
    '$translate',
    'dgNotificationsConfig'
];

angular.module('dugun.provider').factory('DgNotificationsTranslate', DgNotificationsTranslate);