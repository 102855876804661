/**
 *
 * @param $translate
 * @param taTranslations
 * @constructor
 */
function TextAngularTranslate($translate, taTranslations) {
    var service = {};

    service.translate = function () {
        $translate.onReady().then(function () {
            taTranslations.html.tooltip = $translate.instant('TEXT_ANGULAR.HTML_TOOLTIP');
            taTranslations.bold.tooltip = $translate.instant('TEXT_ANGULAR.BOLD_TOOLTIP');
            taTranslations.italic.tooltip = $translate.instant('TEXT_ANGULAR.ITALIC_TOOLTIP');
            taTranslations.underline.tooltip = $translate.instant('TEXT_ANGULAR.UNDERLINE_TOOLTIP');
            taTranslations.insertImage.tooltip = $translate.instant('TEXT_ANGULAR.INSERT_IMAGE_TOOLTIP');
        });
    };

    return service;
}

TextAngularTranslate.$inject = [
    '$translate',
    'taTranslations',
];

angular.module('dugun.provider').factory('TextAngularTranslate', TextAngularTranslate);
