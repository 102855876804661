/**
 * @ngdoc object
 * @memberof dugun.signatures
 * @name SignaturesListCtrl

 * @requires $scope {service} controller scope
 * @requires $route {service} $route
 * @requires $location {service} $location
 * @requires dgNotifications {service} dgNotifications
 * @requires CustomerUsers {factory} CustomerUsers service
 * @requires Signatures {factory} Signatures service
 * @requires SignatureUpload {factory} SignatureUpload service
 */
function SignaturesListCtrl($scope, $route, $filter ,$location, dgNotifications,
                            CustomerUsers, Signatures, SignatureUpload) {

    $scope.list = [];
    $scope.loading = [];

    $scope.loadList = function () {
        var promise = CustomerUsers.get(
            {
                id: 'me',
                customerId: $scope.user.customerId
            },
            function (successResponse) {
                $scope.list = successResponse.data;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );

        $scope.loading.push(promise);
    };

    $scope.setSignature = function (provider) {
        var urlData = {
                customerId: $scope.user.customerId,
                customerUserId: $scope.user.id,
                customerUserProviderId: provider.id,
            },
            data = {},
            promise;

        if (provider.signatureContent) {
            data.content = provider.signatureContent;
        }

        if(angular.equals(data, {})) {
            return true;
        }

        promise = Signatures.insert(
            urlData,
            data,
            function(successResponse) {
                $scope.loadList();
                dgNotifications.success('GENERAL.SAVED');
                var filteredProvider = $filter('filter')($scope.providersActive, {id: provider.providerId}, true)[0];
                dataLayer.push(
                    {
                        event: 'addSignatures',
                        provider: {
                            id: filteredProvider.id,
                            name: filteredProvider.name,
                            category: $scope.getProviderCategory(filteredProvider.categoryId),
                            cityId: $scope.getCity(filteredProvider.cityId)
                        }
                    }
                );
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(promise);
    };

    $scope.uploadSignature = function(provider, $files) {
        var urlData = {
            customerId: $scope.user.customerId,
            customerUserId: $scope.user.id,
            customerUserProviderId: provider.id,
        };

        angular.forEach($files, function(file) {
            file.upload = SignatureUpload.upload(file, urlData);
            file.progress = 0;

            file.upload.then(
                function(successResponse) {
                    file.result = successResponse.data;
                    provider.signatureImage = successResponse.data.image;
                },
                function(errorResponse) {
                    dgNotifications.error(errorResponse);
                },
                function(event) {
                    file.progress = (event.loaded / event.total) * 100;
                }
            );
        });
    };

    $scope.loadList();
    $scope.$root.heading.name = $route.current.label;
}

SignaturesListCtrl.$inject = [
    '$scope',
    '$route',
    '$filter',
    '$location',
    'dgNotifications',
    'CustomerUsers',
    'Signatures',
    'SignatureUpload'
];

angular.module('dugun.signatures')
    .controller('SignaturesListCtrl', SignaturesListCtrl);
