/**
 * @ngdoc controller
 * @memberof dugun.attachments
 * @name AttachmentSelectCtrl
 *
 * @requires $scope
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires $timeout
 * @requires dugun.notifications:dgNotifications
 * @requires Attachments
 * @requires AttachmentUpload
 *
 * @param data
 */
function AttachmentSelectCtrl(
    $scope, $uibModalInstance, $timeout,
    dgNotifications,
    Attachments, AttachmentUpload,
    data
) {
    $scope.data = data;
    $scope.list = [];
    $scope.filesList = [];
    $scope.loading = [];
    $scope.attachments = [];
    if($scope.data) {
        if($scope.data.selectedAttachments) {
            $scope.attachments = $scope.data.selectedAttachments;
        }
    }
    $scope.customerId = $scope.user.customerId;
    $scope.providerId = null;
    if($scope.data) {
        if($scope.data.providerId) {
            $scope.providerId = $scope.data.providerId;
        }
    }
    $scope.pagination = {
        perPage: 100,
        currentPage: 1
    };

    $scope.loadList = function () {
        if (window.getPromiseStatus($scope.loading) === 0) return true;
        if (!$scope.customerId) return true;

        var data = {
            sort: 'id',
            order: 'DESC',
            page: $scope.pagination.currentPage,
            limit: $scope.pagination.perPage,
            customerId: $scope.customerId
        };

        if($scope.providerId) {
            data['providerIds[]'] = [$scope.providerId];
        }
        var promise = Attachments.query(
            data,
            function (successResponse) {
                $scope.list = successResponse.data;
                $scope.pagination = successResponse.meta;
            }, function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    /**
     * @ngdoc method
     * @memberof AttachmentSelectCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function(data) {
        if(data) $uibModalInstance.close(data);
        else $uibModalInstance.dismiss('cancel');
    };

    $scope.uploadAttachments = function($files) {
        angular.forEach($files, function(file) {
            file.upload = AttachmentUpload.upload(file, {customerId: $scope.customerId, providerId: $scope.providerId});
            file.progress = 0;

            file.upload.then(
                function(successResponse) {
                    $timeout(function() {
                        file.result = successResponse.data;
                        $scope.loadList();
                    });
                },
                function(errorResponse) {
                    dgNotifications.error(errorResponse);
                },
                function(event) {
                    file.progress = (event.loaded / event.total) * 100;
                }
            );

            $scope.filesList.push(file);
        });
    };

    $scope.setAttachment = function () {
        $uibModalInstance.close({attachments: angular.copy($scope.attachments)});
    };

    $scope.$watch('pagination.currentPage', function (newValue, oldValue) {
        $scope.loadList();
    });

    $scope.getNextPage = function (page) {
        $scope.pagination.currentPage = page + 1;
    };
}

AttachmentSelectCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$timeout',
    'dgNotifications',
    'Attachments',
    'AttachmentUpload',
    'data',
];

angular.module('dugun.attachments')
    .controller('AttachmentSelectCtrl', AttachmentSelectCtrl);
