(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/message-carbonCopy.modal.html',
    '<div class="modal-header modal-panel-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="closeCarbonCopyModal()" ga-track-event="[\'carbonCopyLeadForm\', \'click\', \'closeIcon\']">&times;</button><h3 class="modal-title"><span>{{ \'LEADS.CC_FORM.HEADER\' | translate }}</span></h3></div><form class="form-horizontal" role="form" cg-busy="loading" ng-submit="setCarbonCopy()"><div class="modal-body modal-panel-body"><div class="row"><div class="col-md-12"><label for="email" class="input-sm text-bold mb-0">{{ \'LEADS.EMAIL\' | translate }}</label><span class="input-icon"><input type="email" class="form-control" id="email" name="email" maxlength="70" ng-model="carbonCopyData.email"> <i class="fa fa-envelope default circular"></i></span></div></div><hr><div class="pull-right"><button type="button" class="btn btn-default" ng-click="closeCarbonCopyModal()" ga-track-event="[\'carbonCopyLeadForm\', \'click\', \'closeButton\']">{{ \'LEADS.CC_FORM.CLOSE\' | translate }}</button> <button type="submit" class="btn btn-filter" ga-track-event="[\'carbonCopyLeadForm\', \'click\', \'save\']">{{ \'LEADS.CC_FORM.SAVE\' | translate }}</button></div><div class="clearfix"></div></div></form>');
}]);
})();
