function userResolver($rootScope, $q) {
    var defer = $q.defer();

    if($rootScope.user) {
        defer.resolve('success');
    } else {
        $rootScope.$on('user.got', function () {
            defer.resolve('success');
        });
    }

    return defer.promise;
}

userResolver.$inject = [
    '$rootScope',
    '$q',
];

function AgreementsRoutes($routeProvider) {
    $routeProvider
        .when('/agreements/:agreementId', {
        templateUrl: 'agreements/form.html',
        controller: 'AgreementCtrl',
        label: 'Sözleşme',
        reloadOnSearch: false,
        resolve: {
            init: userResolver
        }
    });
}
AgreementsRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.relatedProviders').config(AgreementsRoutes);
