/**
 * @ngdoc factory
 * @memberof dugun.galleries.media
 * @name MediaPopups
 *
 * @requires dugun.popup:dgPopup
 *
 * @description
 * MediaPopups factory
 */
function MediaPopups(dgPopup) {
    var service = {};

    /**
     * @ngdoc method
     * @memberof MediaPopups
     * @param galleryId {integer} ID of gallery
     * @description
     * Opens an image upload modal
     */
    service.openUpload = function(galleryId) {
        return dgPopup.openModal({
            templateUrl: 'galleries/media/upload/upload.html',
            controller: 'MediaUploadCtrl',
            itemId: galleryId
        });
    };

    /**
     * @ngdoc method
     * @memberof MediaPopups
     * @param id {integer} ID of image exists
     * @param data {Object=} Any additional data for modal controller
     * @description
     * Opens an image edit form
     */
    service.openImageForm = function(mediaId, data) {
        return dgPopup.openModal({
            templateUrl: 'galleries/media/image.form.html',
            controller: 'ProviderCtrl',
            itemId: id,
            data: data || undefined
        });
    };

    return service;
}

MediaPopups.$inject = [
    'dgPopup',
];

angular.module('dugun.galleries.media')
    .factory('MediaPopups', MediaPopups);
