/**
 * @memberof dugun.provider.home
 * @ngdoc route
 * @name HomeRoutes
 * @param $routeProvider {provider} $routeProvider
 *
 * @description
 * /
 */

function DugunLoader($rootScope, $q) {
    var deferred = $q.defer();
    $q.all($rootScope.loadingBase).finally(function() {
        deferred.resolve();
    });
    return deferred.promise;
}

DugunLoader.$inject = [
    '$rootScope',
    '$q',
];

function HomeRoutes($routeProvider) {
    $routeProvider
        .when('/', {
            templateUrl: 'home/home.html',
            controller: 'HomeCtrl',
            label: 'Ana Sayfa',
            resolve: {
                loader: DugunLoader
            }
        });
}

HomeRoutes.$inject = [
    '$routeProvider'
];

angular.module('dugun.provider.home').config(HomeRoutes);
