function TextAngularDelegate($delegate, $translate, environmentConfig) {
    var langCookie = document.cookie.match('(^|;) ?lang=([^;]*)(;|$)');
    var lang = langCookie ? langCookie[2] : null;
    var translations = {
        ar: {
            "html": "تنشيط - إلغاء تنشيط Html/نص منسق",
            "bold": "عريض",
            "italic": "خط مائل",
            "underline": "وضع خط تحت الكلمة",
            "insertImage": "إضافة صورة"
        },
        en: {
            "html": "Activate-Deactivate Html/Rich Text",
            "bold": "Bold",
            "italic": "Italic",
            "underline": "Underline",
            "insertImage": "Insert Image"
        },
        tr: {
            "html": "Html/Zengin Metin Aç-Kapa",
            "bold": "Kalın",
            "italic": "İtalik",
            "underline": "Alt Çizgi",
            "insertImage": "Görsel Ekle"
        }
    };
    var currentLang = lang ? lang : environmentConfig.languages.default;
    for (var key in translations[currentLang]) {
        $delegate[key].tooltip = translations[currentLang][key];
    }
    return $delegate;
}

function TextAngularConfig($provide) {
    $provide.decorator('taTranslations', TextAngularDelegate);
}

TextAngularDelegate.$inject = [
    '$delegate',
    '$translate',
    'environmentConfig',
];

TextAngularConfig.$inject = [
    '$provide',
];

angular.module('dugun.provider').config(TextAngularConfig);
