/**
 * @ngdoc controller
 * @memberof dugun.discounts
 * @name DiscountListCtrl
 *
 * @requires $scope
 * @requires $route
 * @requires $window
 * @requires dugun.notifications:dgNotifications
 * @requires queryString
 * @requires Analytics
 * @requires moment
 * @requires Discounts
 */
function DiscountListCtrl($scope, $route, $window, $translate,
                          dgNotifications, queryString, moment,
                          Discounts) {
    $scope.$root.heading.name = $route.current.label;

    $scope.openItemForm = function(item) {
        var editable = true;
        if(!item) {
            if($scope.providersWithDiscount.length === 0) {
                $scope.openUpgradeModal('discounts');
                return;
            }
        } else {
            if(item.listingType === 'published') {
                editable = false;
            }
        }
        $scope.openModal(
            'discounts/form.modal.html',
            'DiscountCtrl',
            item ? item.id : null,
            null,
            {
                editable: editable,
                discountTotal: $scope.list.length
            }
        ).result.then(function(data){
            if(data == 'success'){
                $scope.loadList();
            }
        });
    };

    $scope.openItemStatic = function(item) {
        $scope.openModal(
            'discounts/show.modal.html',
            'DiscountCtrl',
            item ? item.id : null
        );
    };

    if(queryString.getHash() == 'new') {
        $scope.openItemForm();
        queryString.setHash('');
    }

    $scope.list = [];
    $scope.loading = [];

    $scope.orderBy = {
        key: 'validFrom',
        direction: true
    };

    $scope.loadList = function() {
        if($window.getPromiseStatus($scope.loading) === 0) return true;
        var data = {};

        data.sort = $scope.orderBy.key;
        data.order = $scope.orderBy.direction ? 'DESC' : 'ASC';

        data.paginate = 0;
        data['scopes[]'] = ['getEmailLogCounts'];

        var promise = Discounts.query(
            data,
            function(successResponse) {
                $scope.list = manipulateList(successResponse.data);

            }, function(errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(promise);
    };

    function manipulateList(list) {
        var i;

        for(i in list) {
            if(list[i].isPrimary) {
                list[i].primaryDiscount = true;
            }
            if(list[i].discountType === 'discount') {
                list[i].discountText = '%' + list[i].discountRatio;
            } else {
                list[i].discountText = $scope.getConfigItem('discount_type', list[i].discountType);
            }
        }

        return list;
    }

    $scope.isDiscountBanner = function(){
        return function(discount) {
            return discount.listingType == 'pendingApproval' || discount.listingType == 'unpublished' || discount.listingType == 'future' || discount.listingType == 'published';
        };
    };

    $scope.markAsPrimary = function(item) {
        Discounts.update(
            { id: item.id },
            { isPrimary: true },
            function (successResponse) {
                $scope.loadList();
            },
            function(errorResponse) {
                item.isPrimary = false;
                dgNotifications.error(errorResponse);
            });
    };

    $scope.loadList();

    $translate.onReady().then(function () {
        if ($translate.instant('DISCOUNTS.HELPER_VIDEO.IS_EXIST') == 'true') {
            $scope.$root.tutorials.push(
                {
                    type: 'video',
                    data: {
                        video_title: $translate.instant('DISCOUNTS.HELPER_VIDEO.VIDEO_TITLE'),
                        video_code: $translate.instant('DISCOUNTS.HELPER_VIDEO.VIDEO_CODE')
                    }
                }
            );
        }
        if ($translate.instant('DISCOUNTS.HELPER_PAGE.IS_EXIST') == 'true') {
            $scope.$root.tutorials.push(
                {
                    type: 'page',
                    data: {
                        page_title: $translate.instant('DISCOUNTS.HELPER_PAGE.PAGE_TITLE'),
                        page_url: 'discounts/helper.html'
                    }
                }
            );
        }

    });
}

DiscountListCtrl.$inject = [
    '$scope',
    '$route',
    '$window',
    '$translate',
    'dgNotifications',
    'queryString',
    'moment',
    'Discounts'
];

angular.module('dugun.discounts').controller('DiscountListCtrl', DiscountListCtrl);
