/**
 * @ngdoc controller
 * @memberof dugun.discounts.providerImages
 * @name ProviderImageSelectionCtrl
 *
 * @requires $scope
 * @requires $uibModalInstance
 * @requires $window
 * @requires Analytics
 * @requires ProviderGallery
 *
 * @param itemId
 * @param data
 */
function ProviderImageSelectionCtrl($scope, $uibModalInstance, $window,
                      Analytics,
                      ProviderGallery,
                      itemId, data) {

    $scope.providerId = $window.parseInt(itemId);
    $scope.galleries = [];
    $scope.discountImages = data.discountImages;

    $scope.close = function() {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.submit = function() {
        $uibModalInstance.close({discountImages: angular.copy($scope.discountImages)});
    };

    $scope.getImages = function() {
        $scope.loading = ProviderGallery.query(
            {
                providerId: $scope.providerId,
                scopes: 'images'
            },
            function(successResponse) {
                for(var i in successResponse.data) {
                    for(var j in successResponse.data[i].medias) {
                        if(data.discountImages.indexOf(successResponse.data[i].medias[j].id) > -1) {
                            successResponse.data[i].medias[j].selected = true;
                        }
                    }
                }
                $scope.galleries = successResponse.data;
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                $scope.close();
            }
        );
    };

    $scope.imageToggled = function(image) {
        if(image.selected) {
            $scope.discountImages.push(image.id);
            Analytics.trackEvent('discuntProviderImagesForm', 'select', 'discountImage');
        } else {
            var index = $scope.discountImages.indexOf(image.id);
            if(index) $scope.discountImages.splice(index, 1);
            Analytics.trackEvent('discuntProviderImagesForm', 'deselect', 'discountImage');
        }
    };

    $scope.getImages();
}

ProviderImageSelectionCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    'Analytics',
    'ProviderGallery',
    'itemId',
    'data',
];

angular.module('dugun.discounts.providerImages')
    .controller('ProviderImageSelectionCtrl', ProviderImageSelectionCtrl);
