function CacheConfig(CacheFactoryProvider) {
    angular.extend(CacheFactoryProvider.defaults, {
        maxAge: 60*60*1000, // 1 hour
        cacheFlushInterval: 24*60*60*1000, // 24 hours
        deleteOnExpire: 'aggressive',
        storageMode: 'localStorage'
    });
}

CacheConfig.$inject = [
    'CacheFactoryProvider',
];

angular.module('dugun.provider').config(CacheConfig);
