(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('leads/rejection.modal.html',
    '<div class="modal-header modal-panel-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true" ng-click="closeRejectionModal()" ga-track-event="[\'rejectionLeadForm\', \'click\', \'closeIcon\']">&times;</button><h3 class="modal-title"><span>{{\'LEADS.REJECTION_FORM.HEADER\' | translate}}</span></h3></div><form class="form-horizontal" role="form" cg-busy="loading" ng-submit="setRejection()"><div class="modal-body modal-panel-body"><p class="mb-10 help-block" ng-bind-html="\'LEADS.REJECTION_FORM.REJECTION_REASON_LABEL\' | translate"></p><div class="row"><div class="col-md-12"><select class="form-control" ng-model="rejection.reasonId" required><option></option><option ng-repeat="item in getConfig(\'info_request_reject_reason_id\')" value="{{item.itemId}}">{{item.value}}</option></select></div></div><hr><div class="pull-right"><button type="button" class="btn btn-default" ng-click="closeRejectionModal()" ga-track-event="[\'rejectionLeadForm\', \'click\', \'closeButton\']">{{\'LEADS.REJECTION_FORM.CLOSE\' | translate}}</button> <button type="submit" class="btn btn-filter" ga-track-event="[\'rejectionLeadForm\', \'click\', \'save\']">{{\'LEADS.REJECTION_FORM.SAVE\' | translate}}</button></div><div class="clearfix"></div></div></form>');
}]);
})();
