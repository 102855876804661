/**
 * Controls the couple lead modals
 * @ngdoc controller
 * @memberof dugun.relatedProviders
 * @name RelatedProviderGalleryCtrl
 *
 * @requires $scope {service} controller scope
 * @requires $uibModalInstance {service} $uibModalInstance
 * @requires $window {service} $window
 * @requires dgNotifications {service} dgNotifications
 * @requires RelatedProviders {factory} RelatedProviders service
 *
 * @param itemId {integer=} ID of CustomerUser to use
 * @param data {object=} related provider object
 */
function RelatedProviderGalleryCtrl($scope, $uibModalInstance, $window, $translate,
                        dgNotifications,
                        RelatedProviders,
                        itemId, data) {
    $scope.isApproved = false;
    $scope.cleanData = {};
    $scope.dirtyData = {};
    $scope.relatedProviderData = data;

    $scope.$on('modal.closing', function(event, reason, closed) {
        var message = $translate.instant('RELATED_PROVIDERS.UNSAVED_DATA_CONFIRMATION_QUESTION'),
            data = $scope.dirtyData.getDiff($scope.cleanData);
        if(angular.equals(data, {})) {
            return;
        }
        switch (reason){
            // clicked outside
            case "backdrop click":
                break;

            // cancel button
            case "cancel":
                break;

            // escape key
            case "escape key press":
                break;
        }
        if (!confirm(message)) {
            event.preventDefault();
        }
    });

    /**
     * @ngdoc method
     * @memberof RelatedProviderGalleryCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.close(angular.copy($scope.cleanData));
    };

    /**
     * @ngdoc method
     * @memberof RelatedProviderGalleryCtrl
     * @description
     * set the gallery
     */
    $scope.setGallery = function() {
        var urlParams = {
                id: $scope.relatedProviderData.id,
                providerId: $scope.relatedProviderData.provider.id
            },
            params = $scope.dirtyData.getDiff($scope.cleanData);

        if(angular.equals(params, {})) return true;

        params = params.convertDates(['weddingDate']);

        if (params.couplePhone) {
            params.couplePhone = '+' + params.couplePhone;
        } else if (params.couplePhone === null || params.couplePhone === '') {
            delete params.couplePhone;
        }

        $scope.loading = RelatedProviders.insertGallery(
            urlParams,
            params,
            function(successResponse) {
                dgNotifications.success($translate.instant('GENERAL.SAVED'));
                $scope.cleanData = angular.copy($scope.dirtyData);
                $scope.close();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
    };

    $scope.datepickerOptions = {
        maxDate: new Date(),
    };
}

RelatedProviderGalleryCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$window',
    '$translate',
    'dgNotifications',
    'RelatedProviders',
    'itemId',
    'data'
];

angular.module('dugun.relatedProviders')
    .controller('RelatedProviderGalleryCtrl', RelatedProviderGalleryCtrl);
