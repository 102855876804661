/**
 * Leads factory
 * @constructor
 * @ngdoc factory
 * @memberof dugun.leads
 * @name Leads
 * @requires dugun.core:resource
 *
 * @returns {service} Leads resource service
 */
function Leads(resource, CacheFactory, $translate, $rootScope, environmentConfig) {
    var url = 'leads/:id';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof Leads
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get lead list
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name get
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get single lead by id
             */
            get: {
                method: 'GET',
                transformResponse: function(response) {
                    if(!response) {
                        return response;
                    }

                    if (response.status_code === 403 && response.status_text === 'Forbidden') {
                        $rootScope.openUpgradeModal('leads', response.message);
                    }

                    if(response.data.unmaskPhone) {
                        response.data.unmaskPhone = response.data.unmaskPhone.number;
                    }

                    if (environmentConfig.code === 'zafaf') {
                        response.data.leadDetails.email = angular.copy(response.data.unmaskEmail);
                        response.data.leadDetails.phone = angular.copy(response.data.unmaskPhone);
                    }

                    return response;
                }
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name get
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get leads messages
             */
            getMessages: {
                url: url + '/messages',
                method: 'GET',
                transformResponse: function(response) {
                    if(!response) {
                        return response;
                    }
                    if(response.meta.outboundNumber) {
                        response.meta.outboundNumber = response.meta.outboundNumber.replace(/^009/, '');
                        response.meta.outboundNumber = response.meta.outboundNumber.replace(/^\(009/, '(');
                    }

                    return response;
                }
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name insertMessage
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * add message
             */
            insertMessage: {
                url: url + '/messages',
                method: 'POST',
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name sendSMS
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * send SMS
             */
            sendSMS: {
                url: url + '/messages/sms',
                method: 'POST',
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name update
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * update lead
             */
            update: {
                url: 'leads/:id',
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name unreadCount
             * @param params {Object} Parameters to send in GET data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get the unread leads count
             */
            unreadCount: {
                url: 'leads/unread-counts',
                method: 'GET'
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name countsByReadStatus
             * @param params {Object} Parameters to send in GET data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get the unread leads count and read leads count
             */
            countsByReadStatus: {
                url: 'leads/counts-by-read-status',
                method: 'GET'
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name averageResponseTime
             * @param params {Object} Parameters to send in GET data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get the average response time of leads
             */
            averageResponseTime: {
                url: 'leads/average-response-time',
                method: 'GET'
            },
            providerAverageResponseTime: {
                url: 'leads/provider-average-response-time',
                method: 'GET'
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name averageResponseTime
             * @param params {Object} Parameters to send in GET data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Approve couple providers
             */
            approveCoupleProvider: {
                url: 'couple-providers/:coupleProviderId/actions',
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name getCoupleData
             * @param params {Object} Parameters to send in GET data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * get couple data
             */
            getCoupleData: {
                url: 'couples/:id/data',
                method: 'GET'
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name getProviderItems
             * @param params {Object} Parameters to send in GET data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * get provider items
             */
            getProviderItems: {
                url: url + '/items',
                method: 'GET'
            },
            /**
             * @ngdoc method
             * @memberof Leads
             * @name getProviderItems
             * @param params {Object} Parameters to send in GET data
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * load lead history
             */
            getLeadHistory: {
                url: url + '/histories',
                method: 'GET'
            }
        }
    );

    return resourceService;
}

Leads.$inject = [
    'resource',
    'CacheFactory',
    '$translate',
    '$rootScope',
    'environmentConfig'
];

angular.module('dugun.leads')
    .factory('Leads', Leads);
