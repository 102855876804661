function Discounts(resource) {
    var url = 'discounts/:id';

    var resourceService = resource(
        url,
        {
            id: '@id'
        },
        {
            query: {
                isArray: false
            },
            update: {
                url: 'discounts/:id',
                method: 'PUT'
            },
            insert: {
                url: 'discounts/:id',
                method: 'POST'
            },
            countsByStatus: {
                url: 'discounts/counts-by-status',
                method: 'GET'
            },
            getProducts: {
                url: 'providers/:id/active-product',
                method: 'GET'
            }
        }
    );

    return resourceService;
}

Discounts.$inject = [
    'resource'
];

angular.module('dugun.discounts').factory('Discounts', Discounts);
