/**
 * @ngdoc object
 * @memberof dugun.leads
 * @name LeadsListCtrl
 * @scope
 * @requires $scope {service} controller scope
 * @requires $route {service} $route
 * @requires $routeParams {service} $routeParams
 * @requires $window {service} $window
 * @requires $timeout
 * @requires $q
 * @requires $uibModal
 * @requires $filter
 * @requires dgNotifications {service} dgNotifications
 * @requires queryString {service} dugun.queryString:queryString
 * @requires moment {service} moment
 * @requires Analytics
 * @requires $translate
 * @requires Providers
 * @requires Leads {factory} Leads service
 * @requires LeadInteractions {factory} LeadInteractions service
 * @requires MailTemplates
 * @requires AttachmentsPopups
 */
function LeadsListCtrl(
    $scope, $rootScope, $route, $routeParams, $window, $timeout, $q, $uibModal, $filter,
    dgNotifications, queryString, moment, Analytics, $translate, $location,
    Providers, Leads, LeadInteractions, MailTemplates, AttachmentsPopups, CustomerUsers,
    Statistics
) {
    /**
     * @memberOf LeadsListCtrl
     * @description
     * Array of promises, used for cgBusy directive
     */
    $scope.loading = [];
    $scope.loadingResponseTime = [];
    $scope.pageLoaded = false;
    $scope.maxMessageLength = '';
    $scope.showProviderItemDetails = false;

    /**
     * @memberOf LeadsListCtrl
     * @description
     * Pagination data
     */
    $scope.pagination = {
        currentPage: 1,
        perPage: 25
    };

    $scope.csvFileName = {
        dugun: 'duguncom_panel_ciftler.csv',
        zafaf: 'zafaf_panel_couples.csv'
    };

    $scope.statuses = {
        negotiating: $translate.instant('LEADS.NEGOTIATING'),
        offered: $translate.instant('LEADS.OFFERED'),
        appointment: $translate.instant('LEADS.APPOINTMENT'),
        offer_pending: $translate.instant('LEADS.OFFER_PENDING'),
    };

    $scope.remindfulProcessStates = [
        'offer_pending',
        'appointment'
    ];
    /**
     * @memberOf LeadsListCtrl
     * @description
     * leads allOption
     */
    $scope.allOption = [
        {itemId: 'all', value: $translate.instant('GENERAL.ALL')}
    ];

    /**
     * @memberOf LeadsListCtrl
     * @description
     * leads first lead type options
     */
    $scope.firstLeadTypeOptions = [
        {itemId: 'all', value: $translate.instant('GENERAL.ALL')},
        {itemId: 'call', value: $translate.instant('LEADS.CALL_LEAD')},
        {itemId: 'infoRequest', value: $translate.instant('LEADS.LEAD')},
        {itemId: 'plusLead', value: $translate.instant('LEADS.PLUS_LEAD')}
    ];

    /**
     * @memberOf LeadsListCtrl
     * @description
     * leads query limits
     */
    $scope.limits = [25, 50, 100, 250];

    /**
     * @memberOf LeadsListCtrl
     * @description
     * array to be exported
     */
    $scope.leadsCSV = [];

    $scope.selectedTab = 'details';
    $scope.selectedTabIndex = 0;

    $scope.tabs = {
        details: {
            index: 0
        },
        messages: {
            index: 1
        },
        notes: {
            index: 2
        },
        interactions: {
            index: 3
        }
    };
    $scope.listTabs = {
        leads: {
            index: 0
        },
        "couple-appointment": {
            index: 1
        },
        "couple-deal": {
            index: 2
        }
    };
    $scope.showInteractionButtons = false;
    $scope.selectedMailTemplateId = null;

    /**
     * @memberOf LeadsListCtrl
     * @description
     * Filters object. It contains all filters that will be sent to service.
     * Gets the filters from queryString service on load.
     */
    $scope.filters = queryString.getFilters() || {};
    var dates = queryString.getDatesFromFilters('createdAt',
        moment().add(-3, 'month').add(1, 'day'),
        moment()
    );

    if(!$routeParams.id) {
        $scope.filters.createdAtStart = dates.startDate.format('YYYY-MM-DD');
        $scope.filters.createdAtEnd = dates.endDate.format('YYYY-MM-DD');
    }
    /**
     * @memberOf LeadsListCtrl
     * @description
     * Date of today. Used for date range picker.
     */
    $scope.today = new Date();

    $scope.orderBy = {
        key: 'lastActionAt',
        direction: true
    };

    $scope.leadStats = {
        unreadLeadCount: 0,
        leadCount: 0,
        responseTime: {
            percentage: 0,
            percentageClass: '',
            providerAverage: 0,
            categoryAverage: 7,
            categoryAverageLevel2: 15
        }
    };

    $scope.tabSelected = function(newTab) {
        if(!newTab || newTab === $scope.selectedTab) return;

        $scope.selectedTab = newTab;
        $scope.selectedTabIndex = $scope.tabs[newTab].index;

        if(newTab == 'messages') {
            $timeout(function() {
                angular.element(document.querySelector('.messages-list')).scrollTop(document.querySelector('.messages-list').scrollHeight);
            });
        }
    };
    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Loads the lists
     */
    $scope.loadList = function() {
        if($window.getPromiseStatus($scope.loading) === 0) return true;
        if($routeParams.id) {
            return true;
        }
        $scope.pageLoaded = true;
        var data = angular.copy($scope.filters);
        var gaFilters = [];

        data.limit = $scope.pagination.perPage;
        data['scopes[]'] = ['withItemCount'];

        if(data.statusId == 'all') {
            delete data.statusId;
        }

        if (data.statusId && $filter('filter')($rootScope.getConfig('lead_process_state'), {itemId: data.statusId}).length) {
            data.processStateId = data.statusId;
            delete data.statusId;
        }

        if(data.firstLeadType == 'all') {
            delete data.firstLeadType;
        }

        if (data['providerIds[]']) {
            var provider = $scope.getProvider(data['providerIds[]'][0] ? data['providerIds[]'][0] : data['providerIds[]']);
            dataLayer.push(
                {
                    event: 'leadFilter',
                    provider: {
                        id: provider.id,
                        name: provider.name,
                        city: $scope.getCity(provider.cityId),
                        category: $scope.getProviderCategory(provider.categoryId)
                    }
                }
            );
        }

        data.sort = $scope.orderBy.key;
        data.order = $scope.orderBy.direction ? 'DESC' : 'ASC';

        angular.forEach($scope.filters, function(value, key) {
            if(value !== '' && value !== null){
                gaFilters.push(key);
            }
        });
        if(gaFilters.length > 0) {
            Analytics.trackEvent('leadsFilter', 'filter', gaFilters.sort().join('|'));
        }
        var loading = Leads.query(
            data,
            function(successResponse) {
                successResponse.data = successResponse.data;
                $scope.leadsCSV = [];
                angular.forEach(successResponse.data, function(item, key){
                    if(item) {
                        if(item.leadDetails) {
                            $scope.leadsCSV.push({
                                coupleName: item.leadDetails.name ? item.leadDetails.name : $translate.instant('LEADS.UNKNOWN'),
                                organizationDate: item.leadDetails.organizationDate ? moment(item.leadDetails.organizationDate).format('DD MMMM YYYY') : $translate.instant('LEADS.UNKNOWN_ORGANIZATION_DATE'),
                                coupleExtension: item.extensionNumber,
                                couplePhone: item.unmaskPhone ? item.unmaskPhone.rawNumber : $translate.instant('LEADS.UNKNOWN'),
                                coupleEmail: item.unmaskEmail ? item.unmaskEmail : $translate.instant('LEADS.UNKNOWN'),
                                createdAt: moment(item.createdAt).format('DD MMMM YYYY H:mm:ss')
                            });
                        }
                    }
                    var duration = moment.duration(moment().diff(successResponse.data[key].updatedAt));
                    var hoursDuration = duration.asHours();
                    var minutesDuration = duration.asMinutes();
                    var addedFrom = '';
                    if(hoursDuration >= 1) {
                        addedFrom += $translate.instant('LEADS.PENDING_HOURS', {hours: parseInt(hoursDuration)});
                    } else {
                        addedFrom += $translate.instant('LEADS.PENDING_MINUTES', {minutes: parseInt(minutesDuration)});
                    }
                    successResponse.data[key].addedFrom = addedFrom;
                    if(successResponse.data[key].callAgainDate) {
                        if(moment() < moment(successResponse.data[key].callAgainDate)) {
                            successResponse.data[key].callAgainDate = moment(successResponse.data[key].callAgainDate).format('LLL');
                        } else {
                            successResponse.data[key].callAgainDate = null;
                        }
                    }
                });

                $scope.list = successResponse.data;
                //$scope.list[0].isRemindable = true;
                $scope.pagination = successResponse.meta;
                $scope.filters.page = successResponse.meta.currentPage;
                loadListReminder();
                getAppointmentsCount();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };
    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Approve Or Disapprove
     */
    $scope.approveCoupleProvider = function (type, item) {
        var params = {
            coupleProviderId: item.coupleProvider.id
        }, data = {
            type: type
        };

        var loading = Leads.approveCoupleProvider(
            params,
            data,
            function (successResponse) {
                $scope.slide.coupleProviderBanner = true;
                if (data.type === 'approve') {
                    $scope.$root.heading.scope.leadData.status = 'deal';
                    $scope.$root.heading.scope.showInteractionButtons = false;
                }
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(loading);
    };

    function getCoupleProviders(providerApproveStatus, providerIds, coupleId) {
        var createdAtStart = '2019-02-19';

        var params = {
            'providerIds[]': providerIds,
            providerApproveStatus: providerApproveStatus,
            sort: 'createdAt',
            order: 'DESC',
            createdAtStart: createdAtStart
        };

        if (coupleId) {
            params.coupleId = coupleId;
        }
        $scope.coupleProviderList = [];
        var loading = Statistics.coupleProviders(
            params,
            function (successResponse) {
                $scope.coupleProviderList = successResponse.data;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            });

        $scope.loading.push(loading);
    }

    function getCoupleAppointments(id) {
        var deferred = $q.defer();
        var data = {
            leadId: id,
            'interactionTypes[]': ['appointment'],
            isCreatedByCouple: true
        };
        var loading = LeadInteractions.query(
            data,
            function (successResponse) {
                $scope.coupleAppointment = successResponse.data.length ? successResponse.data[0] : null;
                deferred.resolve();
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(loading);

        return deferred.promise;
    }

    $scope.$watch('listActiveTab', function (newVal, oldVal) {
        if (newVal === 'couple-appointment') {
            $scope.filters['leadInteractionTypes[]'] = ['appointment'];
            delete $scope.filters.coupleDealStatus;
            $scope.setFilters();
        }

        if (newVal === 'couple-deal') {
            getCoupleProviders('pending', $scope.user.providerIds);
        }

        if (newVal === 'leads') {
            delete $scope.filters['leadInteractionTypes[]'];
            delete $scope.filters.coupleDealStatus;
            $scope.setFilters();
        }
    });

    function getAppointmentsCount() {
        var urlParams = angular.copy($scope.filters);
        urlParams['leadInteractionTypes[]'] = ['appointment'];
        urlParams.limit = 1;

        Leads.query(
            urlParams,
            function (success) {
                $scope.totalCoupleAppointments = success.meta.total;
            }
        );
    }

    function loadListReminder() {
        var notReminder = angular.copy($scope.list);
        $scope.createdDate = new moment();
        var urlParams = {
            remindAtStart: moment($scope.createdDate).add(-1, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            remindAtEnd:moment($scope.createdDate).format('YYYY-MM-DD HH:mm:ss'),
            status: 'negotiating',
            'processStateIds[]': $scope.remindfulProcessStates
        };

        Leads.query(
            urlParams,
            function (success) {
                $scope.loadlistReminder = success.data;
                $scope.listReminder = $scope.loadlistReminder.concat(notReminder);
            }
        );
    }

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Approve couple appointment or couple provider from list
     */
    $scope.action = function (type, status, item, index) {
        if (type === 'coupleProvider') {
            $scope.approveCoupleProvider(status, item);
            $scope.coupleProviderList.splice(index, 1);
            dataLayer.push({
                event: 'dealApprove',
                provider: {
                    id: item.coupleProvider.provider.id,
                    name: item.coupleProvider.provider.name,
                    category: $scope.getProviderCategory(item.coupleProvider.provider.categoryId),
                    city: item.coupleProvider.provider.city.name,
                },
                deal: {
                    id: item.coupleProvider.id,
                    status: status,
                },
                providerApprovalStatus: 'pending',
                status: status,
                lead: {
                    id: item.leadId,
                }
            });
        }

        if (type === 'coupleAppointment') {
            $q.all([
                getCoupleAppointments(item.id)
            ]).then(function () {
                $scope.toggleStatus(status, null, null, $scope.coupleAppointment.id, $scope.coupleAppointment.lead.id, item);
                $scope.list.splice(index, 1);
            });
            dataLayer.push({
                event: 'appointmentApprove',
                provider: {
                    id: item.provider.id,
                    name: item.provider.name,
                    category: $scope.getProviderCategory(item.provider.categoryId),
                    city: $scope.getCity(item.provider.cityId)
                },
                appointment: {
                    id: item.id,
                    status: status
                },
                status: status,
                lead: {
                    id: item.id
                }
            });
        }
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Loads the lists
     */

    $scope.loadResponseTime = function () {
        if($window.getPromiseStatus($scope.loadingResponseTime) === 0) return true;
        var filters = {
            'providerIds[]': $scope.filters['providerIds[]']
        };
        var loadingResponseTime = Leads.providerAverageResponseTime(
            filters,
            function(successResponse) {
                $scope.leadStats.responseTime.providerAverage = successResponse.data;
                if ($scope.leadStats.responseTime.providerAverage) {
                    $scope.leadStats.responseTime.providerAverage = Math.floor($scope.leadStats.responseTime.providerAverage / 60);
                }
            },
            function(response) {
                dgNotifications.error(response);
            });

        $scope.loadingResponseTime.push(loadingResponseTime);
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Opens lead
     */
    $scope.getLead = function(id) {
        $scope.incomingLeadsList = [];
        var deferred = $q.defer();
        $scope.pageLoaded = true;
        $scope.showDetails = true;
        $scope.oldFilters = angular.copy($scope.filters);
        queryString.setFilters({id: id});
        var loading = Leads.get(
            {
                id: id
            },
            function(successResponse) {
                var duration = moment.duration(moment().diff(successResponse.data.updatedAt));
                var hoursDuration = duration.asHours();
                var minutesDuration = duration.asMinutes();
                var addedFrom = '';
                if(hoursDuration >= 1) {
                    addedFrom += $translate.instant('LEADS.PENDING_HOURS', {hours: parseInt(hoursDuration)});
                } else {
                    addedFrom += $translate.instant('LEADS.PENDING_MINUTES', {minutes: parseInt(minutesDuration)});
                }
                successResponse.data.addedFrom = addedFrom;
                $scope.leadData = successResponse.data;
                $scope.leadAppointment = {};
                $scope.leadNote = {};
                $scope.details = formatDetails(successResponse.data.leadDetails);
                if($scope.leadData.status == 'deal' || $scope.leadData.status == 'reject') {
                    $scope.showInteractionButtons = false;
                }
                $scope.$root.heading.url = 'leads/couple-header.html';
                $scope.$root.heading.data = successResponse.data;
                $scope.$root.heading.scope = $scope;
                var leadType = successResponse.data.isRead ? 'read' : 'unread';
                getCoupleAppointments(id);
                getCoupleProviders('pending', [$scope.leadData.providerId], $scope.leadData.coupleId);

                if ($scope.leadData.firstLeadType === 'plusLead') {
                    getCoupleData($scope.leadData.couple, $scope.leadData.provider);
                }

                getProviderItems($scope.leadData.id);

                var provider = $filter('filter')($scope.providersActive, {id: $scope.leadData.provider.id}, true)[0];
                dataLayer.push({
                    event: 'leadOpen',
                    lead: {
                        id: $scope.leadData.id,
                        status: $scope.leadData.status,
                        createdAt: moment($scope.leadData.createdAt).format('YYYY-MM-DD'),
                        organizationDate: moment($scope.leadData.couple.organizationDate).format('YYYY-MM-DD')
                    },
                    provider: {
                        id: $scope.leadData.provider.id,
                        name: $scope.leadData.provider.name,
                        category: $scope.getProviderCategory(provider.categoryId),
                        city: $scope.getCity(provider.cityId)
                    },
                    couple: {
                        id: $scope.leadData.coupleId,
                    },
                });

                deferred.resolve();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                deferred.reject();
                $scope.hideDetails(true);
            }
        );
        $scope.loading.push(loading);
        return deferred.promise;
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @private
     * @param leadDetails {Object} leadDetails
     * @returns details {Object} key-value details
     * @description
     * getCoupleData
     */
    function getCoupleData(couple, provider) {
        var categoryId = $scope.getProvider(provider.id).categoryId;

        var params = {
            id: couple.id
        };

        var loading = Leads.getCoupleData(
            params,
            function (successResponse) {
                $scope.coupleData = $filter('filter')(successResponse.data, {providerForm: {category: {id: categoryId}}}, true);
                $scope.modifiedCoupleData = [];
                var filtered = [];
                angular.forEach($scope.coupleData, function (item) {
                    filtered = [];
                    if (item.providerForm.fieldType === "textarea") {
                        $scope.modifiedCoupleData.push({
                            fieldName: item.providerForm.coupleLabel,
                            value: [{optionText: item.value}]
                        });
                    } else {
                        item.value = item.value.split(',');

                        angular.forEach(item.providerForm.formOptions, function (option) {
                            if (item.value.indexOf(option.optionValue + '') !== -1) {
                                filtered.push(option);
                            }
                        });
                        $scope.modifiedCoupleData.push({
                            fieldName: item.providerForm.coupleLabel,
                            value: filtered
                        });
                    }
                });
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    }

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @private
     * @param leadDetails {Object} leadDetails
     * @returns details {Object} key-value details
     * @description
     * Convert details array to key-value object
     */
    function formatDetails(leadDetails) {
        var details = [],
            i,
            detail = {};
        if(leadDetails) {
            var detailsArray = leadDetails.infoRequestStoreDatas;
            for(i in detailsArray) {
                if(detailsArray[i]) {
                    if(detailsArray[i].infoRequestForm) {
                        if(detailsArray[i].infoRequestForm.fieldDataType == 'message') {
                            $scope.leadData.infoRequestMessage = detailsArray[i].infoRequestForm.infoRequestFormOptions ? detailsArray[i].infoRequestForm.infoRequestFormOptions[0].optionText : detailsArray[i].value;
                        }
                        detail = {
                            'fieldName': detailsArray[i].infoRequestForm.providerLabel || detailsArray[i].infoRequestForm.fieldLabel,
                            'fieldType': detailsArray[i].infoRequestForm.fieldType,
                            'fieldDataType': detailsArray[i].infoRequestForm.fieldDataType,
                            'fieldValue': detailsArray[i].infoRequestForm.infoRequestFormOptions ? detailsArray[i].infoRequestForm.infoRequestFormOptions[0].optionText : detailsArray[i].value
                        };
                        if(detailsArray[i].infoRequestForm.extra !== 'name' && detailsArray[i].infoRequestForm.extra !== 'phone' && detailsArray[i].infoRequestForm.extra !== 'email' && detailsArray[i].infoRequestForm.extra !== 'weddate' && detailsArray[i].infoRequestForm.extra !== 'message') {
                            if(detail.fieldValue) {
                                details.push(detail);
                            }
                        }
                    }
                }
            }
        }

        return details;
    }

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Get lead messages meta
     */
    $scope.metaSettings = function () {
        if($scope.leadHistoryMeta) {
            if($scope.leadHistoryMeta.outboundNumber) {
                $scope.leadHistoryMeta.outboundNumber = $scope.leadHistoryMeta.outboundNumber ? $scope.leadHistoryMeta.outboundNumber.replace(/^\+90 /, '') : null;
                $scope.leadHistoryMeta.outboundNumberCall = '0' + $scope.leadHistoryMeta.outboundNumber;
            }
            if(!$scope.leadHistoryMeta.canSendEmail) {
                $scope.maxMessageLength = 160;
            }
        }
    };

    $scope.loadLeadHistory = function (leadId) {
        var deferred = $q.defer();
        var urlParams = {
            id: leadId,
            'scopes[]': ['messages', 'calls', 'interactions']
        };

        var loading = Leads.getLeadHistory(
            urlParams,
            function (success) {
                $scope.leadHistory = success.data;
                $scope.leadHistoryMeta = success.meta;
                deferred.resolve();
            },
            function (error) {
                dgNotifications.error(error);
                deferred.reject();
            }
        );

        $scope.loading.push(loading);
        return deferred.promise;
    };

    $scope.loadLeadNotes = function (leadId) {
        var deferred = $q.defer();
        var urlParams = {
            id: leadId,
            'scopes[]': ['notes']
        };

        var loading = Leads.getLeadHistory(
            urlParams,
            function (success) {
                $scope.leadNotes = success.data;
                deferred.resolve();
            },
            function (error) {
                dgNotifications.error(error);
                deferred.reject();
            }
        );

        $scope.loading.push(loading);
        return deferred.promise;
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @param statusType {string} call|callAgain|deal|reject
     * @description
     * Sets the call, callAgain, deal, and reject statuses
     */
    $scope.slide = {
        coupleAppointmentBanner: false,
        coupleProviderBanner: false
    };
    $scope.toggleStatus = function(statusType, appointmentDate, remindAt, interactionId, leadId, lead) {
        var id = leadId ? leadId : $scope.leadData.id,
            leadData = lead || $scope.leadData;
        if(!id) return true;

        var data = {},
            loading,
            method = 'insert',
            params = {};
        var provider = $filter('filter')($scope.providersActive, {id: leadData.provider.id}, true)[0];

        if (statusType === 'offer_pending' || statusType === 'appointment' || statusType === 'offered') {
            data.processState = statusType;
            data.status = 'negotiating';
            params = {
                id: id
            };

            if (!angular.isUndefined(remindAt) && statusType !== 'offered') {
                data.remindAt = remindAt;
            }

            loading = Leads.update(
                params,
                data,
                function (successResponse) {
                    $scope.leadData.status = data.status;
                    $scope.leadData.processState = data.processState;
                    if (statusType !== 'offered') {
                        $scope.closeRemindedAtModal();
                    }

                    dataLayer.push(
                        {
                            event: 'toggleStatus',
                            lead: {
                                id: leadData.id,
                                status: statusType,
                                createdAt: moment(leadData.createdAt).format('YYYY-MM-DD'),
                                organizationDate: moment(leadData.couple.organizationDate).format('YYYY-MM-DD')
                            },
                            provider: {
                                id: provider.id,
                                name: provider.name,
                                category: $scope.getProviderCategory(provider.categoryId),
                                city: $scope.getCity(provider.cityId)
                            },
                            couple: {
                                id: leadData.coupleId
                            }
                        }
                    );
                },
                function (errorResponse) {
                    dgNotifications.error(errorResponse);
                }
            );
            $scope.loading.push(loading);
        } else {
            data.type = statusType;
            params = {
                leadId: id
            };

            if(!angular.isUndefined(appointmentDate)) {
                data.appointmentDate = appointmentDate;
            }

            if (statusType === 'appointment_approval' || statusType === 'appointment_reject') {
                method = 'update';
                params.interactionId = interactionId;
                $scope.slide.coupleAppointmentBanner = true;
            }

            loading = LeadInteractions[method](
                params,
                data,
                function(successResponse) {
                    if(statusType == 'deal' || statusType == 'reject') {
                        $scope.lastInteraction = statusType;
                    }
                    if(statusType == 'callAgain') {
                        $scope.closeAppointmentModal();
                    }

                    if (statusType === 'appointment_approval' || statusType === 'appointment_reject') {
                        getCoupleAppointments(id);
                    } else {
                        $scope.getLeadData();
                    }

                    dataLayer.push(
                        {
                            event: 'toggleStatus',
                            lead: {
                                id: leadData.id,
                                status: statusType,
                                createdAt: moment(leadData.createdAt).format('YYYY-MM-DD'),
                                organizationDate: moment(leadData.couple.organizationDate).format('YYYY-MM-DD')
                            },
                            provider: {
                                id: provider.id,
                                name: provider.name,
                                category: $scope.getProviderCategory(provider.categoryId),
                                city: $scope.getCity(provider.cityId)
                            },
                            couple: {
                                id: leadData.coupleId
                            }
                        }
                    );
                },
                function(errorResponse) {
                    dgNotifications.error(errorResponse);
                    $scope.getLeadData();
                }
            );
            $scope.loading.push(loading);
        }
    };

    $scope.rejection = {};

    $scope.openRejectionModal = function() {
        $scope.rejection = {};
        $scope.rejectionModalInstance = $uibModal.open({
            templateUrl: 'leads/rejection.modal.html',
            scope: $scope,
            size: 'sm',
            backdrop: 'static',
            keyboard: false
        });
    };

    $scope.openRemindedAtModal = function(statusType) {
        $scope.remindAt = {
            statusType: statusType
        };
        $scope.remindedAtModalInstance = $uibModal.open({
            templateUrl: 'leads/appointment.modal.html',
            scope: $scope,
            size: 'md',
            backdrop: 'static',
            keyboard: false
        });
    };

    $scope.setRemindAt = function () {
        var statusType = $scope.remindAt.statusType;
        var date = moment($scope.remindAt.date).format('YYYY-MM-DD');
        var time = $scope.remindAt.hour + ':' + $scope.remindAt.minute + ':00';
        var remindAt = date + ' ' + time;
        $scope.toggleStatus(statusType, null, remindAt);
    };

    $scope.setRejection = function () {
        if(!$scope.rejection.reasonId) return;
        var provider = $filter('filter')($scope.providersActive, {id: $scope.leadData.provider.id}, true)[0];
        var data = {
            type: 'reject',
            rejectReasonId: $scope.rejection.reasonId
        };

        var loading = LeadInteractions.insert(
            {
                leadId: $scope.leadData.id
            },
            data,
            function(successResponse) {
                Analytics.trackEvent('changeNegativeLeadReason', $scope.getConfigItem('info_request_reject_reason_id', $scope.rejection.reasonId), 'success');
                $scope.closeRejectionModal();
                $scope.getLeadData();
                $scope.approveCoupleProvider('reject', $scope.coupleProviderList[0]);
                dataLayer.push(
                    {
                        event: 'toggleStatus',
                        lead: {
                            id: $scope.leadData.id,
                            status: data.type,
                            rejectReasonId: $scope.getConfigItem('info_request_reject_reason_id', $scope.rejection.reasonId),
                            createdAt: moment($scope.leadData.createdAt).format('YYYY-MM-DD'),
                            organizationDate: moment($scope.leadData.couple.organizationDate).format('YYYY-MM-DD')
                        },
                        provider: {
                            id: $scope.leadData.provider.id,
                            name: $scope.leadData.provider.name,
                            category: $scope.getProviderCategory(provider.categoryId),
                            city: $scope.getCity(provider.cityId)
                        },
                        couple: {
                            id: $scope.leadData.coupleId
                        }
                    }
                );
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
                Analytics.trackEvent('changeNegativeLeadReason', $scope.getConfigItem('info_request_reject_reason_id', $scope.rejection.reasonId), 'failed : ' + errorResponse.data.message);
            }
        );
        $scope.loading.push(loading);
    };

    $scope.closeRejectionModal = function() {
        $scope.rejectionModalInstance.dismiss('close');
    };

    $scope.closeRemindedAtModal= function() {
        $scope.remindedAtModalInstance.dismiss('close');
    };

    $scope.openCoupleLeadForm = function() {
        $scope.leadData.leadContact = angular.copy($scope.leadData.leadDetails);

        if($scope.leadData.leadContact.organizationDate) {
            $scope.leadData.leadContact.organizationDate = new Date($scope.leadData.leadContact.organizationDate);
        }

        if ($scope.leadData.leadContact.phone && typeof $scope.leadData.leadContact.phone === 'object') {
            $scope.leadData.leadContact.phone = $scope.leadData.leadContact.phone.number;
        }

        $scope.openModal(
            'leads/couples/form.modal.html',
            'CoupleLeadCtrl',
            $scope.leadData.id,
            null,
            {
                leadContact: $scope.leadData.leadContact,
                leadDetails: $scope.leadData.leadDetails
            }
        ).result.then(function(modalResponse) {
            $scope.leadData.leadDetails.email = modalResponse.email;
            $scope.leadData.leadDetails.name = modalResponse.name;
            $scope.leadData.leadDetails.organizationDate = modalResponse.organizationDate;
            $scope.leadData.leadDetails.phone = modalResponse.phone;
            $scope.leadData.leadDetails.organizationTypeId = modalResponse.organizationTypeId;

            $scope.leadData.leadContact.email = modalResponse.email;
            $scope.leadData.leadContact.name = modalResponse.name;
            $scope.leadData.leadContact.organizationDate = modalResponse.organizationDate;
            $scope.leadData.leadContact.phone = modalResponse.phone;
            $scope.leadData.leadContact.organizationTypeId = modalResponse.organizationTypeId;
        });
    };

    $scope.hideDetails = function (withHeader) {
        $scope.showDetails = false;
        $scope.resetFilters(true);
        if(withHeader) {
            $scope.$root.heading.url = 'leads/header.html';
            $scope.$root.heading.data = $scope.leadStats;
            $scope.$root.heading.scope = {};
        }
    };

    $scope.toggleSearch = function() {
        $scope.showSearch = !$scope.showSearch;
        if($scope.showSearch) {
            $timeout(function() {
                angular.element(document).scrollTop(angular.element(document.querySelector('.leads-banner')).offset().top);
            });
        }
    };

    $scope.getFirstLetters = function (name, number) {
        if(!name) {
            return number;
        } else {
            var tmp = name.substr(0, 2);
            var names = name.split(' ');
            if(names.length > 1) {
                tmp = names[0].substr(0, 1) + names[names.length - 1].substr(0, 1);
            }
            return tmp.toUpperCase();
        }
    };

    $scope.note = {
        isReminder: false
    };
    $scope.appointmentHours = ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
    $scope.appointmentDateOptions = {
        minDate: new Date(),
    };

    $scope.openNoteModal = function() {
        $scope.note = {};
        $scope.noteModalInstance = $uibModal.open({
            templateUrl: 'leads/note.modal.html',
            scope: $scope,
            size: 'md',
            backdrop: 'static',
            keyboard: false
        });
    };

    $scope.setNote = function() {
        if(!$scope.leadData.id) return true;

        var data = {comment: angular.copy($scope.note.text)};
        data.type = 'comment';

        if($scope.note.isReminder) {
            data.type = 'callAgain';
            var date = moment($scope.note.date).format('YYYY-MM-DD');
            var time = $scope.note.hour + ':' + $scope.note.minute + ':00';
            data.appointmentDate = date + ' ' + time;
        }

        var loading = LeadInteractions.insert(
            {
                leadId: $scope.leadData.id
            },
            data,
            function(successResponse) {
                $scope.note.text = '';
                $scope.closeNoteModal();
                $scope.getLeadData();
            },
            function(errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );
        $scope.loading.push(loading);
    };

    $scope.closeNoteModal = function() {
        $scope.noteModalInstance.dismiss('close');
    };

    $scope.getProviderMainImage = function() {
        if(!$scope.leadData.providerId) {
            return false;
        }
        var providerId = $scope.leadData.providerId;

        var loading = Providers.getMainImage(
            {id: providerId},
            function(successResponse) {
                $scope.providerMainImage = successResponse.imageUrl ? successResponse.imageUrl.thumbnail : '';
            },
            function(errorResponse) {
            }
        );

        $scope.loading.push(loading);
    };

    $scope.toggleInteractionButtons = function () {
        $scope.showInteractionButtons =! $scope.showInteractionButtons;
        $scope.toggleStatus('negotiating');
    };

    $scope.openLandingPage = function () {
        var url = '/landing/his.html?customer_id=' + $scope.user.customerId;
        url += '&provider_id=' + $scope.leadData.providerId;
        url += '&customer_user_id=' + $scope.user.id;
        url += '&cid=17';
        window.open(url);
    };

    $scope.printLead = function(type) {
        var links = document.getElementsByTagName("link"),
            leadInfoContent = document.getElementById('lead-info-printable').innerHTML,
            leadMessagesContent  = document.getElementById('lead-messages-printable').innerHTML,
            headContent = '',
            windowContent = '';

        for ( var i = 0; i < links.length; i++ ) {
            if(links[i].getAttribute("rel") == 'stylesheet') {
                headContent += "<link rel=\"stylesheet\" href=\"" + links[i].getAttribute("href") + "\">";
                }
        }

        headContent += "<style>" +
            ".cg-busy { display: none !important; }" +
            ".visible-print { display: block !important; }" +
            ".hidden-print { display: none !important; }" +
            ".lead-details { height: auto !important; }" +
            ".messages-list li { list-style-type: none !important; }" +
            ".messages-list li .well { border: 1px solid #aaa !important; }" +
            "</style>";
        windowContent += "<!DOCTYPE html><html><head>"+ headContent +"</head><body>";
        windowContent += leadInfoContent;
        if(type == 'all') {
            windowContent += "<hr class='gray-hr'>" + leadMessagesContent;
        }
        windowContent += "<script type=\"text/javascript\">window.onload = function () {window.print();}</script>";
        windowContent += "</body></html>";

        var myWindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        myWindow.document.open();
        myWindow.document.write(windowContent);
        myWindow.document.close();
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @param key {string} The key to use when sorting
     * @description
     * get provider items
     */

    function getProviderItems(leadId) {
        var params = {
            id: leadId
        };

        var loading = Leads.getProviderItems(
            params,
            function (successResponse) {
                $scope.providerItemsData = successResponse.data;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    }


    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @param key {string} The key to use when sorting
     * @description
     * Called from view. Sets the properties to sort.
     */
    $scope.sortBy = function(key) {
        if($scope.orderBy.key === key) {
            $scope.orderBy.direction = !$scope.orderBy.direction;
        } else {
            $scope.orderBy.key = key;
            $scope.orderBy.direction = false;
        }

        $scope.loadList();
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Sets the filter form
     */
    $scope.setFilters = function () {
        var data = angular.copy($scope.filters);
        $scope.oldFilters = angular.copy($scope.filters);

        queryString.setFilters(data);

        $scope.loadList();
    };

    /**
     * @ngdoc method
     * @memberof LeadsListCtrl
     * @description
     * Resets the filter form
     */
    $scope.resetFilters = function(oldFilters) {
        if($window.getPromiseStatus($scope.loading) === 0) return true;
        if(oldFilters) {
            if($scope.oldFilters.id) {
                delete $scope.oldFilters.id;
            }
            $scope.filters = angular.copy($scope.oldFilters);
            $scope.oldFilters = {};
        } else {
            $scope.filters = {
                createdAtStart: moment().add(-3, 'month').add(1, 'day').format('YYYY-MM-DD'),
                createdAtEnd: moment().format('YYYY-MM-DD')
            };
        }
        queryString.setFilters($scope.filters);
        $route.reload();
    };

    $scope.$watch('filters.page', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if(!angular.isUndefined(oldValue)) {
                $scope.loadList();
            }
        }
    });

    $scope.$watch("filters['providerIds[]']", function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            var params = {
                'providerIds[]': [newValue]
            };
            $scope.loadResponseTime(params);
        }
    });

    $scope.$watch('leadStats', function (newValue, oldValue) {
        $scope.$root.heading.data = newValue;
    });

    $scope.$watch('user.id', function(userId) {
        if(userId && !$routeParams.id) {
            getCoupleProviders('pending', $scope.user.providerIds);
        }
    }, true);

    $scope.incomingLeadsList = [];
    $scope.$watch('newLead' ,function (newItem, oldItem) {
        if (newItem && newItem !== oldItem) {
            $scope.incomingLeadsList.push(newItem);
        }
    });

    $scope.$watch('firebaseData.leadMessage', function (newItem) {
        if (newItem) {
            $scope.leadActivities.push(newItem);
        }
    });

    $scope.loadResponseTime();

    $scope.getLeadData = function(item) {
        var leadId;
        if (item) {
            leadId = item.id || item.leadId;
        }

        if ($routeParams.id) {
            leadId = $routeParams.id;
            $location.hash('');
        }

        $q.all([
            $scope.getLead(leadId),
            $scope.loadLeadHistory(leadId),
            $scope.loadLeadNotes(leadId)
        ]).then(function() {
            $scope.getProviderMainImage();
            $scope.metaSettings();
        });

    };

    $scope.spamCallReport = function () {
        var params = {
            id: $scope.leadData.id,
            status: 'spamCall'
        };

        var loading = Leads.update(
            params,
            function (successResponse) {
                dgNotifications.success($translate.instant('LEADS.SPAM_CALL_REPORTED'));
                $scope.leadData.status = 'spamCall';
                $scope.showInteractionButtons = true;
            },
            function (errorResponse) {
                dgNotifications.error(errorResponse);
            }
        );

        $scope.loading.push(loading);
    };

    $scope.attrs = {
        leads: [
            {name: 'data-track', value: 'couple-tabs'},
            {name: 'data-item', value: 'leads'}
        ],
        'couple-appointment': [
            {name: 'data-track', value: 'couple-tabs'},
            {name: 'data-item', value: 'couple-appointment'}
        ],
        'couple-deal': [
            {name: 'data-track', value: 'couple-tabs'},
            {name: 'data-item', value: 'couple-deal'}
        ]
    };

    $scope.tabSelect = function (tab) {
        $location.hash(tab);
        $scope.listActiveTabIndex = $scope.listTabs[tab].index;
        $scope.listActiveTab = tab;
    };
    $scope.tabSelect('leads');

    if($routeParams.id) {
        $scope.getLeadData();
    }

    $scope.$root.heading.url = 'leads/header.html';
    $scope.$root.heading.name = $translate.instant($route.current.label);
    $scope.$root.heading.data = {};
}

LeadsListCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$route',
    '$routeParams',
    '$window',
    '$timeout',
    '$q',
    '$uibModal',
    '$filter',
    'dgNotifications',
    'queryString',
    'moment',
    'Analytics',
    '$translate',
    '$location',
    'Providers',
    'Leads',
    'LeadInteractions',
    'MailTemplates',
    'AttachmentsPopups',
    'CustomerUsers',
    'Statistics'
];

angular.module('dugun.leads')
    .controller('LeadsListCtrl', LeadsListCtrl);
