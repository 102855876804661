function ItemPopup(dgPopup) {
    var service = {};

    service.openForm = function(id, parentId, data) {
        return dgPopup.openModal({
            templateUrl: 'items/form.html',
            controller: 'ItemsCtrl',
            itemId: id || null,
            parentId: parentId || null,
            data: data || {}
        });
    };

    return service;
}

ItemPopup.$inject = [
    'dgPopup'
];

angular.module('dugun.items')
    .factory('ItemPopup', ItemPopup);
