(function(module) {
try {
  module = angular.module('dugun.provider.templates');
} catch (e) {
  module = angular.module('dugun.provider.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('layout/navbar.html',
    '<div class="navbar-inner"><div class="navbar-container"><div class="navbar-header logo"><a href="/home" class="navbar-brand"><small><img src="{{environment.currentLanguage.headerLogo}}" alt=""></small></a></div><div class="sidebar-toggler" ng-if="!limitedAccess"></div><div class="navbar-header"><div class="navbar-account"><ul class="account-area"><li ng-if="environment.fieldsSettings.languages"><button class="btn btn-sm margin-top-5 margin-right-5 bold-text" ng-repeat="lang in environment.languages.cases" ng-click="changeLanguage(lang.code)" ng-class="{\n' +
    '                                \'btn-success\': cookieLang === lang.code || (!cookieLang && lang.code === \'ar\')\n' +
    '                            }">{{ lang.title }}</button></li><li><a class="login-area dropdown-toggle no-padding" data-toggle="dropdown"><section><span class="h5 profile no-padding no-margin"><span ng-bind="$parent.user.customer.name"></span></span><div><span class="h6 no-padding no-margin" ng-bind="$parent.user.name"></span></div></section><div class="avatar" style="margin: 0;height: 24px;border: none"><i class="icon fa fa-sort-down" style="line-height: 24px"></i></div></a><ul class="pull-right dropdown-menu dropdown-arrow dropdown-login-area"><li class="username"><a ng-bind="$parent.user.customer.name"></a></li><li class="edit"><a ng-click="logout()" dg-confirm="{{ \'LAYOUT.NAVBAR.LOGOUT_CONFIRMATION_QUESTION\' | translate }}" class="pull-right"><i class="menu-icon fa fa-sign-out-alt"></i> {{ \'LAYOUT.NAVBAR.EXIT\' | translate }} </a><a ng-click="openProfileModal()" ng-if="!limitedAccess" class="pull-left"><i class="menu-icon fa fa-cog"></i> {{ \'LAYOUT.NAVBAR.SETTINGS\' | translate }}</a></li></ul></li></ul></div></div></div></div>');
}]);
})();
