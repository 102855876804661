/**
 * MailTemplates factory
 * @ngdoc factory
 * @memberof dugun.mailTemplates
 * @name MailTemplates
 * @requires dugun.core:resource
 *
 * @returns {service} MailTemplates resource service
 */
function MailTemplates(resource) {
    var url = 'customers/:customerId/templates/:templateId';

    var resourceService = resource(
        url,
        {
            customerId: '@customerId',
            templateId: '@templateId'
        },
        {
            /**
             * @ngdoc method
             * @memberof MailTemplates
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get MailTemplates list
             */
            query: {
                isArray: false
            },
            /**
             * @ngdoc method
             * @memberof MailTemplates
             * @name update
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * insert mail template
             */
            insert: {
                method: 'POST'
            },
            /**
             * @ngdoc method
             * @memberof MailTemplates
             * @name update
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * update mail template
             */
            update: {
                method: 'PUT'
            },
            /**
             * @ngdoc method
             * @memberof MailTemplates
             * @name delete
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * delete mail template
             */
            delete: {
                method: 'DELETE',
            },
            /**
             * @ngdoc method
             * @memberof MailTemplates
             * @name delete
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * delete mail template attachment
             */
            deleteAttachment: {
                url: url + '/attachments/:attachmentId',
                method: 'DELETE',
            }
        }
    );

    return resourceService;
}

MailTemplates.$inject = [
    'resource'
];

angular.module('dugun.mailTemplates')
    .factory('MailTemplates', MailTemplates);
