
/**
 * @constructor
 * @ngdoc controller
 * @memberof dugun.galleries.media.upload
 * @name MediaUploadCtrl
 *
 * @requires $scope
 * @requires $routeParams
 * @requires $window
 * @requires ui.bootstrap.modal:$uibModalInstance
 * @requires $timeout
 * @requires dugun.notifications:dgNotifications
 * @requires Analytics
 * @requires MediaUpload
 *
 * @param itemId
 */
function MediaUploadCtrl(
    $scope, $routeParams, $window, $uibModalInstance, $timeout, $translate, dgNotifications, Analytics, MediaUpload,
    itemId
) {

    $scope.providerId = $window.parseInt($routeParams.providerId) || null;
    $scope.galleryId = itemId;
    $scope.list = [];

    /**
     * @ngdoc method
     * @memberof MediaUploadCtrl
     * @description
     * Closes the modal
     */
    $scope.close = function() {
        $uibModalInstance.close();
    };

    /**
     * @ngdoc method
     * @memberof MediaListCtrl
     * @description
     * Uploads the selected images
     */
    $scope.uploadImages = function($files) {
        angular.forEach($files, function(file) {
            file.upload = MediaUpload.upload(file, {galleryId: $scope.galleryId});
            file.progress = 0;

            file.upload.then(
                function(successResponse) {
                    $timeout(function() {
                        file.result = successResponse.data;
                        Analytics.trackEvent('uploadImageForm', 'upload', 'success');
                        dataLayer.push({
                            event: 'addImages',
                            addedImagesCount: $files.length
                        });
                        dgNotifications.success($translate.instant('GALLERIES.UPLOAD_FORM.UPLOADED_SUCCESSFULLY'));
                    });
                },
                function(errorResponse) {
                    dgNotifications.error(errorResponse);
                    Analytics.trackEvent('uploadImageForm', 'upload', 'failed : ' + errorResponse.data.message);
                },
                function(event) {
                    file.progress = (event.loaded / event.total) * 100;
                }
            );

            $scope.list.push(file);
        });
    };
}

MediaUploadCtrl.$inject = [
    '$scope',
    '$routeParams',
    '$window',
    '$uibModalInstance',
    '$timeout',
    '$translate',
    'dgNotifications',
    'Analytics',
    'MediaUpload',
    'itemId',
];

angular.module('dugun.galleries.media.upload')
    .controller('MediaUploadCtrl', MediaUploadCtrl);
