angular.module('dugun.core')
    .factory('DgnSelect2', ['$http', DgnSelect2]);

function DgnSelect2($http) {
    var self = {};

    self.providers = function(searchText, filters, options) {
        if(!searchText) {
            self.providersResult = [];
            return;
        }

        filters = filters || {};
        filters.providerName = searchText;

        $http
            .get('search/provider', {params: filters})
            .then(function(response) {
                self.providersResult = response.data.data;

                if(options && options.createOption && self.providersResult.length === 0) {
                    self.providersResult.push({
                        name: searchText,
                        id: searchText
                    });
                }
            });
    };

    self.couples = function(filters, options) {
        filters = filters || {};

        for(var i in filters) {
            if(filters[i] === 0 || filters[i] === '') {
                delete filters[i];
            }
        }

        if(angular.equals(filters, {})) {
            self.couplesResult = [];
            return;
        }

        $http
            .get('search/couple', {params: filters})
            .then(function(response) {
                self.couplesResult = response.data.data;
            });
    };

    self.providersResult = [];
    self.couplesResult = [];

    return self;
}
