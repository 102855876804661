/**
 * GalleryCategoryInfoTags factory
 * @ngdoc factory
 * @memberof dugun.galleries.media.infoTags
 * @name GalleryCategoryInfoTags
 *
 * @requires $q
 * @requires dugun.core:resource
 *
 * @returns {service} GalleryCategoryInfoTags resource service
 */
function GalleryCategoryInfoTags($q, resource) {
    var self = this,
        service = {};

    self.url = 'gallery-category-info-tag';
    self.loading = $q.defer();
    self.items = [];
    self.itemsByCategory = {};
    self.itemsById = {};
    self.initialized = false;

    self.resourceService = resource(
        self.url,
        {
            id: '@id'
        },
        {
            /**
             * @ngdoc method
             * @memberof GalleryCategoryInfoTags
             * @name query
             * @param params {Object=} Parameters to send in url
             * @param successCallback {callback} Called if request is successful
             * @param errorCallback {callback} Called if request has error
             * @description
             * Get list
             */
            query: {
                isArray: false
            }
        }
    );

    /**
     * @ngdoc method
     * @memberof GalleryCategoryInfoTags
     * @description
     * description
     */
    service.init = function() {
        if(self.initialized && self.items === 0) {
            return self.loading.promise;
        } else {
            if(self.initialized) {
                self.loading = $q.defer();
            }

            self.initialized = true;
        }

        self.resourceService.query(
            null,
            self.initSuccess,
            self.initError
        );

        return self.loading.promise;
    };

    /**
     * @ngdoc method
     * @memberof GalleryCategoryInfoTags
     * @param galleryCategoryId {integer} Related gallery category id
     * @description
     * Gets an array of related info tags of given galleryCategoryId
     */
    service.getCategoryInfoTags = function(galleryCategoryId) {
        return self.itemsByCategory[galleryCategoryId] || [];
    };

    /**
     * @ngdoc method
     * @memberof GalleryCategoryInfoTags
     * @param infoTagId {integer}
     * @description
     * Returns the info tag
     */
    service.getInfoTag = function(infoTagId) {
        return self.itemsById[infoTagId] || null;
    };

    /**
     * @ngdoc method
     * @memberof GalleryCategoryInfoTags
     * @private
     * @description
     * Success callback of init
     */
    self.initSuccess = function(response) {
        var item;

        self.items = response.data;
        self.itemsByCategory = [];
        self.itemsById = [];

        for(var i in self.items) {
            item = self.items[i];

            if(!angular.isArray(self.itemsByCategory[item.galleryCategoryId])) {
                self.itemsByCategory[item.galleryCategoryId] = [];
            }

            self.itemsByCategory[item.galleryCategoryId].push(item);
            self.itemsById[item.id] = item;
        }

        self.loading.resolve(self.items);
    };

    /**
     * @ngdoc method
     * @memberof GalleryCategoryInfoTags
     * @private
     * @description
     * Error callback of init
     */
    self.initError = function(response) {
        self.loading.reject(response);
    };

    return service;
}

GalleryCategoryInfoTags.$inject = [
    '$q',
    'resource',
];

angular.module('dugun.galleries.media.infoTags')
    .factory('GalleryCategoryInfoTags', GalleryCategoryInfoTags);
